import { useCallback, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { setInventories } from "../../../../../../redux/actions/inventory.action";
import inventoriesServices from "../../../../../../Services/inventories.services";

function useTopup(inventoryId, onClose, manual) {
  const initState = {
    inventory: inventoryId,
    qty: null,
    isLoading: false,
  };

  const { kitchenId } = useParams();
  const [state, dispatch] = useReducer(
    (productState, value) => ({ ...productState, ...value }),
    initState
  );
  const { inventories } = useSelector((state) => state.inventory);

  const reduxDispatch = useDispatch();
  const myObjectString = localStorage.getItem("user");

  const currentUser = JSON.parse(myObjectString);
  // const kitchenId = currentUser?.kitchen_id;
  console.log(currentUser)

  const getInventories = useCallback(() => {
    inventoriesServices
      .getLocationInventories(kitchenId)
      .then(({ data }) => {
        setInventories(data?.products)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
    // .finally(() => setIsLoading(false)(reduxDispatch));
  }, [kitchenId, reduxDispatch]);

  useEffect(() => {
    getInventories();
  }, [getInventories])


  const handleSubmit = (event) => {
    event.preventDefault();
    if (state.qty) {
    if(manual) {
      dispatch({ isLoading: true });
      inventoriesServices
      .resetInventory(kitchenId, inventoryId, {
        inventory: {
            quantity: state?.qty
        },
      })
      .then(() => {
        toast.success("Inventory has been manually changed");
        dispatch({ isLoading: false });
        onClose();
        getInventories();
      })
      .catch((err) => {
        dispatch({ isLoading: false });
        toast.error(err.message);
      })

    } 
    else {
      dispatch({ isLoading: true });
    inventoriesServices
      .topUpInventory(kitchenId, inventoryId, {
        inventory: {
            quantity: state?.qty
        },
      })
      .then(() => {
        toast.success("Inventory has been topped up");
        dispatch({ isLoading: false });
        onClose();
        getInventories();
      })
      .catch((err) => {
        dispatch({ isLoading: false });
        toast.error(err.message);
      })
     } 
    } else {
       toast.error("Add quantity")
    }
  };

  return {
    handleSubmit,
    inventories,
    state,
    dispatch,
  };
}

export default useTopup;
