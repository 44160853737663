import React from "react";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import styles from "./DeleteModal.module.scss";

const DeleteModal = ({ onClose, title, handleDelete, isDeleting, modal2, text, text2 }) => {

  return (
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
          </div>
          { modal2 ?  (<div className={styles.modalContent}>
            {text}
          </div>)
          : (<div className={styles.modalContent}>
            {`Are you sure you want to delete this ${title}?`}
          </div>)}
          <div className={styles.modalActions}>
            <div className={styles.actionsContainer}>
              <button className={!modal2 ? styles.deleteBtn : styles.changeBtn} onClick={handleDelete}>
               {isDeleting ? (
                  <LoadingSpinner />
               ) : (modal2 ? text2 : "Delete") }
              </button>
              <button
                className={styles.cancelBtn}
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
  );
};

export default DeleteModal;
