import {
  SET_CATEGORIES,
  SET_CATEGORY,
  SET_CATEGORY_ID,
} from "../actionTypes/categoriesActionType";

const initialState = {
  categories: [],
  category: [],
  categoryId: null,
};

export default function categoryReducer(state = initialState, action) {
  const { data, type } = action;
  switch (type) {
    case SET_CATEGORIES:
      return {
        ...state,
        categories: data,
      };
    case SET_CATEGORY:
      return {
        ...state,
        category: data,
      };
    case SET_CATEGORY_ID:
      return {
        ...state,
        categoryId: data,
      };
    default:
      return state;
  }
}
