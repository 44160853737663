import { Navigate } from 'react-router-dom'
import { useMemo } from 'react';

export default function PrivateRoute({ children }){

    const loginToken = localStorage.getItem('token');

    const isLoggedIn = useMemo(() => (
        Boolean(loginToken)
    ), [loginToken])

    return isLoggedIn ? children : <Navigate to="/" replace />;
}