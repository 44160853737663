import React from "react";
import Card from "../../../../UI/Card/Card";
import {
  NumberInput,
  SelectInput,
  TextInput,
} from "../../../../UI/FormComponents/Input";
import FormGroupHeader from "../../../../UI/FormComponents/FormHeaders/FormGroupHeader";
import styles from "../../AddDeliveryAreaForm.module.scss";
import Button from "../../../../UI/Button/Button";

const FormLHS = ({ region, state, dispatch, edit, handleSubmit }) => {
  const { name, regionId, dawnPrice, duskPrice, nightPrice, dayPrice, isLoading } = state;
  return (
    <Card classes={styles["form-6grid-main"]}>
      <FormGroupHeader
        title="Area Information"
        description="General information about this Area"
      />
      <TextInput
        name="Area_name"
        label="name"
        type="text"
        value={name}
        onChange={({ target }) => dispatch({name: target.value})}
      />
      <SelectInput
        name="available_region"
        label="Available Region"
        options={region}
        value={regionId}
        onChange={(e) => dispatch({ regionId: e.target.value })}
      />
      <NumberInput
        name="day_price"
        label="Day Price"
        type="number"
        value={dayPrice}
        onChange={(e) => dispatch({ dayPrice: e.target.value })}
      />
      <NumberInput
        name="dusk_price"
        label="Dusk Price"
        type="number"
        value={duskPrice}
        onChange={(e) => dispatch({ duskPrice: e.target.value })}
      />
      <NumberInput
        name="night_price"
        label="Night Price"
        type="number"
        value={nightPrice}
        onChange={(e) => dispatch({ nightPrice: e.target.value })}
      />
      <NumberInput
        name="dawn_price"
        label="Dawn Price"
        type="number"
        value={dawnPrice}
        onChange={(e) => dispatch({ dawnPrice: e.target.value })}
      />
      <div className={styles["btn-div"]}>
        <Button
          name="Area"
          bgColor="green-bg"
          createFnc={handleSubmit}
          edit={edit}
          isLoading={isLoading}
        />
      </div>
    </Card>
  );
};

export default FormLHS;
