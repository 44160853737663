import React from "react";
import Sidebar from "../UI/Sidebar/Sidebar";
import Grid from "../UI/Grid/Grid";
import GridRow from "../UI/Grid/GridRow";
import TopHeader from "../UI/TopHeader/Main";
import styles from "./Base.module.scss";
import { Outlet } from "react-router";
import usePopRoutes from "../../utils/hooks/usePopRoutes";
import AddModal from "../UI/Popup";
import { useState } from "react";
import LocationSidebar from "../UI/LocationSideBar/LocationSidebar";

const Base = () => {
  usePopRoutes()
  const [openModal, setOpenModal] = useState(false); 
  const myObjectString = localStorage.getItem("user");

  const currentUser = JSON.parse(myObjectString);
  const sideBarType = currentUser?.restricted_by_location;

  return (
    <div className={styles.main} onClick={() => setOpenModal(false)}>
      <AddModal />
      <Grid span="12">
        <GridRow span="3">
          {
            sideBarType ? <LocationSidebar /> : <Sidebar />
          }
        </GridRow> 
        <GridRow span="9" customClass="main-section">
          <TopHeader openModal={openModal} setOpenModal={setOpenModal} />
          <div className={styles["main-inner"]}><Outlet /></div>
        </GridRow>
      </Grid>
    </div>
  );
};

export default Base;
