import { useCallback, useEffect, useMemo, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { setCustomer } from "../../../../redux/actions/customer.Action";
import { showPopup } from "../../../../redux/actions/popUp.action";
import { paths } from "../../../../Routes/path";
import customersServices from "../../../../Services/customer.services";
import { isValidEmail, objectToArray } from "../../../../utils/helpers";
import useCustomer from "./useCustomer";

function useAddCustomer(edit, view) {
  const { getCustomers } = useCustomer();
  const { customer } = useSelector((state) => state.customer);
  const { ispopupOpen } = useSelector((state) => state.popup)
  const initState = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    receiveEmail: true,
    terms: true,
    isLoading: false,
  };

  const { customerId } = useParams();
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();

  const myObjectString = localStorage.getItem('user');

  const currentUser = JSON.parse(myObjectString)
  const currentUserAbilities = currentUser?.account_abilities?.abilities


  const [state, dispatch] = useReducer(
    (customerState, value) => ({ ...customerState, ...value }),
    initState
  );

  useEffect(() => {
    if (edit || view) {
      dispatch({
        firstName: customer?.first_name,
        lastName: customer?.last_name,
        email: customer?.email,
        phone: customer?.phone,
      });
    }
  }, [customer, edit, view]);

  const handleForm = (e) => {
    if (!state?.firstName) {
      toast.error("First Name cannot be empty")
      return ;
    }
    if (!state?.lastName) {
      toast.error("Last Name cannot be empty")
      return ;
    }
    if (!state?.email) {
      toast.error("Email cannot be empty")
      return ;
    }
    if (!(isValidEmail(state.email))) {
      toast.error("Email is Invalid")
      return ;
    }
    if (!state?.phone) {
      toast.error("Phone cannot be empty")
      return ;
    }
    handleSubmit(e);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!edit) {
      dispatch({ isLoading: true})
      customersServices
        .createCustomer({
          user: {
            first_name: state?.firstName,
            last_name: state?.lastName,
            email: state?.email,
            phone: state?.phone,
            receive_email: state?.receiveEmail,
            terms: state?.terms,
          },
        })
        .then(() => {
          toast.success("Customer was succesfully created");
          dispatch({ isLoading: false})
          reduxDispatch(showPopup({ispopupOpen: false}))
          navigate(paths.customers)
          getCustomers(1, 10);
        })
        .catch((err) => {
          dispatch({ isLoading: false})
          if (err.response.status === 422) {
            let errorMsg = objectToArray(err?.response?.data?.data);
            toast.error(`${errorMsg[0][0]} ${errorMsg[0][1][0]}`);
          } else {
            toast.error(err.message);
          }
        });
    } else {
      dispatch({ isLoading: true})
      customersServices
        .updateCustomer(customerId, {
          user: {
            first_name: state?.firstName,
            last_name: state?.lastName,
            email: state?.email,
            phone: state?.phone,
            receive_email: state?.receiveEmail,
            terms: state?.terms,
          },
        })
        .then(() => {
          dispatch({ isLoading: false})
          toast.success("Customer was succesfully updated");
          navigate(paths.customers)
          reduxDispatch(showPopup({ispopupOpen: false}))
          getCustomers(1, 10);
        })
        .catch((err) => {
          dispatch({ isLoading: false})
          if (err.response.status === 422) {
            let errorMsg = objectToArray(err?.response?.data?.data);
            toast.error(`${errorMsg[0][0]} ${errorMsg[0][1][0]}`);
          } else {
            toast.error(err.message);
          }
        });
    }
  };

  const getACustomer = useCallback(() => {
    customersServices
      .getACustomer(customerId)
      .then(({ data }) => {
        setCustomer(data)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
  }, [customerId, reduxDispatch]);


  useEffect(() => {
    if ((edit || view ) && ispopupOpen) {
      getACustomer();
    }
  }, [edit, getACustomer, ispopupOpen, view]);

  const pageTitle = useMemo(() => {
      if (edit) {
        return "Update Customer"
      }
      if (view) {
        return "View Customer"
      }
      if (!edit && !edit) {
        return "Add New Customer"
      }
  }, [edit, view])

  return {
    state,
    handleForm,
    dispatch,
    pageTitle,
    currentUserAbilities,
  };
}

export default useAddCustomer;
