import React from "react";
import styles from "./Popup.module.scss";

const AddModal = ({
  ispopupOpen,
  popupComponent,
  showPopup,
  onClose,
  type2,
  type3,
}) => {
  //function
  function closePopup() {
    showPopup(false);
    onClose?.();
  }
  return type3 ? (
    <div
      onClick={closePopup}
      className={`${styles.popupRootType3} ${
        !ispopupOpen ? styles.popupOpen : undefined
      }`}
    >
      <span
        onClick={(event) => event.stopPropagation()}
        className={styles.childComponentType3}
      >
        {popupComponent}
      </span>
    </div>
  ) : (
    <div
      onClick={closePopup}
      className={`${type2 ? styles.popupRootCenter : styles.popupRoot} ${
        !ispopupOpen ? styles.popupOpen : undefined
      }`}
    >
      <span
        onClick={(event) => event.stopPropagation()}
        className={
          type2 ? styles.childComponentCentered : styles.childComponent
        }
      >
        {popupComponent}
      </span>
    </div>
  );
};

export default AddModal;
