import React from "react";
import MetaTags from "react-meta-tags";
import Grid from "../../UI/Grid/Grid";
import GridRow from "../../UI/Grid/GridRow";
import FormLHS from "./component/FormGroups/FormLHS";
import FormRHS from "./component/FormGroups/FormRHS";
import TopDisplay from "../../UI/TopDisplay/TopDisplay";
import styles from "./AddStockForm.module.scss";
import stockstyles from "./Stocks.module.scss";
import useAddStock from "./hooks/useAddStock";

const AddStockForm = ({ edit }) => {
  const { state, handleSubmit, dispatch, inventories } = useAddStock(edit);
  return (
    <>
      <MetaTags>
        <title>Add Stock Page</title>
        <meta name="description" content="#" />
      </MetaTags>
      <div className={stockstyles.main}>
        <TopDisplay
          title={edit ? "Update Stock" : "Add New Stock"}
          firstText="Kitchen"
          secondText="Stock"
          thirdText={edit ? "Update Stock" : "Add New Stock"}
          style2
        />
        <div className={styles["form-main"]}>
          <Grid span="9">
            <GridRow span="6" customClass="section2">
              <FormLHS
                edit={edit}
                state={state}
                dispatch={dispatch}
                inventories={inventories}
              />
            </GridRow>
            <GridRow span="3" customClass="section2">
              <FormRHS
                edit={edit}
                state={state}
                handleSubmit={handleSubmit}
                dispatch={dispatch}
              />
            </GridRow>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default AddStockForm;
