import {
  SET_PRODUCTS,
  SET_PRODUCT,
  SET_PRODUCT_ID,
  SET_PRODUCT_PRICES,
} from "../actionTypes/productActionType";

export function setProducts(data) {
  return (dispatch) => {
    dispatch({ type: SET_PRODUCTS, data });
  };
}

export function setProduct(data) {
  return (dispatch) => {
    dispatch({ type: SET_PRODUCT, data });
  };
}

export function setProductId(data) {
  return (dispatch) => {
    dispatch({ type: SET_PRODUCT_ID, data });
  };
}

export function setProductPrices(data) {
  return (dispatch) => {
    dispatch({ type: SET_PRODUCT_PRICES, data });
  };
}
