import React from "react";
import Card from "../../../../UI/Card/Card";
import { TextInput, SelectInput, NumberInput } from "../../../../UI/FormComponents/Input";
import FormGroupHeader from "../../../../UI/FormComponents/FormHeaders/FormGroupHeader";
import styles from "../../AddStockForm.module.scss";
import { stateOptions } from "../../../../../utils/constant";
import AddItemArray from "../../../../UI/AddItemArray/AddItemArray";

const FormLHS = ({ state, dispatch, inventories }) => {
  const { alertLevel, state: stockState, name, units } = state;
  return (
    <Card classes={styles["form-6grid-main"]}>
      <FormGroupHeader
        title="Stock Information"
        description="General information about this Stock"
      />
      <TextInput
        name="item"
        label="Item"
        type="text"
        value={name}
        onChange={(e) => dispatch({ name: e.target.value })}
      />
      <SelectInput
        name="stock_state"
        label="Stock State"
        options={stateOptions}
        value={stockState}
        onChange={(e) => {
          dispatch({units: e.target.selectedOptions[0].dataset.unit})
          dispatch({ state: e.target.value })
        }}
      />
      <NumberInput
        name="alert_level"
        label="Alert level"
        type="number"
        value={alertLevel}
        units={units}
        onChange={(e) => dispatch({ alertLevel: e.target.value })}
      />
      <div className={styles["inventory-div"]}>
        <FormGroupHeader
          title="Inventories"
          description="quantity of each inventory that makes up a portion of this product"
        />
        <AddItemArray type="inventory" inventoryOptions={inventories} />
      </div>
    </Card>
  );
};

export default FormLHS;
