import React from "react";
import AddItem from "../../../../UI/AddItem/AddItem";
import Modal from "../../../../UI/Modal/Modal";
import { ActionMenuIcon } from "../../../../UI/RegularIcons";
import Tables from "../../../../UI/Tables/Tables";
import styles from "./DTable.module.scss";
import useDTable from "./hooks/useDTable";

function DTable({
  handlePageChange,
  handlePerRowsChange,
  customers,
  totalRows,
  onClickAction,
  onAddCustomer,
  currentUserAbilities,
}) {
  
  const {
    onDelete,
    isLoading,
    handleEdit,
    handleView,
    onClick,
    selected,
    openModal,
    setOpenModal,
    onRowClicked,
    del,
    update,
    create,
    show,
  } = useDTable(onClickAction, currentUserAbilities);
  const columns = [
    {
      selector: (row) => (
        <div className={styles.imageDiv}>
          <img
            src={row?.avatar.url}
            alt={row?.first_name}
            className={styles.image}
          />
        </div>
      ),
      width: "7%",
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      name: "Name",
      selector: (row) => `${row.first_name} ${row.last_name}`,
      sortable: true,
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      name: "Status",
      selector: (row) => (row?.active ? "active" : "inactive"),
      sortable: true,
      width: "17%",
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      selector: (row, i) => (
        <>
          <div className={styles["circle-icon"]} onClick={() => onClick(i)}>
            <ActionMenuIcon />
          </div>
          {openModal && i === selected && (
            <Modal
              openModal={openModal}
              onClose={() => setOpenModal(false)}
              viewFn={() => handleView(row?.id)}
              editFn={() => handleEdit(row?.id)}
              deleteFn={() => onDelete(row?.id, row?.name)}
              archive
              del={del}
              update={update}
              show
              secondText="View"
            />
          )}
        </>
      ),
      sortable: true,
      width: "5%",
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
  ];

  return (
    <div className={styles.divv}>
      <Tables
        data={customers}
        columns={columns}
        pagination
        title="All Customers"
        atcBtn={create ? <AddItem name="Customer" addFnc={() => onAddCustomer()} /> : ""}
        firstRow
        fetchingData={isLoading}
        paginationServer
        handlePageChange={handlePageChange}
        handlePerRowsChange={handlePerRowsChange}
        totalRows={totalRows}
        onRowClicked={show ? (row) => onRowClicked(row) : () => {}}
      />
    </div>
  );
}

export default DTable;
