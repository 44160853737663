import { API_URL } from "../env";
import { request } from "./api";

const URL = {
  getCustomerData: `${API_URL}api/v1/be/dashboard/customers_data`,
  getOrderData: `${API_URL}api/v1/be/dashboard/orders_data`,
  getCashFlowData: `${API_URL}api/v1/be/dashboard/cash_flow`,
  getRecentOrders: `${API_URL}api/v1/be/dashboard/orders/recent`,
  getPendingOrders: `${API_URL}api/v1/be/dashboard/orders/pending`,
  getUserStatistics: `${API_URL}api/v1/be/dashboard/users/stats`,
  getAllPayments: (page, perPage) => `${API_URL}api/v1/be/payments?page=${page}&per_page=${perPage}`,
  getLogs: (page, perPage) => `${API_URL}api/v1/be/activities?page=${page}&per_page=${perPage}`
};

function getCustomerData() {
  return request(URL.getCustomerData, "get")
}

function getOrderData() {
    return request(URL.getOrderData, "get")
  }

  function getCashFlowData() {
    return request(URL.getCashFlowData, "get")
  }

  function getRecentOrder() {
    return request(URL.getRecentOrders, "get")
  }

  function getPendingOrder() {
    return request(URL.getPendingOrders, "get")
  }

  function getUserStatistics() {
    return request(URL.getUserStatistics, "get")
  }

  function getAllPayments(page, perPage) {
    return request(URL.getAllPayments(page, perPage), "get");
  }

  function getLogs(page, perPage) {
    return request(URL.getLogs(page, perPage), "get");
  }


const dashboardServices = {
  getCustomerData,
  getOrderData,
  getCashFlowData,
  getRecentOrder,
  getPendingOrder,
  getUserStatistics,
  getAllPayments,
  getLogs
}

export default dashboardServices;
