import {
  SET_INVENTORIES,
  SET_INVENTORY,
  SET_INVENTORY_ID,
  SET_INVENTORY_ITEMS,
} from "../actionTypes/inventoryActionType";

const initialState = {
  inventoryItems: [
    {
      inventory_name: null,
      quantity: null,
      unit: "",
    },
  ],
  inventories: [],
  inventory: [],
  inventoryId: null,
};

export default function inventoryReducer(state = initialState, action) {
  const { data, type } = action;
  switch (type) {
    case SET_INVENTORY_ITEMS:
      return {
        ...state,
        inventoryItems: data,
      };
    case SET_INVENTORIES:
      return {
        ...state,
        inventories: data,
      };
    case SET_INVENTORY:
      return {
        ...state,
        inventory: data,
      };
    case SET_INVENTORY_ID:
      return {
        ...state,
        inventoryId: data,
      };
    default:
      return state;
  }
}
