import React from "react";
import styles from "./ToggleSwitch.module.scss";

function ToggleSwitch({ selected, toggleSelected, title }) {
  return (
    <div className={styles["toggle-box"]}>
      <div
        className={`${styles["toggle-container"]} ${
          styles[selected ? "" : "toggle-container-disabled"]
        }`}
        onClick={toggleSelected}
      >
        <div
          className={`${styles["dialog-button"]} ${
            styles[selected ? "" : "disabled"]
          }`}
        ></div>
      </div>
      <div className={styles.title}>{title}</div>
    </div>
  );
}

export default ToggleSwitch;
