import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { paths } from "../../../../../../Routes/path";

function useDTable(onClickAction, currentUserAbilities) {
  const { kitchenZones, kitchenRegions: region, kitchenLocations } = useSelector((state) => state.kitchen);
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const onClick = (value) => {
    setOpenModal(true);
    setSelected(value);
  };
  const isLoading = useSelector((state) => state.app.isLoading);
  const navigate = useNavigate();

  const handleEdit = (kitchenId) => {
    navigate(paths.editKitchen.replace(":kitchenId", kitchenId));
  };

  const onDelete = (kitchenId) => {
    onClickAction(kitchenId);
  };

  const update = currentUserAbilities?.update;
  const create = currentUserAbilities?.create;
  const show = currentUserAbilities?.show;

  const del = currentUserAbilities?.destroy;

  return {
    onClick,
    selected,
    openModal,
    setOpenModal,
    isLoading,
    onDelete,
    region,
    kitchenLocations,
    kitchenZones,
    handleEdit,
    del,
    update,
    create,
    show,
  };
}

export default useDTable;
