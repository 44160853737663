import React from "react";
import Card from "../../../../UI/Card/Card";
import {
  SelectInput,
  TextAreaInput,
  TextInput,
} from "../../../../UI/FormComponents/Input";
import FormGroupHeader from "../../../../UI/FormComponents/FormHeaders/FormGroupHeader";
import styles from "../../AddKitchenForm.module.scss";

const FormLHS = ({ region, state, dispatch }) => {
  return (
    <Card classes={styles["form-6grid-main"]}>
      <FormGroupHeader
        title="Kitchen Information"
        description="General information about this Kitchen"
      />
      <TextInput
        name="kitchen_name"
        label="Kitchen name"
        type="text"
        value={state?.name}
        onChange={({ target }) => dispatch({name: target.value})}
      />
      <SelectInput
        name="available_region"
        label="Available Region"
        options={region}
        value={state?.regionId}
        onChange={(e) => dispatch({ regionId: e.target.value })}
      />
      <TextInput
        name="city_name"
        label="City"
        value={state?.city}
        onChange={({ target }) => dispatch({city: target.value})}
      />
      <TextAreaInput
        name="kitchen_description"
        label="Description"
        value={state?.description}
        onChange={({ target }) => dispatch({ description: target.value })}
      />
    </Card>
  );
};

export default FormLHS;
