export const SET_PRODUCT_ITEMS = 'SET_PRODUCT_ITEMS';
export const SET_ORDERS = 'SET_ORDERS';
export const SET_USERS = 'SET_USERS';
export const SET_USER_ADDRESS = 'SET_USER_ADDRESS';
export const SET_ORDER = 'SET_ORDER';
export const SET_ORDER_ID = 'SET_ORDER_ID';
export const SET_ORDER_PRODUCTS = 'SET_ORDER_PRODUCTS';
export const SET_ORDER_STATUS = 'SET_ORDER_STATUS';
export const SET_ORDER_PAYMENTS = 'SET_ORDER_PAYMENTS';
export const SET_SELECTED_CUSTOMER = 'SET_SELECTED_CUSTOMER'

