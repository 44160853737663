import React from "react";
import { menuitems } from "./MenuList";
import Logo from "../../../Assets/Images/logo.png";
import MenuItem from "./SidebarLinks/MenuItem";
import LinkGroup from "./SidebarLinks/LinkGroup";
import styles from "./Sidebar.module.scss";
import useLocationSidebar from "./hooks/useLocationSidebar";

const Sidebar = () => {
    const { currentUserAbilities, region } = useLocationSidebar();
  return (
    <div className={styles["sidebar-main"]}>
      <div className={styles["admin-logo"]}>
        <img src={Logo} alt="" />
      </div>
      <div className={styles["sidebar-menu"]}>
        <MenuItem title="Dashboard" href="home" />
        {Object.entries(menuitems(currentUserAbilities, region)).map(
          ([key, item]) => {
            return (
              <LinkGroup key={item.id} title={item.title} items={item.items} />
            );
          }
        )}
        <MenuItem title="Activity Logs" href="logs" />
        <MenuItem title="Payments" href="payments" />
      </div>
    </div>
  );
};

export default Sidebar;
