import { SET_DEPARTMENT, SET_DEPARTMENTS, SET_DEPARTMENT_ID } from "../actionTypes/departmentType";
  
  export function setDepartments(data) {
    return (dispatch) => {
      dispatch({ type: SET_DEPARTMENTS, data });
    };
  }
  
  export function setDepartment(data) {
    return (dispatch) => {
      dispatch({ type: SET_DEPARTMENT, data });
    };
  }
  
  export function setDepartmentId(data) {
    return (dispatch) => {
      dispatch({ type: SET_DEPARTMENT_ID, data });
    };
  }

  