import React from "react";
import AddItem from "../../../../UI/AddItem/AddItem";
import {
  SelectInput,
  TextInput,
} from "../../../../UI/FormComponents/Input";
import styles from "./AddAddressForm.module.scss";
import useAddAddress from "./hooks/useAddAddress";

function AddAddressForm({ customer, onClosePage, getUserAddress }) {
  const { countries, state, dispatch, states, cities, handleSubmit } = useAddAddress(
    customer,
    onClosePage,
    getUserAddress,
  );

  const {
    countryId,
    stateId,
    cityId,
    houseNumber,
    street,
    latitude,
    longitude,
    isLoading
  } = state;
  return (
    <div className={styles.mains}>
      <h5 className="header grey-800">Add Address</h5>
      <p className="caption grey-500">General information about this Address</p>
      <div className={styles["form-flex"]}>
        <div className={styles["form-flex-1"]}>
          <SelectInput
            name="country"
            label="Country"
            value={countryId}
            options={countries}
            onChange={(e) =>
              dispatch({
                countryId: e.target.value,
                country: e.target.selectedOptions[0].dataset.unit,
              })
            }
          />
        </div>
        <div className={styles["form-flex-2"]}>
          <SelectInput
            name="state"
            label="State"
            value={stateId}
            options={states}
            onChange={(e) => dispatch({ stateId: e.target.value })}
          />
        </div>
      </div>
      <div className={styles["form-flex"]}>
        <div className={styles["form-flex-1"]}>
          <SelectInput
            name="LGA"
            label="Local Government Area"
            value={cityId}
            options={cities}
            onChange={(e) => dispatch({ cityId: e.target.value })}
          />
        </div>
        <div className={styles["form-flex-2"]}></div>
      </div>
      <div className={styles["form-flex"]}>
        <div className={styles["form-flex-1"]}>
          <TextInput
            name="house_number"
            label="House Number"
            type="number"
            value={houseNumber}
            onChange={({ target }) => dispatch({ houseNumber: target.value })}
          />
        </div>
        <div className={styles["form-flex-1"]}>
          <TextInput
            name="street"
            label="Street"
            type="text"
            value={street}
            onChange={(e) => dispatch({ street: e.target.value })}
          />
        </div>
      </div>
      <div className={styles["form-flex"]}>
        <div className={styles["form-flex-1"]}>
          <TextInput
            name="longitude"
            label="Longitude"
            type="text"
            value={longitude}
            onChange={({ target }) => dispatch({ longitude: target.value })}
          />
        </div>
        <div className={styles["form-flex-2"]}>
          <TextInput
            name="latitude"
            label="Latitude"
            type="text"
            value={latitude}
            onChange={({ target }) => dispatch({ latitude: target.value })}
          />
        </div>
      </div>
      <div className={styles["btn-div"]}>
        <AddItem
          name="new address for this Customer"
          bgColor="blue-bg"
          noItem
          addFnc={handleSubmit}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

export default AddAddressForm;
