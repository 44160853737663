import React from "react";
import MetaTags from "react-meta-tags";
import Grid from "../../UI/Grid/Grid";
import GridRow from "../../UI/Grid/GridRow";
import FormLHS from "./component/FormGroups/FormLHS";
import FormRHS from "./component/FormGroups/FormRHS";
import TopDisplay from "../../UI/TopDisplay/TopDisplay";
import styles from "./AddOrderForm.module.scss";
import productstyles from "./Orders.module.scss";
import useAddOrder from "./hooks/useAddOrder";
import AddModal from "../../UI/Popup/Popup";
import AddAddressForm from "./component/AddAddressForm/AddAddressForm";

const AddOrderForm = ({ edit }) => {
  const {
    orderProducts,
    userAddress,
    state,
    users,
    dispatch,
    openAddModal,
    onClosePage,
    onAddCustomer,
    handleCustomerId,
    handleSubmit,
    sumOfPrices,
    totalPaymentDue,
    currency,
    discounts,
  } = useAddOrder(edit);
  return (
    <>
      <MetaTags>
        <title>Add Order Page</title>
        <meta name="description" content="#" />
      </MetaTags>
      <div className={productstyles.main}>
        {openAddModal && (
          <AddModal
            onClose={onClosePage}
            body={
              <AddAddressForm
                customer={state?.customer}
                onClosePage={onClosePage}
              />
            }
          />
        )}
        <TopDisplay
          title={edit ? "Update Order" : "Create New Order"}
          firstText="Orders"
          secondText={edit ? "Update Order" : "Create New Order"}
          style2
        />
        <div className={styles["form-main"]}>
          <Grid span="9">
            <GridRow span="6" customClass="section2">
              <FormLHS
                edit={edit}
                products={orderProducts}
                users={users}
                dispatch={dispatch}
                onAddCustomer={onAddCustomer}
                handleCustomerId={handleCustomerId}
                state={state}
                userAddress={userAddress}
              />
            </GridRow>
            <GridRow span="3" customClass="section2">
              <FormRHS
                edit={edit}
                state={state}
                handleSubmit={handleSubmit}
                sumOfPrices={sumOfPrices}
                totalPaymentDue={totalPaymentDue}
                currency={currency}
                discounts={discounts}
                dispatch={dispatch}
              />
            </GridRow>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default AddOrderForm;
