import { useCallback, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { setInventory } from "../../../../redux/actions/inventory.action";
import { paths } from "../../../../Routes/path";
import inventoriesServices from "../../../../Services/inventories.services";

function useAddInventories(edit) {
  const { inventory } = useSelector((state) => state.inventory);
  const myObjectString = localStorage.getItem("user");

  const currentUser = JSON.parse(myObjectString);
  const kitchenId = currentUser?.kitchen_id;
  const sideBarType = currentUser?.restricted_by_location;

  const initState = {
    name: "",
    code: "",
    alertLevel: "",
    expires: false,
    period: "",
    periodLength: "",
    state: "",
    isLoading: false,
    units: "",
  };

  const navigate = useNavigate();
  const { inventoryId } = useParams();
  const reduxDispatch = useDispatch();

  const [state, dispatch] = useReducer(
    (inventoryState, value) => ({ ...inventoryState, ...value }),
    initState
  );

  const getAInventory = useCallback(() => {
    if (sideBarType) {
      inventoriesServices
      .getALocationInventory(kitchenId, inventoryId)
      .then(({ data }) => {
        setInventory(data)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
    } else {
    inventoriesServices
      .getAInventory(inventoryId)
      .then(({ data }) => {
        setInventory(data?.inventory)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
    }
  }, [inventoryId, kitchenId, reduxDispatch, sideBarType]);

  useEffect(() => {
    if (edit) getAInventory();
  }, [edit, getAInventory]);

  useEffect(() => {
    if (edit) {
      dispatch({
        name: inventory?.name,
        code: inventory?.code,
        alertLevel: inventory?.alert_level,
        expires: inventory?.expires,
        state: inventory?.state,
        units: inventory?.measure,
        period: inventory?.expiry_period,
        periodLength: inventory?.expiry_count,
      });
    }
  }, [inventory, edit]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (state.name && state.state) {
      if (!edit) {
        dispatch({ isLoading: true });
        inventoriesServices
          .createInventory({
            inventory: {
              name: state?.name,
              code: state?.code,
              alert_level: state?.alertLevel,
              expires: state?.expires,
              expiry_period: state?.period,
              expiry_count: state?.periodLength,
              state: state?.state,
            },
          })
          .then(() => {
            toast.success("Inventory was succesfully created");
          })
          .catch((err) => {
            dispatch({ isLoading: false });
            toast.error(err.message);
          })
          .finally(() => navigate(paths.inventory));
      } else {
        dispatch({ isLoading: true });
        inventoriesServices
          .updateInventory(inventoryId, {
            inventory: {
              name: state?.name,
              code: state?.code,
              alert_level: state?.alertLevel,
              expires: state?.expires,
              expiry_period: state?.period,
              expiry_count: state?.periodLength,
              state: state?.state,
            },
          })
          .then(() => {
            toast.success("Inventory was succesfully updated");
          })
          .catch((err) => {
            dispatch({ isLoading: false });
            toast.error(err.message);
          })
          .finally(() => navigate(paths.inventory));
      }
    } else {
      toast.error("Fields cannot be empty");
    }
  };
  return { state, handleSubmit, dispatch };
}

export default useAddInventories;
