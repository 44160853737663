import React from "react";
import { TextInput } from "../../../../UI/FormComponents/Input";
import styles from "../../AddCustomerForm.module.scss";
import Button from "../../../../UI/Button/Button";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const FormLHS = ({ edit, state, handleSubmit, dispatch, view }) => {
  const { firstName, lastName, phone, email, isLoading } = state;
  return (
    <div className={styles["form-6grid-main"]}>
      <TextInput
        name="first_name"
        label="First Name"
        type="text"
        value={firstName}
        disabled={view}
        onChange={(e) => dispatch({ firstName: e.target.value })}
      />
      <TextInput
        name="last_name"
        label="Last Name"
        type="text"
        value={lastName}
        disabled={view}
        onChange={(e) => dispatch({ lastName: e.target.value })}
      />

      <TextInput
        name="email"
        label="Email"
        type="email"
        value={email}
        disabled={view}
        onChange={(e) => dispatch({ email: e.target.value })}
      />
      <div className={styles["form-flex"]}>
        <div className={styles["form-flex-1"]}>
          <p className={styles["whatsapp"]}>Phone</p>
          <PhoneInput
            country={"ng"}
            value={phone}
            disabled={view}
            onChange={(phone) => dispatch({ phone: phone })}
          />
        </div>
        <div className={styles["form-flex-2"]}>
          {!view ? (
            <div className={styles["btn-div"]}>
              <Button
                name="Customer"
                bgColor="green-bg"
                createFnc={handleSubmit}
                edit={edit}
                isLoading={isLoading}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default FormLHS;
