import React from "react";
import MenuItem from "./MenuItem";
import styles from "../Sidebar.module.scss";

const LinkGroup = (props) => {
  return (
    <div className={styles["link-group"]}>
      <div className={styles["link-group-header"]}>
        <p className="overline">{props.title}</p>
      </div>
      <div className="link-group-menu-items">
        {props?.items?.map((menuitem, index) => {
           if (!menuitem) {
            return null;
          }
          return (
            <MenuItem
              key={index}
              icon={menuitem.icon}
              title={menuitem.menuTitle}
              href={menuitem.urlGroup}
              submenus={menuitem.items}
            />
          );
        })}
      </div>
    </div>
  );
};

export default LinkGroup;
