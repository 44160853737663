import React from "react";
import { menuitems } from "./MenuList";
import Logo from "../../../Assets/Images/logo.png";
import MenuItem from "./SidebarLinks/MenuItem";
import LinkGroup from "./SidebarLinks/LinkGroup";
import styles from "./LocationSidebar.module.scss";
import useLocationSidebar from "./hooks/useLocationSidebar";

const LocationSidebar = () => {
  const { region, currentUserAbilities, kitchenLocations, kitchenRestricted } =
    useLocationSidebar();
  return (
    <div className={styles["sidebar-main"]}>
      <div className={styles["admin-logo"]}>
        <img src={Logo} alt="" />
      </div>
      <div className={styles["sidebar-menu"]}>
        <MenuItem title="Dashboard" href="home" />

        {Object.entries(
          menuitems(
            region,
            currentUserAbilities,
            kitchenLocations,
            kitchenRestricted
          )
        ).map(([key, item]) => {
          return (
            <LinkGroup key={item.id} title={item.title} items={item.items} />
          );
        })}
      </div>
    </div>
  );
};

export default LocationSidebar;
