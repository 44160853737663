import React from "react";
import getSymbolFromCurrency from "currency-symbol-map";
import { cashFormater, numberFormater } from "../../../../../utils/helpers";
import styles from "./Table.module.scss";

function Table({ orderItems, voucher, totalItems, order }) {
  console.log(voucher)
  return (
    <div className={styles.table}>
      <div className={styles.head}>
        <span className={styles["hd-one"]}>Description</span>
        <span className={styles["hd-two"]}>Qty</span>
        <span className={styles["hd-three"]}>Unit Price</span>
        <span className={styles["hd-four"]}>Total</span>
      </div>
      {
        orderItems && orderItems?.map(({ product, quantity, unit_price}) => (
          <div className={styles.body}>
          <span className={styles["bd-one"]}>{product?.title}</span>
          <span className={styles["bd-two"]}>{quantity}x</span>
          <span className={styles["bd-three"]}>{`${getSymbolFromCurrency(unit_price?.currency) || ''}${numberFormater(
              unit_price.price || 0
            )}`}</span>
          <span className={styles["bd-four"]}>{`${getSymbolFromCurrency(unit_price?.currency) || ''}${numberFormater(
              quantity * unit_price?.price || 0
            )}`}
            </span>
        </div>
        ))
      }
      <div className={styles.total}>
        <span className={styles["hd-one"]}></span>
        <span className={styles["hd-two"]}></span>
        <span className={styles["hd-three"]}>Subtotal</span>
        <span className={styles["hd-four"]}>{totalItems?.currency && `${getSymbolFromCurrency(totalItems?.currency) || ''} ${cashFormater(order?.sub_total)}`}</span>
      </div>
      <div className={styles.total}>
        <span className={styles["hd-one"]}></span>
        <span className={styles["hd-two"]}></span>
        <span className={styles["hd-three"]}>Discount</span>
        <span className={`${styles["hd-four"]} ${styles["discount"]}`}>{order?.discount_amount && (`${voucher?.percentage}% (-${getSymbolFromCurrency(totalItems?.currency) || ''} ${cashFormater(order?.discount_amount)})`)}</span>
      </div>
      <div className={styles.total}>
        <span className={styles["hd-one"]}></span>
        <span className={styles["hd-two"]}></span>
        <span className={styles["hd-three"]}>Delivery Fee</span>
        <span className={styles["hd-four"]}>{totalItems?.currency && `${getSymbolFromCurrency(totalItems?.currency) || ''} ${cashFormater(totalItems?.delivery_charge)}`}</span>
      </div>
      <div className={styles.total}>
        <span className={styles["hd-one"]}></span>
        <span className={styles["hd-two"]}></span>
        <span className={styles["hd-three"]}>VAT (7.5%)</span>
        <span className={styles["hd-four"]}></span>
      </div>
      <div className={styles.total}>
        <span className={styles["hd-one"]}></span>
        <span className={styles["hd-two"]}></span>
        <span className={styles["hd-three"]}>Total Payable</span>
        <span className={`${styles["hd-four"]} ${styles["weigh"]}`}>{totalItems?.currency && `${getSymbolFromCurrency(totalItems?.currency) || ''} ${cashFormater(order?.total_payable)}`}</span>
      </div>
    </div>
  );
}

export default Table;
