import React from "react";
import {
  DateTimeInput,
  NumberInput,
  SelectInput,
  TextInput,
} from "../../../../UI/FormComponents/Input";
import styles from "../../AddVoucherForm.module.scss";
import Button from "../../../../UI/Button/Button";
import DateTime from "../../../../UI/FormComponents/InputTypes/DateTime";

const FormLHS = ({ edit, state, handleSubmit, dispatch, influencers }) => {
  return (
    <div className={styles["form-6grid-main"]}>
      <div className={styles["display-flex"]}>
        <TextInput
          name="campaign_name"
          label="Campaign Name"
          type="text"
          value={state.name}
          onChange={(e) => dispatch({ name: e.target.value })}
        />
        <SelectInput
          name="influencer_name"
          label="Influencer's Full Name"
          options={influencers}
          value={state.influencerId}
          onChange={(e) => dispatch({ influencerId: e.target.value })}
        />
      </div>
      <div className={styles["display-flex"]}>
        <TextInput
          name="discount code"
          label="Discount Code"
          type="text"
          value={state.discountCode}
          onChange={(e) => dispatch({ discountCode: e.target.value })}
        />

        <NumberInput
          name="discount off"
          label="Discount off %"
          units="%"
          value={state.percentage}
          onChange={(e) => dispatch({ percentage: e.target.value })}
        />
      </div>
      <div className={styles["display-flex"]}>
        <DateTimeInput
          name="Expiry Date"
          label="Expiry Date"
          value={state.expirationDate}
          onChange={(e) => dispatch({ expirationDate: e.target.value })}
        />
      </div>
      <Button
        name="Voucher"
        bgColor="green-bg"
        createFnc={handleSubmit}
        edit={edit}
        isLoading={state.isLoading}
      />
    </div>
  );
};

export default FormLHS;
