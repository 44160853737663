export const menuitems = (region, currentUserAbilities, kitchenLocations) => {
    
  const items = [
    {
      id: 2,
      title: "Order Management Dashboard",
      items: [
        currentUserAbilities?.order?.index && {
          id: 1,
          menuTitle: "Orders",
          urlGroup: "orders",
          items: [
            {
              title: "All Orders",
              url: "index",
            },
            {
              title: "Manage Orders",
              url: "manage",
            },
          ],
        },
        {
          id: 2,
          menuTitle: "Deliveries",
          urlGroup: "deliveries",
          items: [
            currentUserAbilities?.rider?.index && {
              title: "Riders",
              url: "riders",
            },
            {
              title: "Manage Deliveries",
              url: "index",
            },
          ],
        },
        {
          id: 3,
          menuTitle: `${region[0]?.state} Warehouse`,
          urlGroup: `warehouse/${region[0]?.id}`,
        },
        ...kitchenLocations?.map((item) => {
          if (
            !currentUserAbilities?.stock?.index ||
            !currentUserAbilities?.inventory?.index
          ) {
            return null;
          }
          return {
            id: item.id,
            menuTitle: `${item?.name}`,
            urlGroup: `kitchin/${item.id}`,
            items: [
              currentUserAbilities?.stock?.index && {
                title: "Inventory",
                url: "inventories",
              },
              currentUserAbilities?.stock?.index && {
                title: "Stock",
                url: "stocks",
              },
            ],
          };
        }),
      ],
    },
    {
      id: 3,
      title: "Customer Management",
      items: [
        currentUserAbilities?.user?.index && {
          id: 3,
          menuTitle: "Customers",
          urlGroup: "customers",
        },
        {
          id: 4,
          menuTitle: "Discount",
          urlGroup: "discount",
          items: [
            {
              title: "Influencers",
              url: "influencers",
            },
            {
              title: "Vouchers",
              url: "vouchers",
            },
          ],
        },
        currentUserAbilities?.admin?.index && {
          id: 5,
          menuTitle: "Staff Management",
          urlGroup: "management",
          items: [
            {
              title: "Departments",
              url: "departments",
            },
            {
              title: "Staff Profile",
              url: "staffs",
            },
          ],
        },
      ],
    },
  ];
  return items.filter((item) => {
    return item;
  });
};

export default menuitems;
