import React from "react";
import MetaTags from "react-meta-tags";
import Grid from "../../UI/Grid/Grid";
import GridRow from "../../UI/Grid/GridRow";
import FormLHS from "./component/FormGroups/FormLHS";
import FormRHS from "./component/FormGroups/FormRHS";
import TopDisplay from "../../UI/TopDisplay/TopDisplay";
import styles from "./AddRiderForm.module.scss";
import riderstyles from "./Riders.module.scss";
import useAddRider from "./hooks/useAddRider";

const AddRiderForm = ({ edit, verify }) => {
  const { region, dispatch, state, handleForm, pageTitle, onSelectFile, kitchenZones, countries, cities, onViewImage, onSelectImage, onClickAction } = useAddRider(edit, verify);
  return (
    <>
      <MetaTags>
        <title>Add Rider Page</title>
        <meta name="description" content="#" />
      </MetaTags>
      <div className={riderstyles.main}>
        <TopDisplay
          title={pageTitle}
          firstText="Deliveries"
          secondText="Riders"
          thirdText={pageTitle}
          style2
        />
        <div className={styles["form-main"]}>
          <Grid span="9">
            <GridRow span="6" customClass="section2">
              <FormLHS
                edit={edit}
                verify={verify}
                region={region}
                state={state}
                dispatch={dispatch}
                kitchenZones={kitchenZones}
                countries={countries}
                cities={cities}
                onViewImage={onViewImage}
                onSelectImage={onSelectImage}
              />
            </GridRow>
            <GridRow span="3" customClass="section2">
              <FormRHS
                handleSubmit={handleForm}
                edit={edit}
                verify={verify}
                state={state}
                dispatch={dispatch}
                onSelectFile={onSelectFile}
                onViewImage={onViewImage}
                onClickAction={onClickAction}
              />
            </GridRow>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default AddRiderForm;
