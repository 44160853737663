import {
  SET_ORDER,
  SET_ORDERS,
  SET_ORDER_ID,
  SET_ORDER_PAYMENTS,
  SET_ORDER_PRODUCTS,
  SET_ORDER_STATUS,
  SET_PRODUCT_ITEMS,
  SET_USERS,
  SET_USER_ADDRESS,
  SET_SELECTED_CUSTOMER,
} from "../actionTypes/orderActionType";

const initialState = {
  productItems: [
    {
      product_name: null,
      quantity: "",
      title: "",
      id: null,
      price: null,
      currency: "",
    },
  ],
  orders: [],
  order: [],
  orderId: "",
  users: [],
  userAddress: [],
  orderProducts: [],
  orderStatus: [],
  orderPayments: [],
  selectedCustomer: [],
};

export default function orderReducer(state = initialState, action) {
  const { data, type } = action;
  switch (type) {
    case SET_PRODUCT_ITEMS:
      return {
        ...state,
        productItems: data,
      };
    case SET_ORDERS:
      return {
        ...state,
        orders: data,
      };
    case SET_USERS:
      return {
        ...state,
        users: data,
      };
    case SET_USER_ADDRESS:
      return {
        ...state,
        userAddress: data,
      };
    case SET_ORDER:
      return {
        ...state,
        order: data,
      };
    case SET_ORDER_ID:
      return {
        ...state,
        orderId: data,
      };
    case SET_ORDER_PRODUCTS:
      return {
        ...state,
        orderProducts: data,
      };
    case SET_ORDER_STATUS:
      return {
        ...state,
        orderStatus: data,
      };
    case SET_ORDER_PAYMENTS:
      return {
        ...state,
        orderPayments: data,
      };
      case SET_SELECTED_CUSTOMER:
        return {
          ...state,
          selectedCustomer: data,
        };
    default:
      return state;
  }
}
