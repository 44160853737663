import React, { useRef } from "react";
import styles from "./BulkUpload.module.scss";

function BulkUpload({ name, createFn }) {
  const formRef = useRef(null);
  const inputFileRef = useRef(null);

  return (
    <form ref={formRef} className={styles.form}>
      <input
        type="file"
        id="bulk"
        ref={inputFileRef}
        style={{
          display: "none",
        }}
        onChange={createFn}
      />
      <label htmlFor="bulk" className={styles["upload-button"]}>
        {`Bulk Upload ${name}`}
      </label>
    </form>
  );
}

export default BulkUpload;
