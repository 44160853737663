import React from "react";
import MetaTags from "react-meta-tags";
import Grid from "../../UI/Grid/Grid";
import GridRow from "../../UI/Grid/GridRow";
import TopDisplay from "../../UI/TopDisplay/TopDisplay";
import DTable from "./component/DTable/DTable";
import styles from "./Roles.module.scss";

function Roles() {
  return (
    <>
      <MetaTags>
        <title>Roles Page</title>
        <meta name="description" content="#" />
      </MetaTags>
      <div className={styles.main}>
        <TopDisplay
          title="Roles"
          firstText="Dashboard"
          secondText="Management"
          thirdText="Roles"
          style2
        />
        <div className={styles.section2}>
          <Grid span="9">
            <GridRow span="9" customClass="section2">
              <DTable />
            </GridRow>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default Roles;
