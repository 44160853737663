import React from "react";
import Card from "../../../../UI/Card/Card";
import {
  SelectInput,
} from "../../../../UI/FormComponents/Input";
import styles from "../../AssignDelivery.module.scss";
import Button from "../../../../UI/Button/Button";
import FormGroupHeader from "../../../../UI/FormComponents/FormHeaders/FormGroupHeader";

const FormRHS = ({
  state,
  handleSubmit,
  riders,
  dispatch,
}) => {
  const { riderId, isLoading } = state;
  return (
    <>
      <Card classes={styles["form-3grid-main"]}>
      <FormGroupHeader
        title="Select Rider"
        description="Only showing available riders registered around the area of delivery"
      />
        <div className={styles["order-total"]}>
          <div className={styles["inventory-div2"]}>
            <SelectInput
              name="select_rider"
              label="Rider"
              value={riderId}
              options={riders}
              onChange={(e) => dispatch({ riderId: e.target.value })}
            />
          </div>
        </div>
      </Card>
      <div className={styles.cards}>
        <Button
          name="Assign Delivery to Rider"
          bgColor="green-bg"
          custom
          createFnc={handleSubmit}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default FormRHS;
