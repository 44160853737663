import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { setIsLoading } from '../../../../redux/actions/appAction';
import { setOrders, setOrderStatus } from '../../../../redux/actions/order.action';
import { setIsDeleting, setPopupComponent, showPopup } from '../../../../redux/actions/popUp.action';
import { paths } from '../../../../Routes/path';
import ordersServices from '../../../../Services/orders.services';
import DeleteModal from '../../../UI/DeleteModal';
import AssignKitchen from '../component/AssignKitchen/AssignKitchen';

function useManageOrders() {
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const initState = {
      totalOrders: null,
      isDeleting: false,
    };
  
    const navigate = useNavigate();
    const { openModal } = useSelector((state) => state.app);
  
    const [state, dispatch] = useReducer(
      (productState, value) => ({ ...productState, ...value }),
      initState
    );
    const reduxDispatch = useDispatch();
    const {
      orders,
      orderStatus,
    } = useSelector((state) => state.order);
    const { totalOrders, isDeleting } = state;
    const statusType = useMemo(() => (
        orderStatus[0]?.status || "paid"
    ), [orderStatus])

    const getOrders = useCallback(
        (page, perPage) => {
          setIsLoading(true)(reduxDispatch);
          ordersServices
            .getFilteredOrder(statusType, page, perPage)
            .then(({ data }) => {
              setOrders(data.data)(reduxDispatch);
              setTotalRows(data.pagination.total);
            })
            .catch((err) => {
              toast.error(err.message || "Unable to get");
            })
            .finally(() => setIsLoading(false)(reduxDispatch));
        },
        [reduxDispatch, statusType]
      );
  
    const getFilteredOrders = useCallback(
      (orderStatus, page, perPage) => {
        setIsLoading(true)(reduxDispatch);
        ordersServices
          .getFilteredOrder(orderStatus, page, perPage)
          .then(({ data }) => {
            setOrders(data.data)(reduxDispatch);
            setTotalRows(data.pagination.total);
          })
          .catch((err) => {
            toast.error(err.message || "Unable to get");
          })
          .finally(() => setIsLoading(false)(reduxDispatch));
      },
      [reduxDispatch]
    );
  
    const getOrderStatus = useCallback(() => {
      ordersServices
        .getOrderStatus()
        .then(({ data }) => {
            const newArr = data.filter(item => (
                item.status !== 'completed' && item.status !== 'initiated'
            ))
            let newArray = newArr?.map((item) => ({
              status: item.status,
              count: item.count,
              id: item.status,
            }));
          setOrderStatus(newArray)(reduxDispatch);
        })
        .catch((err) => {
          toast.error(err.message || "Unable to get");
        });
    }, [reduxDispatch]);

  useEffect(() => {
    getOrderStatus();
    // eslint-disable-next-line
  }, [getOrderStatus])

    useEffect(() => {
        getOrders(1, perPage);
    }, [getOrders, perPage]);

    useEffect(() => {
      setOrders([])(reduxDispatch);
    }, [reduxDispatch])
  
    const handlePageChange = (page) => {
      getOrders(page, perPage);
    };
  
    const handlePerRowsChange = async (newPerPage, page) => {
      setIsLoading(true)(reduxDispatch);
      ordersServices
        .getOrders(page, newPerPage)
        .then(({ data }) => {
          setOrders(data.data)(reduxDispatch);
          setPerPage(newPerPage);
          setTotalRows(data.pagination.total);
        })
        .catch((err) => {
          toast.error(err.message || "Unable to get");
        })
        .finally(() => setIsLoading(false)(reduxDispatch));
    };
  
    const onClose = () => {
      showPopup({ ispopupOpen: false })(reduxDispatch);
    };
  
    const secondRowFcn = (orderStatus) => {
      getFilteredOrders(orderStatus, 1, perPage);
    };
  
    const secondRowAllFcn = () => {
      getOrders(1, perPage);
    };
  
    const handleDelete = (action) => {
      setIsDeleting(true)(reduxDispatch)
      ordersServices
        .updateOrderStatus(action?.next_action?.endpoint_path)
        .then(() => {
          toast.success(`Order Status was updated from ${action?.current_status} to ${action?.next_action?.text}`);
          getOrders();
          getOrderStatus();
          onClose();
        })
        .catch((err) => toast.error(err.message))
      .finally(() => {
        setIsDeleting(false)(reduxDispatch)
        navigate(paths.manageOrders)
      });
    };

    const onClosePage = useCallback(() => {
      reduxDispatch(showPopup({ ispopupOpen: false }));
    }, [reduxDispatch]);

    const handleKitchen = (id, orderRef) => {
      reduxDispatch(
        setPopupComponent(
          <AssignKitchen
            onClose={() => {}}
            orderRef={orderRef}
            orderId={id} 
            onClosePage={onClosePage}
          />
        )
      );
      reduxDispatch(
        showPopup({ ispopupOpen: true, onClose: () => onClosePage() })
      );
    }
  
    const onClickAction = (actions) => {
      reduxDispatch(
        setPopupComponent(
          <DeleteModal
            modal2
            onClose={onClose}
            handleDelete={() => handleDelete(actions)}
            text={`Are you sure you want to change this order status from ${actions?.current_status} to ${actions?.next_action?.text} ?`}
            text2="Change Status"
          />
        )
      );
      showPopup({ ispopupOpen: true, onClose: () => onClose() })(reduxDispatch);
    };

    const myObjectString = localStorage.getItem("user");

    const currentUser = JSON.parse(myObjectString);
    const currentUserAbilities = currentUser?.account_abilities?.abilities?.["order"];
  
    return {
      state,
      handlePageChange,
      handlePerRowsChange,
      dispatch,
      orders,
      totalRows,
      secondRowAllFcn,
      secondRowFcn,
      onClickAction,
      onClose,
      openModal,
      orderStatus,
      totalOrders,
      isDeleting,
      currentUserAbilities,
      handleKitchen,
      getOrderStatus,
    };
  }

export default useManageOrders