import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { paths } from "../../../../../../Routes/path";

function useDTable(onClickAction, currentUserAbilities) {
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const onClick = (value) => {
    setOpenModal(true);
    setSelected(value);
  };
  const { zoneByRegion } = useSelector((state) => state.delivery);
  const isLoading = useSelector((state) => state.app.isLoading);
  const navigate = useNavigate();

  const handleView = (referenceId, orderId) => {
    navigate(paths.orderDetails.replace(":orderId", referenceId), {
      state: orderId,
    });
  };

  const handleProcess = (actions) => {
    onClickAction(actions);
    navigate(paths.manageOrders)
  };

  const onRowClicked = (rows) => {
    handleView(rows?.reference, rows?.id);
  };

  const update = currentUserAbilities?.update;
  const create = currentUserAbilities?.create;
  const show = currentUserAbilities?.show;

  const del = currentUserAbilities?.destroy;

  return {
    handleProcess,
    onClick,
    selected,
    openModal,
    setOpenModal,
    isLoading,
    zoneByRegion,
    handleView,
    onRowClicked,
    del,
    update,
    create,
    show,
  };
}

export default useDTable;
