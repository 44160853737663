import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { setIsLoading } from "../../../../redux/actions/appAction";
import { setIsDeleting, setPopupComponent, showPopup } from "../../../../redux/actions/popUp.action";
import { setRiders } from "../../../../redux/actions/ridersAction";
import { paths } from "../../../../Routes/path";
import ridersServices from "../../../../Services/riders.services";
import DeleteModal from "../../../UI/DeleteModal";

function useRider() {
  const {
    riders,
  } = useSelector((state) => state.rider);

  const myObjectString = localStorage.getItem("user");

  const currentUser = JSON.parse(myObjectString);
  const currentUserAbilities = currentUser?.account_abilities?.abilities?.["rider"];

  const reduxDispatch = useDispatch();

  const navigate = useNavigate();

  const getRiders = useCallback(() => {
    setIsLoading(true)(reduxDispatch);
    ridersServices
      .getRiders()
      .then(({ data }) => {
        setRiders(data)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      })
      .finally(() => setIsLoading(false)(reduxDispatch));
  }, [reduxDispatch]);

  useEffect(() => {
    getRiders();
  }, [getRiders]);

  const onClose = () => {
    showPopup({ ispopupOpen: false })(reduxDispatch);
  };

  const handleDelete = (riderId) => {
    setIsDeleting(true)(reduxDispatch)
    ridersServices
      .deleteRider(riderId)
      .then(() => {
        toast.success("Rider was deleted");
        getRiders();
        onClose();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => {
        setIsDeleting(false)(reduxDispatch)
        navigate(paths.riders)
      });
  };

  const onClickAction = (riderId) => {
    reduxDispatch(
      setPopupComponent(
        <DeleteModal
          onClose={onClose}
          handleDelete={() => handleDelete(riderId)}
          title="Rider"
        />
      )
    );
    showPopup({ ispopupOpen: true, onClose: () => onClose() })(reduxDispatch);
  };

  return {
    riders,
    onClickAction,
    currentUserAbilities,
  };
}

export default useRider;
