import { useCallback, useEffect, useMemo, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { setInfluencer } from "../../../../redux/actions/discount.Action";
import { showPopup } from "../../../../redux/actions/popUp.action";
import { paths } from "../../../../Routes/path";
import discountServices from "../../../../Services/discount.services";
import { objectToArray } from "../../../../utils/helpers";
import useInfluencer from "./useInfluencer";

function useAddInfluencer(edit) {
  const { getInfluencers } = useInfluencer();
  const { influencer } = useSelector((state) => state.discount);
  const { ispopupOpen } = useSelector((state) => state.popup)
  const initState = {
    name: "",
    username: "",
    url: "",
    isLoading: false,
  };

  const { influencerId } = useParams();
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();

  const myObjectString = localStorage.getItem('discount');

  const currentUser = JSON.parse(myObjectString)
  const currentUserAbilities = currentUser?.account_abilities?.abilities


  const [state, dispatch] = useReducer(
    (discountState, value) => ({ ...discountState, ...value }),
    initState
  );

  useEffect(() => {
    if (edit) {
      dispatch({
        name: influencer?.full_name,
        username: influencer?.username,
        url: influencer?.social_media_url,
      });
    }
  }, [influencer, edit]);

  const handleForm = (e) => {
    if (!state?.name) {
      toast.error("Name cannot be empty")
      return ;
    }
    if (!state?.username) {
      toast.error("Username cannot be empty")
      return ;
    }
    if (!state?.url) {
      toast.error("Url cannot be empty")
      return ;
    }
    handleSubmit(e);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!edit) {
      dispatch({ isLoading: true})
      discountServices
        .createInfluencer({
          influencer: {
            full_name: state?.name,
            username: state?.username,
            social_media_url: state?.url,
          },
        })
        .then(() => {
          toast.success("Influencer was succesfully created");
          dispatch({ isLoading: false})
          reduxDispatch(showPopup({ispopupOpen: false}))
          navigate(paths.influencer)
          getInfluencers(1, 10);
        })
        .catch((err) => {
          dispatch({ isLoading: false})
          if (err.response.status === 422) {
            let errorMsg = objectToArray(err?.response?.data?.data);
            toast.error(`${errorMsg[0][0]} ${errorMsg[0][1][0]}`);
          } else {
            toast.error(err.message);
          }
        });
    } else {
      dispatch({ isLoading: true})
      discountServices
        .updateInfluencer(influencerId, {
          influencer: {
            full_name: state?.name,
            username: state?.username,
            social_media_url: state?.url,
          },
        })
        .then(() => {
          dispatch({ isLoading: false})
          toast.success("Influencer was succesfully updated");
          navigate(paths.influencer)
          reduxDispatch(showPopup({ispopupOpen: false}))
          getInfluencers(1, 10);
        })
        .catch((err) => {
          dispatch({ isLoading: false})
          if (err.response.status === 422) {
            let errorMsg = objectToArray(err?.response?.data?.data);
            toast.error(`${errorMsg[0][0]} ${errorMsg[0][1][0]}`);
          } else {
            toast.error(err.message);
          }
        });
    }
  };

  const getAInfuencer = useCallback(() => {
    discountServices
      .getAInfluencer(influencerId)
      .then(({ data }) => {
        setInfluencer(data)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
  }, [influencerId, reduxDispatch]);


  useEffect(() => {
    if ((edit) && ispopupOpen) {
      getAInfuencer();
    }
  }, [edit, getAInfuencer, ispopupOpen]);

  const pageTitle = useMemo(() => {
      if (edit) {
        return "Update Influencer"
      }
      if (!edit) {
        return "Add New Influencer"
      }
  }, [edit])

  return {
    state,
    handleForm,
    dispatch,
    pageTitle,
    currentUserAbilities,
  };
}

export default useAddInfluencer;
