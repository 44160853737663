import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { ChevronIcon, ArrowDownwardIcon } from "../RegularIcons";
import filterIcon from "../../../Assets/Images/RegularIcons/sort-icon.svg";
import styles from "./Tables.module.scss";
import { camelToNormalCase, sentenceCase } from "../../../utils/helpers";

const sortIcon = <ArrowDownwardIcon />;

const customStyles = {
  headCells: {
    style: {
      backgroundColor: "#f4f6f8",
      color: "#637381",
      padding: "16px",
      fontSize: "14px",
      fontWeight: "600",
    },
  },
  cells: {
    style: {
      padding: "16px",
    },
  },
};

function Tables({
  data,
  columns,
  pagination,
  styleName,
  title,
  atcBtn,
  atcBtn2,
  bottomSide,
  firstRow,
  secondRow,
  secondRowData,
  fetchingData,
  secondRowFcn,
  secondRowAllFcn,
  type,
  allLength,
  paginationServer,
  handlePageChange,
  handlePerRowsChange,
  totalRows,
  onRowClicked,
  bottomFnc,
}) {

  useEffect(() => {
    const columns = Array.from(
      document.querySelectorAll("div.rdt_TableCol_Sortable")
    );

    const columnContainers = Array.from(
      document.querySelectorAll("div.rdt_TableCol")
    );

    let actives = ["active", "paid", "activated"];
    let alarms = ["abandoned", "deactivated", "inactive"];
    let otherStatus = ["processing", "delivering", "completed", "awaiting packaging", "awaiting processing"]

    const rowCells = Array.from(document.querySelectorAll("div.rdt_TableCell"));

    const activeSortHandler = (e) => {
      setStatusColors();
      columns.forEach((element) => {
        if (element.dataset.columnId !== e.target.dataset.columnId) {
          element.closest(".rdt_TableCol").classList.remove("active");
        }
      });
      e.target.closest(".rdt_TableCol").classList.add("active");
    };

    columnContainers.forEach((element) => {
      element.addEventListener("click", activeSortHandler, false);
    });

    columns.forEach((element) => {
      let sortIcon = element.querySelector(".rdt_TableCol_SortableFilter");
      if (sortIcon !== null) element.removeChild(sortIcon);
      const icon = document.createElement("img");
      icon.src = filterIcon;
      icon.className = "rdt_TableCol_SortableFilter";
      element.prepend(icon);
      element.addEventListener("click", (e) => {
        e.preventDefault();
      });
    });

    const checkStatusType = (inner_text) => {
      if (actives.includes(inner_text.toLowerCase())) {
        return "active";
      } else if (alarms.includes(inner_text.toLowerCase())) {
        return "alarmed";
      } else if (otherStatus.includes(inner_text.toLowerCase())) {
        return "processing";
      } else if (inner_text.toLowerCase() === "initiated") {
        return "initiated";
      }
    };

    const setStatusColors = () => {
      rowCells.forEach((element) => {
        switch (checkStatusType(element.firstChild.innerText)) {
          case "active":
            return (element.firstChild.className = "active-text");
          case "alarmed":
            return (element.firstChild.className = "alarm-text");
          case "processing":
            return (element.firstChild.className = "processing-text");
          case "initiated":
            return (element.firstChild.className = "initiated-text");
          case "completed":
            return (element.firstChild.className = "completed-text");
          case "delivering":
            return (element.firstChild.className = "delivering-text");
          default:
            return (element.firstChild.className = "");
        }
      });
    };

    setStatusColors();
  }, [data, columns]);
  const [active, setActive] = useState("");

  useEffect(() => {
    if (type === "manageDelivery") setActive(secondRowData[0]?.status)
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (type === "manageOrder") setActive(secondRowData[0]?.status)
  }, [secondRowData, type]);

  useEffect(() => {
    if (type !== "manageOrder" && type !== "manageDelivery") setActive(type)
  }, [type])

  return (
    <div className={styles.main} style={{ ...styleName }}>
      {firstRow && (
        <div className={styles.topSide}>
          <div className={styles.title}>{title}</div>
          <div className={styles.btnSide}>
            {type === "orders" && <div className={styles.atcBtn}>{atcBtn2}</div>}
            <div>{atcBtn}</div>
          </div>
        </div>
      )}
      {secondRow && (
        <div
          className={styles.secondRow}
          style={{
            borderRadius: firstRow ? "" : "16px 16px 0px 0px",
          }}
        >
          {type !== "manageOrder" && type !== "manageDelivery" && (
            <div
              className={styles.secondRow1}
              onClick={() => {
                secondRowAllFcn();
                setActive(type);
              }}
            >
              <span className={styles.circle}>{allLength || 0}</span>
              <span
                className={`${styles.circleText} ${active === type && styles.active
                  }`}
              >
                All {sentenceCase(type)}
              </span>
            </div>
          )}
          {secondRowData?.map((data, index) => (
            <div
              key={index}
              className={styles.secondRow1}
              onClick={() => {
                secondRowFcn(data?.id);
                setActive(data?.status);
              }}
            >

              {type !== "order" && type !== "manageDelivery" && (
                <span className={styles.circle}>{data.count}</span>
              )}
              {
                type === "kitchens" || type === "areas" ?

                  <span
                    className={`${styles.circleText} ${active === data.status && styles.active
                      }`}
                  >

                    {sentenceCase(data.status)}
                  </span>
                  :
                  <span
                    className={`${styles.circleText} ${active === data.status && styles.active
                      }`}
                  >
                    {(type === "manageOrder" && type === "manageDelivery" && type === "orders")
                      ? data.status
                      : sentenceCase(camelToNormalCase(data.status))}
                  </span>
              }
            </div>
          ))}
        </div>
      )}
      <DataTable
        columns={columns}
        data={data}
        sortIcon={sortIcon}
        pagination={pagination}
        responsive
        customStyles={customStyles}
        highlightOnHover
        progressPending={fetchingData}
        paginationServer={paginationServer}
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        onRowClicked={(row) => onRowClicked(row)}
      />
      {bottomSide && (
        <div className={styles.bottomSide}>
          <div className={styles.side} onClick={bottomFnc}>
            <div className={styles.bottomTxt}>view all </div>
            <div className={styles["icon"]}>
              <ChevronIcon />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Tables;
