export const SET_CUSTOMER_DATA = 'SET_CUSTOMER_DATA';
export const SET_ORDER_DATA = 'SET_ORDER_DATA';
export const SET_CASHFLOW_DATA = 'SET_CASHFLOW_DATA';
export const SET_RECENT_ORDERS = 'SET_RECENT_ORDERS';
export const SET_PENDING_ORDERS = 'SET_PENDING_ORDERS';
export const SET_USER_STATS = 'SET_USER_DATA';
export const SET_PAYMENTS = 'SET_PAYMENTS';
export const SET_LOGS = 'SET_LOGS'




