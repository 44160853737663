import React from "react";
import { NumberInput } from "../../../../UI/FormComponents/Input";
import FormGroupHeader from "../../../../UI/FormComponents/FormHeaders/FormGroupHeader";
import TextArea from "../../../../UI/FormComponents/InputTypes/TextArea";
import styles from "./AnotherPayment.module.scss";
import useAnotherPayment from "./hooks/useAnotherPayment";
import Button from "../../../../UI/Button/Button";
import getSymbolFromCurrency from "currency-symbol-map";

function AnotherPayment({ onClose, order, orderId }) {
  const { state, dispatch, handleSubmit } = useAnotherPayment(orderId, onClose);
  return (
    <div className={styles.mains}>
      <FormGroupHeader
        title={
          state.next ? "Payment has been created" : "Collect another payment"
        }
        // description="General information about this Kitchen"
      />
      {!state.next ? (
        <>
          <NumberInput
            name="amount"
            label="Amount"
            value={state?.amount}
            units={getSymbolFromCurrency(order?.currency)}
            onChange={({ target }) => dispatch({ amount: target.value })}
          />
          <TextArea
            name="reason"
            label="Reason for payment"
            value={state?.reason}
            onChange={({ target }) => dispatch({ reason: target.value })}
          />
        </>
      ) : (
        <div className={styles["sendPaymentLink"]}>
          <section className={styles["section"]}>
            <p className={styles["message"]}>
              Your order has been placed, but cannot be processed until payment
              has been made. To complete this transaction please make payment
              using *any* of the following methods:
            </p>
            <p className={styles["paystack-name"]}>Paystack:</p>
            <a href={state?.paymentLink} className={styles["link"]}><span className={styles["link-text"]}>{state?.paymentLink}</span></a>

            <p>Flutterwave:</p>
            <p className={styles["unavailable"]}>Unavailable</p>

            <p>Bank Transfer</p>
            <p className={styles["unavailable"]}>Unavailable</p>
          </section>
        </div>
      )}
      <div className={styles.cards}>
        <Button
          name={state.next ? "Copy as Message" : "Create Payment"}
          bgColor="green-bg"
          custom
          createFnc={handleSubmit}
          isLoading={state.isLoading}
        />
      </div>
    </div>
  );
}

export default AnotherPayment;
