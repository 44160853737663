import React from "react";
import Card from "../../../../UI/Card/Card";
import {
  TextInput,
  TextAreaInput,
} from "../../../../UI/FormComponents/Input";
import FormGroupHeader from "../../../../UI/FormComponents/FormHeaders/FormGroupHeader";
import styles from "../../AddRoleForm.module.scss";
import Button from "../../../../UI/Button/Button";

const FormLHS = () => {
  return (
    <Card classes={styles["form-6grid-main"]}>
      <FormGroupHeader
        title="Management Role Information"
        description="General information about this Management Role"
      />
      <TextInput name="role_name" label="name" type="text"/>
      <TextAreaInput name="role_description" label="Description" />
      <div className={styles["btn-div"]}>
      <Button name="Role" bgColor="green-bg" />
      </div>
    </Card>
  );
};

export default FormLHS;
