import React from "react";
import TopDisplay from "../../../../UI/TopDisplay/TopDisplay";
import locationstyles from "../../Stocks.module.scss";
import FormLHS from "./FormLHS";
import useTopup from "./hooks/useTopup";

const TopUp = ({ stockId, onClose, manual }) => {
  const { stocks, dispatch, state, handleSubmit } = useTopup(stockId, onClose, manual)
  return (
    <div className={locationstyles.mains}>
      <TopDisplay
        title={manual ? "Change Stock Value" : "Top Up Stock"}
      />
      <div className={locationstyles.side}>
        <FormLHS
          stockId={stockId}
          stocks={stocks}
          state={state}
          handleSubmit={handleSubmit}
          dispatch={dispatch}
        />
      </div>
    </div>
  );
};

export default TopUp;
