import {
    SET_RIDERS,
    SET_RIDER,
    SET_RIDER_ID,
    SET_READY_ORDER,
  } from "../actionTypes/ridersTypes";
  
  export function setRiders(data) {
    return (dispatch) => {
      dispatch({ type: SET_RIDERS, data });
    };
  }

  export function setReadyOrders(data) {
    return (dispatch) => {
      dispatch({ type: SET_READY_ORDER, data });
    };
  }
  
  export function setRider(data) {
    return (dispatch) => {
      dispatch({ type: SET_RIDER, data });
    };
  }
  
  export function setRiderId(data) {
    return (dispatch) => {
      dispatch({ type: SET_RIDER_ID, data });
    };
  }
  
  