import { useCallback, useEffect, useMemo, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import {
  setInfluencers,
  setVoucher,
} from "../../../../redux/actions/discount.Action";
import { showPopup } from "../../../../redux/actions/popUp.action";
import { paths } from "../../../../Routes/path";
import discountServices from "../../../../Services/discount.services";
import { objectToArray } from "../../../../utils/helpers";
import useVoucher from "./useVoucher";

function useAddVoucher(edit) {
  const { getVouchers } = useVoucher();
  const { voucher, influencers } = useSelector((state) => state.discount);
  const { ispopupOpen } = useSelector((state) => state.popup);
  const initState = {
    name: "",
    influencerId: null,
    discountCode: "",
    expirationDate: "",
    percentage: null,
    isLoading: false,
  };

  const { voucherId } = useParams();
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();

  const myObjectString = localStorage.getItem("discount");

  const currentUser = JSON.parse(myObjectString);
  const currentUserAbilities = currentUser?.account_abilities?.abilities;

  const [state, dispatch] = useReducer(
    (discountState, value) => ({ ...discountState, ...value }),
    initState
  );

  useEffect(() => {
    if (edit) {
      dispatch({
        name: voucher?.campaign_name,
        discountCode: voucher?.code,
        influencerId: voucher?.influencer_id,
        percentage: voucher?.percentage,
        expirationDate: voucher?.expiration_date,
      });
    }
  }, [voucher, edit]);

  const getInfluencers = useCallback(() => {
    discountServices
      .getTheInfluencers()
      .then(({ data }) => {
        let valuesArr = data.map((item) => ({
          name: item?.full_name,
          id: item?.id,
        }));
        setInfluencers(valuesArr)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
  }, [reduxDispatch]);

  const handleForm = (e) => {
    if (!state?.name) {
      toast.error("Name cannot be empty");
      return;
    }
    if (!state?.discountCode) {
      toast.error("Code cannot be empty");
      return;
    }
    if (!state?.influencerId) {
      toast.error("Please select an influencer");
      return;
    }
    if (!state?.percentage) {
      toast.error("Percentage cannot be empty");
      return;
    }
    handleSubmit(e);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Submitting:", state);
    if (!edit) {
      dispatch({ isLoading: true });
      discountServices
        .createVoucher({
          voucher: {
            campaign_name: state?.name,
            code: state?.discountCode,
            influencer_id: state?.influencerId,
            percentage: state?.percentage,
            expiration_date: state?.expirationDate,
          },
        })
        .then(() => {
          toast.success("Voucher was succesfully created");
          dispatch({ isLoading: false });
          reduxDispatch(showPopup({ ispopupOpen: false }));
          navigate(paths.voucher);
          getVouchers(1, 10);
        })
        .catch((err) => {
          dispatch({ isLoading: false });
          if (err.response.status === 422) {
            let errorMsg = objectToArray(err?.response?.data?.data);
            toast.error(`${errorMsg[0][0]} ${errorMsg[0][1][0]}`);
          } else {
            toast.error(err.message);
          }
        });
    } else {
      dispatch({ isLoading: true });
      discountServices
        .updateVoucher(voucherId, {
          voucher: {
            campaign_name: state?.name,
            code: state?.discountCode,
            influencer_id: state?.influencerId,
            percentage: state?.percentage,
            expiration_date: state?.expirationDate,
          },
        })
        .then(() => {
          dispatch({ isLoading: false });
          toast.success("Voucher was succesfully updated");
          navigate(paths.voucher);
          reduxDispatch(showPopup({ ispopupOpen: false }));
          getVouchers(1, 10);
        })
        .catch((err) => {
          dispatch({ isLoading: false });
          if (err.response.status === 422) {
            let errorMsg = objectToArray(err?.response?.data?.data);
            toast.error(`${errorMsg[0][0]} ${errorMsg[0][1][0]}`);
          } else {
            toast.error(err.message);
          }
        });
    }
  };

  const getAVoucher = useCallback(() => {
    discountServices
      .getAVoucher(voucherId)
      .then(({ data }) => {
        setVoucher(data)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
  }, [voucherId, reduxDispatch]);

  useEffect(() => {
    getInfluencers();
    if (edit && ispopupOpen) {
      getAVoucher();
    }
  }, [edit, getAVoucher, ispopupOpen, getInfluencers]);

  const pageTitle = useMemo(() => {
    if (edit) {
      return "Update Voucher";
    }
    if (!edit) {
      return "Add New Voucher";
    }
  }, [edit]);

  return {
    state,
    handleForm,
    dispatch,
    pageTitle,
    currentUserAbilities,
    influencers,
  };
}

export default useAddVoucher;
