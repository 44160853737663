import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { setIsLoading } from "../../../../redux/actions/appAction";
import {
  setDeliveryRegions,
  setDeliveryZones,
  setZoneByRegion,
} from "../../../../redux/actions/deliveryZonesAction";
import {
  setIsDeleting,
  setPopupComponent,
  showPopup,
} from "../../../../redux/actions/popUp.action";
import { paths } from "../../../../Routes/path";
import deliveryZonesServices from "../../../../Services/deliveryZones.services";
import locationServices from "../../../../Services/location.services";
import DeleteModal from "../../../UI/DeleteModal";

function useDeliveryAreas() {
  const reduxDispatch = useDispatch();
  const { deliveryZones, zoneByRegion, deliveryRegions: region } = useSelector(
    (state) => state.delivery
  );
  const { openModal } = useSelector((state) => state.app);
  const navigate = useNavigate();
  const getAllRegions = useCallback(() => {
    setIsLoading(true)(reduxDispatch);
    locationServices
      .getAllRegions()
      .then(({ data }) => {
        let newArray = data?.map((item) => ({
          status: item?.state,
          count: item?.delivery_areas_count,
          id: item?.state,
        }));
        setDeliveryRegions(newArray)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      })
      .finally(() => setIsLoading(false)(reduxDispatch));
  }, [reduxDispatch]);

  const getAllDeliveryZones = useCallback(() => {
    deliveryZonesServices
      .getDeliveryZones()
      .then(({ data }) => {
        setDeliveryZones(data)(reduxDispatch);
        setZoneByRegion(data)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      })
      .finally(() => setIsLoading(false)(reduxDispatch));
  }, [reduxDispatch]);

  const getADeliveryZone = (zoneId) => {
    deliveryZonesServices
      .getADeliveryZone(zoneId)
      .then(({ data }) => {
        setZoneByRegion(data)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
  };

  const secondRowFcn = (id) => {
    const newArr = zoneByRegion?.filter(
      (item) => item?.region_details.name === id
    );
    setDeliveryZones(newArr)(reduxDispatch);
  };

  const secondRowAllFcn = () => {
    getAllDeliveryZones();
  };

  // useEffect(() => {
  //    setRegion([])(reduxDispatch)
  //   // eslint-disable-next-line
  // },[])

  useEffect(() => {
    getAllRegions();
    getAllDeliveryZones();
  }, [getAllRegions, getAllDeliveryZones]);

  const onClose = () => {
    showPopup({ ispopupOpen: false })(reduxDispatch);
  };

  const handleDelete = (zoneId) => {
    setIsDeleting(true)(reduxDispatch);
    deliveryZonesServices
      .deleteDeliveryZone(zoneId)
      .then(() => {
        toast.success("Area was deleted");
        getAllDeliveryZones();
        getAllRegions();
        onClose();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => {
        navigate(paths.deliveryAreas);
        setIsDeleting(false)(reduxDispatch);
      });
  };

  const onClickAction = (zoneId) => {
    reduxDispatch(
      setPopupComponent(
        <DeleteModal
          onClose={onClose}
          handleDelete={() => handleDelete(zoneId)}
          title="Delivery Area"
        />
      )
    );
    showPopup({ ispopupOpen: true, onClose: () => onClose() })(reduxDispatch);
  };

  const myObjectString = localStorage.getItem("user");

  const currentUser = JSON.parse(myObjectString);
  const currentUserAbilities = currentUser?.account_abilities?.abilities?.["delivery_zone"];
  return {
    region,
    secondRowAllFcn,
    deliveryZones,
    secondRowFcn,
    onClickAction,
    onClose,
    openModal,
    getADeliveryZone,
    currentUserAbilities,
  };
}

export default useDeliveryAreas;
