import React from "react";
import BulkUpload from "../../../../UI/BulkUpload/BulkUpload";
import { EditIcon, PlusIcon } from "../../../../UI/RegularIcons";
import Tables from "../../../../UI/Tables/Tables";
import styles from "./DTable.module.scss";
import useDTable from "./hooks/useDTable";
import Button from "../../../../UI/Button/Button";

function DTable({ inventories, onClickAction, currentUserAbilities }) {
  const {
    isLoading,
    sideBarType,
    addInventory,
    onSelectImage,
    downloadInventories,
    isDownloading,
    manualInventory,
  } = useDTable(onClickAction, currentUserAbilities);

  const columns = [
    {
      name: "Code",
      selector: (row) => row.code,
      sortable: true,
      width: "12%",
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "30%",
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      name: <span>Total Available</span>,
      width: "58%",
      selector: (row, i) => (
        <>
          {!sideBarType ? (
            <div className={styles["inventory"]}>
              <div className={styles["qty-div"]}>
                {row?.related_quantity} {row?.measure}
              </div>
              <div className={styles["buttons"]}>
                <div
                  className={styles["add-inventory"]}
                  onClick={() => addInventory(row?.id)}
                >
                  <span className={styles["plus-icon"]}>
                    <PlusIcon />
                  </span>
                  <span className={styles["edit-text"]}>Add to Inventory</span>
                </div>
                {!sideBarType && (
                  <div className={styles["reset-inventory"]}>
                    <span className={styles["edit-icon"]}>
                      <EditIcon />
                    </span>
                    <span
                      className={styles["reset-text"]}
                      onClick={() => manualInventory(row?.id)}
                    >
                      Change Manually
                    </span>
                  </div>
                )}
              </div>
            </div>
          ) : (
            row?.related_quantity
          )}
        </>
      ),
      sortable: true,
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
  ];

  return (
    <div className={styles.divv}>
      <Tables
        data={inventories}
        columns={columns}
        pagination
        title="All Inventories"
        type="orders"
        atcBtn2={
          <div className={styles["second-field"]}>
            <div className={styles.btnExport}>
              {" "}
              <Button
                name="Export To CSV"
                bgColor="black-bg"
                createFnc={downloadInventories}
                isLoading={isDownloading}
                custom
              />
            </div>
            <div className={styles.searchBar}>
              <BulkUpload name="Inventories" createFn={onSelectImage} />
            </div>
          </div>
        }
        firstRow
        selectableRows
        fetchingData={isLoading}
      />
    </div>
  );
}

export default DTable;
