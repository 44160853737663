import { combineReducers } from 'redux';
import appReducer from './appReducer';
import categoriesReducer from './categoriesReducer';
import customerReducer from './customerReducer';
import deliveryZoneReducer from './deliveryZoneReducer';
import departmentReducer from './departmentReducer';
import discountReducer from './discountReducer';
import inventoryReducer from './inventoryReducer';
import kitchenReducer from './kitchenReducer';
import locationReducer from './locationReducer';
import orderReducer from './orderReducer';
import popupReducer from './popUpReducer';
import productReducer from './productsReducer';
import riderReducer from './ridersReducer';
import staffReducer from './staffReducer';
import stockReducer from './stockReducer';
import dashboardReducer from './dashboardReducer';

export default combineReducers({
  app: appReducer,
  popup: popupReducer,
  location: locationReducer,
  category: categoriesReducer,
  product: productReducer,
  inventory: inventoryReducer,
  stock: stockReducer,
  rider: riderReducer,
  delivery: deliveryZoneReducer,
  kitchen: kitchenReducer,
  order: orderReducer,
  customer: customerReducer,
  staff: staffReducer,
  department: departmentReducer,
  discount: discountReducer,
  dashboard: dashboardReducer,
});
