import React from "react";
import MetaTags from "react-meta-tags";
import Grid from "../../UI/Grid/Grid";
import GridRow from "../../UI/Grid/GridRow";
import FormLHS from "./component/FormGroups/FormLHS";
import TopDisplay from "../../UI/TopDisplay/TopDisplay";
import styles from "./AddStaffForm.module.scss";
import areastyles from "./Staff.module.scss";
import FormRHS from "./component/FormGroups/FormRHS";
import useManageStaff from "./hooks/useManageStaff";

const AddStaffForm = ({ edit }) => {
  const { state, dispatch, onSelectImage, handleSubmit, departments, region, kitchenZones } = useManageStaff(edit);
  return (
    <>
      <MetaTags>
        <title>Add New Staff Page</title>
        <meta name="description" content="#" />
      </MetaTags>
      <div className={areastyles.main}>
        <TopDisplay
          title={edit ? "Update Staff" : "Add New Staff"}
          firstText="Management"
          secondText="Staff"
          thirdText={edit ? "Update Staff" : "Add New Staff"}
          style2
        />
        <div className={styles["form-main"]}>
          <Grid span="9">
            <GridRow span="6" customClass="section2">
              <FormLHS
              edit={edit}
              state={state}
              dispatch={dispatch}
              onSelectImage={onSelectImage}
              />
            </GridRow>
            <GridRow span="3" customClass="section2">
              <FormRHS
              edit={edit}
              onSelectImage={onSelectImage}
              state={state}
              handleSubmit={handleSubmit}
              dispatch={dispatch}
              departments={departments}
              regions={region}
              kitchenZones={kitchenZones}
              />
            </GridRow>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default AddStaffForm;
