import React, { useMemo } from "react";
import { cashFormater, trendType } from "../../../../../utils/helpers";
import Grid from "../../../../UI/Grid/Grid";
import GridRow from "../../../../UI/Grid/GridRow";
import Cards from "../Cards/Cards";
import Skeleton from "react-loading-skeleton";

function TopSide({ state, customerData, orderData, cashflowData }) {
  return (
    <Grid span="9">
      <GridRow span="3" customClass="section2">
        {state?.isLoadingCustomerData ? (
          <Skeleton height={138} width="310px" borderRadius="16px" />
        ) : (
          <Cards
            title="Total Active Customers"
            percentage={customerData?.growth_rate}
            qty={cashFormater(customerData?.total_users)}
            trend={trendType(customerData?.growth_rate)}
          />
        )}
      </GridRow>

      <GridRow span="3" customClass="section2">
        {state?.isLoadingOrderData ? (
          <Skeleton height={138} width="310px" borderRadius="16px" />
        ) : (
          <Cards
            title="Today's Orders"
            percentage={orderData?.daily_growth_rate}
            qty={orderData?.current_day_count}
            trend={trendType(orderData?.daily_growth_rate)}
          />
        )}
      </GridRow>

      <GridRow span="3" customClass="section2">
        {state?.isLoadingCashData ? (
          <Skeleton height={138} width="310px" borderRadius="16px" />
        ) : (
          <Cards
            title="Today's Cash Inflow"
            percentage={cashflowData?.daily_growth_rate}
            qty={`₦ ${cashFormater(cashflowData?.current_day_amount)}`}
            trend={trendType(cashflowData?.daily_growth_rate)}
          />
        )}
      </GridRow>
    </Grid>
  );
}

export default TopSide;
