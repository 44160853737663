import React from "react";
import Card from "../../../../UI/Card/Card";
import {
  TextInput,
  TextAreaInput,
  FileInput,
} from "../../../../UI/FormComponents/Input";
import FormGroupHeader from "../../../../UI/FormComponents/FormHeaders/FormGroupHeader";
import styles from "../../AddCategoryForm.module.scss";
import Button from "../../../../UI/Button/Button";

const FormLHS = ({ edit, state, handleSubmit, dispatch, onSelectImage }) => {
  const { name, description, image, image_src, isLoading, imgName } = state;
  return (
    <Card classes={styles["form-6grid-main"]}>
      <FormGroupHeader
        title="Category Information"
        description="General information about this Category"
      />
      <TextInput
        name="category_name"
        label="name"
        type="text"
        value={name}
        onChange={({ target }) => dispatch({name: target.value})}
      />
      <TextAreaInput
        name="role_description"
        label="Description"
        value={description}
        onChange={({ target }) => dispatch({description: target.value})}
      />
      <FileInput
        name="category_image"
        label=" Image"
        onSelectImage={onSelectImage}
        value={image}
        imgSrc={image_src}
        imgName={imgName}
      />
      <div className={styles["btn-div"]}>
        <Button
          name="Category"
          bgColor="green-bg"
          createFnc={handleSubmit}
          edit={edit}
          isLoading={isLoading}
        />
      </div>
    </Card>
  );
};

export default FormLHS;
