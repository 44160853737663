import { SET_CASHFLOW_DATA, SET_CUSTOMER_DATA, SET_ORDER_DATA, SET_PAYMENTS, SET_LOGS, SET_PENDING_ORDERS, SET_RECENT_ORDERS, SET_USER_STATS } from '../actionTypes/dashboardTypes';

export function setCustomerData(data) {
  return dispatch => {
    dispatch({ type: SET_CUSTOMER_DATA, data });
  };
}
export function setOrderData(data) {
    return dispatch => {
      dispatch({ type: SET_ORDER_DATA, data });
    };
  }
  
  export function setCashflowData(data) {
    return dispatch => {
      dispatch({ type: SET_CASHFLOW_DATA, data });
    };
  }

  export function setRecentOrders(data) {
    return dispatch => {
      dispatch({ type: SET_RECENT_ORDERS, data });
    };
  }

  export function setPendingOrders(data) {
    return dispatch => {
      dispatch({ type: SET_PENDING_ORDERS, data });
    };
  }

  export function setUserStats(data) {
    return dispatch => {
      dispatch({ type: SET_USER_STATS, data });
    };
  }

  export function setPayments(data) {
    return dispatch => {
      dispatch({ type: SET_PAYMENTS, data });
    };
  }

  export function setLogs(data) {
    return dispatch => {
      dispatch({ type: SET_LOGS, data });
    };
  }
  
  
  

  