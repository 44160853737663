import React, { useState } from "react";
import styles from "./Text.module.scss";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";

const Text = (props) => {
  const [showPassword, setShowPasssword] = useState(false);
  return (
    <div className={styles["form-group"]}>
      <div className={styles["form-container"]}>
        <input
          type={showPassword ? "text" : props.type}
          name={props.name}
          value={props.value}
          placeholder=" "
          onChange={props.onChange}
          onBlur={props.onBlur}
          disabled={props.disabled}
        />
        <label htmlFor={props.name}>{props.label}</label>
        {props.type === "password" && (
          <span
            onClick={() => setShowPasssword(!showPassword)}
            className={styles.passwordEndIcon}
          >
            {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
          </span>
        )}
      </div>
    </div>
  );
};

export default Text;
