import { API_URL } from "../env";
import { request } from "./api";

const URL = {
  getCustomers:(page, perPage) => `${API_URL}api/v1/be/users?page=${page}&per_page=${perPage}`,
  getACustomer: (customerId) => `${API_URL}api/v1/be/users/${customerId}`,
  createCustomer: `${API_URL}api/v1/be/users/new`,
  deleteCustomer: (customerId) => `${API_URL}api/v1/be/users/${customerId}`,
  updateCustomer: (customerId) => `${API_URL}api/v1/be/users/${customerId}`,
};

function getCustomers(page, perPage) {
  return request(URL.getCustomers(page, perPage), "get");
}

function getACustomer(customerId) {
  return request(URL.getACustomer(customerId), "get");
}

function deleteCustomer(customerId, customer) {
  return request(URL.deleteCustomer(customerId), customer, "delte");
}

function createCustomer(customer) {
  return request(URL.createCustomer, customer, "post");
}

function updateCustomer(customerId, customer) {
  return request(URL.updateCustomer(customerId), customer, "put");
}

const customersServices = {
  getCustomers,
  getACustomer,
  updateCustomer,
  createCustomer,
  deleteCustomer,
};

export default customersServices;
