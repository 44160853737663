import React from "react";
import { FaCheck } from "react-icons/fa";
import "./CheckBox.scss";

const CheckBox = ({ active, setActive, label }) => {
  return (
    <div className="wrapper">
      <div
        onClick={setActive}
        className={`checkbox ${active ? "active_" : "inactive_"}`}
      >
        <FaCheck className={`icon ${active ? "active" : "inactive"}`} />
      </div>
      <span className="label">{label}</span>
    </div>
  );
};

export default CheckBox;
