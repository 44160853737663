import React from "react";
import MetaTags from "react-meta-tags";
import Grid from "../../UI/Grid/Grid";
import GridRow from "../../UI/Grid/GridRow";
import TopDisplay from "../../UI/TopDisplay/TopDisplay";
import DTable from "./component/DTable/DTable";
import useOrder from "./hooks/useOrder";
import styles from "./Orders.module.scss";

function Orders() {
  const {
    handlePageChange,
    handlePerRowsChange,
    orders,
    totalRows,
    onClickAction,
    getAnOrder,
    orderStatus,
    totalOrders,
    secondRowAllFcn,
    secondRowFcn,
    currentUserAbilities,
  } = useOrder();
  return (
    <>
      <MetaTags>
        <title>Order Page</title>
        <meta name="description" content="#" />
      </MetaTags>
        <div className={styles.main}>
          <TopDisplay
            title="Orders"
            firstText="Dashboard"
            secondText="Orders"
            style1
          />
          <div className={styles.section2}>
            <Grid span="9">
              <GridRow span="9" customClass="section2">
                <DTable
                  handlePageChange={handlePageChange}
                  handlePerRowsChange={handlePerRowsChange}
                  orders={orders}
                  totalRows={totalRows}
                  getAnOrder={getAnOrder}
                  onClickAction={onClickAction}
                  orderStatus={orderStatus}
                  totalOrders={totalOrders}
                  secondRowAllFcn={secondRowAllFcn}
                  secondRowFcn={secondRowFcn}
                  currentUserAbilities={currentUserAbilities}
                />
              </GridRow>
            </Grid>
          </div>
        </div>
    </>
  );
}

export default Orders;
