import React from "react";
import MetaTags from "react-meta-tags";
import Grid from "../../UI/Grid/Grid";
import GridRow from "../../UI/Grid/GridRow";
import FormLHS from "./component/FormGroups/FormLHS";
import TopDisplay from "../../UI/TopDisplay/TopDisplay";
import styles from "./AddKitchenForm.module.scss";
import kitchenstyles from "./Kitchen.module.scss";
import useAddKitchen from "./hooks/useAddKitchen";
import FormRHS from "./component/FormGroups/FormRHS";

const AddKitchenForm = ({ edit }) => {
  const { region, state, dispatch, handleSubmit } = useAddKitchen(edit);
  return (
    <>
      <MetaTags>
        <title>Add Kitchen Page</title>
        <meta name="description" content="#" />
      </MetaTags>
      <div className={kitchenstyles.main}>
        <TopDisplay
          title={edit ? "Update Kitchen" : "Add New Kitchen"}
          firstText="Locations"
          secondText="Kitchen"
          thirdText={edit ? "Update Kitchen" : "Add New Kitchen"}
          style2
        />
        <div className={styles["form-main"]}>
          <Grid span="9">
            <GridRow span="6" customClass="section2">
              <FormLHS
                edit={edit}
                region={region}
                state={state}
                dispatch={dispatch}
              />
            </GridRow>
            <GridRow span="3" customClass="section2">
              <FormRHS
                edit={edit}
                handleSubmit={handleSubmit}
                state={state}
                dispatch={dispatch}
              />
            </GridRow>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default AddKitchenForm;
