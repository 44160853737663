import React, { useMemo } from "react";
import {
  DownloadPdfIcon,
  ShareLinkIcon,
  SharePaymentIcon,
} from "../../../../UI/RegularIcons";
import { RWebShare } from "react-web-share";
import { useReactToPrint } from "react-to-print";
import styles from "./TopSide.module.scss";
import { toast } from "react-toastify";
import SendPaymentLink from "../SendPaymentLink/SendPaymentLink";
import { camelToNormalCase } from "../../../../../utils/helpers";

function TopSide({
  state,
  reference,
  sendPaymentLink,
  componentRef,
  openModal,
  setOpenModal,
  refreshOrder,
  collectPayment,
  viewPayments,
}) {
  const handlePrint = useReactToPrint({
    pageStyle: `@media print {
      @page {
        size: 500mm 500mm;
        margin: 0;
      }
    }`,
    content: () => componentRef.current,
    documentTitle: "order-details",
    // onAfterPrint: () => toast.success("Print successful")
  });

  const paymentLink = useMemo(
    () => state?.order_payment?.paystack?.checkout_url || "",
    [state]
  );

  return (
    <section className={styles["section-one"]}>
      <div className={styles["status"]}>
        <div className={styles["status-div"]}>
          <span className={styles[state?.status]}>
            {(camelToNormalCase(state?.status))?.toUpperCase()}
          </span>
          {state.paid && (
            <span className={styles["view-payments"]} onClick={viewPayments}>
              View payments
            </span>
          )}
        </div>
        <div className={styles["download-div"]}>
          {state.status === "initiated" && (
            <RWebShare
              data={{
                text: `Share - ${reference} payment link`,
                url: paymentLink,
                title: `${reference} payment link`,
              }}
              onClick={() => toast.message("shared successfully!")}
            >
              <span className={styles["pdf-icon"]}>
                {" "}
                <ShareLinkIcon />{" "}
              </span>
            </RWebShare>
          )}
          <span className={styles["pdf-icon"]} onClick={handlePrint}>
            {" "}
            <DownloadPdfIcon />{" "}
          </span>
          {state.status === "initiated" && (
            <div
              className={styles["send-div"]}
              onClick={(event) => {
                event.stopPropagation();
                sendPaymentLink(state);
              }}
            >
              <SharePaymentIcon />
            </div>
          )}
          {state?.paid && state.status !== "completed" && (
            <div className={styles["paid-div"]}>
              <button className={styles["button"]} onClick={refreshOrder}>
                Refresh Order
              </button>
              <button className={styles["button2"]} onClick={collectPayment}>
                Collect another payment
              </button>
            </div>
          )}
        </div>
      </div>
      {openModal && (
        <div
          className={styles["send-payment"]}
          onClick={(event) => event.stopPropagation()}
        >
          <SendPaymentLink
            payment={paymentLink}
            onClose={() => setOpenModal(false)}
          />
        </div>
      )}
      <div className={styles["reference"]}>{reference}</div>
    </section>
  );
}

export default TopSide;
