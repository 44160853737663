import React, { useEffect, useState } from "react";
import Card from "../../../../UI/Card/Card";
import {
  TextInput,
  TextAreaInput,
  SelectInput,
  FileInput,
} from "../../../../UI/FormComponents/Input";
import FormGroupHeader from "../../../../UI/FormComponents/FormHeaders/FormGroupHeader";
import styles from "../../AddProductForm.module.scss";
import AddItemArray from "../../../../UI/AddItemArray/AddItemArray";
import CheckBox from "../../../../UI/FormComponents/InputTypes/CheckBox";
import AutoComplete from "../../../../UI/FormComponents/InputTypes/AutoComplete";
import categoriesServices from "../../../../../Services/categories.services";
import { IoIosClose } from "react-icons/io";
import { toast } from "react-toastify";

const FormLHS = ({
  edit,
  state,
  dispatch,
  onSelectImage,
  categories,
  stocks,
  inventories,
  allergens,
  setAllergens,
  products,
  selectedProducts,
  fetchingProducts,
  dep_product,
  addOnCategory,
  handleAddOnCatgory,
  handleDependentProducts,
  removeSelectedProduct,
  onSale,
  setOnsale,
}) => {
  const {
    title,
    description,
    image,
    categoryId,
    image_src,
    has_spice,
    has_dependent_product,
    has_allergens,
  } = state;

  return (
    <Card classes={styles["form-6grid-main"]}>
      <FormGroupHeader
        title="Product Information"
        description="General information about this product"
      />
      <SelectInput
        name="product_category"
        label="Product Category"
        value={categoryId}
        options={categories}
        onChange={(e) => dispatch({ categoryId: e.target.value })}
      />
      <TextInput
        name="product_name"
        label="Product Title"
        type="text"
        value={title}
        onChange={({ target }) => dispatch({ title: target.value })}
      />
      <TextAreaInput
        name="product_description"
        label="Description"
        value={description}
        onChange={({ target }) => dispatch({ description: target.value })}
      />
      <FileInput
        name="product_image"
        label="Product Image"
        onSelectImage={onSelectImage}
        value={image}
        imgSrc={image_src}
      />
      <div className={styles["inventory-div"]}>
        <FormGroupHeader
          title="Inventories"
          description="Quantity of each inventory that makes up a portion of this product"
        />
        <AddItemArray type="inventory" inventoryOptions={inventories} />
      </div>
      <div className={styles["inventory-div"]}>
        <FormGroupHeader
          title="Stocks"
          description="Quantity of each stock item that makes up a portion of this product"
        />
        <AddItemArray type="stock" stockOptions={stocks} />
      </div>
      <div className={styles["inventory-div"]}>
        <FormGroupHeader title="Add-Ons" description="" />
        <div className={styles["add-on-container"]}>
          <span className={styles["desc"]}>
            Check this box if this product is going to be on sale
          </span>
          <div className={styles["add-on"]}>
            <CheckBox
              active={onSale}
              setActive={() => setOnsale(!onSale)}
              label={"On Sale"}
            />
          </div>
        </div>
        <div className={styles["add-on-container"]}>
          <span className={styles["desc"]}>
            Check this box if this product have spice levels
          </span>
          <div className={styles["add-on"]}>
            <CheckBox
              active={has_spice}
              setActive={() => dispatch({ has_spice: !has_spice })}
              label={"Has spice"}
            />
          </div>
        </div>
        <div className={styles["add-on-container"]}>
          <span className={styles["desc"]}>
            Check this box if this product have dependent products
          </span>
          <div className={styles["add-on"]}>
            <CheckBox
              active={has_dependent_product}
              setActive={() => {
                if (selectedProducts?.length === 0)
                  dispatch({ has_dependent_product: !has_dependent_product });
                else {
                  toast.error("Remove all dependent products");
                }
              }}
              label={"Has dependent products"}
            />
            {has_dependent_product && (
              <div className={styles["bg-wrap"]}>
                <span className={styles["desc"]}>
                  Select dependent products
                </span>
                <div className={styles["row"]}>
                  <SelectInput
                    name="product_category"
                    label="Product Category"
                    value={addOnCategory}
                    options={categories}
                    onChange={(e) => handleAddOnCatgory(e.target.value)}
                  />
                  <AutoComplete
                    options={products}
                    label={"Search product"}
                    value={dep_product}
                    onChange={handleDependentProducts}
                  />
                </div>
                <div className={styles["selected_items"]}>
                  {selectedProducts?.map((s, n) => (
                    <div className={styles["item"]} key={n}>
                      <img src={s?.image?.url} alt="" />
                      <span>{s?.title}</span>
                      <IoIosClose
                        className="icon"
                        onClick={() => removeSelectedProduct(s)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className={styles["add-on-container"]}>
            <div className={styles["add-on"]}>
              <div className={styles["bg-wrap"]}>
                <span className={styles["desc"]}>Select allergens</span>
                <div className={styles["row"]}>
                  {allergens &&
                    Object.keys(allergens)?.map((allergen, n) => (
                      <CheckBox
                        key={n}
                        active={allergens[allergen]}
                        setActive={() =>
                          setAllergens({
                            ...allergens,
                            [allergen]: !allergens[allergen],
                          })
                        }
                        label={allergen.replaceAll("_", " ")}
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <AddItemArray type="inventory" inventoryOptions={inventories} /> */}
      </div>
    </Card>
  );
};

export default FormLHS;
