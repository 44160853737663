import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { setIsLoading } from "../../../../redux/actions/appAction";
import { setCustomers } from "../../../../redux/actions/customer.Action";
import {
  setIsDeleting,
  setPopupComponent,
  showPopup,
} from "../../../../redux/actions/popUp.action";
import { paths } from "../../../../Routes/path";
import customersServices from "../../../../Services/customer.services";
import DeleteModal from "../../../UI/DeleteModal";

function useCustomer() {
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const navigate = useNavigate();
  const { openModal, openAddModal, openEditModal } = useSelector(
    (state) => state.app
  );

  const myObjectString = localStorage.getItem('user');

  const currentUser = JSON.parse(myObjectString)
  const currentUserAbilities = currentUser?.account_abilities?.abilities

  const reduxDispatch = useDispatch();
  const { customers } = useSelector((state) => state.customer);

  const getCustomers = useCallback(
    (page, perPage) => {
      setIsLoading(true)(reduxDispatch);
      customersServices
        .getCustomers(page, perPage)
        .then(({ data }) => {
          setCustomers(data?.data)(reduxDispatch);
          setTotalRows(data?.pagination?.total);
        })
        .catch((err) => {
          toast.error(err.message || "Unable to get");
        })
        .finally(() => setIsLoading(false)(reduxDispatch));
    },
    [reduxDispatch]
  );

  useEffect(() => {
    getCustomers(1, perPage);
  }, [getCustomers, perPage]);

  const handlePageChange = (page) => {
    getCustomers(page, perPage);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setIsLoading(true)(reduxDispatch);
    customersServices
      .getCustomers(page, newPerPage)
      .then(({ data }) => {
        setCustomers(data?.data)(reduxDispatch);
        setPerPage(newPerPage);
        setTotalRows(data.pagination.total);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      })
      .finally(() => setIsLoading(false)(reduxDispatch));
  };

  const onClose = () => {
    showPopup({ ispopupOpen: false })(reduxDispatch);
  };

  const onAddCustomer = () => {
    navigate(paths.newCustomer)
  };

  const handleDelete = (customerId) => {
    setIsDeleting(true)(reduxDispatch);
    customersServices
      .deleteCustomer(customerId)
      .then(() => {
        toast.success("Customer was deleted");
        getCustomers();
        onClose();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => {
        setIsDeleting(false)(reduxDispatch);
        navigate(paths.customers)
      });
  };

  const onClickAction = (customerId) => {
    reduxDispatch(
      setPopupComponent(
        <DeleteModal
          onClose={onClose}
          handleDelete={() => handleDelete(customerId)}
          title="Customer"
        />
      )
    );
    showPopup({ ispopupOpen: true, onClose: () => onClose() })(reduxDispatch);
  };

  // useEffect(() => {
  //   setOpenAddModal(/\/customers\/new$/g.test(location.pathname))(reduxDispatch);
  // }, [location.pathname, reduxDispatch]);

  // useEffect(() => {
  //   setOpenEditModal(/^\/customers\/edit\/\d+\/?$/.test(location.pathname))(reduxDispatch);
  // }, [location.pathname, reduxDispatch]);

  return {
    handlePageChange,
    handlePerRowsChange,
    customers,
    totalRows,
    handleDelete,
    onClickAction,
    onClose,
    openModal,
    openAddModal,
    openEditModal,
    onAddCustomer,
    getCustomers,
    currentUserAbilities,
  };
}

export default useCustomer;
