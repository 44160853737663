import React from "react";
import TopDisplay from "../../../../UI/TopDisplay/TopDisplay";
import locationstyles from "../../Warehouse.module.scss";
import FormLHS from "./FormLHS";
import useTopup from "./hooks/useTopup";

const TopUp = ({inventoryId, onClose, manual}) => {
  const { inventories, dispatch, state, handleSubmit} = useTopup(inventoryId, onClose, manual)
  
  return (
    <div className={locationstyles.mains}>
      <TopDisplay
        title={manual ? "Change Inventory Value" :"Top Up Inventory"}
      />
      <div className={locationstyles.side}>
      <FormLHS
        inventoryId={inventoryId}
        inventories={inventories}
        state={state}
        handleSubmit={handleSubmit}
        dispatch={dispatch}
        manual={manual}
      />
      </div>
    </div>
  );
};

export default TopUp;
