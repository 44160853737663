import React from "react";
import { cashFormater } from "../../../../../utils/helpers";
import AddItem from "../../../../UI/AddItem/AddItem";
import { ActionMenuIcon } from "../../../../UI/RegularIcons";
import Tables from "../../../../UI/Tables/Tables";
import pluralize from "pluralize";
import styles from "./DTable.module.scss";
import Modal from "../../../../UI/Modal/Modal";
import { useNavigate } from "react-router";
import useDTable from "./hooks/useDTable";
import { paths } from "../../../../../Routes/path";

function DTable({
  selectedState,
  regionsAvail,
  onClickAction,
  currentUserAbilities,
}) {
  const {
    onDelete,
    isLoading,
    onClick,
    selected,
    openModal,
    setOpenModal,
    del,
    create,
    handleEdit,
    update,
  } = useDTable(onClickAction, currentUserAbilities, selectedState);
  const navigate = useNavigate();

  const columns = [
    {
      name: <span>State</span>,
      width: "50%",
      selector: (row) => row.state,
      sortable: true,
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      name: <span>Areas</span>,
      selector: (row) =>
        `${row?.delivery_areas_count} ${pluralize("delivery area", row.areas)}`,
      sortable: true,
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      name: <span>Revenue</span>,
      selector: (row) => `₦ ${cashFormater(row?.revenue)}`,
      sortable: true,
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      name: <span>Status</span>,
      selector: (row) => row?.status,
      sortable: true,
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      selector: (row, i) => (
        <>
          <div className={styles["circle-icon"]} onClick={() => onClick(i)}>
            <ActionMenuIcon />
          </div>
          {openModal && i === selected && create && (
            <Modal
              openModal={openModal}
              onClose={() => setOpenModal(false)}
              deleteFn={() => onDelete(row?.id, row?.name)}
              del={del}
              editFn={() => handleEdit(row?.id)}
              update={update}
            />
          )}
        </>
      ),
      width: "5%",
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
  ];

  return (
    <div className={styles.divv}>
      <Tables
        data={regionsAvail}
        columns={columns}
        pagination
        title={`Operational Regions in ${selectedState.countryName || "--"}`}
        atcBtn={
          create ? 
          <AddItem
            name="Region"
            addFnc={() =>
              navigate(paths.newRegion.replace(':countryId', selectedState?.countryId), {
                state: {
                  countryCode: selectedState?.countryCode,
                  countryId: selectedState?.countryId,
                },
              })
            }
          /> : ""
        }
        firstRow
        fetchingData={isLoading}
        onRowClicked={() => {}}
      />
    </div>
  );
}

export default DTable;
