import {
  SET_RIDERS,
  SET_RIDER,
  SET_RIDER_ID,
  SET_READY_ORDER,
} from "../actionTypes/ridersTypes";

const initialState = {
  riders: [],
  rider: [],
  riderId: null,
  readyOrders: [],
};

export default function riderReducer(state = initialState, action) {
  const { data, type } = action;
  switch (type) {
    case SET_RIDERS:
      return {
        ...state,
        riders: data,
      };
    case SET_RIDER:
      return {
        ...state,
        rider: data,
      };
    case SET_RIDER_ID:
      return {
        ...state,
        riderId: data,
      };
    case SET_READY_ORDER:
      return {
        ...state,
        readyOrders: data,
      };
    default:
      return state;
  }
}
