import React, { useEffect, useState } from "react";
import icon from "../../../../Assets/Images/RegularIcons/submenu-link.svg";
import activeIcon from "../../../../Assets/Images/RegularIcons/submenu-link-active.svg";
import styles from "../LocationSidebar.module.scss";
import { NavLink, useLocation } from "react-router-dom";

const SubMenuItem = (props) => {
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    let activeState;
    if (
      window.location.href === `${window.location.origin}/${props.parent}` &&
      props.children.map((item) => item.url)[0] === props.url
    ) {
      activeState = true;
      setIsActive(activeState);
    } else {
      activeState = window.location.href.includes(
        `${props.parent}/${props.url}` || props.parent
      );
      setIsActive(activeState);
    }
  }, [location.pathname, props.children, props.href, props.parent, props.url]);

  return (
    <NavLink
      to={`${props.parent}/${props.url}`}
      className={`${styles["submenu-item"]} ${
        isActive ? styles["active"] : styles["inactive"]
      }`}
    >
      <div className={styles["submenu-item-icon"]}>
        <img
          src={isActive ? activeIcon : icon}
          alt="submenu icon"
          className={styles["submenu-icon"]}
        />
      </div>
      <div className={styles["submenu-item-title"]}>
        <span className="body-2">{props.title}</span>
      </div>
    </NavLink>
  );
};

export default SubMenuItem;
