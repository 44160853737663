import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { paths } from '../../../../../../Routes/path';

function useDTable( onClickAction, currentUserAbilities ) {
    const [openModal, setOpenModal] = useState(false);
    const [selected, setSelected] = useState(null);
    const onClick = (value) => {
      setOpenModal(true);
      setSelected(value);
    };
    const navigate = useNavigate();
    const isLoading = useSelector(state => state.app.isLoading)
  
    const handleEdit = (riderId) => {
      navigate(paths.editRider.replace(':riderId', riderId))
    };

    const handleVerify = (riderId) => {
      navigate(paths.verifyRider.replace(':riderId', riderId))
    };
  
    const onDelete = (riderId) => {
        onClickAction(riderId)
    };

    const update = currentUserAbilities?.update
    const create = currentUserAbilities?.create
    const show = currentUserAbilities?.show

    const del = currentUserAbilities?.destroy

  return {
    onDelete,
    handleEdit,
    onClick,
    selected,
    openModal,
    setOpenModal,
    isLoading,
    del,
    update,
    create,
    show,
    handleVerify,
  }
}

export default useDTable