// eslint-disable-next-line
export function cashFormater(cash) {
  cash += "";
  const indexOfDecimal = cash.indexOf(".");

  cash =
    cash.substr(indexOfDecimal).length > 3
      ? cash.substr(0, indexOfDecimal + 3)
      : cash;
  cash += cash.indexOf(".") === -1 ? ".00" : "";
  cash = cash.substr(cash.indexOf(".")).length === 2 ? `${cash}0` : cash;
  return cash
    .replace(/(\d((?=(.{3})+$)))/g, "$1,")
    .replace(/,\./, ".")
    .replace(".00", "");
}

export function numberFormater(cash) {
  cash += "";
  const indexOfDecimal = cash.indexOf(".");

  cash =
    cash.substr(indexOfDecimal).length > 3
      ? cash.substr(0, indexOfDecimal + 3)
      : cash;
  cash += cash.indexOf(".") === -1 ? ".00" : "";
  cash = cash.substr(cash.indexOf(".")).length === 2 ? `${cash}0` : cash;
  return cash.replace(/(\d((?=(.{3})+$)))/g, "$1,").replace(/,\./, ".");
  // .replace('.00', '');
} // eslint-disable-next-line

export const getBase64 = (file) => {
  return new Promise((resolve) => {
    let baseURL = "";
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
};

export function formatPhoneNumber(phoneNumber) {
  const TRIM_LENGTH = 10;
  return phoneNumber?.length < TRIM_LENGTH
    ? phoneNumber
    : phoneNumber?.slice(
        phoneNumber?.length - TRIM_LENGTH,
        phoneNumber?.length
      );
}

export function isValidEmail(email) {
  return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.test(
    email
  );
}

export const constants = {
  naira: "₦",
};

// eslint-disable-next-line
export const getStatusColor = (status) => {
  switch (status) {
    case "abandoned" || "deactivated":
      return "#B71D18";
    case "paid" || "active":
      return "#36B37E";
    case "processing":
      return "#FFAB00";
    case "completed":
      return " #FFFFFF";
    case "initiated":
      return "#3366FF";
    case "delivering":
      return "#3366FF";
    default:
      return "";
  }
};

export const getBgStatusColor = (status) => {
  switch (status) {
    case "abandoned" || "deactivated":
      return "#B71D1833";
    case "paid" || "active":
      return "#36B37E33";
    case "arocessing":
      return "#FFAB0033";
    case "completed":
      return "#1B806A";
    case "initiated":
      return "#3366FF33";
    case "delivering":
      return "#3366FF33";
    default:
      return "";
  }
};

export function objectToArray(val) {
  const obj = val;
  return Object.entries(obj);
}

// eslint-disable-next-line
export function sentenceCase(value) {
  if (value) {
    return value.toLowerCase().replace(/^./g, value.charAt(0).toUpperCase());
  }
  return "";
}

export function titleCase(value) {
  if (value) {
    return value
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  return "";
}

export function camelToNormalCase(str) {
  return str
    ?.replace(/_/g, " ")
    .replace(/([A-Z])/g, " $1")
    ?.toLowerCase();
}

export function trendType(value) {
  if (value === 0) {
    return "flat";
  } else if (value < 0) {
    return "down";
  } else {
    return "up";
  }
}
