import { SET_DEPARTMENT, SET_DEPARTMENTS, SET_DEPARTMENT_ID } from "../actionTypes/departmentType";
  
  const initialState = {
    departments: [],
    department: [],
    departmentId: null,
  };
  
  export default function departmentReducer(state = initialState, action) {
    const { data, type } = action;
    switch (type) {
      case SET_DEPARTMENTS:
        return {
          ...state,
          departments: data,
        };
      case SET_DEPARTMENT:
        return {
          ...state,
          department: data,
        };
      case SET_DEPARTMENT_ID:
        return {
          ...state,
          departmentId: data,
        };
      default:
        return state;
    }
  }
  