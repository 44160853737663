import {
  SET_DISCOUNTS,
  SET_INFLUENCER,
  SET_INFLUENCERS,
  SET_INFLUENCER_ID,
  SET_VOUCHER,
  SET_VOUCHERS,
  SET_VOUCHER_ID,
} from "../actionTypes/discountActionType";

const initialState = {
  vouchers: [],
  voucher: [],
  voucherId: null,
  influencers: [],
  influencer: [],
  influencerId: null,
  discounts: [],
};

export default function discountReducer(state = initialState, action) {
  const { data, type } = action;
  switch (type) {
    case SET_INFLUENCERS:
      return {
        ...state,
        influencers: data,
      };
    case SET_INFLUENCER:
      return {
        ...state,
        influencer: data,
      };
    case SET_INFLUENCER_ID:
      return {
        ...state,
        influencerId: data,
      };
    case SET_VOUCHERS:
      return {
        ...state,
        vouchers: data,
      };
    case SET_VOUCHER:
      return {
        ...state,
        voucher: data,
      };
    case SET_VOUCHER_ID:
      return {
        ...state,
        voucherId: data,
      };
    case SET_DISCOUNTS:
      return {
        ...state,
        discounts: data,
      };
    default:
      return state;
  }
}
