import React from "react";
import { SelectInput } from "../../../../../UI/FormComponents/Input";
import styles from "../../../ManageOrders.module.scss";
import Button from "../../../../../UI/Button/Button";

const FormLHS = ({ state, handleSubmit, dispatch, orderRef, kitchenZones }) => {
  
  return (
    <div className={styles["form-6grid-main"]}>
      <SelectInput
        name="kitchen"
        label="Kitchen"
        value={state.kitchen}
        options={kitchenZones}
        onChange={(e) => dispatch({ kitchen: e.target.value })}
      />
      <div className={styles["btn-div"]}>
        <Button
          name={`Assign Order ${orderRef} to Kitchen`}
          bgColor="green-bg"
          createFnc={handleSubmit}
          custom
          isLoading={state.isLoading}
        />
      </div>
    </div>
  );
};

export default FormLHS;
