import React from "react";
import CircleCharts from "../../../../UI/CircleCharts/CircleCharts";
import { OrdersIcon } from "../../../../UI/MenuIcons";
import styles from "./OrdersCard.module.scss";
import pluralize from "pluralize";
import Skeleton from "react-loading-skeleton";

function OrdersCard({
  state,
  pendingOrders,
}) {
  return (
    <>
    {state?.isLoadingPendingOrder ? (
      <Skeleton height={110} width="310px" borderRadius="16px" />
    ) : (
    <div className={styles.cardDiv}>
      <div className={styles.side1}>
        <CircleCharts
          value={pendingOrders?.completion_rate}
          strokeWidth={10}
          trailColor="#FFFF"
          pathColor="#D9D9D9"
          text="30%"
          opacity={2}
          textColor="#FFFFFF"
          textSize="14px"
          fontWeight={400}
        />
      </div>
      <div className={styles.side2}>
        <span className={styles.orders}>{`${pendingOrders?.pending_count} ${pluralize("Orders", pendingOrders?.pending_count)}`}</span>
        <span className={styles.pending}>Pending deliveries</span>
        <span className={styles["cart-icon"]}>
          <OrdersIcon />
        </span>
      </div>
    </div>
    )}
    </>
  );
}

export default OrdersCard;
