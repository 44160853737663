import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  setLocations,
  setRegion,
} from "../../../../redux/actions/location.action";
import {
  setIsDeleting,
  setPopupComponent,
  showPopup,
} from "../../../../redux/actions/popUp.action";
import locationServices from "../../../../Services/location.services";
import DeleteModal from "../../../UI/DeleteModal";

function useLocation() {
  const dispatch = useDispatch();
  const { locations, region } = useSelector((state) => state.location);
  const { openModal, openAddModal } = useSelector((state) => state.app);
  const [isGettingCards, setIsGettingCards] = useState(false);
  const [selectedState, setSelectedState] = useState({});
  const [regionsAvail, setRegionsAvail] = useState([]);

  const handleCountry = (countryCode, countryName, countryId) => {
    setSelectedState({
      countryCode: countryCode,
      countryName: countryName,
      countryId: countryId,
    });
  };

  useEffect(() => {
    setSelectedState({
      countryCode: locations[0]?.country,
      countryName: locations[0]?.country_data?.data.unofficial_names[0],
      countryId: locations[0]?.id,
    });
  }, [locations]);

  const getAllLocations = useCallback(() => {
    setIsGettingCards(true);
    locationServices
      .getAllLocations()
      .then(({ data }) => {
        setLocations(data)(dispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      })
      .finally(() => setIsGettingCards(false));
  }, [dispatch]);

  const getAllRegions = useCallback(() => {
    locationServices
      .getAllRegions()
      .then(({ data }) => {
        setRegion(data)(dispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
    //  .finally(() => setIsGettingCards(false));
  }, [dispatch]);

  useEffect(() => {
    setRegion([])(dispatch)
    // eslint-disable-next-line
  },[])

  const regionsAv = () => {
    if (region.length){
    setRegionsAvail(region?.filter((item) => +item?.location_id === +selectedState?.countryId))
    } return []
  };

  useEffect(() => {
    regionsAv();
        // eslint-disable-next-line
  }, [selectedState, region]);
  const onClose = () => {
    showPopup({ ispopupOpen: false })(dispatch);
  };

  const handleDelete = (regionId) => {
    setIsDeleting(true)(dispatch);
    locationServices
      .deleteRegion(regionId)
      .then(() => {
        toast.success("Region was deleted");
        getAllLocations();
        getAllRegions();
        onClose();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => {
        setIsDeleting(false)(dispatch);
      });
  };

  const onClickAction = (regionId) => {
    setPopupComponent(
      <DeleteModal
        onClose={onClose}
        handleDelete={() => handleDelete(regionId)}
        title="Region"
      />
    )(dispatch);
    showPopup({ ispopupOpen: true, onClose: () => onClose() })(dispatch);
  };

  useEffect(() => {
    getAllLocations();
    getAllRegions();
  }, [getAllLocations, getAllRegions]);

  const myObjectString = localStorage.getItem("user");

  const currentUser = JSON.parse(myObjectString);
  const currentUserAbilities =
    currentUser?.account_abilities?.abilities?.["location"];

  return {
    locations,
    isGettingCards,
    regionsAvail,
    region,
    selectedState,
    handleCountry,
    handleDelete,
    onClickAction,
    openModal,
    onClose,
    openAddModal,
    getAllRegions,
    getAllLocations,
    currentUserAbilities,
  };
}

export default useLocation;
