import { API_URL } from "../env";
import { request } from "./api";

const URL = {
  getRiders: `${API_URL}api/v1/be/riders`,
  getARider: (riderId) =>`${API_URL}api/v1/be/riders/${riderId}`,
  createRider: `${API_URL}api/v1/be/riders/new`,
  deleteRider:(riderId) => `${API_URL}api/v1/be/riders/${riderId}`,
  assignRiderToOrder:(riderId, orderId) => `${API_URL}api/v1/be/riders/${riderId}/assign/${orderId}`,
  updateRider: (riderId) =>
    `${API_URL}api/v1/be/riders/${riderId}`,
    verifyRider: (riderId) =>
    `${API_URL}api/v1/be/riders/${riderId}/verify`,
};

function getRiders() {
  return request(URL.getRiders, "get")
}

function getARider(riderId) {
  return request(URL.getARider(riderId), "get")
}

function getAllReadyOrders(orderStatus, page, perPage) {
  return request(URL.getAllReadyOrders(orderStatus, page, perPage), "get")
}

function deleteRider(riderId, rider) {
  return request(URL.deleteRider(riderId), rider,  "delete")
  
}

function createRider(rider) {
    return request(URL.createRider, rider, "post")
}

function updateRider(riderId, rider) {
    return request(URL.updateRider(riderId), rider, "put")  
}

function verifyRider(riderId, rider) {
  return request(URL.verifyRider(riderId), rider, "post")  
}

function declineRider(riderId, rider) {
  return request(URL.verifyRider(riderId), rider, "delete")  
}

function assignRiderToOrder(riderId, orderId, rider) {
  return request(URL.assignRiderToOrder(riderId, orderId), rider, "put")  
}

const ridersServices = {
  getRiders,
  getARider,
  deleteRider,
  createRider,
  updateRider,
  getAllReadyOrders,
  assignRiderToOrder,
  verifyRider,
  declineRider,
}

export default ridersServices;
