import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { paths } from '../../../../../../Routes/path';

function useDTable( onClickAction, currentUserAbilities ) {
    const [openModal, setOpenModal] = useState(false);
    const [selected, setSelected] = useState(null);
    const onClick = (value) => {
      setOpenModal(true);
      setSelected(value);
    };
    const update = currentUserAbilities["user"]?.update
    const create = currentUserAbilities["user"]?.create
    const show = currentUserAbilities["user"]?.show

    const del = currentUserAbilities["user"]?.destroy

    const { zoneByRegion } = useSelector(
      (state) => state.delivery
    );
    const isLoading = useSelector(state => state.app.isLoading) 
    const navigate = useNavigate();
  
    const handleEdit = (userId) => {
      navigate(paths.editCustomer.replace(':customerId', userId ))
    };

    const handleView = (userId) => {
      navigate(paths.viewCustomer.replace(':customerId', userId ))
    };
  
    const onDelete = (userId) => {
        onClickAction(userId)
    };

    const onRowClicked = (rows ) => {
      handleView(rows?.id)
    };


  return {
    onDelete,
    handleEdit,
    handleView,
    onClick,
    selected,
    openModal,
    setOpenModal,
    isLoading,
    zoneByRegion,
    onRowClicked,
    del,
    update,
    create,
    show,
  }
}

export default useDTable