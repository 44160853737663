import React from "react";
import styles from "./Number.module.scss";

const Number = (props) => {
  const onChangeHandler = (e) => {
    let formattedValue = parseFloat(e.target.value).toFixed(2);
    let value = formattedValue === "0.00" ? "" : formattedValue;
    e.target.value = value;
  };

  const onInput = (event) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/^-/, '');
    event.target.value = inputValue;
  };

  return (
    <div className={styles["form-group"]}>
      <div className={styles["form-container"]}>
        <input
          type="number"
          defaultValue={props.value}
          // value={props.value}
          name={props.name}
          placeholder={props.integer ? "0" : "0.00"}
          pattern={props.integer ? "d*" : "d.d{2}"}
          onBlur={onChangeHandler}
          onChange={props.onChange}
          onInput={onInput}
          disabled={props.disabled}
          min="0"
        />
        <label htmlFor={props.name}>{props.label}</label>
        <span className={styles["currency"]}>{props.units}</span>
      </div>
    </div>
  );
};

export default Number;
