import React from "react";
import Card from "../../../../UI/Card/Card";
import { SelectInput, TextInput } from "../../../../UI/FormComponents/Input";
import FormGroupHeader from "../../../../UI/FormComponents/FormHeaders/FormGroupHeader";
import styles from "../../AddDepartmentForm.module.scss";
import Button from "../../../../UI/Button/Button";
import TextArea from "../../../../UI/FormComponents/InputTypes/TextArea";
import { accessName, accessType } from "../../../../../utils/constant";

const FormLHS = ({ state, dispatch, edit, handleSubmit }) => {
  return (
    <Card classes={styles["form-6grid-main"]}>
      <FormGroupHeader
        title="Department Information"
        description="General information about this Department"
      />
      <TextInput
        name="department_title"
        label="Department Title"
        type="text"
        value={state.title}
        onChange={({ target }) => dispatch({title: target.value})}
      />
      <TextArea
        name="department_description"
        label="Department Description"
        type="text"
        value={state.description}
        onChange={({ target }) => dispatch({description: target.value})}
      />
      <div className={styles["inventory-div"]}>
        <FormGroupHeader
          title="Abilities"
          description="Resources all staffs in this department would be able to / not able to read, write or update"
        />
        {accessName.map(({ name, id }) => (
          <div className={styles["access-div"]}>
            <TextInput
              name="resource_name"
              label="Resource Name"
              type="text"
              value={name}
            />
            <SelectInput
              name="Access_type"
              label="Access Type"
              options={accessType}
              value={state[id]}
              onChange={(e) => dispatch({ [id]: e.target.value })}
            />
          </div>
        ))}
      </div>
      <div className={styles["btn-div"]}>
        <Button
          name="Department"
          bgColor="green-bg"
          createFnc={handleSubmit}
          edit={edit}
          isLoading={state.isLoading}
        />
      </div>
    </Card>
  );
};

export default FormLHS;
