export const stateOptions = [
  { id: "solid", name: "Solid", measure: "g" },
  { id: "fluid", name: "Fluid", measure: "ml" },
  { id: "paste", name: "Paste", measure: "g" },
  { id: "powder", name: "Powder", measure: "g" },
  { id: "grains", name: "Grains", measure: "g" },
  { id: "order_packaging", name: "Order Packaging", measure: "#" },
  { id: "food_packaging", name: "Food Packaging", measure: "#" },
  { id: "cutlery", name: "Cutlery", measure: "#" },
];

export const documentType = [
  { id: "license", name: "Driver's License" },
  { id: "passport", name: "International Passport" },
];

export const dateDurations = [
  { id: "days", name: "Days" },
  { id: "weeks", name: "Weeks" },
  { id: "months", name: "Months" },
  { id: "years", name: "Years" },
];

export const accessType = [
  { name: "Read Acess", id: "read" },
  { name: "Mangement Access", id: "manage" },
  { name: "No Access", id: "none" },
];

export const accessName = [
  // { name: "Addresses", id: "address" },
  { name: "Staff", id: "admin" },
  { name: "States", id: "available_region" },
  { name: "Category", id: "category" },
  { name: "Delivery Zone", id: "delivery_zone" },
  // { name: "General Review", id: "general_review" },
  { name: "Inventory", id:"inventory" },
  { name: "Kitchen", id: "kitchen" },
  { name: "Location", id: "location" },
  { name: "Order", id: "order" },
  // { name: "Order Item", id: "order_item" },
  { name: "Payment", id: "payment" },
  { name: "Product", id: "product"},
  { name: "Rider", id: "rider" },
  { name: "Rider Verification", id: "rider_verification" },
  // { name: "Social Medium", id: "social_medium" },
  { name: "Stock", id: "stock" },
  { name: "Customer", id: "user" },
  { name: "Discount", id: "discount" },
]

