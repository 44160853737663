import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router";
import { inventoryData, secondData } from "../../../../../data/data";
import AddItem from "../../../../UI/AddItem/AddItem";
import Modal from "../../../../UI/Modal/Modal";
import { ActionMenuIcon } from "../../../../UI/RegularIcons";
import Tables from "../../../../UI/Tables/Tables";
import styles from "./DTable.module.scss";

function DTable() {
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState(null)
  const onClickAction = (value, e) => {
    setOpenModal(true)
    setSelected(value)
      e.stopPropagation();
  }
  const columns = [
    {
      name: 'Code',
      selector: (row) => row.code,
      sortable: true,
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "70%",
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      selector: (row, i) => (
        <>
          <div
            className={styles["circle-icon"]}
            onClick={() => onClickAction(i)}
          >
            <ActionMenuIcon />
          </div>
          {openModal && i === selected && (
            <Modal
              openModal={openModal}
              onClose={() => setOpenModal(false)}
            />
          )}
        </>
      ),
      sortable: true,
      width: "5%",
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
  ];

  const navigate = useNavigate();
  return (
    <div className={styles.divv}>
      <Tables
        data={inventoryData}
        columns={columns}
        pagination
        title="All Roles"
        atcBtn={<AddItem name="Role" addFnc={() => navigate('/management/roles/new')}/>}
        firstRow
        selectableRows
        secondRowData={secondData}
        secondRow
      />
    </div>
  );
}

export default DTable;
