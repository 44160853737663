import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { paths } from '../../../../../../Routes/path';

function useDTable( onClickAction, currentUserAbilities, selectedState ) {
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const [selected, setSelected] = useState(null);
    const onClick = (value) => {
      setOpenModal(true);
      setSelected(value);
    };
    
    const isLoading = useSelector(state => state.app.isLoading) 
  
    const onDelete = (regionId) => {
        onClickAction(regionId)
    };

    const handleEdit = (id) => {
      // getAnOrder(orderId)
      navigate(paths.editRegion.replace(':countryId', selectedState?.countryId).replace(":regionId", id), {
        state: {
          countryCode: selectedState?.countryCode,
          countryId: selectedState?.countryId,
        },
    });
  }

    const create = currentUserAbilities?.create;
    const update = currentUserAbilities?.update
    const del = currentUserAbilities?.destroy;

  return {
    onDelete,
    onClick,
    selected,
    openModal,
    setOpenModal,
    isLoading,
    create,
    del,
    handleEdit,
    update,
  }
}

export default useDTable