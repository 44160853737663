import { API_URL } from "../env";
import { request } from "./api";

const URL = {
  getAllPossibleLocations: `${API_URL}api/v1/be/location/all`,
  getAllLocations: `${API_URL}api/v1/be/regions/locations`,
  getAllRegions: `${API_URL}api/v1/be/regions`,
  createLocation: `${API_URL}api/v1/be/location/new`,
  createRegion: `${API_URL}api/v1/be/regions/new`,
  deleteLocation: `${API_URL}api/v1/be/location/new`,
  deleteRegion: (regionId) => `${API_URL}api/v1/be/regions/${regionId}`,
  editRegion: (regionId) => `${API_URL}api/v1/be/regions/${regionId}`,
  editRegionContact: (regionId) => `${API_URL}api/v1/be/regions/${regionId}/update/contacts`,
  getARegion: (regionId) => `${API_URL}api/v1/be/regions/${regionId}`,
  getAllState:(countryCode) => `${API_URL}api/v1/be/regions/${countryCode}/states`,
  getAllStateAvailable: (countryId) => `${API_URL}api/v1/be/kitchen/regions?location_id=${countryId}`,
  getAllCityAvailable: (countryId) => `${API_URL}api/v1/be/kitchen/cities?region_id=${countryId}`,
};

function getAllLocations() {
  return request(URL.getAllLocations, "get")
}

function getAllPossibleLocations() {
  return request(URL.getAllPossibleLocations, "get")
}

function getAllRegions() {
  return request(URL.getAllRegions, "get")
}

function getAllState(countryCode) {
  return request(URL.getAllState(countryCode), "get")
}

function getAllStateAvailable(countryId) {
  return request(URL.getAllStateAvailable(countryId), "get")
}

function getAllCityAvailable(stateId) {
  return request(URL.getAllCityAvailable(stateId), "get")
}

function createLocation(country) {
  return request(URL.createLocation, country, "post");
}

function createRegion(state) {
  return request(URL.createRegion, state, "post");
}

function getARegion(regionId) {
  return request(URL.getARegion(regionId), "get")
}

function editRegion(regionId, region) {
  return request(URL.editRegion(regionId), region, "put");
}

function editRegionContact(regionId, contact) {
  return request(URL.editRegionContact(regionId), contact, "put");
}

function deleteRegion(orderId) {
  return request(URL.deleteRegion(orderId), {}, "delete")
}

const locationServices = {
  getAllLocations,
  createLocation,
  getAllStateAvailable,
  getAllState,
  getAllRegions,
  getAllCityAvailable,
  createRegion,
  deleteRegion,
  getAllPossibleLocations,
  getARegion,
  editRegion,
  editRegionContact,
}

export default locationServices;
