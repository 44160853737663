import React from "react";
import { useNavigate } from "react-router";
import { paths } from "../../../Routes/path";
import styles from "./ErrorPage.module.scss";

function ErrorPage({ type2 }) {
  const navigate = useNavigate();
  return (
    <main className={styles.main}>
      <h2 className={styles.header}>Oops !</h2>
      <p className={styles.lineOne}>
        {!type2 ? "404 - PAGE NOT FOUND" : "403 - Access Denied"}
      </p>
      <p className={styles.lineTwo}>
        {!type2
          ? "We cannnot seem to find the page you are looking for"
          : "The page you are trying to access has restricted access. Please refer to your system administractor"}
      </p>
      <button onClick={() => navigate(paths.dashboard)}>GO TO HOMEPAGE</button>
    </main>
  );
}

export default ErrorPage;
