import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { paths } from '../../../../../../Routes/path';

function useDTable() {
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const [selected, setSelected] = useState(null);
    const onClick = (value) => {
      setOpenModal(true);
      setSelected(value);
    };
    const { zoneByRegion } = useSelector(
      (state) => state.delivery
    );
    const isLoading = useSelector(state => state.app.isLoading) 
  
    const handleEdit = (orderId) => {
      navigate(paths.assignDelivery.replace(':orderId', orderId));
    };
  
  return {
    handleEdit,
    onClick,
    selected,
    openModal,
    setOpenModal,
    isLoading,
    zoneByRegion,
  }
}

export default useDTable