import React from "react";
import MetaTags from "react-meta-tags";
import Grid from "../../UI/Grid/Grid";
import GridRow from "../../UI/Grid/GridRow";
import styles from "./Dashboard.module.scss";
import DTable from "./component/DTable/DTable";
import TopSide from "./component/TopSide/TopSide";
import OrdersCard from "./component/OrdersCard/OrdersCard";
import UserStatistics from "./component/UserStatistics/UserStatistics";
import useDashboard from "./hooks/useDashboard";

const Dashboard = () => {
  const { state, customerData, orderData, cashflowData, userStats, recentOrders, pendingOrders } = useDashboard();
  return (
    <>
      <MetaTags>
        <title>Dashboard</title>
        <meta name="description" content="#" />
      </MetaTags>
      <div className={styles["dash-main"]}>
        <TopSide
          state={state}
          customerData={customerData}
          orderData={orderData}
          cashflowData={cashflowData}
        />
        <div className={styles.section2}>
          <Grid span="9">
            <GridRow span="3" customClass="section2">
              <div className={styles.progressDiv}>
                <div className={styles.progressDiv1}>
                  <UserStatistics state={state} userStats={userStats} />
                </div>
                <div className={styles.progressDiv2}>
                  <OrdersCard state={state} pendingOrders={pendingOrders} />
                </div>
              </div>
            </GridRow>
            <GridRow span="6" customClass="section2">
              <DTable state={state} recentOrders={recentOrders} />
            </GridRow>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
