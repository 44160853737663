import { createBrowserRouter } from "react-router-dom";
import AssignDelivery from "../Components/Pages/AssignDelivery/AssignDelivery";
import Base from "../Components/Pages/Base";
import AddCategoryForm from "../Components/Pages/Categories/AddCategoryForm";
import Categories from "../Components/Pages/Categories/Categories";
import CustomerDashboard from "../Components/Pages/CustomerDashboard/CustomerDashboard";
import Dashboard from "../Components/Pages/Dashboard/Dashboard";
import AddDeliveryAreaForm from "../Components/Pages/DeliveryAreas/AddDeliveryAreaForm.";
import DeliveryAreas from "../Components/Pages/DeliveryAreas/DeliveryAreas";
import AddDepartmentForm from "../Components/Pages/Departments/AddDepartmentForm";
import Departments from "../Components/Pages/Departments/Departments";
import ErrorPage from "../Components/Pages/ErrorPage/ErrorPage";
import Influencers from "../Components/Pages/Influencers/Influencers";
import AddInventoryForm from "../Components/Pages/Inventory/AddInventoryForm";
import Inventory from "../Components/Pages/Inventory/Inventory";
import AddKitchenForm from "../Components/Pages/Kitchen/AddKitchenForm.";
import Kitchen from "../Components/Pages/Kitchen/Kitchen";
import Login from "../Components/Pages/Login/Login";
import ManageDelivery from "../Components/Pages/ManageDeliveries/ManageDelivery";
import ManageOrders from "../Components/Pages/ManageOrders/ManageOrders";
import AddRegionForm from "../Components/Pages/OperationalLocations/AddRegionForm";
import OperationalLocations from "../Components/Pages/OperationalLocations/OperationalLocations";
import OrderDetails from "../Components/Pages/OrderDetails/OrderDetails";
import AddOrderForm from "../Components/Pages/Orders/AddOrderForm";
import Orders from "../Components/Pages/Orders/Orders";
import AddProductForm from "../Components/Pages/Products/AddProductForm";
import Products from "../Components/Pages/Products/Products";
import AddRiderForm from "../Components/Pages/Riders/AddRiderForm";
import Riders from "../Components/Pages/Riders/Riders";
import AddRoleForm from "../Components/Pages/Roles/AddRoleForm";
import Roles from "../Components/Pages/Roles/Roles";
import AddStaffForm from "../Components/Pages/Staff/AddStaffForm.";
import Staff from "../Components/Pages/Staff/Staff";
import AddStockForm from "../Components/Pages/Stocks/AddStockForm";
import Stocks from "../Components/Pages/Stocks/Stocks";
import UserProfile from "../Components/Pages/UseProfile/UserProfile";
import Vouchers from "../Components/Pages/Vouchers/Vouchers";
import Warehouse from "../Components/Pages/Warehouse/Warehouse";
import { paths } from "./path";
import PrivateRoute from "./PrivateRoute";
import ActivityLogs from "../Components/Pages/ActivityLogs/ActivityLogs";
import Payments from "../Components/Pages/Payments/Payments";

  export const router = createBrowserRouter([
    {
      path: "*",
      element: <ErrorPage />
    },
    {
      path: "/",
      element: <Login />
    },
    {
    path: "/",
    element: <PrivateRoute><Base /></PrivateRoute>,
    children: [
      {
        path: paths.dashboard,
        element: <Dashboard />
      },
      {
        path: paths.logs,
        element: <ActivityLogs />
      },
      {
        path: paths.payments,
        element: <Payments />
      },
      {
        path: paths.operationalLocations,
        element: <OperationalLocations />
      },
      {
        path: paths.newOperationalLocation,
        element: <OperationalLocations />
      },
      {
        path: paths.newRegion,
        element: <AddRegionForm />
      },
      {
        path: paths.editRegion,
        element: <AddRegionForm edit />
      },
      {
        path: paths.deliveryAreas,
        element: <DeliveryAreas />
      },
      {
        path: paths.newDeliveryArea,
        element: <AddDeliveryAreaForm />
      },
      {
        path: paths.editDeliveryArea,
        element: <AddDeliveryAreaForm edit />
      },
      {
        path: paths.kitchen,
        element: <Kitchen />
      },
      {
        path: paths.newKitchen,
        element: <AddKitchenForm />
      },
      {
        path: paths.editKitchen,
        element: <AddKitchenForm edit />
      },
      {
        path: paths.inventory,
        element: <Inventory />
      },
      {
        path: paths.kitchenInventory,
        element: <Inventory />
      },
      {
        path: paths.newInventory,
        element: <AddInventoryForm />
      },
      {
        path: paths.editInventory,
        element: <AddInventoryForm edit />
      },
      {
        path: paths.stock,
        element: <Stocks />
      },
      {
        path: paths.kitchenStock,
        element: <Stocks />
      },
      {
        path: paths.newStock,
        element: <AddStockForm />
      },
      {
        path: paths.editStock,
        element: <AddStockForm edit />
      },
      {
        path: paths.categories,
        element: <Categories />
      },
      {
        path: paths.newCategory,
        element: <AddCategoryForm />
      },
      {
        path: paths.editCategory,
        element: <AddCategoryForm edit />
      },
      {
        path: paths.products,
        element: <Products />
      },
      {
        path: paths.newProduct,
        element: <AddProductForm />
      },
      {
        path: paths.editProduct,
        element: <AddProductForm edit />
      },
      {
        path: paths.userProfile,
        element: <UserProfile />
      },
      {
        path: paths.roles,
        element: <Roles />
      },
      {
        path: paths.newRole,
        element: <AddRoleForm />
      },
      {
        path: paths.orders,
        element: <Orders />
      },
      {
        path: paths.newOrder,
        element: <AddOrderForm />
      },
      {
        path: paths.editOrder,
        element: <AddOrderForm edit />
      },
      {
        path: paths.orderDetails,
        element: <OrderDetails />
      },
      {
        path: paths.manageOrders,
        element: <ManageOrders />
      },
      {
        path: paths.riders,
        element: <Riders />
      },
      {
        path: paths.newRider,
        element: <AddRiderForm />
      },
      {
        path: paths.editRider,
        element: <AddRiderForm edit />
      },
      {
        path: paths.verifyRider,
        element: <AddRiderForm verify />
      },
      {
        path: paths.manageDelivery,
        element: <ManageDelivery />
      },
      {
        path: paths.assignDelivery,
        element: <AssignDelivery />
      },
      {
        path: paths.customers,
        element: <CustomerDashboard />
      },
      {
        path: paths.newCustomer,
        element: <CustomerDashboard />
      },
      {
        path: paths.editCustomer,
        element: <CustomerDashboard />
      },
      {
        path: paths.viewCustomer,
        element: <CustomerDashboard />
      },
      {
        path: paths.staff,
        element: <Staff />
      },
      {
        path: paths.newStaff,
        element: <AddStaffForm />
      },
      {
        path: paths.editStaff,
        element: <AddStaffForm edit/>
      },
      {
        path: paths.departments,
        element: <Departments />
      },
      {
        path: paths.newDepartment,
        element: <AddDepartmentForm />
      },
      {
        path: paths.editDepartment,
        element: <AddDepartmentForm edit />
      },
      {
        path: paths.influencer,
        element: <Influencers />
      },
      {
        path: paths.newInfluencer,
        element: <Influencers />
      },
      {
        path: paths.editInfluencer,
        element: <Influencers />
      },
      {
        path: paths.voucher,
        element: <Vouchers />
      },
      {
        path: paths.newVoucher,
        element: <Vouchers />
      },
      {
        path: paths.editVoucher,
        element: <Vouchers />
      },
      {
        path: paths.warehouse,
        element: <Warehouse />
      },
    ],
  },
])