import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { paths } from "../../../../../../Routes/path";

function useDTable(onClickAction, currentUserAbilities) {
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const onClick = (value) => {
    setOpenModal(true);
    setSelected(value);
  };
  const { zoneByRegion } = useSelector((state) => state.delivery);
  const isLoading = useSelector((state) => state.app.isLoading);
  const navigate = useNavigate();

  const handleEdit = (productId) => {
    navigate(paths.editProduct.replace(":productId", productId));
  };

  const onDelete = (productId) => {
    onClickAction(productId);
  };

  const update = currentUserAbilities?.update;
  const create = currentUserAbilities?.create;
  const show = currentUserAbilities?.show;

  const del = currentUserAbilities?.destroy;

  return {
    onDelete,
    handleEdit,
    onClick,
    selected,
    openModal,
    setOpenModal,
    isLoading,
    zoneByRegion,
    del,
    update,
    create,
    show,
  };
}

export default useDTable;
