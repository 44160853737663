import React from "react";
import styles from "./ViewPayments.module.scss";
import FormGroupHeader from "../../../../UI/FormComponents/FormHeaders/FormGroupHeader";
import useViewPayments from "./hooks/useViewPayments";
import { ActionMenuIcon, ViewProofIcon } from "../../../../UI/RegularIcons";

function ViewPayments({ orderId, onClose, refId, verifyManualPayments, viewProof, sendPayment }) {
  const {
    openModal,
    onClick,
    setOpenModal,
    verifyAuto,
    orderPayments,
    payments,
    selected,
  } = useViewPayments(orderId, onClose);
  return (
    <>
      <div
        className={styles.modal}
        onClick={() => {
          setOpenModal(false);
        }}
      >
        <FormGroupHeader title={`Payments for Order #${refId}`} />
        {orderPayments.map((pay, i) => (
          <div className={styles.card}>
            <div className={styles.textCard}>
              <p className={styles.title}>{payments[pay?.payment_type]}</p>
              {pay?.note && (
                <p className={styles.extra}>
                  <span className={styles.reason}>Reason: </span>
                  {pay?.note}
                </p>
              )}
              <span
                className={styles[pay?.status === "paid" ? "paid" : "pending"]}
              >
                {pay?.status}
              </span>
            </div>
            {openModal && i === selected && (
              <>
              {
                pay?.status !== "paid" ?
                <div
                className={styles["action-m"]}
                onClick={(event) => event.stopPropagation()}
              >
                    <span
                      className={styles["items"]}
                      onClick={(event) => {
                        event.stopPropagation();
                        sendPayment(pay?.paystack);
                      }}
                    >
                      Send Payment link
                    </span>
              </div>
                :
              <div
                className={styles["action-m"]}
                onClick={(event) => event.stopPropagation()}
              >
                {pay?.status === "paid" && pay?.gateway === "manual" ? (
                  <span
                    className={styles["items"]}
                    onClick={(event) => {
                      event.stopPropagation();
                      verifyAuto(event, pay?.id);
                    }}
                  >
                    <span className={styles["chevron-icon"]}>
                    <ViewProofIcon />
                  </span>
                  <span className={styles["edit-text"]}
                  onClick={(event) => {
                    event.stopPropagation();
                    viewProof(pay)
                  }}>
                  {}View proof
                  </span>
                  </span>
                ) : (
                  <>
                    <span
                      className={styles["items"]}
                      onClick={(event) => {
                        event.stopPropagation();
                        verifyAuto(event, pay?.id);
                      }}
                    >
                      Verify Payment
                    </span>
                    <span
                      className={styles["items"]}
                      onClick={(event) => {
                        event.stopPropagation();
                        verifyManualPayments(pay?.id);
                      }}
                    >
                      Verify Manually
                    </span>
                  </>
                )}
              </div>
            }
            </>
            )}
            {(pay.status !== "paid" || pay?.gateway === "manual") && (
              <div className={styles.menuCard}>
                <div
                  className={styles["circle-icon"]}
                  onClick={(e) => onClick(e, i)}
                >
                  <ActionMenuIcon />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
}

export default ViewPayments;
