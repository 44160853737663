export const SET_COUNTRY_LIST = 'SET_COUNTRY_LIST';
export const SET_COUNTRY_REGION_LIST = 'SET_COUNTRY_REGION_LIST';
export const SET_COUNTRY_NAME_LIST = 'SET_COUNTRY_NAME_LIST';
export const SET_LOCATIONS = 'SET_LOCATIONS';
export const SET_REGION = 'SET_REGION';
export const SET_LOCATION_TABLE = 'SET_LOCATION_TABLE';
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_STATES = 'SET_STATES';
export const SET_CITIES = 'SET_CITIES';
export const SET_REGION_ID = 'SET_REGION_ID';
export const SET_WAREHOUSE_REGION = 'SET_WAREHOUSE_REGION'
