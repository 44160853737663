import React from "react";
import MetaTags from "react-meta-tags";
import Grid from "../../UI/Grid/Grid";
import GridRow from "../../UI/Grid/GridRow";
import FormLHS from "./component/FormGroups/FormLHS";
import FormRHS from "./component/FormGroups/FormRHS";
import TopDisplay from "../../UI/TopDisplay/TopDisplay";
import styles from "./AddProductForm.module.scss";
import productstyles from "./Products.module.scss";
import useAddProduct from "./hooks/useAddProduct";

const AddProductForm = ({ edit }) => {
  const {
    state,
    handleSubmit,
    dispatch,
    onSelectImage,
    categories,
    productPrices,
    setButtonState,
    stocks,
    inventories,
    allergens,
    setAllergens,
    products,
    selectedProducts,
    dep_product,
    addOnCategory,
    handleAddOnCatgory,
    handleDependentProducts,
    removeSelectedProduct,
    fetchingProducts,
    onSale,
    setOnsale,
  } = useAddProduct(edit);
  return (
    <>
      <MetaTags>
        <title>Add Product Page</title>
        <meta name="description" content="#" />
      </MetaTags>
      <div className={productstyles.main}>
        <TopDisplay
          title={edit ? "Update Product" : "Add New Product"}
          firstText="Kitchen"
          secondText="Products"
          thirdText={edit ? "Update Product" : "Add New Product"}
          style2
        />
        <div className={styles["form-main"]}>
          <Grid span="9">
            <GridRow span="6" customClass="section2">
              <FormLHS
                edit={edit}
                state={state}
                dispatch={dispatch}
                onSelectImage={onSelectImage}
                categories={categories}
                stocks={stocks}
                inventories={inventories}
                allergens={allergens}
                setAllergens={setAllergens}
                products={products}
                selectedProducts={selectedProducts}
                fetchingProducts={fetchingProducts}
                dep_product={dep_product}
                addOnCategory={addOnCategory}
                handleAddOnCatgory={handleAddOnCatgory}
                handleDependentProducts={handleDependentProducts}
                removeSelectedProduct={removeSelectedProduct}
                onSale={onSale}
                setOnsale={setOnsale}
              />
            </GridRow>
            <GridRow span="3" customClass="section2">
              <FormRHS
                edit={edit}
                state={state}
                handleSubmit={handleSubmit}
                dispatch={dispatch}
                productPrices={productPrices}
                setButtonState={setButtonState}
              />
            </GridRow>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default AddProductForm;
