import { API_URL } from "../env";
import { request } from "./api";

const URL = {
  getDeliveryZones: `${API_URL}api/v1/be/delivery_zones`,
  getADeliveryZone:(zoneId) => `${API_URL}api/v1/be/delivery_zones/${zoneId}`,
  createDeliveryZone: `${API_URL}api/v1/be/delivery_zones/new`,
  updateDeliveryZone: (zoneId) => `${API_URL}api/v1/be/delivery_zones/${zoneId}`,
  deleteDeliveryZone:(zoneId) => `${API_URL}api/v1/be/delivery_zones/${zoneId}`,
  getAllState:(countryCode) => `${API_URL}api/v1/be/regions/${countryCode}/states`,
  getAllStateAvailable:(countryCode) => `${API_URL}api/v1/be/regions/${countryCode}/states`,
};

function getDeliveryZones() {
  return request(URL.getDeliveryZones, "get");
}

function getADeliveryZone(zoneId) {
  return request(URL.getADeliveryZone(zoneId), "get");
}

function createDeliveryZone(delivery_zone) {
  return request(URL.createDeliveryZone, delivery_zone, "post");
}

function updateDeliveryZone(zoneId, delivery_zone) {
  return request(URL.updateDeliveryZone(zoneId), delivery_zone, "put");
}

function deleteDeliveryZone(zoneId, zone) {
  return request(URL.deleteDeliveryZone(zoneId), zone, "delete");
}

const deliveryZonesServices = {
  getDeliveryZones,
  deleteDeliveryZone,
  getADeliveryZone,
  createDeliveryZone,
  updateDeliveryZone,
}

export default deliveryZonesServices;
