import React from "react";
import Select from "react-dropdown-select";

function Dropdown(props) {
  return (
    <Select
      options={props?.options}
      labelField={props?.labelField}
      valueField="id"
      onChange={props?.onChange}
      values={props?.value}
      name={props?.name}
      searchFn={props.searchFn}
      multi={false}
      searchable={true}
      placeholder={`Select ${props?.label}`}
      searchBy={props.searchBy}
    />
  );
}

export default Dropdown;
