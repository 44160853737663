import React, { useState } from "react";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { setIsLoading } from "../../../../redux/actions/appAction";
import {
  setOrder,
  setUserAddress,
} from "../../../../redux/actions/order.action";
import {
  setPopupComponent,
  showPopup,
} from "../../../../redux/actions/popUp.action";
import ordersServices from "../../../../Services/orders.services";
import SendPaymentLink from "../components/SendPaymentLink/SendPaymentLink";
import ViewPayments from "../components/ViewPayments/ViewPayments";
import AnotherPayment from "../components/AnotherPayment/AnotherPayment";
import RefreshOrder from "../components/RefreshOrder/RefreshOrder";
import VerifyPayments from "../components/VerifyPayments/VerifyPayments";
import ViewProof from "../../../UI/ViewProof/ViewProof";
import discountServices from "../../../../Services/discount.services";
import { setVoucher } from "../../../../redux/actions/discount.Action";

function useOrderDetails() {
  const reduxDispatch = useDispatch();
  const { state: orderId } = useLocation();
  const { voucher } = useSelector((state) => state.discount);
  const { order, userAddress } = useSelector((state) => state?.order);
  const { orderId: refId } = useParams();
  const reference = refId?.substring(0, 3) + "-" + refId?.substring(3);
  const myObjectString = localStorage.getItem("user");

  const currentUser = JSON.parse(myObjectString);
  const currentUserAbilities =
    currentUser?.account_abilities?.abilities?.["user"];
  const [openModal, setOpenModal] = useState(false);
  const onClick = () => {
    setOpenModal(true);
  };
  const [openModal2, setOpenModal2] = useState(false);
  const onClick2 = () => {
    setOpenModal2(true);
  };

  const getAVoucher = useCallback(() => {
    discountServices
      .getAVoucher(order?.voucher_id)
      .then(({ data }) => {
        setVoucher(data)(reduxDispatch);
      })
      .catch((err) => {
        // toast.error(err.message || "Unable to get");
      });
  }, [order, reduxDispatch]);

  useEffect(() => {
    if (order?.voucher_id){
      getAVoucher()
    }
  }, [getAVoucher, order?.voucher_id])

  const onClosePage = useCallback(() => {
    reduxDispatch(showPopup({ ispopupOpen: false }));
  }, [reduxDispatch]);

  const sendPaymentLink = (val) => {
    reduxDispatch(
      setPopupComponent(<SendPaymentLink payment={val?.payment} />)
    );
    reduxDispatch(
      showPopup({
        ispopupOpen: true,
        onClose: () => onClosePage(),
        type2: true,
      })
    );
  };

  const getAnOrder = useCallback(() => {
    ordersServices
      .getAnOrder(orderId)
      .then(({ data }) => {
        setOrder(data)(reduxDispatch);
      })
      .catch((err) => {});
  }, [orderId, reduxDispatch]);

  useEffect(() => {
    getAnOrder();
  }, [getAnOrder]);

  const getUserAddresses = useCallback(() => {
    setIsLoading(true)(reduxDispatch);
    ordersServices
      .getUserAddresses(order?.user_id)
      .then(({ data }) => {
        setUserAddress(data)(reduxDispatch);
      })
      .catch((err) => {})
      .finally(() => setIsLoading(false)(reduxDispatch));
  }, [reduxDispatch, order]);

  useEffect(() => {
    if (order?.user_id) {
      getUserAddresses();
    }
    return;
  }, [order, getUserAddresses]);

  const refreshOrder = () => {
    reduxDispatch(
      setPopupComponent(
        <RefreshOrder onClose={onClosePage} order={order} orderId={orderId} />
      )
    );
    reduxDispatch(
      showPopup({ ispopupOpen: true, onClose: () => onClosePage() })
    );
  };

  const collectPayment = () => {
    reduxDispatch(
      setPopupComponent(
        <AnotherPayment orderId={orderId} onClose={onClosePage} order={order} />
      )
    );
    reduxDispatch(
      showPopup({ ispopupOpen: true, onClose: () => onClosePage() })
    );
  };

  const verifyManualPayments = (pay) => {
    reduxDispatch(
      setPopupComponent(
        <VerifyPayments onClose={onClosePage} orderId={orderId} refId={refId} paymentId={pay} />
      )
    );
    reduxDispatch(
      showPopup({ ispopupOpen: true, onClose: () => onClosePage() })
    );
  };

  const sendPayment = (paymentLink) => {
    reduxDispatch(
      setPopupComponent(
        <SendPaymentLink
          onClose={onClosePage}
          payment={paymentLink?.checkout_url}
        />
      )
    );
    reduxDispatch(
      showPopup({ ispopupOpen: true, onClose: () => onClosePage() })
    );
  };

  const viewPayments = () => {
    reduxDispatch(
      setPopupComponent(
        <ViewPayments
          onClose={onClosePage}
          orderId={orderId}
          refId={refId}
          verifyManualPayments={verifyManualPayments}
          viewProof={viewProof}
          sendPayment={sendPayment}
        />
      )
    );
    reduxDispatch(
      showPopup({ ispopupOpen: true, onClose: () => onClosePage() })
    );
  };

  const viewProof = (pay) => {
    reduxDispatch(
      setPopupComponent(
        <ViewProof img={pay?.proof?.url} />
      )
    );
    reduxDispatch(
      showPopup({ ispopupOpen: true, onClose: () => onClosePage() })
    );
  };

  return {
    sendPaymentLink,
    reference,
    order,
    userAddress,
    openModal,
    onClick,
    setOpenModal,
    openModal2,
    onClick2,
    setOpenModal2,
    currentUserAbilities,
    refreshOrder,
    collectPayment,
    viewPayments,
    voucher,
  };
}

export default useOrderDetails;
