import React from "react";
import Icon from "./Icon";
import styles from "./TopHeader.module.scss";
import userIcon from "../../../Assets/Images/user-icon.png";
import useTopHeader from "./hooks/useTopHeader";

const TopHeader = ({ openModal, setOpenModal }) => {

  const { currentUser, logout } = useTopHeader()
  return (
    <>
      <div className={styles["top-header"]}>
        <div className={styles["top-header-notification"]}>
          <Icon icon="bell" />
        </div>
        <div className={styles["top-header-staffs"]}>
          <Icon icon="staff" />
        </div>
        <div className={styles["top-header-user"]}>
          <div className={styles["top-header-user-img"]}>
            <img src={currentUser?.avatar?.url || userIcon} alt={currentUser?.first_name} className={styles["user-img"]} />
          </div>
          <div className={styles["top-header-user-name"]}>
            <span className="body-2">{`${currentUser?.first_name} ${currentUser?.last_name.substring(0, 1)} .`}</span>
          </div>
          <div className={styles["top-header-user-chevron"]} 
          onClick={(event) => {
            event.stopPropagation()
            setOpenModal(true)
          }}>
            <Icon icon="chevron" />
          </div>
        </div>
      </div>
      {openModal && (
      <div className={styles["log-out"]} onClick={logout}>
        Logout
      </div>
      )}
    </>
  );
};

export default TopHeader;
