import React from "react";
import { useState } from "react";
import { cashFormater } from "../../../../../utils/helpers";
import Modal from "../../../../UI/Modal/Modal";
import { ActionMenuIcon } from "../../../../UI/RegularIcons";
import Tables from "../../../../UI/Tables/Tables";
import styles from "./DTable.module.scss";
import getSymbolFromCurrency from "currency-symbol-map";

function DTable({ recentOrders, state }) {
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const onClickAction = (value, e) => {
    setOpenModal(true);
    setSelected(value);
    e.stopPropagation();
  };
  const [showAllData, setShowAllData] = useState(false);

  const bottomFnc = () => {
    setShowAllData(true);
  };

  const dataToShow = showAllData ? recentOrders : recentOrders.slice(0, 6);

  const columns = [
    {
      name: "Invoice ID",
      width: "36%",
      selector: (row) => row.reference,
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      name: "User",
      selector: (row) => (
        <div className={styles.nameDiv}>
          <div>
            <img src={row?.user?.avatar?.url} alt={row?.user?.first_name} className={styles.imageDiv} />
          </div>
          <div className={styles.imageName}>{row?.user?.first_name}</div>
        </div>
      ),
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      name: <span>Price</span>,
      selector: (row) => `${getSymbolFromCurrency(row?.currency)} ${cashFormater(row?.total)}`,
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      name: <span>Status</span>,
      selector: (row) => row?.status,
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      selector: (row, i) => (
        <>
          <div
            className={styles["circle-icon"]}
            onClick={() => onClickAction(i)}
          >
            <ActionMenuIcon />
          </div>
          {openModal && i === selected && (
            <Modal openModal={openModal} onClose={() => setOpenModal(false)} />
          )}
        </>
      ),
      width: "7%",
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
  ];

  return (
    <div>
      <Tables
        data={dataToShow}
        title="Recent Orders"
        bottomSide={recentOrders.length > 6 ? true : false}
        bottomFnc={bottomFnc}
        firstRow
        columns={columns}
        fetchingData={state?.isLoadingRecentOrders}
        onRowClicked={() => {}} 
      />
    </div>
  );
}

export default DTable;
