import React from "react";
import Card from "../../../../UI/Card/Card";
import { TextInput, SelectInput } from "../../../../UI/FormComponents/Input";
import FormGroupHeader from "../../../../UI/FormComponents/FormHeaders/FormGroupHeader";
import styles from "../../AddRegionForm.module.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const FormLHS = ({ state, dispatch, states, edit }) => {

  return (
    <Card classes={styles["form-6grid-main"]}>
      <FormGroupHeader
        title="Region Information"
        description="General information about this region"
      />

      <div className={styles["form-flex"]}>
        <div className={styles["form-flex-1"]}>
          <SelectInput
            name="state"
            label="State"
            value={state?.stateCode}
            options={states}
            onChange={(e) => dispatch({ stateCode: e.target.value })}
          />
        </div>
        <div className={styles["form-flex-2"]}>
          <TextInput
            name="email"
            label="Email"
            type="email"
            value={state.email}
            onChange={(e) => dispatch({ email: e.target.value })}
          />
        </div>
      </div>
      <div className={styles["form-flex"]}>
        <div className={styles["form-flex-1"]}>
          <TextInput
            name="facebook"
            label="Facebook"
            type="text"
            value={state.facebook}
            onChange={(e) => dispatch({ facebook: e.target.value })}
          />
        </div>
        <div className={styles["form-flex-2"]}>
          <TextInput
            name="twitter"
            label="Twitter"
            type="text"
            value={state.twitter}
            onChange={(e) => dispatch({ twitter: e.target.value })}
          />
        </div>
      </div>
      <div className={styles["form-flex"]}>
        <div className={styles["form-flex-1"]}>
          <TextInput
            name="instagram"
            label="Instagram"
            type="text"
            value={state.instagram}
            onChange={(e) => dispatch({ instagram: e.target.value })}
          />
        </div>
        <div className={styles["form-flex-2"]}>
          <TextInput
            name="linkedin"
            label="Linkedin"
            type="text"
            value={state.linkedin}
            onChange={(e) => dispatch({ linkedin: e.target.value })}
          />
        </div>
      </div>
      <div className={styles["form-flex"]}>
        <div className={styles["form-flex-1"]}>
          <TextInput
            name="youtube"
            label="Youtube"
            type="text"
            value={state.youtube}
            onChange={(e) => dispatch({ youtube: e.target.value })}
          />
        </div>
        <div className={styles["form-flex-2"]}>
          <p className={styles["whatsapp"]}>Whatsapp</p>
          <PhoneInput
            country={"ng"}
            value={state.whatsapp}
            onChange={(phone) => dispatch({ whatsapp: phone })}
          />
        </div>
      </div>
    </Card>
  );
};

export default FormLHS;
