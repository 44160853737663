import React from "react";
import { useNavigate } from "react-router";
import { paths } from "../../../../../Routes/path";
import AddItem from "../../../../UI/AddItem/AddItem";
import Modal from "../../../../UI/Modal/Modal";
import { ActionMenuIcon } from "../../../../UI/RegularIcons";
import Tables from "../../../../UI/Tables/Tables";
import styles from "./DTable.module.scss";
import useDTable from "./hooks/useDTable";

function DTable({ riders, onClickAction, currentUserAbilities }) {
  const {
    onDelete,
    handleEdit,
    onClick,
    selected,
    openModal,
    setOpenModal,
    isLoading,
    del,
    update,
    create,
    handleVerify,
  } = useDTable(onClickAction, currentUserAbilities);
  const columns = [
    {
      name: "Code",
      selector: (row) => row.id,
      sortable: true,
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      name: "Name",
      selector: (row) => `${row.first_name} ${row.last_name}`,
      sortable: true,
      width: "70%",
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      selector: (row, i) => (
        <>
          <div className={styles["circle-icon"]} onClick={() => onClick(i)}>
            <ActionMenuIcon />
          </div>
          {openModal && i === selected && (
            <>
              {row?.verified ? (
                <Modal
                  openModal={openModal}
                  onClose={() => setOpenModal(false)}
                  editFn={() => handleEdit(row?.id)}
                  deleteFn={() => onDelete(row?.id, row?.name)}
                  del={del}
                  update={update}
                />
              ) : (
                <Modal
                  openModal={openModal}
                  onClose={() => setOpenModal(false)}
                  editFn={() => handleEdit(row?.id)}
                  deleteFn={() => onDelete(row?.id, row?.name)}
                  del={del}
                  viewFn={() => handleVerify(row?.id, row?.name)}
                  update={update}
                  verify
                  secondText="Verify Rider"
                />
              )}
            </>
          )}
        </>
      ),
      sortable: true,
      width: "5%",
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
  ];

  const navigate = useNavigate();
  return (
    <div className={styles.divv}>
      <Tables
        data={riders}
        columns={columns}
        pagination
        title="All Riders"
        atcBtn={
          create ? (
            <AddItem name="Rider" addFnc={() => navigate(paths.newRider)} />
          ) : (
            ""
          )
        }
        firstRow
        selectableRows
        fetchingData={isLoading}
        typeCount="riders_count"
        onRowClicked={() => {}}
      />
    </div>
  );
}

export default DTable;
