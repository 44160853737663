import React from "react";
import { NumberInput, SelectInput } from "../../../../UI/FormComponents/Input";
import styles from "../../Warehouse.module.scss";
import Button from "../../../../UI/Button/Button";

const FormLHS = ({
  inventories,
  state,
  handleSubmit,
  dispatch,
  manual,
  inventoryId,
}) => {
  return (
    <div className={styles["form-6grid-main"]}>
      <SelectInput
        name="Inventory item"
        label="Inventory item"
        value={inventoryId}
        options={inventories}
        onChange={(e) => dispatch({ inventory: e.target.value })}
        disabled
      />
      <div className={styles["space"]}>
        <NumberInput
          name="quantity"
          label="Quantity"
          value={state.value}
          // units={type === "product" ? "#" : item?.unit}
          onChange={(e) => dispatch({ qty: e.target.value })}
        />
      </div>
      {manual && (
        <div className={styles["warning"]}>This change is not reversible</div>
      )}
      <div className={styles["btn-div"]}>
        <Button
          name={manual ? "Change Inventory Value" : "Submit"}
          bgColor={manual ? "lred-bg" : "green-bg"}
          createFnc={handleSubmit}
          custom
          isLoading={state.isLoading}
        />
      </div>
    </div>
  );
};

export default FormLHS;
