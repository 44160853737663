import React from "react";
import TopDisplay from "../../../../UI/TopDisplay/TopDisplay";
import locationstyles from "../../ManageOrders.module.scss";
import FormLHS from "./FormGroups/FormLHS";
import useAsssign from "./hooks/useAsssign";

const AssignKitchen = ({orderRef, orderId, onClosePage}) => {
  const { 
    state,
    dispatch,
    handleSubmit,
    kitchenZones,
  } = useAsssign(orderId, onClosePage);
  return (
    <div className={locationstyles.mains}>
      <TopDisplay
        title="Assign Order to Kitchen"
        firstText={`Assign Order ${orderRef} to a Kitchen`}
      />
      <FormLHS
         orderRef={orderRef}
        state={state}
        handleSubmit={handleSubmit}
        dispatch={dispatch}
        kitchenZones={kitchenZones}
      />
    </div>
  );
};

export default AssignKitchen;
