import React from "react";
import styles from "./VerifyPayments.module.scss";
import FormGroupHeader from "../../../../UI/FormComponents/FormHeaders/FormGroupHeader";
import useVerify from "./hooks/useVerify";
import { FileInput, SelectInput } from "../../../../UI/FormComponents/Input";
import Button from "../../../../UI/Button/Button";

function VerifyPayments({onClose, orderId, paymentId}) {
  const { paymentMethod, state, dispatch, onSelectImage, handleSubmit } = useVerify(onClose, orderId, paymentId);
  return (
    <div className={styles.modal}>
      <FormGroupHeader title="Verify Payment Manually" />
      <SelectInput
        options={paymentMethod}
        label="Payment Method"
        name="payment_method"
        value={state.paymentType}
        onChange={(e) => dispatch({ paymentType: e.target.value })}
      />
      <FileInput
        name="proof_image"
        label="Proof of Payment"
        onSelectImage={onSelectImage}
        value={state.proof}
        imgSrc={state.image_src}
      />
      <div className={styles.cards}>
        <Button
          custom
          name="Verify Payment"
          bgColor="green-bg"
          createFnc={handleSubmit}
          isLoading={state?.isLoading}
        />
      </div>
    </div>
  );
}

export default VerifyPayments;
