import React from "react";
import styles from "./TextArea.module.scss";

const TextArea = (props) => {
  return (
    <div className={styles["form-group"]}>
      <div className={styles["form-container"]}>
        <textarea
          type="text"
          name={props.name}
          value={props.value}
          placeholder={`Enter ${props.label.toLowerCase()} here`}
          onChange={props.onChange}
        ></textarea>
        <label htmlFor={props.name}>{props.label}</label>
      </div>
    </div>
  );
};

export default TextArea;
