import React from "react";
import "./FormGroupHeader.scss";

const FormGroupHeader = (props) => {
  return (
    <div className="form-group-header">
      <h5>{props.title}</h5>
      <p className="caption grey-500">{props.description}</p>
    </div>
  );
};

export default FormGroupHeader;
