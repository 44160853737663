import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

function CircleCharts({
  strokeWidth,
  value,
  trailColor,
  pathColor,
  text,
  fontWeight,
  textColor,
  textSize,
}) {
  return (
    <CircularProgressbar
      value={value}
      text={text}
      strokeWidth={strokeWidth}
      styles={buildStyles({
        textColor: textColor,
        textSize: textSize,
        pathColor: pathColor,
        trailColor: trailColor,
        fontWeight: fontWeight,
      })}
    />
  );
}

export default CircleCharts;
