import React from "react";
import AddItem from "../AddItem/AddItem";
import styles from "./TopDisplay.module.scss";

function TopDisplay({
  title,
  firstText,
  secondText,
  thirdText,
  addItem,
  name,
  style1,
  style2,
  addFnc,
}) {
  return (
    <div className={styles.top}>
      <div className={styles.topDiv}>
        <h3 className={styles.title}>{title}</h3>
        <div className={styles.div1}>
          <span className={styles.text1}>{firstText}</span>
          <span className={styles.text2}>.</span>
          {style1 ? (
            <span className={styles.text3}>{secondText}</span>
          ) : (
            <span className={styles.text1}>{secondText}</span>
          )}
          {style2 && (
            <>
              <span className={styles.text2}>.</span>
              <span className={styles.text3}>{thirdText}</span>
            </>
          )}
        </div>
      </div>
      {addItem && (
        <div className={styles.addDiv}>
          <AddItem name={name} addFnc={addFnc} />
        </div>
      )}
    </div>
  );
}

export default TopDisplay;
