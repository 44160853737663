import React from "react";
import { cashFormater } from "../../../../../utils/helpers";
import { ActionMenuIcon } from "../../../../UI/RegularIcons";
import Tables from "../../../../UI/Tables/Tables";
import styles from "./DTable.module.scss";
import Modal from "../../../../UI/Modal/Modal";
import useDTable from "./hooks/useDTable";

function DTable({ secondRowFcn, secondRowAllFcn, onClickAction, currentUserAbilities }) {
  const {
    onClick,
    selected,
    onDelete,
    openModal,
    setOpenModal,
    isLoading,
    region,
    kitchenLocations,
    kitchenZones,
    handleEdit,
    del,
    update,
  } = useDTable(onClickAction, currentUserAbilities );
  const columns = [
    {
      name: (
        <div className={styles.statusDiv}>
          <div>
            <div>State</div>
          </div>
        </div>
      ),
      selector: (row) => row?.name,
      sortable: true,
      width: "25%",
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      name: <span>City</span>,
      selector: (row) => row?.city,
      sortable: true,
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      name: <span>Description</span>,
      selector: (row) => row?.description,
      sortable: true,
      width: "25%",
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
        width: '80px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    {
      name: <span>Revenue</span>,
      selector: (row) => `₦ ${cashFormater(row?.revenue)}`,
      sortable: true,
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      name: <span>Status</span>,
      selector: (row) => row?.status,
      sortable: true,
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },

    {
      selector: (row, i) => (
        <>
          <div className={styles["circle-icon"]} onClick={() => onClick(i)}>
            <ActionMenuIcon />
          </div>
          {openModal && i === selected && (
            <Modal
            openModal={openModal}
            onClose={() => setOpenModal(false)}
            editFn={() => handleEdit(row?.id)}
            deleteFn={() => onDelete(row?.id, row?.name)}
            del={del}
            update={update}
          />
          )}
        </>
      ),
      sortable: true,
      width: "5%",
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
  ];

  return (
    <div>
      <Tables
        data={kitchenLocations}
        columns={columns}
        pagination
        secondRow
        type="kitchens"
        fetchingData={isLoading}
        secondRowData={region}
        secondRowFcn={secondRowFcn}
        secondRowAllFcn={secondRowAllFcn}
        allLength={kitchenZones?.length}
        typeCount="kitchen_count"
        onRowClicked={() => {}}
      />
    </div>
  );
}

export default DTable;
