import React from "react";
import Card from "../../../../UI/Card/Card";
import {
  NumberInput,
  SelectInput,
  TextInput,
} from "../../../../UI/FormComponents/Input";
import styles from "../../AddInventoryForm.module.scss";
import Button from "../../../../UI/Button/Button";
import { dateDurations } from "../../../../../utils/constant";
import ToggleSwitch from "../../../../UI/FormComponents/InputTypes/ToggleSwitch";

const FormRHS = ({ edit, state, handleSubmit, dispatch }) => {
  return (
    <>
      <Card classes={styles["form-3grid-main"]}>
        <TextInput
          name="code"
          label="Inventory Simple Code"
          type="text"
          value={state?.code}
          onChange={({ target }) => dispatch({ code: target.value })}
        />
        <div>
          <ToggleSwitch
            selected={state?.expires}
            toggleSelected={() => {
              dispatch({ expires: !state?.expires });
            }}
            title="Expires"
          />
          {state?.expires && (
            <div className={styles.cardss}>
              <div className={styles["cardss-div1"]}>
                <SelectInput
                  name="date_duration"
                  label="Date Duration"
                  options={dateDurations}
                  value={state?.period}
                  onChange={(e) => dispatch({ period: e.target.value })}
                />
              </div>

              <div className={styles["cardss-div2"]}>
                <NumberInput
                  name="expiry date"
                  label="expiry date"
                  type="number"
                  integer
                  value={state?.periodLength}
                  onChange={(e) => dispatch({ periodLength: e.target.value })}
                />
              </div>
            </div>
          )}
        </div>
      </Card>

      <div className={styles.cards}>
        <Button
          name="Inventory"
          bgColor="green-bg"
          edit={edit}
          createFnc={handleSubmit}
          isLoading={state?.isLoading}
        />
      </div>
    </>
  );
};

export default FormRHS;
