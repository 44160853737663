import { SET_STAFF, SET_STAFFS, SET_STAFF_ID } from "../actionTypes/staffType";
  
  export function setStaffs(data) {
    return (dispatch) => {
      dispatch({ type: SET_STAFFS, data });
    };
  }
  
  export function setStaff(data) {
    return (dispatch) => {
      dispatch({ type: SET_STAFF, data });
    };
  }
  
  export function setStaffId(data) {
    return (dispatch) => {
      dispatch({ type: SET_STAFF_ID, data });
    };
  }

  