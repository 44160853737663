import axios from "axios";

export const defaultHeader = {
  Accept: "application/json",
  "Cache-Control": "no-cache",
  "Content-Type": "application/json",
  "Access-Control-Request-Method" : "*"
};

export async function request(url, payload, method, header, options = {}) {
  if (options.isFormData) delete defaultHeader['Content-Type'];
  if (options.isPdf) defaultHeader['Content-Type'] = 'application/pdf';
  if (options.isCsv) defaultHeader['Content-Type'] = 'text/csv';
  const requestMethod = method?.toLowerCase();
  try {
    const token = localStorage.getItem("token");
    if (token) {
      header = {
        ...header,
        Authorization: `bearer ${token}`,
      };
    }
    const response = await axios({
      method: requestMethod,
      url,
      data: payload,
      headers: Object.assign(defaultHeader, header),
    });
    
      if (options.isCsv) {
        // Convert the JSON response to a CSV Blob
        const csvData = response.data;
        const blob = new Blob([csvData], { type: "text/csv" });
    
        // Create a URL for the Blob and download the file
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = options.fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }
    return response.data;
  } catch (error) {
    if (error.message === 'Failed to fetch') {
      error.message = 'Network connection error';
    }
    return new Promise((response, reject) => {
      reject(error);
    });
  }
}
