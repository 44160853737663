import React from "react";
import { useNavigate } from "react-router";
import { paths } from "../../../../../Routes/path";
import AddItem from "../../../../UI/AddItem/AddItem";
import Button from "../../../../UI/Button/Button";
import Modal from "../../../../UI/Modal/Modal";
import { ActionMenuIcon } from "../../../../UI/RegularIcons";
import Tables from "../../../../UI/Tables/Tables";
import styles from "./DTable.module.scss";
import useDTable from "./hooks/useDTable";
import { camelToNormalCase } from "../../../../../utils/helpers";
import { SearchInput } from "../../../../UI/FormComponents/Input";

function DTable({
  handlePageChange,
  handlePerRowsChange,
  orders,
  totalRows,
  getAnOrder,
  onClickAction,
  secondRowFcn,
  secondRowAllFcn,
  orderStatus,
  totalOrders,
  currentUserAbilities,
}) {
  const {
    onDelete,
    isLoading,
    handleEdit,
    onClick,
    selected,
    openModal,
    setOpenModal,
    handleView,
    onRowClicked,
    del,
    update,
    create,
    show,
    setSearchValue,
    searchValue,
    downloadOrders,
    isDownloading,
  } = useDTable(getAnOrder, onClickAction, currentUserAbilities);
  const columns = [
    {
      name: "Reference",
      selector: (row) => row.reference,
      sortable: true,
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      name: "Name",
      selector: (row) => row.recipient.full_name,
      sortable: true,
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      name: "Status",
      selector: (row) => camelToNormalCase(row.status),
      sortable: true,
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      selector: (row, i) => (
        <>
          <div className={styles["circle-icon"]} onClick={() => onClick(i)}>
            <ActionMenuIcon />
          </div>
          {openModal && i === selected && (
            <Modal
              openModal={openModal}
              onClose={() => setOpenModal(false)}
              viewFn={() => handleView(row?.reference, row?.id)}
              editFn={() => handleEdit(row?.id)}
              deleteFn={() => onDelete(row?.id, row?.name)}
              del={del}
              update={update}
              show
              secondText="View"
            />
          )}
        </>
      ),
      sortable: true,
      width: "5%",
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
  ];

  const navigate = useNavigate();
  return (
    <div className={styles.divv}>
      <Tables
        data={orders}
        columns={columns}
        pagination
        title="All Orders"
        atcBtn={
          create ? (
            <AddItem name="Order" addFnc={() => navigate(paths.newOrder)} />
          ) : (
            ""
          )
        }
        atcBtn2={
          <div className={styles["second-field"]}>
            <div className={styles.searchBar}>
              <SearchInput
              placeholder="Search for an Order "
              name="search order"
              onChange={({ target }) => setSearchValue(target.value)}
              isLoading={isLoading && searchValue}
              />
            </div>
           <div className={styles.btnExport}> <Button name="Export To CSV" bgColor="black-bg" createFnc={downloadOrders} isLoading={isDownloading} custom /> </div>
          </div>
        }
        firstRow
        fetchingData={isLoading}
        selectableRows
        paginationServer
        secondRowData={orderStatus}
        secondRow
        handlePageChange={handlePageChange}
        handlePerRowsChange={handlePerRowsChange}
        totalRows={totalRows}
        type="orders"
        allLength={totalOrders}
        secondRowFcn={secondRowFcn}
        secondRowAllFcn={secondRowAllFcn}
        onRowClicked={show ? (row) => onRowClicked(row) : () => {}}
      />
    </div>
  );
}

export default DTable;
