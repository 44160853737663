import React from "react";
import MetaTags from "react-meta-tags";
import Grid from "../../UI/Grid/Grid";
import GridRow from "../../UI/Grid/GridRow";
import FormLHS from "./component/FormGroups/FormLHS";
import FormRHS from "./component/FormGroups/FormRHS";
import TopDisplay from "../../UI/TopDisplay/TopDisplay";
import styles from "./AddInventoryForm.module.scss";
import inventorystyles from "./Inventory.module.scss";
import useAddInventories from "./hooks/useAddInventories";

const AddInventoryForm = ({ edit }) => {
  const { state, handleSubmit, dispatch } = useAddInventories(edit);
  return (
    <>
      <MetaTags>
        <title>Add Inventory Page</title>
        <meta name="description" content="#" />
      </MetaTags>
      <div className={inventorystyles.main}>
        <TopDisplay
          title={edit ? "Update Inventory" : "Add New Inventory"}
          firstText="Kitchen"
          secondText="Inventory"
          thirdText={edit ? "Update Inventory" : "Add New Inventory"}
          style2
        />
        <div className={styles["form-main"]}>
          <Grid span="9">
            <GridRow span="6" customClass="section2">
              <FormLHS edit={edit} state={state} dispatch={dispatch} />
            </GridRow>
            <GridRow span="3" customClass="section2">
              <FormRHS
                edit={edit}
                state={state}
                handleSubmit={handleSubmit}
                dispatch={dispatch}
              />
            </GridRow>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default AddInventoryForm;
