import React from "react";
import MetaTags from "react-meta-tags";
import Grid from "../../UI/Grid/Grid";
import GridRow from "../../UI/Grid/GridRow";
import TopDisplay from "../../UI/TopDisplay/TopDisplay";
import ErrorPage from "../ErrorPage/ErrorPage";
import DTable from "./component/DTable/DTable";
import useManageOrders from "./hooks/useManageOrders";
import styles from "./ManageOrders.module.scss";

function ManageOrders() {
  const {
    handlePageChange,
    handlePerRowsChange,
    orders,
    totalRows,
    onClickAction,
    orderStatus,
    totalOrders,
    secondRowAllFcn,
    secondRowFcn,
    handleKitchen,
    currentUserAbilities,
    getOrderStatus
  } = useManageOrders();
  return (
    <>
      <MetaTags>
        <title>Manage Orders Page</title>
        <meta name="description" content="#" />
      </MetaTags>
      {currentUserAbilities?.index ? (
      <div className={styles.main}>
        <TopDisplay
          title="Manage Orders"
          firstText="Dashboard"
          secondText="Orders"
          thirdText="Manage Orders"
          style2
        />
        <div className={styles.section2}>
          <Grid span="9">
            <GridRow span="9" customClass="section2">
              <DTable
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
                orders={orders}
                totalRows={totalRows}
                handleKitchen={handleKitchen}
                onClickAction={onClickAction}
                orderStatus={orderStatus}
                totalOrders={totalOrders}
                secondRowAllFcn={secondRowAllFcn}
                secondRowFcn={secondRowFcn}
                currentUserAbilities={currentUserAbilities}
                getOrderStatus={getOrderStatus}
              />
            </GridRow>
          </Grid>
        </div>
      </div>
      ) : (
        <ErrorPage type2 />
      )}
    </>
  );
}

export default ManageOrders;
