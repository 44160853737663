import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

function useTopHeader() {
    const navigate = useNavigate();

    const myObjectString = localStorage.getItem('user');

    const currentUser = JSON.parse(myObjectString)

    const logout = (event) => {
        event.stopPropagation()
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate("/")
        toast.message("You Logged out!")
    }
  
  return{
    currentUser,
    logout,
  }
}

export default useTopHeader