import React from "react";
import "./PriceSetHeader.scss";

const PriceSetHeader = (props) => {
  return (
    <div className="form-group-header">
      <h6>{props.title}</h6>
    </div>
  );
};

export default PriceSetHeader;
