import React from "react";
import MetaTags from "react-meta-tags";
import Grid from "../../UI/Grid/Grid";
import GridRow from "../../UI/Grid/GridRow";
import TopDisplay from "../../UI/TopDisplay/TopDisplay";
import ErrorPage from "../ErrorPage/ErrorPage";
import DTable from "./component/DTable/DTable";
import useInventories from "./hooks/useInventories";
import styles from "./Warehouse.module.scss";

function Warehouse() {
  const { inventories, onClickAction, currentUserAbilities, region } =
    useInventories();
  return (
    <>
      <MetaTags>
        <title>{`Warehouse ${region?.state} Page`}</title>
        <meta name="description" content="#" />
      </MetaTags>
      {currentUserAbilities?.index ? (
        <div className={styles.main}>
          <TopDisplay
            title={`${region?.state} Warehouse Inventories`}
            firstText="Dashboard"
            secondText={region?.state}
            thirdText="Warehouse"
            style2
          />
          <div className={styles.section2}>
            <Grid span="9">
              <GridRow span="9" customClass="section2">
                <DTable
                  inventories={inventories}
                  onClickAction={onClickAction}
                  currentUserAbilities={currentUserAbilities}
                />
              </GridRow>
            </Grid>
          </div>
        </div>
      ) : (
        <ErrorPage type2 />
      )}
    </>
  );
}

export default Warehouse;
