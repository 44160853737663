import { useEffect } from "react";
import { useCallback } from "react";
import { useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { setDepartment } from "../../../../redux/actions/departmentAction";
import { paths } from "../../../../Routes/path";
import departmentServices from "../../../../Services/department.services";
import { objectToArray } from "../../../../utils/helpers";
import { initState } from "../constant";

function useManageDepartment(edit) {
  const { department } = useSelector((state) => state.department);

  const { departmentId } = useParams();
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();

  const [state, dispatch] = useReducer(
    (departmentState, value) => ({ ...departmentState, ...value }),
    initState
  );

  const getAStaff = useCallback(() => {
    departmentServices
      .getADepartment(departmentId)
      .then(({ data }) => {
        setDepartment(data)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
  }, [departmentId, reduxDispatch]);

  useEffect(() => {
    if (edit) getAStaff();
  }, [edit, getAStaff]);

  useEffect(() => {
    if (edit) {
      dispatch({
        title: department.title,
        description: department.description,
        // address: department.address, // read or none
        admin: department.admin,
        available_region: department.available_region,
        category: department.category,
        delivery_zone: department.delivery_zone,
        // general_review: department.general_review,
        inventory: department.inventory,
        kitchen: department.kitchen,
        // location_contact: department.delivery_zone,
        location: department.location,
        order: department.order,
        // order_item: department.order_item,
        payment: department.payment,
        product: department.product,
        rider: department.rider,
        rider_verification: department.rider_verification,
        // social_medium: department.social_medium,
        stock: department.stock,
        user: department.user,
        discount: department.discount,
      });
    }
  }, [department, edit]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!state?.title) {
      toast.error("Title cannot be empty");
      return;
    }

    dispatch({ isLoading: true });
    if (!edit) {
      departmentServices
        .createDepartment({
          department: {
            title: state.title,
            description: state.description,
            address: state.address, // read or none
            admin: state.admin,
            available_region: state.available_region,
            category: state.category,
            delivery_zone: state.delivery_zone,
            general_review: state.general_review,
            inventory: state.inventory,
            kitchen: state.kitchen,
            location_contact: state.delivery_zone,
            location: state.location,
            order: state.order,
            order_item: state.order_item,
            payment: state.payment,
            product: state.product,
            rider: state.rider,
            rider_verification: state.rider_verification,
            social_medium: state.social_medium,
            stock: state.stock,
            user: state.user,
            discount: state.discount,
          },
        })
        .then(() => {
          toast.success("Department was succesfully created");
          navigate(paths.departments);
        })
        .catch((err) => {
          dispatch({ isLoading: false });
          if (err.response.status === 422) {
            let errorMsg = objectToArray(err?.response?.data?.data);
            toast.error(`${errorMsg[0][0]} ${errorMsg[0][1][0]}`);
          } else {
            toast.error(err.message);
          }
        });
    } else {
      departmentServices
        .updateDepartment(departmentId, {
          department: {
            title: state.title,
            description: state.description,
            address: state.address, // read or none
            admin: state.admin,
            available_region: state.available_region,
            category: state.category,
            delivery_zone: state.delivery_zone,
            general_review: state.general_review,
            inventory: state.inventory,
            kitchen: state.kitchen,
            location_contact: state.delivery_zone,
            location: state.location,
            order: state.order,
            order_item: state.order_item,
            payment: state.payment,
            product: state.product,
            rider: state.rider,
            rider_verification: state.rider_verification,
            social_medium: state.social_medium,
            stock: state.stock,
            user: state.user,
            discount: state.discount,
          },
        })
        .then(() => {
          toast.success("Department was succesfully updated");
          navigate(paths.departments);
        })
        .catch((err) => {
          dispatch({ isLoading: false });
          if (err.response.status === 422) {
            let errorMsg = objectToArray(err?.response?.data?.data);
            toast.error(`${errorMsg[0][0]} ${errorMsg[0][1][0]}`);
          } else {
            toast.error(err.message);
          }
        });
    }
  };

  return {
    state,
    dispatch,
    handleSubmit,
  };
}

export default useManageDepartment;
