import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { paths } from '../../../../../../Routes/path';
import { useCallback } from 'react';
import { setOrders } from '../../../../../../redux/actions/order.action';
import ordersServices from '../../../../../../Services/orders.services';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { setIsLoading } from '../../../../../../redux/actions/appAction';

function useDTable( getAnOrder, onClickAction, currentUserAbilities ) {
    const [openModal, setOpenModal] = useState(false);
    const [searchValue, setSearchValue] = useState(false);
    const [selected, setSelected] = useState(null);
    const [isDownloading, setIsDownloading] = useState(false)
    const onClick = (value) => {
      setOpenModal(true);
      setSelected(value);
    };
    const reduxDispatch = useDispatch();

    const update = currentUserAbilities["order"]?.update
    const create = currentUserAbilities["order"]?.create

    const del = currentUserAbilities["order"]?.destroy

    const show = currentUserAbilities["order"]?.show

    const { zoneByRegion } = useSelector(
      (state) => state.delivery
    );
    const isLoading = useSelector(state => state.app.isLoading) 
    const navigate = useNavigate();
  
    const handleEdit = (orderId) => {
      getAnOrder(orderId)
      navigate(paths.editOrder.replace(":orderId", orderId));
    };

    const handleView = (referenceId, orderId) => {
      navigate(paths.orderDetails.replace(":orderId", referenceId), { state: orderId })
    }
  
    const onDelete = (orderId) => {
        onClickAction(orderId)
    };

    const onRowClicked = (rows ) => {
      handleView(rows?.reference, rows?.id)
    };

    const searchOrders = useCallback(() => {
      setIsLoading(true)(reduxDispatch);
      ordersServices
        .searchOrders(searchValue, 1, 50)
        .then(({ data }) => {
          setOrders(data.data)(reduxDispatch);
        })
        .catch((err) => {
          toast.error(err.message || "Unable to get");
        })
        .finally(() => setIsLoading(false)(reduxDispatch));
    }, [reduxDispatch, searchValue]);

    const downloadOrders = (orderId) => {
      setIsDownloading(true)
      ordersServices
        .downloadOrder()
        .then(({ data }) => {
          setIsDownloading(false)
        })
        .catch((err) => {
          setIsDownloading(false)
          toast.error(err.message || "Unable to get");
        });
    };

    useEffect(() => {
      if (searchValue) searchOrders();
    }, [searchOrders, searchValue]);

  return {
    onDelete,
    handleEdit,
    onClick,
    selected,
    openModal,
    setOpenModal,
    isLoading,
    zoneByRegion,
    handleView,
    onRowClicked,
    del,
    update,
    create,
    show,
    setSearchValue,
    downloadOrders,
    isDownloading,
  }
}

export default useDTable