import { SET_CASHFLOW_DATA, SET_CUSTOMER_DATA, SET_ORDER_DATA, SET_PENDING_ORDERS, SET_RECENT_ORDERS, SET_USER_STATS, SET_PAYMENTS, SET_LOGS } from "../actionTypes/dashboardTypes";


const initialState = {
  customerData: [],
  orderData: [],
  cashflowData: [],
  recentOrders: [],
  pendingOrders: [],
  userStats: [],
  payments: [],
  logs: [],
};

export default function dashboardReducer(state = initialState, action) {
  const { data, type } = action;
  switch (type) {
    case SET_CUSTOMER_DATA:
      return {
        ...state,
        customerData: data,
      };
    case SET_ORDER_DATA:
      return {
        ...state,
        orderData: data,
      };
    case SET_CASHFLOW_DATA:
      return {
        ...state,
        cashflowData: data,
      };
    case SET_RECENT_ORDERS:
      return {
        ...state,
        recentOrders: data,
      };
    case SET_PENDING_ORDERS:
      return {
        ...state,
        pendingOrders: data,
      };
    case SET_USER_STATS:
      return {
        ...state,
        userStats: data,
      };
    case SET_PAYMENTS:
      return {
        ...state,
        payments: data,
      };
    case SET_LOGS:
      return {
        ...state,
        logs: data,
      }

    default:
      return state;
  }
}
