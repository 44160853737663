import { API_URL } from "../env";
import { request } from "./api";

const URL = {
  getStaffs: `${API_URL}api/v1/be/coy/staff`,
  getAStaff: (staffId) => `${API_URL}api/v1/be/coy/staff/${staffId}`,
  createStaff: `${API_URL}api/v1/be/coy/staff/new`,
  deleteStaff: (staffId) => `${API_URL}api/v1/be/coy/staff/${staffId}`,
  updateStaff: (staffId) => `${API_URL}api/v1/be/coy/staff/${staffId}`,
};

function getStaffs() {
  return request(URL.getStaffs, "get");
}

function getAStaff(staffId) {
  return request(URL.getAStaff(staffId), "get");
}

function deleteStaff(staffId) {
  return request(URL.deleteStaff(staffId), {}, "delte");
}

function createStaff(staff) {
  return request(URL.createStaff, staff, "post");
}

function updateStaff(staffId, staff) {
  return request(URL.updateStaff(staffId), staff, "put");
}

const staffServices = {
  getStaffs,
  getAStaff,
  updateStaff,
  createStaff,
  deleteStaff,
};

export default staffServices;
