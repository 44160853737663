import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { setIsLoading } from "../../../../redux/actions/appAction";
import {
  setCategories,
} from "../../../../redux/actions/categories.action";
import {
  setIsDeleting,
  setPopupComponent,
  showPopup,
} from "../../../../redux/actions/popUp.action";
import { paths } from "../../../../Routes/path";
import categoriesServices from "../../../../Services/categories.services";
import DeleteModal from "../../../UI/DeleteModal/DeleteModal";

function useCategories() {
  const { categories } = useSelector(
    (state) => state.category
  );
  const { openModal } = useSelector((state) => state.app);
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  const getCategories = useCallback(() => {
    setIsLoading(true)(reduxDispatch);
    categoriesServices
      .getCategories()
      .then(({ data }) => {
        setCategories(data)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      })
      .finally(() => setIsLoading(false)(reduxDispatch));
  }, [reduxDispatch]);


  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const onClose = () => {
    showPopup({ ispopupOpen: false })(reduxDispatch);
  };

  const handleDelete = (categoryId) => {
    setIsDeleting(true)(reduxDispatch);
    categoriesServices
      .deleteCategory(categoryId)
      .then(() => {
        toast.success("Category was deleted");
        getCategories();
        onClose();
        navigate(paths.categories)
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setIsDeleting(false)(reduxDispatch));
  };

  const onClickAction = (categoryId) => {
    reduxDispatch(
      setPopupComponent(
        <DeleteModal
          onClose={onClose}
          handleDelete={() => handleDelete(categoryId)}
          title="Category"
        />
      )
    );
    showPopup({ ispopupOpen: true, onClose: () => onClose() })(reduxDispatch);
  };

  const myObjectString = localStorage.getItem("user");

  const currentUser = JSON.parse(myObjectString);
  const currentUserAbilities = currentUser?.account_abilities?.abilities?.["category"];

  return {
    categories,
    openModal,
    handleDelete,
    onClose,
    onClickAction,
    currentUserAbilities,
  };
}

export default useCategories;
