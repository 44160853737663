import { API_URL } from "../env";
import { request } from "./api";

const URL = {
  getProducts: `${API_URL}api/v1/be/products`,
  getFormData: `${API_URL}api/v1/be/products/new/form-config`,
  getAProduct: (productId) => `${API_URL}api/v1/be/products/${productId}`,
  createProduct: `${API_URL}api/v1/be/products/new`,
  deleteProduct: (productId) => `${API_URL}api/v1/be/products/${productId}`,
  updateProduct: (productId) => `${API_URL}api/v1/be/products/${productId}`,
};

function getProducts() {
  return request(URL.getProducts, "get");
}

function getFormData() {
  return request(URL.getFormData, "get");
}

function getAProduct(productId) {
  return request(URL.getAProduct(productId), "get");
}

function deleteProduct(productId, product) {
  return request(URL.deleteProduct(productId), product, "delete");
}

function createProduct(product) {
  console.log(product);
  return request(URL.createProduct, product, "post");
}

function updateProduct(productId, product) {
  return request(URL.updateProduct(productId), product, "put");
}

const productsServices = {
  getProducts,
  getAProduct,
  updateProduct,
  createProduct,
  deleteProduct,
  getFormData,
};

export default productsServices;
