import {
  SET_DELIVERY_REGIONS,
  SET_DELIVERY_ZONES,
  SET_ZONE_BY_REGION,
  SET_ZONE_ID,
} from "../actionTypes/deliveryZonesType";

const initialState = {
  deliveryZones: [],
  zoneByRegion: [],
  zoneId: null,
  deliveryRegions: []
};

export default function deliveryZoneReducer(state = initialState, action) {
  const { data, type } = action;
  switch (type) {
    case SET_DELIVERY_ZONES:
      return {
        ...state,
        deliveryZones: data,
      };
    case SET_ZONE_BY_REGION:
      return {
        ...state,
        zoneByRegion: data,
      };
    case SET_ZONE_ID:
      return {
        ...state,
        zoneId: data,
      };
      case SET_DELIVERY_REGIONS:
      return {
        ...state,
        deliveryRegions: data,
      };
    default:
      return state;
  }
}
