import { SET_CUSTOMER, SET_CUSTOMERS, SET_CUSTOMER_ID } from "../actionTypes/customerActionType";
  
  export function setCustomers(data) {
    return (dispatch) => {
      dispatch({ type: SET_CUSTOMERS, data });
    };
  }
  
  export function setCustomer(data) {
    return (dispatch) => {
      dispatch({ type: SET_CUSTOMER, data });
    };
  }
  
  export function setCustomerId(data) {
    return (dispatch) => {
      dispatch({ type: SET_CUSTOMER_ID, data });
    };
  }

  