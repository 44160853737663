import { useEffect, useCallback, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { setDepartments } from "../../../../redux/actions/departmentAction";
import { setKitchenZones } from "../../../../redux/actions/kitchen.action";
import { setRegion } from "../../../../redux/actions/location.action";
import { setStaff } from "../../../../redux/actions/staffAction";
import { paths } from "../../../../Routes/path";
import departmentServices from "../../../../Services/department.services";
import kitchenServices from "../../../../Services/kitchen.services";
import locationServices from "../../../../Services/location.services";
import staffServices from "../../../../Services/staff.services";
import { getBase64, objectToArray } from "../../../../utils/helpers";

function useManageStaff(edit) {
  const { staff } = useSelector((state) => state.staff);
  const { departments } = useSelector((state) => state.department);
  const { region } = useSelector((state) => state?.location);
  const { kitchenZones } = useSelector((state) => state.kitchen);

  const initState = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    image: "",
    image_src: "",
    password: "",
    isLoading: false,
    active: true,
    department: null,
    location: null,
    restrictedLocation: false,
    kitchen: null,
    restrictedKitchen: false,
  };

  const { staffId } = useParams();
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();

  const [state, dispatch] = useReducer(
    (staffState, value) => ({ ...staffState, ...value }),
    initState
  );

  const getAStaff = useCallback(() => {
    staffServices
      .getAStaff(staffId)
      .then(({ data }) => {
        setStaff(data)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
  }, [staffId, reduxDispatch]);

  const getDepartments = useCallback(() => {
    departmentServices
      .getDepartments()
      .then(({ data }) => {
        let valuesArr = data.map((item) => ({
          name: item.title,
          id: item.id,
        }));
        setDepartments(valuesArr)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
  }, [reduxDispatch]);

  const getAllRegions = useCallback(() => {
    locationServices
      .getAllRegions()
      .then(({ data }) => {
        setRegion(data)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
  }, [reduxDispatch]);

  const getAllKichens = useCallback(() => {
    kitchenServices
      .getKitchens()
      .then(({ data }) => {
        let kitchens = data?.filter(
          (item) => +item?.available_region_id === +state?.location
        );
        setKitchenZones(kitchens)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
  }, [reduxDispatch, state]);

  const onSelectImage = (e) => {
    let file = e.target.files[0];

    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        dispatch({
          image: result,
          image_src: URL.createObjectURL(e.target.files[0]),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getDepartments();
    getAllRegions();
    if (edit) {
      getAStaff();
    }
    if (state?.location && state?.location !== null) {
      getAllKichens();
    }
  }, [edit, getAStaff, getAllKichens, getAllRegions, getDepartments, state]);

  useEffect(() => {
    if (edit) {
      const departmentId = departments.find(
        (item) => item.name === staff?.account_abilities?.department
      );
      dispatch({
        firstName: staff?.first_name,
        lastName: staff?.last_name,
        email: staff?.email,
        phone: staff?.phone_number,
        image: staff?.avatar?.url,
        image_src: staff?.avatar?.url,
        active: staff?.active,
        department: departmentId?.id,
        restrictedLocation: staff?.restricted_by_location,
        restrictedKitchen: staff?.restricted_by_kitchen,
        location: staff?.available_region_id,
        kitchen: staff?.kitchen_id,
      });
    }
  }, [staff, edit, departments]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!state?.firstName) {
      toast.error("First Name cannot be empty");
      return;
    }
    if (!state?.lastName) {
      toast.error("Last Name cannot be empty");
      return;
    }
    if (!state?.email) {
      toast.error("Email cannot be empty");
      return;
    }
    if (!state?.phone) {
      toast.error("Phone cannot be empty");
      return;
    }
    if (!state?.department) {
      toast.error("Select a department");
      return;
    }
    if (!state?.password && !edit) {
      toast.error("Password cannot be empty");
      return;
    }
    dispatch({ isLoading: true });
    if (!edit) {
      staffServices
        .createStaff({
          admin: {
            first_name: state.firstName,
            last_name: state.lastName,
            email: state.email,
            phone_number: state.phone,
            avatar: state.image,
            active: state?.active,
            password: state?.password,
            department_id: state?.department,
            restricted_by_location: state?.restrictedLocation,
            restricted_by_kitchen: state?.restrictedKitchen,
            available_region_id: state?.location,
            kitchen_id: state?.kitchen,
          },
        })
        .then(() => {
          toast.success("Staff was succesfully created");
          navigate(paths.staff);
        })
        .catch((err) => {
          dispatch({ isLoading: false });
          if (err.response.status === 422) {
            let errorMsg = objectToArray(err?.response?.data?.data);
            toast.error(`${errorMsg[0][0]} ${errorMsg[0][1][0]}`);
          } else {
            toast.error(err.message);
          }
        });
    } else {
      staffServices
        .updateStaff(staffId, {
          admin: {
            first_name: state.firstName,
            last_name: state.lastName,
            email: state.email,
            phone_number: state.phone,
            active: state?.active,
            department_id: state?.department,
            restricted_by_location: state?.restrictedLocation,
            restricted_by_kitchen: state?.restrictedKitchen,
            available_region_id: state?.location,
            kitchen_id: state?.kitchen,
            ...(state.image === staff?.avatar.url
              ? {}
              : { avatar: state.image }),
            ...(!state.password ? {} : { password: state.password }),
          },
        })
        .then(() => {
          toast.success("Staff was succesfully updated");
          navigate(paths.staff);
        })
        .catch((err) => {
          dispatch({ isLoading: false });
          if (err.response.status === 422) {
            let errorMsg = objectToArray(err?.response?.data?.data);
            toast.error(`${errorMsg[0][0]} ${errorMsg[0][1][0]}`);
          } else {
            toast.error(err.message);
          }
        });
    }
  };

  return {
    state,
    dispatch,
    onSelectImage,
    handleSubmit,
    departments,
    region,
    kitchenZones,
  };
}

export default useManageStaff;
