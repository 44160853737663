import React from "react";
import { toast } from "react-toastify";
import CopyIcon from '../../../../../Assets/Images/RegularIcons/copy-icon-1.png'
import styles from "./SendPaymentLink.module.scss";

function SendPaymentLink({ payment, onClose }) {
    function copyToClipboard() {
        toast.success("Payment Link copied");
        navigator.clipboard.writeText(payment);
        onClose();
    }
  return (
    <div className={styles["sendPaymentLink"]}>
      <section className={styles["section"]}>
        <p className={styles["message"]}>
          Your order has been placed, but cannot be processed until payment has
          been made. To complete this transaction please make payment using
          *any* of the following methods:
        </p>
        <p className={styles["paystack-name"]}>Flutterwave:</p>
        <a href={payment} className={styles["link"]}><span className={styles["link-text"]}>{payment}</span></a>
      </section>
      <div className={styles["copy-div"]} onClick={copyToClipboard}>
        <div className={styles["copy-img-box"]}>
           <img src={CopyIcon} alt="copy icon" className={styles["copy-img"]} />
        </div>
        <p>Copy Message</p>
      </div>
    </div>
  );
}

export default SendPaymentLink;
