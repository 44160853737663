import React, { useEffect, useRef, useState } from "react";
import "./AutoComplete.scss";
import { IoIosClose } from "react-icons/io";

const AutoComplete = ({ options, label, onChange }) => {
  const [input, setInput] = useState("");
  const [showOptions, setOptions] = useState(false);
  const [optionsList, setOptionsList] = useState([...options]);

  const ref = useRef();

  const handleInputChange = (val) => {
    setOptions(true);
    setInput(val);
    const filter = [...options].filter((o) => o.title.includes(val));
    setOptionsList(filter);
  };

  const handleSelect = (opt) => {
    setInput(opt.title);
    onChange(opt);
    setTimeout(() => {
      setOptions(false);
      setInput("");
    }, 10);
  };

  window.addEventListener("click", (e) => {
    if (options && !ref.current?.contains(e.target)) setOptions(false);
  });

  useEffect(() => {
    if (options.length !== optionsList.lentgh) setOptionsList(options);
  }, [options]);

  return (
    <div
      className="field"
      onClick={() => {
        setOptions(true);
      }}
      ref={ref}
    >
      <span className={`label ${input === "" ? "hidden" : "vissible"}`}>
        {label}
      </span>
      <div className="row">
        <input
          type="text"
          value={input}
          onChange={(e) => handleInputChange(e.target.value)}
          placeholder={label}
        />
        <IoIosClose className="icon" onClick={() => setInput("")} />
      </div>
      {showOptions && (
        <div className="options">
          {[...optionsList].map((opt, n) => (
            <span onClick={() => handleSelect(opt)} className="option" key={n}>
              {opt.title}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default AutoComplete;
