import React from "react";
import styles from "./RefreshOrder.module.scss";
import { NumberInput } from "../../../../UI/FormComponents/Input";
import useRefreshOrders from "./hooks/useRefreshOrders";
import Button from "../../../../UI/Button/Button";
import FormGroupHeader from "../../../../UI/FormComponents/FormHeaders/FormGroupHeader";
import ToggleSwitch from "../../../../UI/FormComponents/InputTypes/ToggleSwitch";
import getSymbolFromCurrency from "currency-symbol-map";

function RefreshOrder({ order, orderId, onClose }) {
  const { state, dispatch, handleSubmit } = useRefreshOrders(
    orderId,
    onClose,
    order
  );
  return (
    <div className={styles.mains}>
      <FormGroupHeader title="Refresh & Redeliver Order to Customer" />
      {!state.next ? (
        <>
          <ToggleSwitch
            title="Refresh Order"
            selected={state.refreshOrder}
            toggleSelected={() => {
              dispatch({ refreshOrder: !state.refreshOrder });
            }}
          />
          {state?.refreshOrder && (
              <NumberInput
                name="new_price"
                label="Refreshing Cost Price"
                value={state?.price}
                units={getSymbolFromCurrency(order?.currency)}
                onChange={({ target }) => dispatch({ price: target.value })}
              />
            )}
              <NumberInput
                name="delivery_price"
                label="Delivery Price"
                units={getSymbolFromCurrency(order?.currency)}
                value={state?.delivery}
                disabled={true}
              />
              {/* <p>Delivery price is based on the order's delivery address</p> */}
        </>
      ) : (
        <div className={styles["sendPaymentLink"]}>
          <section className={styles["section"]}>
            <p className={styles["message"]}>
              Your order has been placed, but cannot be processed until payment
              has been made. To complete this transaction please make payment
              using *any* of the following methods:
            </p>
            <p className={styles["paystack-name"]}>Paystack:</p>
            <a href={state?.paymentLink} className={styles["link"]}>
              <span className={styles["link-text"]}>{state?.paymentLink}</span>
            </a>

            <p>Flutterwave:</p>
            <p className={styles["unavailable"]}>Unavailable</p>

            <p>Bank Transfer</p>
            <p className={styles["unavailable"]}>Unavailable</p>
          </section>
        </div>
      )}
        <div className={styles.cards}>
          <Button
            name={
              state.next ? "Copy as Message" : "Generate Payment for Refreshing"
            }
            bgColor="green-bg"
            custom
            createFnc={handleSubmit}
            isLoading={state?.isLoading}
          />
        </div>
    </div>
  );
}

export default RefreshOrder;
