import { useCallback, useEffect, useMemo, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { setIsLoading } from "../../../../redux/actions/appAction";
import {
  setCities,
  setCountries,
  setRegion,
} from "../../../../redux/actions/location.action";
import { paths } from "../../../../Routes/path";
import locationServices from "../../../../Services/location.services";
import ridersServices from "../../../../Services/riders.services";
import { getBase64, objectToArray } from "../../../../utils/helpers";
import { setRider } from "../../../../redux/actions/ridersAction";
import kitchenServices from "../../../../Services/kitchen.services";
import { setKitchenZones } from "../../../../redux/actions/kitchen.action";
import deliveryZonesServices from "../../../../Services/deliveryZones.services";
import {
  setPopupComponent,
  showPopup,
} from "../../../../redux/actions/popUp.action";
import ViewProof from "../../../UI/ViewProof/ViewProof";
import DeleteModal from "../../../UI/DeleteModal";

function useAddRider(edit, verify) {
  const { rider } = useSelector((state) => state.rider);
  const { kitchenZones } = useSelector((state) => state.kitchen);

  const initState = {
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    receive_email: true,
    receive_sms: true,
    terms: true,
    password: "",
    password_confirmation: "",
    isLoading: false,
    status: false,
    kitchenId: null,
    image: "",
    image_src: "",
    make: "",
    model: "",
    registration_number: "",
    vehicle_type: "",
    location_id: null,
    available_region_id: null,
    delivery_zone_id: null,
    street: "",
    house_number: "",
    id_type: "",
    id_file: "",
    id_src: "",
    selfie: "",
    isVerifying: false,
  };

  const reduxDispatch = useDispatch();
  const { region } = useSelector((state) => state.location);
  const { countries, cities } = useSelector((state) => state.location);
  const { riderId } = useParams();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(
    (categoryState, value) => ({ ...categoryState, ...value }),
    initState
  );

  const getCountries = useCallback(() => {
    setIsLoading(true)(reduxDispatch);
    locationServices
      .getAllLocations()
      .then(({ data }) => {
        let valuesArr = data.map((item) => ({
          name: item.country_data?.data?.translated_names[0],
          id: item.id,
        }));
        setCountries(valuesArr)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      })
      .finally(() => setIsLoading(false)(reduxDispatch));
  }, [reduxDispatch]);

  const getARider = useCallback(() => {
    ridersServices
      .getARider(riderId)
      .then(({ data }) => {
        setRider(data)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
  }, [riderId, reduxDispatch]);

  const getAllRegions = useCallback(() => {
    locationServices
      .getAllRegions()
      .then(({ data }) => {
        let regionsAvail = data?.filter(
          (item) => item?.location_id === state?.location_id
        );
        let valuesArr = regionsAvail.map((item) => ({
          name: item.state,
          id: item.id,
        }));
        setRegion(valuesArr)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
  }, [reduxDispatch, state]);

  const getCities = useCallback(() => {
    deliveryZonesServices
      .getDeliveryZones()
      .then(({ data }) => {
        let regionsAvail = data?.filter(
          (item) => +item?.region_details?.id === +state?.available_region_id
        );
        let valuesArr = regionsAvail.map((item) => ({
          name: item.name,
          id: item.id,
        }));
        setCities(valuesArr)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, [reduxDispatch, state?.available_region_id]);

  useEffect(() => {
    if (edit || verify) getARider();
  }, [edit, getARider, verify]);

  const onSelectImage = (e) => {
    let file = e.target.files[0];

    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        dispatch({
          image: result,
          image_src: URL.createObjectURL(e.target.files[0]),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSelectFile = (e) => {
    let file = e.target.files[0];

    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        dispatch({
          id_file: result,
          id_src: URL.createObjectURL(e.target.files[0]),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllKichens = useCallback(() => {
    kitchenServices
      .getKitchens()
      .then(({ data }) => {
        let regionsAvail = data?.filter(
          (item) => +item?.available_region_id === +state.available_region_id
        );
        setKitchenZones(regionsAvail)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      })
      .finally(() => setIsLoading(false)(reduxDispatch));
  }, [reduxDispatch, state]);

  useEffect(() => {
    getCountries();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (state?.available_region_id) {
      getCities();
      getAllKichens();
    }
    if (state?.location_id) getAllRegions();
    // eslint-disable-next-line
  }, [state?.location_id, state?.available_region_id]);

  useEffect(() => {
    if (edit || verify) {
      dispatch({
        first_name: rider?.first_name,
        last_name: rider?.last_name,
        phone: rider?.phone,
        email: rider?.email,
        receive_email: rider.receive_email,
        receive_sms: rider.receive_sms,

        location_id: rider?.address?.location_id,
        street: rider?.address?.street,
        house_number: rider?.address?.house_number,
        delivery_zone_id: rider?.address?.delivery_zone_id,

        available_region_id: rider?.available_region_id,
        kitchenId: rider?.kitchen_id,
        image_src: rider?.image?.url,
        make: rider?.bike?.make,
        model: rider?.bike?.model,
        vehicle_type: rider?.bike?.vehicle_type,
        registration_number: rider?.bike?.registration_number,

        id_type: rider?.rider_verification?.id_type,
        id_src: rider?.rider_verification?.id_file?.url,
        selfie: rider?.rider_verification?.selfie?.url,
        status: rider?.rider_verification?.id_verified,
      });
    }
  }, [rider, edit, verify]);

  // let password = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  const handleForm = (e) => {
    // if (!edit && !state.password.match(password)) {
    //   toast.error("Password must at least have 8 letters, a symbol, upper, lower case letters and a number")
    //   return ;
    // }
    if (!edit && state.password !== state.password_confirmation) {
      toast.error("Password must be same as Confirm Password");
      return;
    }
    handleSubmit(e);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("update");
    dispatch({ isLoading: true });
    if (!edit) {
      ridersServices
        .createRider({
          rider: {
            first_name: state.first_name,
            last_name: state.last_name,
            available_region_id: state.available_region_id,
            kitchen_id: state.kitchenId,
            email: state.email,
            phone: state.phone,
            receive_email: true,
            receive_sms: true,
            terms: true,
            password: state.password,
            password_confirmation: state.password_confirmation,
            image: state.image,
          },
          bike: {
            make: state.make,
            model: state.model,
            registration_number: state.registration_number,
            vehicle_type: state.vehicle_type,
          },
          address: {
            location_id: state.location_id,
            available_region_id: state.available_region_id,
            delivery_zone_id: state.delivery_zone_id,
            street: state.street,
            house_number: state.house_number,
          },
          verification: {
            id_type: state.id_type,
            id_file: state.id_file,
          },
        })
        .then(() => {
          toast.success("Rider was succesfully created");
          navigate(paths.riders);
        })
        .catch((err) => {
          dispatch({ isLoading: false });
          if (err.response.status === 422) {
            let errorMsg = objectToArray(err?.response?.data?.data);
            toast.error(`${errorMsg[0][0]} ${errorMsg[0][1][0]}`);
          } else {
            toast.error(err.message);
          }
        });
    } else {
      ridersServices
        .updateRider(riderId, {
          rider: {
            first_name: state.first_name,
            last_name: state.last_name,
            available_region_id: state.available_region_id,
            kitchen_id: state.kitchenId,
            email: state.email,
            phone: state.phone,
            receive_email: true,
            receive_sms: true,
            terms: true,
            image: state.image,
          },
          bike: {
            make: state.make,
            model: state.model,
            registration_number: state.registration_number,
            vehicle_type: state.vehicle_type,
          },
          address: {
            location_id: state.location_id,
            available_region_id: state.available_region_id,
            delivery_zone_id: state.delivery_zone_id,
            street: state.street,
            house_number: state.house_number,
          },
          verification: {
            id_type: state.id_type,
            id_file: state.id_file,
          },
        })
        .then(() => {
          toast.success("Rider was succesfully updated");
          navigate(paths.riders);
        })
        .catch((err) => {
          dispatch({ isLoading: false });
          if (err.response.status === 422) {
            let errorMsg = objectToArray(err?.response?.data?.data);
            toast.error(`${errorMsg[0][0]} ${errorMsg[0][1][0]}`);
          } else {
            toast.error(err.message);
          }
        });
    }
  };

  const pageTitle = useMemo(() => {
    if (edit) {
      return "Update Rider";
    }
    if (verify) {
      return "Verify Rider";
    }
    if (!edit && !edit) {
      return "Add New Rider";
    }
  }, [edit, verify]);

  const onClose = () => {
    showPopup({ ispopupOpen: false })(reduxDispatch);
  };

  const onViewImage = (img) => {
    reduxDispatch(setPopupComponent(<ViewProof img={img} />));
    showPopup({ ispopupOpen: true, onClose: () => onClose() })(reduxDispatch);
  };

  const handleVerify = (type) => {
    dispatch({ isVerifying: true });
    if (type === "Verify Rider") {
      ridersServices
        .verifyRider(riderId)
        .then((res) => {
          toast.success("Rider has been verified");
          navigate(paths.riders);
          onClose()
        })
        .catch((err) => {
          dispatch({ isVerifying: false });
          toast.error(err.message);
        });
    } else {
      ridersServices
        .declineRider(riderId, {
          verification: {
            declination_note: "Uploaded ID seems fake",
          },
        })
        .then((res) => {
          toast.success("Rider's verification has been declined");
          navigate(paths.riders);
          onClose()
        })
        .catch((err) => {
          dispatch({ isVerifying: false });
          toast.error(err.message);
        });
    }
  };

  const onClickAction = (type) => {
    reduxDispatch(
      setPopupComponent(
        <DeleteModal
          onClose={onClose}
          handleDelete={() => handleVerify(type)}
          modal2
          text={
            type === "Verify Rider"
              ? "Are you sure you want to verify this rider"
              : "Are you sure you want to decline verification of this rider"
          }
          text2={
            type !== "Verify Rider" ? "Decline Verification" : "Verify Rider"
          }
          isDeleting={state.isVerifying}
        />
      )
    );
    showPopup({ ispopupOpen: true, onClose: () => onClose() })(reduxDispatch);
  };

  return {
    state,
    dispatch,
    onSelectImage,
    region,
    handleForm,
    pageTitle,
    onSelectFile,
    kitchenZones,
    countries,
    cities,
    onViewImage,
    onClickAction,
  };
}

export default useAddRider;
