import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
  setIsLoading,
} from "../../../../redux/actions/appAction";
import {
  setZoneByRegion,
} from "../../../../redux/actions/deliveryZonesAction";
import {
  setKitchenLocations,
  setKitchenRegions,
  setKitchenZones,
} from "../../../../redux/actions/kitchen.action";
import { setRegion } from "../../../../redux/actions/location.action";
import { setIsDeleting, setPopupComponent, showPopup } from "../../../../redux/actions/popUp.action";
import { paths } from "../../../../Routes/path";
import deliveryZonesServices from "../../../../Services/deliveryZones.services";
import kitchenServices from "../../../../Services/kitchen.services";
import locationServices from "../../../../Services/location.services";
import DeleteModal from "../../../UI/DeleteModal";

function useKitchen() {
  const reduxDispatch = useDispatch();
  const { kitchenZones, kitchenRegions: region } = useSelector((state) => state.kitchen);
  const { openModal } = useSelector((state) => state.app);
  const navigate = useNavigate();

  const getAllRegions = useCallback(() => {
    setIsLoading(true)(reduxDispatch);
    locationServices
      .getAllRegions()
      .then(({ data }) => {
        let newArray = data?.map((item) => ({
          status: item.state,
          count: item.kitchen_count,
          id: item.state,
        }));
        setKitchenRegions(newArray)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      })
      .finally(() => setIsLoading(false)(reduxDispatch));
  }, [reduxDispatch]);

  const getAllKichens = useCallback(() => {
    kitchenServices
      .getKitchens()
      .then(({ data }) => {
        setKitchenLocations(data)(reduxDispatch);
        setKitchenZones(data)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      })
      .finally(() => setIsLoading(false)(reduxDispatch));
  }, [reduxDispatch]);

  const getADeliveryZone = (zoneId) => {
    deliveryZonesServices
      .getADeliveryZone(zoneId)
      .then(({ data }) => {
        setZoneByRegion(data)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
  };

  const secondRowFcn = (id) => {
    const newArr = kitchenZones.filter((item) => item.state === id);
    setKitchenLocations(newArr)(reduxDispatch);
  };

  const secondRowAllFcn = () => {
    getAllKichens();
  };

  useEffect(() => {
    getAllRegions();
    getAllKichens();
  }, [getAllRegions, getAllKichens]);

  const onClose = () => {
    showPopup({ ispopupOpen: false })(reduxDispatch);
  };

  const handleDelete = (zoneId) => {
    setIsDeleting(true)(reduxDispatch)
    deliveryZonesServices
      .deleteDeliveryZone(zoneId)
      .then(() => {
        toast.success("Kitchen was deleted");
        getAllKichens();
        getAllRegions();
        onClose();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => {
        setIsDeleting(false)(reduxDispatch);
        navigate(paths.kitchen)
      });
  };

  const onClickAction = (kitchenId) => {
    reduxDispatch(
      setPopupComponent(
        <DeleteModal
          onClose={onClose}
          handleDelete={() => handleDelete(kitchenId)}
          title="Kitchen"
        />
      )
    );
    showPopup({ ispopupOpen: true, onClose: () => onClose() })(reduxDispatch);
  };

  const myObjectString = localStorage.getItem("user");

  const currentUser = JSON.parse(myObjectString);
  const currentUserAbilities = currentUser?.account_abilities?.abilities?.["kitchen"];
  return {
    region,
    secondRowAllFcn,
    secondRowFcn,
    onClickAction,
    onClose,
    openModal,
    handleDelete,
    getADeliveryZone,
    currentUserAbilities,
  };
}

export default useKitchen;
