import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setKitchen, setKitchenLocations } from '../../../../redux/actions/kitchen.action';
import kitchenServices from '../../../../Services/kitchen.services';
import locationServices from '../../../../Services/location.services';
import { setRegion } from '../../../../redux/actions/location.action';

function useLocationSidebar() {
    const myObjectString = localStorage.getItem("user");

  const currentUser = JSON.parse(myObjectString);
  const kitchenId = currentUser?.kitchen_id;
  const regionId = currentUser?.available_region_id;
  const kitchenRestricted = currentUser?.restricted_by_kitchen;
  const locationRestricted = currentUser?.restricted_by_location;
  const currentUserAbilities = currentUser?.account_abilities?.abilities;

    const reduxDispatch = useDispatch();
    const { kitchenLocations } = useSelector((state) => state?.kitchen);
    const { region } = useSelector((state) => state?.location);

    const getAKitchen = useCallback(() => {
        kitchenServices
          .getAkitchen(kitchenId)
          .then(({ data }) => {
            setKitchen([data])(reduxDispatch);
          })
          .catch((err) => {
          });
      }, [reduxDispatch, kitchenId]);

      const getAllRegions = useCallback(() => {
        locationServices
          .getAllRegions()
          .then(({ data }) => {
            let region = data?.filter(
              (item) => +item?.id === +regionId
            );
            setRegion(region)(reduxDispatch);
          })
          .catch((err) => {
            // toast.error(err.message || "Unable to get");
          });
      }, [reduxDispatch, regionId]);
    
      const getAllKichens = useCallback(() => {
        kitchenServices
          .getKitchens()
          .then(({ data }) => {
            const newArr = data?.filter(item => item?.available_region_id === regionId) 
            if (locationRestricted) {
              setKitchenLocations(newArr)(reduxDispatch);
            } else setKitchenLocations(data)(reduxDispatch);
          })
          .catch((err) => {
            //toast.error(err.message || "Unable to get");
          })
      }, [locationRestricted, reduxDispatch, regionId]);

      useEffect(() => {
        if (kitchenRestricted){
          getAKitchen();
        }
        getAllRegions();
        getAllKichens();
      }, [getAllRegions, getAKitchen, getAllKichens, kitchenRestricted]);


  return {
    currentUserAbilities,
    kitchenLocations,
    kitchenRestricted,
    region,
  }
}

export default useLocationSidebar