import React from "react";
import Card from "../../../../UI/Card/Card";
import { SelectInput } from "../../../../UI/FormComponents/Input";
import styles from "../../AddStaffForm.module.scss";
import Button from "../../../../UI/Button/Button";
import ToggleSwitch from "../../../../UI/FormComponents/InputTypes/ToggleSwitch";

const FormRHS = ({
  edit,
  state,
  handleSubmit,
  dispatch,
  departments,
  regions,
  kitchenZones,
}) => {
  return (
    <>
      <Card classes={styles["form-3grid-main"]}>
        {edit && (
          <ToggleSwitch
            selected={state.active}
            toggleSelected={() => {
              dispatch({ active: !state.active });
            }}
            title="Active"
          />
        )}
        <SelectInput
          name="department"
          label="Department"
          options={departments}
          value={state.department}
          onChange={(e) => dispatch({ department: e.target.value })}
        />
        <ToggleSwitch
          selected={state.restrictedLocation}
          toggleSelected={() => {
            dispatch({ restrictedLocation: !state.restrictedLocation });
          }}
          title="Restricted by Location"
        />
        {state.restrictedLocation && (
          <SelectInput
            name="Location"
            label="Location"
            options={regions}
            value={state.location}
            onChange={(e) => dispatch({ location: e.target.value })}
          />
        )}
        {state.restrictedLocation && state.location !== null && (
          <ToggleSwitch
            selected={state.restrictedKitchen}
            toggleSelected={() => {
              dispatch({ restrictedKitchen: !state.restrictedKitchen });
            }}
            title="Restricted by Kitchen"
          />
        )}
        {state.restrictedLocation && state.restrictedKitchen && (
          <SelectInput
            name="Kitchen"
            label="Kitchen"
            options={kitchenZones}
            value={state.kitchen}
            onChange={(e) => dispatch({ kitchen: e.target.value })}
          />
        )}
      </Card>
      <div className={styles.cards}>
        <Button
          name="Staff"
          bgColor="green-bg"
          createFnc={handleSubmit}
          edit={edit}
          isLoading={state?.isLoading}
        />
      </div>
    </>
  );
};

export default FormRHS;
