import { useReducer } from "react";
import { toast } from "react-toastify";
import ordersServices from "../../../../../../Services/orders.services";

function useAnotherPayment(orderId, onClose) {
  const initState = {
    amount: null,
    reason: "",
    next: false,
    isLoading: false,
    paymentLink: "",
  };

  const [state, dispatch] = useReducer(
    (orderState, value) => ({ ...orderState, ...value }),
    initState
  );


  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch({ isLoading: true });
    if (state.next) {
      dispatch({ isLoading: false });
      toast.success("Payment Link copied");
      navigator.clipboard.writeText(state?.paymentLink);
      onClose();
      dispatch({ next: false });
    } else {
      ordersServices
        .collectPayment(orderId, {
            extra_payment: {
              extra_amount: state?.amount,
              note: state?.reason,
            }
          })
        .then(({ data }) => {
          toast.success("Another Payment has been created");
          dispatch({ 
            paymentLink: data?.paystack?.checkout_url,
            next: true,
            isLoading: false,
         });
        })
        .catch((err) => {
          dispatch({ isLoading: false });
          if (err?.response?.status === 409) {
            toast.error(err.response.data.message);
          } else {
            toast.error(err.message);
          }
        });
    }
  };

  return {
    dispatch,
    state,
    handleSubmit,
  };
}

export default useAnotherPayment;
