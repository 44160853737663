import { SET_ORDER, SET_ORDERS, SET_ORDER_ID, SET_ORDER_PAYMENTS, SET_ORDER_PRODUCTS, SET_ORDER_STATUS, SET_PRODUCT_ITEMS, SET_SELECTED_CUSTOMER, SET_USERS, SET_USER_ADDRESS } from "../actionTypes/orderActionType";
  
  export function setProductItems(data) {
    return (dispatch) => {
      dispatch({ type: SET_PRODUCT_ITEMS, data });
    };
  }
  
  export function setOrders(data) {
    return (dispatch) => {
      dispatch({ type: SET_ORDERS, data });
    };
  }

  export function setOrderStatus(data) {
    return (dispatch) => {
      dispatch({ type: SET_ORDER_STATUS, data });
    };
  }

  export function setOrderPayments(data) {
    return (dispatch) => {
      dispatch({ type: SET_ORDER_PAYMENTS, data });
    };
  }

  export function setOrder(data) {
    return (dispatch) => {
      dispatch({ type: SET_ORDER, data });
    };
  }

  export function setOrderProducts(data) {
    return (dispatch) => {
      dispatch({ type: SET_ORDER_PRODUCTS, data });
    };
  }

  export function setUsers(data) {
    return (dispatch) => {
      dispatch({ type: SET_USERS, data });
    };
  }
  
  export function setUserAddress(data) {
    return (dispatch) => {
      dispatch({ type: SET_USER_ADDRESS, data });
    };
  }
  
  export function setOrderId(data) {
    return (dispatch) => {
      dispatch({ type: SET_ORDER_ID, data });
    };
  }

  export function setSelectedCustomer(data) {
    return (dispatch) => {
      dispatch({ type: SET_SELECTED_CUSTOMER, data });
    };
  }
  