import React from "react";
import styles from "./Text.module.scss";

const DateTime = (props) => {
  return (
    <div className={styles["form-group"]}>
      <div className={styles["form-container"]}>
        <input
          type="datetime-local"
          name={props.name}
          value={props.value}
          placeholder=" "
          onChange={props.onChange}
          onBlur={props.onBlur}
          disabled={props.disabled}
        />
        <label htmlFor={props.name}>{props.label}</label>
      </div>
    </div>
  );
};

export default DateTime;
