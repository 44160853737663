import React from "react";
import {
  TextInput,
} from "../../../../UI/FormComponents/Input";
import styles from "../../AddInfluencerForm.module.scss";
import Button from "../../../../UI/Button/Button";

const FormLHS = ({ edit, state, handleSubmit, dispatch}) => {
  return (
    <div className={styles["form-6grid-main"]}>
      <TextInput
        name="influencer_name"
        label="Influencer's Full Name"
        type="text"
        value={state.name}
        onChange={(e) => dispatch({ name: e.target.value })}
      />
      <div className={styles["display-flex"]}>
      <TextInput
        name="username"
        label="Username"
        type="text"
        value={state.username}
        onChange={(e) => dispatch({ username: e.target.value })}
      />

      <TextInput
        name="social media profile url"
        label="Social Media Profile URL"
        type="text"
        value={state.url}
        onChange={(e) => dispatch({ url: e.target.value })}
      />
      </div>
        <Button
          name="Influencer"
          bgColor="green-bg"
          createFnc={handleSubmit}
          edit={edit}
          isLoading={state.isLoading}
        />
      </div>
  );
};

export default FormLHS;
