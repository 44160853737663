import {
  SET_INVENTORIES,
  SET_INVENTORY,
  SET_INVENTORY_ID,
  SET_INVENTORY_ITEMS,
} from "../actionTypes/inventoryActionType";

export function setInventoryItems(data) {
  return (dispatch) => {
    dispatch({ type: SET_INVENTORY_ITEMS, data });
  };
}

export function setInventories(data) {
  return (dispatch) => {
    dispatch({ type: SET_INVENTORIES, data });
  };
}

export function setInventory(data) {
  return (dispatch) => {
    dispatch({ type: SET_INVENTORY, data });
  };
}

export function setInventoryId(data) {
  return (dispatch) => {
    dispatch({ type: SET_INVENTORY_ID, data });
  };
}
