import React from "react";
import { NumberInput, SelectInput } from "../../../../UI/FormComponents/Input";
import styles from "../../Stocks.module.scss";
import Button from "../../../../UI/Button/Button";

const FormLHS = ({ stocks, state, handleSubmit, dispatch, manual, stockId }) => {

  return (
    <div className={styles["form-6grid-main"]}>
      <SelectInput
        name="Stock item"
        label="Stock item"
        value={stockId}
        options={stocks}
        onChange={(e) => dispatch({ stock: e.target.value })}
        disabled
      />
      <div className={styles["space"]}>
        <NumberInput
          name="quantity"
          label="Quantity"
          value={state.value}
          // units={type === "product" ? "#" : item?.unit}
          onChange={(e) => dispatch({ qty: e.target.value })}
        />
      </div>
      {
        manual && <div className={styles["warning"]}>
          Changing stock value would automatically decrement the value of the stock available in the parent warehouse of this kitchen
        </div> 
      }
      <div className={styles["btn-div"]}>
        <Button
          name={manual ? "Change Stock Value" : "Submit"}
          bgColor={manual ? "lred-bg" : "green-bg"}
          createFnc={handleSubmit}
          custom
          isLoading={state.isLoading}
        />
      </div>
    </div>
  );
};

export default FormLHS;