import React from "react";
import Card from "../../../../UI/Card/Card";
import { SelectInput } from "../../../../UI/FormComponents/Input";
import styles from "../../AddOrderForm.module.scss";
import getSymbolFromCurrency from "currency-symbol-map";
import Button from "../../../../UI/Button/Button";
import FormGroupHeader from "../../../../UI/FormComponents/FormHeaders/FormGroupHeader";
import { numberFormater } from "../../../../../utils/helpers";

const FormRHS = ({
  edit,
  state,
  handleSubmit,
  sumOfPrices,
  totalPaymentDue,
  currency,
  dispatch,
  discounts,
}) => {
  return (
    <>
      <Card classes={styles["form-3grid-main"]}>
        <FormGroupHeader title="Order Details" />
        <div className={styles["order-total"]}>
          <div className={styles["inventory-div1"]}>
            <div className={styles["total-name"]}>Subtotal</div>
            <div>{`${getSymbolFromCurrency(currency) || ''}${numberFormater(
              sumOfPrices || 0
            )}`}</div>
          </div>
          <div className={styles["inventory-div1"]}>
            <div className={styles["total-name"]}>Delivery Fee</div>
            <div>{`${getSymbolFromCurrency(currency) || ''}${numberFormater(
              state?.deliveryFee || 0
            )}`}</div>
          </div>
          <div className={styles["inventory-div2"]}>
            <SelectInput
              name="apply_discount"
              label="Apply Discount"
              value={state?.discountOf}
              options={discounts}
              onChange={(e) => {
                dispatch({ 
                discountOf: e.target.value,
                discount: e.target.selectedOptions[0].dataset.unit,
               })
              }}
            />
          </div>
          <div className={styles["inventory-div1"]}>
            <div className={styles["total-name"]}>Discount</div>
            <div>{
              `${state?.discount} %` || 0}</div>
          </div>
          <div className={styles["inventory-div2"]}>
            <div className={styles["total-name1"]}>Total Payment Due</div>
            <div className={styles["total"]}>{`${getSymbolFromCurrency(
              currency
            ) || ''}${numberFormater(totalPaymentDue || 0)}`}</div>
          </div>
        </div>
      </Card>
      <div className={styles.cards}>
        <Button
          name="Order"
          bgColor="green-bg"
          createFnc={handleSubmit}
          edit={edit}
          isLoading={state?.isLoading}
        />
      </div>
    </>
  );
};

export default FormRHS;
