import React from "react";
import MetaTags from "react-meta-tags";
import Grid from "../../UI/Grid/Grid";
import GridRow from "../../UI/Grid/GridRow";
import FormLHS from "./component/FormGroups/FormLHS";
import TopDisplay from "../../UI/TopDisplay/TopDisplay";
import styles from "./AddRoleForm.module.scss";
import rolestyles from "./Roles.module.scss";

const AddRoleForm = () => {
  return (
    <>
      <MetaTags>
        <title>Add Role Page</title>
        <meta name="description" content="#" />
      </MetaTags>
      <div className={rolestyles.main}>
        <TopDisplay
          title="Add New Rider"
          firstText="Management"
          secondText="Roles"
          thirdText="Add New Role"
          style2
        />
        <div className={styles["form-main"]}>
          <Grid span="9">
            <GridRow span="6" customClass="section2">
              <FormLHS />
            </GridRow>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default AddRoleForm;
