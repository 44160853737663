import { useDispatch, useSelector } from "react-redux";
import { setInventoryItems } from "../../../../redux/actions/inventory.action";
import { setProductItems } from "../../../../redux/actions/order.action";
import { setStockItems } from "../../../../redux/actions/stock.action";

function useAddItem(type, inventoryOptions, stockOptions, productOptions) {
  const reduxDispatch = useDispatch();
  const { inventoryItems } = useSelector((state) => state?.inventory);
  const { stockItems } = useSelector((state) => state.stock);
  const { productItems } = useSelector(state => state.order)

  const valueItems = {
    inventory: inventoryItems,
    stock: stockItems,
    product: productItems,
  };

  const setValueItems = {
    inventory: setInventoryItems,
    stock: setStockItems,
    product: setProductItems,
  };

  const addFunction = {
    inventory: function addNewInventoryItem() {
      setInventoryItems([
        ...inventoryItems,
        {
          inventory_name: "",
          quantity: null,
        },
      ])(reduxDispatch);
    },
    stock: function addNewStockItem() {
      setStockItems([
        ...stockItems,
        {
          stock_name: "",
          quantity: null,
        },
      ])(reduxDispatch);
    },
    product: function addNewProductItem() {
      setProductItems([
        ...productItems,
        {
          product_name: "",
          quantity: null,
          price: null,
        },
      ])(reduxDispatch);
    },
  };

  const removeFnc = {
    inventory: function removeInventoryItem(index) {
      const newArr = inventoryItems.filter((item, i) => i !== index);
      setInventoryItems(newArr)(reduxDispatch);
    },
    stock: function removeStockItem(index) {
      const filterdStockItems = stockItems.filter((item, i) => i !== index);
      setStockItems(filterdStockItems)(reduxDispatch);
    },
    product: function removeProductItem(index) {
      const filterdProductItems = productItems.filter((item, i) => i !== index);
      setProductItems(filterdProductItems)(reduxDispatch);
    },
  };

  function handleChange(e, index) {
    const { name, value } = e.target;
    const newPaymentTypes = valueItems[type]?.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          [name]: value,
          unit: e.target.selectedOptions[0].dataset.unit,
        };
      }
      return item;
    });
    setValueItems[type](newPaymentTypes)(reduxDispatch);
  }

  function handleProduct(e, index) {
    const { id, title, product_price } = e[0];
    const newPaymentTypes = valueItems[type]?.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          product_name: id,
          title: title,
          price: +product_price?.price,
          currency: product_price?.currency,
        };
      }
      return item;
    });
    setValueItems[type](newPaymentTypes)(reduxDispatch);
  }

  const handleQuantity = (e, index) => {
    const { name, value } = e.target;
    const newPaymentTypes = valueItems[type]?.map((item, i) => {
      if (i === index) {
        return { ...item, [name]: value };
      }
      return item;
    });
    setValueItems[type](newPaymentTypes)(reduxDispatch);
  };

  const options = {
    inventory: inventoryOptions,
    stock: stockOptions,
    product: productOptions,
  }

  return {
    valueItems,
    handleChange,
    handleQuantity,
    removeFnc,
    addFunction,
    options,
    handleProduct,
  };
}

export default useAddItem;
