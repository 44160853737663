import React from "react";
import styles from "./Grid.module.css";

const GridRow = (props) => {
  let gridTitle = "uigrid-" + props.span;

  return (
    <div className={`${styles[gridTitle]} ${styles[props.customClass]}`}>
      {props.children}
    </div>
  );
};

export default GridRow;
