import React from "react";
import FormLHS from "./component/FormGroups/AddCountryFormLHS";
import TopDisplay from "../../UI/TopDisplay/TopDisplay";
import locationstyles from "./OperationalLocations.module.scss";
import useAddCountry from "./hooks/useAddCountry";

const AddCountryForm = () => {
  const { state, dispatch, handleSubmit } = useAddCountry();
  return (
    <div className={locationstyles.mains}>
      <TopDisplay
        title="Add New Country"
        firstText="Operational Locations"
        secondText="Add New Country"
        style1
      />
      <FormLHS
        state={state}
        handleSubmit={handleSubmit}
        dispatch={dispatch}
      />
    </div>
  );
};

export default AddCountryForm;
