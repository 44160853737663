import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { paths } from "../../../../../../Routes/path";

function useDTable( onClickAction, currentUserAbilities) {
  const { deliveryZones, zoneByRegion, deliveryRegions: region } = useSelector(
    (state) => state.delivery
  ); 
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const onClick = (value) => {
    setOpenModal(true);
    setSelected(value);
  };
  const isLoading = useSelector((state) => state.app.isLoading);
  const navigate = useNavigate();

  const handleEdit = (zoneId) => {
    navigate(paths.editDeliveryArea.replace(':zoneId', zoneId))
  };

  const onDelete = (zoneId) => {
      onClickAction(zoneId)
  };

  const update = currentUserAbilities?.update

  const del = currentUserAbilities?.destroy
  

  return {
    onClick,
    selected,
    openModal,
    setOpenModal,
    isLoading,
    onDelete,
    region,
    deliveryZones,
    zoneByRegion,
    handleEdit,
    del,
    update,
  };
}

export default useDTable;
