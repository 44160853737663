import React from "react";
import Card from "../../../../UI/Card/Card";
import { FileInput, SelectInput } from "../../../../UI/FormComponents/Input";
import styles from "../../AddRiderForm.module.scss";
import Button from "../../../../UI/Button/Button";
import ToggleSwitch from "../../../../UI/FormComponents/InputTypes/ToggleSwitch";
import { documentType } from "../../../../../utils/constant";

const FormRHS = ({
  edit,
  handleSubmit,
  state,
  dispatch,
  verify,
  onSelectFile,
  onViewImage,
  onClickAction,
}) => {
  return (
    <>
      <Card classes={styles["form-3grid-main"]}>
        <div className={styles.div1}>
          <div className={styles.text}>Verification Status</div>
          <ToggleSwitch
            selected={state?.status}
            toggleSelected={() => {
              dispatch({ status: !state?.status });
            }}
            title={state?.status ? "Verified" : "Pending"}
          />
        </div>
        <SelectInput
          name="verification_type"
          label="Verification Type"
          options={documentType}
          value={state?.id_type}
          onChange={(e) => dispatch({ id_type: e.target.value })}
        />
        {!verify ? (
          <FileInput
            name="verification_document"
            label="Verification Document"
            onSelectImage={onSelectFile}
            value={state.id_file}
            imgSrc={state.id_src}
          />
        ) : (
          <div className={styles.modal} onClick={() => onViewImage(state.id_src)}>
            <p className={styles.verifyText}>Verification Document</p>
            <img src={state.id_src} alt="verification document" className={styles.image} />
          </div>
        )}
        {!verify && (
          <>
            <ToggleSwitch
              selected={state?.activeStatus}
              toggleSelected={() => {
                dispatch({ activeStatus: !state.activeStatus });
              }}
              title="Active"
            />

            <ToggleSwitch
              selected={state.receive_email}
              toggleSelected={(e) =>
                dispatch({ receive_sms: !state.receive_email })
              }
              title="Receive Email"
            />
            <ToggleSwitch
              selected={state.receive_sms}
              toggleSelected={(e) =>
                dispatch({ receive_sms: !state.receive_sms })
              }
              title="Receive sms"
            />
          </>
        )}
      </Card>
      {verify ? (
        <div>
          <div className={styles.cards}>
            <Button
              name="Verify Rider"
              bgColor="green-bg"
              edit={edit}
              createFnc={(e) => onClickAction(e.target.innerHTML)}
              isLoading={state?.isLoading}
              custom
            />
          </div>
          <div className={styles.cards}>
            <Button
              name="Decline Rider Verification"
              bgColor="red-bg"
              edit={edit}
              createFnc={(e) => onClickAction(e.target.innerHTML)}
              isLoading={state?.isLoading}
              custom
            />
          </div>
        </div>
      ) : (
        <div className={styles.cards}>
          <Button
            name="Rider"
            bgColor="green-bg"
            edit={edit}
            createFnc={handleSubmit}
            isLoading={state?.isLoading}
          />
        </div>
      )}
    </>
  );
};

export default FormRHS;
