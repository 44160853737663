import React from 'react'
import MetaTags from "react-meta-tags";
import styles from './Payments.module.scss'
import TopDisplay from '../../UI/TopDisplay/TopDisplay'
import GridRow from '../../UI/Grid/GridRow';
import Grid from '../../UI/Grid/Grid';
import DTable from './Components/DTable/DTable'

function Payments() {
  return (
    <>
      <MetaTags>
        <title>Payment Page</title>
        <meta name="description" content="#" />
      </MetaTags>
        <div className={styles.main}>
          <TopDisplay
            title="Payment Logs"
            firstText="Dashboard"
            secondText="Payment Logs"
            style1
          />
          <div className={styles.section2}>
            <Grid span="9">
              <GridRow span="9" customClass="section2">
                <DTable
                />
              </GridRow>
            </Grid>
          </div>
        </div>
    </>
  )
}

export default Payments