import { useCallback, useEffect, } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { setIsLoading } from "../../../../redux/actions/appAction";
import {
  setInventories,
} from "../../../../redux/actions/inventory.action";
import { setIsDeleting, setPopupComponent, showPopup } from "../../../../redux/actions/popUp.action";
import { paths } from "../../../../Routes/path";
import inventoriesServices from "../../../../Services/inventories.services";
import DeleteModal from "../../../UI/DeleteModal";
import locationServices from "../../../../Services/location.services";
import { setWarehouseRegion } from "../../../../redux/actions/location.action";

function useInventories() {
  const {
    inventories,
  } = useSelector((state) => state.inventory);

  const {
    warehouseRegion: region,
  } = useSelector((state) => state.location);
  
  const { openModal } = useSelector((state) => state.app);
  const myObjectString = localStorage.getItem("user");

  const currentUser = JSON.parse(myObjectString);
  const currentUserAbilities = currentUser?.account_abilities?.abilities?.["inventory"];
  const { regionId } = useParams();

  const reduxDispatch = useDispatch();


  const navigate = useNavigate();

  const getInventories = useCallback(() => {
    setIsLoading(true)(reduxDispatch);
      inventoriesServices
      .getRegionInventories(regionId)
      .then(({ data }) => {
        setInventories(data?.products)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      })
      .finally(() => setIsLoading(false)(reduxDispatch));
  }, [regionId, reduxDispatch]);

  const getAllRegions = useCallback(() => {
    locationServices
      .getAllRegions()
      .then(({ data }) => {
        let regionName = data?.filter(
          (item) => +item?.id === +regionId
        );
        setWarehouseRegion(regionName[0])(reduxDispatch);
      })
      .catch((err) => {
        // toast.error(err.message || "Unable to get");
      });
  }, [reduxDispatch, regionId]);

  useEffect(() => {
    getAllRegions();
  }, [getAllRegions]);


  useEffect(() => getInventories(), [getInventories]);

  const onClose = () => {
    showPopup({ ispopupOpen: false })(reduxDispatch);
  };

  const handleDelete = (inventoryId) => {
    setIsDeleting(true)(reduxDispatch);
    inventoriesServices
      .deleteInventory(inventoryId)
      .then(() => {
        toast.success("Inventory was deleted");
        getInventories();
        onClose();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => {
        setIsDeleting(false)(reduxDispatch)
        navigate(paths.inventory)
      });
  };

  const onClickAction = (inventoryId) => {
    reduxDispatch(
      setPopupComponent(
        <DeleteModal
          onClose={onClose}
          handleDelete={() => handleDelete(inventoryId)}
          title="Inventory"
        />
      )
    );
    showPopup({ ispopupOpen: true, onClose: () => onClose() })(reduxDispatch);
  };

  return {
    inventories,
    onClickAction,
    openModal,
    handleDelete,
    onClose,
    currentUserAbilities,
    region,
  };
}

export default useInventories;
