import React from "react";
import MetaTags from "react-meta-tags";
import Grid from "../../UI/Grid/Grid";
import GridRow from "../../UI/Grid/GridRow";
import TopDisplay from "../../UI/TopDisplay/TopDisplay";
import ErrorPage from "../ErrorPage/ErrorPage";
import DTable from "./component/DTable/DTable";
import useInventories from "./hooks/useInventories";
import styles from "./Inventory.module.scss";

function Inventory() {
  const {
    inventories,
    onClickAction,
    currentUserAbilities,
    kitchen,
    kitchenId,
  } = useInventories();

  return (
    <>
      <MetaTags>
        <title>Inventories Page</title>
        <meta name="description" content="#" />
      </MetaTags>
      {currentUserAbilities?.index ? (
      <div className={styles.main}>
        <TopDisplay
          title={kitchenId ? `${kitchen?.name} Inventories` : "Inventories"}
          firstText="Dashboard"
          secondText="Kitchen"
          thirdText="Inventory"
          style2
        />
        <div className={styles.section2}>
          <Grid span="9">
            <GridRow span="9" customClass="section2">
              <DTable
                inventories={inventories}
                onClickAction={onClickAction}
                currentUserAbilities={currentUserAbilities}
              />
            </GridRow>
          </Grid>
        </div>
      </div>
       ) : (
        <ErrorPage type2 />
      )}
    </>
  );
}

export default Inventory;
