import {
  SET_LOCATIONS,
  SET_REGION,
  SET_LOCATION_TABLE,
  SET_COUNTRIES,
  SET_STATES,
  SET_CITIES,
  SET_REGION_ID,
  SET_WAREHOUSE_REGION,
} from "../actionTypes/locationActionType";

const initialState = {
  locations: [],
  region: [],
  locationTable: [],
  countries: [],
  states: [],
  cities: [],
  regionId: null,
  warehouseRegion: {}
};

export default function locationReducer(state = initialState, action) {
  const { data, type } = action;
  switch (type) {
    case SET_LOCATIONS:
      return {
        ...state,
        locations: data,
      };
    case SET_REGION:
      return {
        ...state,
        region: data,
      };
    case SET_REGION_ID:
      return {
        ...state,
        regionId: data,
      };
    case SET_LOCATION_TABLE:
      return {
        ...state,
        locationTable: data,
      };
    case SET_COUNTRIES:
      return {
        ...state,
        countries: data,
      };
    case SET_STATES:
      return {
        ...state,
        states: data,
      };
    case SET_CITIES:
      return {
        ...state,
        cities: data,
      };
      case SET_WAREHOUSE_REGION:
        return {
          ...state,
          warehouseRegion: data,
        };
    default:
      return state;
  }
}
