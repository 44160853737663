import { useEffect, useReducer } from "react";
import dashboardServices from "../../../../Services/dashboard.services";
import {
  setCashflowData,
  setCustomerData,
  setOrderData,
  setPendingOrders,
  setRecentOrders,
  setUserStats,
} from "../../../../redux/actions/dashboardAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

function useDashboard() {
  const initState = {
    isLoadingCustomerData: false,
    isLoadingOrderData: false,
    isLoadingUserStats: false,
    isLoadingPendingOrder: false,
    isLoadingRecentOrder: false,
    isLoadingCashData: false,
  };
  const [state, dispatch] = useReducer(
    (productState, value) => ({ ...productState, ...value }),
    initState
  );
  const reduxDispatch = useDispatch();
  const {
    customerData,
    orderData,
    cashflowData,
    userStats,
    recentOrders,
    pendingOrders,
  } = useSelector((state) => state.dashboard);
  const getCustomerData = () => {
    dispatch({ isLoadingCustomerData: true });
    dashboardServices
      .getCustomerData()
      .then(({ data }) => {
        setCustomerData(data)(reduxDispatch);
        dispatch({ isLoadingCustomerData: false });
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch({ isLoadingCustomerData: false });
      });
  };

  const getOrderData = () => {
    dispatch({ isLoadingOrderData: true });
    dashboardServices
      .getOrderData()
      .then(({ data }) => {
        setOrderData(data)(reduxDispatch);
        dispatch({ isLoadingOrderData: false });
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch({ isLoadingOrderData: false });
      });
  };

  const getCashflowData = () => {
    dispatch({ isLoadingCashData: true });
    dashboardServices
      .getCashFlowData()
      .then(({ data }) => {
        setCashflowData(data)(reduxDispatch);
        dispatch({ isLoadingCashData: false });
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch({ isLoadingCashData: false });
      });
  };

  const getRecentOrder = () => {
    dispatch({ isLoadingRecentOrder: true });
    dashboardServices
      .getRecentOrder()
      .then(({ data }) => {
        setRecentOrders(data)(reduxDispatch);
        dispatch({ isLoadingRecentOrder: false });
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch({ isLoadingRecentOrder: false });
      });
  };

  const getPendingOrder = () => {
    dispatch({ isLoadingPendingOrder: true });
    dashboardServices
      .getPendingOrder()
      .then(({ data }) => {
        setPendingOrders(data)(reduxDispatch);
        dispatch({ isLoadingPendingOrder: false });
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch({ isLoadingPendingOrder: false });
      });
  };

  const getUserStatistics = () => {
    dispatch({ isLoadingUserStats: true });
    dashboardServices
      .getUserStatistics()
      .then(({ data }) => {
        setUserStats(data)(reduxDispatch);
        dispatch({ isLoadingUserStats: false });
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch({ isLoadingUserStats: false });
      });
  };

  useEffect(() => {
    getCustomerData();
    getOrderData();
    getUserStatistics();
    getPendingOrder();
    getRecentOrder();
    getCashflowData();
    // eslint-disable-next-line
  }, []);

  return {
    state,
    customerData,
    orderData,
    cashflowData,
    userStats,
    recentOrders,
    pendingOrders,
  };
}

export default useDashboard;
