import React from "react";
import MetaTags from "react-meta-tags";
import { useNavigate } from "react-router";
import { paths } from "../../../Routes/path";
import Grid from "../../UI/Grid/Grid";
import GridRow from "../../UI/Grid/GridRow";
import TopDisplay from "../../UI/TopDisplay/TopDisplay";
import ErrorPage from "../ErrorPage/ErrorPage";
import DTable from "./component/DTable/DTable";
import styles from "./DeliveryAreas.module.scss";
import useDeliveryAreas from "./hooks/useDeliveryAreas";

function DeliveryAreas() {
  const {
    secondRowFcn,
    getADeliveryZone,
    secondRowAllFcn,
    onClickAction,
    currentUserAbilities,
  } = useDeliveryAreas();
  const navigate = useNavigate();
  return (
    <>
      <MetaTags>
        <title>Delivery Areas Page</title>
        <meta name="description" content="#" />
      </MetaTags>
      {currentUserAbilities?.index ? (
      <div className={styles.main}>
        <TopDisplay
          title="Operational Locations"
          firstText="Dashboard"
          secondText="Operational Locations"
          thirdText="Delivery areas"
          name="Areas"
          addItem={currentUserAbilities?.create}
          style2
          addFnc={() => navigate(paths.newDeliveryArea)}
        />
        <div className={styles.section2}>
          <Grid span="9">
            <GridRow span="9" customClass="section2">
              <DTable
                secondRowAllFcn={secondRowAllFcn}
                secondRowFcn={secondRowFcn}
                onClickAction={onClickAction}
                getADeliveryZone={getADeliveryZone}
                currentUserAbilities={currentUserAbilities}
              />
            </GridRow>
          </Grid>
        </div>
      </div>
      ) : (
        <ErrorPage type2 />
      )}
    </>
  );
}

export default DeliveryAreas;
