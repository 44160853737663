import React from "react";
import MetaTags from "react-meta-tags";
import Grid from "../../UI/Grid/Grid";
import GridRow from "../../UI/Grid/GridRow";
import TopDisplay from "../../UI/TopDisplay/TopDisplay";
import ErrorPage from "../ErrorPage/ErrorPage";
import DTable from "./component/DTable/DTable";
import useStaff from "./hooks/useStaff";
import styles from "./Staff.module.scss";

function Staff() {
  const { onClickAction, currentUserAbilities } = useStaff();
  return (
    <>
      <MetaTags>
        <title>Staff Page</title>
        <meta name="description" content="#" />
      </MetaTags>
      {currentUserAbilities["admin"]?.index ? (
        <div className={styles.main}>
          <TopDisplay
            title="Staff Profile"
            firstText="Dashboard"
            secondText="Management"
            thirdText="Staff Profile"
            style2
          />
          <div className={styles.section2}>
            <Grid span="9">
              <GridRow span="9" customClass="section2">
                <DTable
                  onClickAction={onClickAction}
                  currentUserAbilities={currentUserAbilities}
                />
              </GridRow>
            </Grid>
          </div>
        </div>
      ) : (
        <ErrorPage type2 />
      )}
    </>
  );
}

export default Staff;
