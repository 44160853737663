import React from "react";
import { MetaTags } from "react-meta-tags";
import Grid from "../../UI/Grid/Grid";
import GridRow from "../../UI/Grid/GridRow";
import TopDisplay from "../../UI/TopDisplay/TopDisplay";
import styles from "./Influencers.module.scss";
import DTable from "./component/DTable/DTable";
import useInfluencer from "./hooks/useInfluencer";

function Influencers() {
  const {
    handlePageChange,
    handlePerRowsChange,
    influencers,
    totalRows,
    onClickAction,
    onAddInfluencer,
    currentUserAbilities,
  } = useInfluencer();
  console.log(influencers);
  return (
    <>
      <MetaTags>
        <title>Influencer Page</title>
        <meta name="description" content="#" />
      </MetaTags>
      <div className={styles.section2}>
        <TopDisplay
          title="Influencer"
          firstText="Discount"
          secondText="Influencer"
          thirdText="Influencer"
          style2
        />
        <div className={styles.section2}>
          <Grid span="9">
            <GridRow span="9" customClass="section2">
              <DTable
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
                influencers={influencers}
                totalRows={totalRows}
                onClickAction={onClickAction}
                onAddInfluencer={onAddInfluencer}
                currentUserAbilities={currentUserAbilities}
              />
            </GridRow>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default Influencers;
