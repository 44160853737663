import React, { useMemo } from 'react';
import styles from "./Cards.module.scss";
import { DownwardTrendIcon, UpwardTrendIcon } from "../../../../UI/RegularIcons";


function Cards({
    title,
    percentage,
    qty,
    trend,
}) {
    const trendStatus = useMemo(() => {
        if (trend === 'up') {
            return <div className={styles["trend-up-icon"]}><UpwardTrendIcon className={styles.trendUp} /></div>;
          }
          if (trend === 'down') {
            return <div className={styles["trend-down-icon"]}><DownwardTrendIcon /></div>;
          }
          if (trend === 'flat') {
            return <div className={styles.flatTrandIcon}> -- </div>;
          }
          return '';
    }, [trend])
  return (
    <div className={styles.cardDiv}>
        <span className={styles.title}>{title}</span>
        <div className={styles.div1}>
            <div className={styles.trendIcon}>{trendStatus}</div>
            <div className={styles.per}>{percentage}%</div>
        </div>
        <span className={styles.qty}>{qty}</span>
    </div>
  )
}

export default Cards;