import React, { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { setIsLoading } from '../../../../redux/actions/appAction';
import { setDepartments } from '../../../../redux/actions/departmentAction';
import { setIsDeleting, setPopupComponent, showPopup } from '../../../../redux/actions/popUp.action';
import { paths } from '../../../../Routes/path';
import departmentServices from '../../../../Services/department.services';
import DeleteModal from '../../../UI/DeleteModal';

function useDepartment() {
    const reduxDispatch = useDispatch();
    const navigate = useNavigate();
    const myObjectString = localStorage.getItem("user");

  const currentUser = JSON.parse(myObjectString);
  const currentUserAbilities = currentUser?.account_abilities?.abilities;

    const getDepartments = useCallback(() => {
        setIsLoading(true)(reduxDispatch);
        departmentServices
          .getDepartments()
          .then(({ data }) => {
            setDepartments(data)(reduxDispatch);
          })
          .catch((err) => {
            toast.error(err.message || "Unable to get");
          })
          .finally(() => setIsLoading(false)(reduxDispatch));
      }, [reduxDispatch]);

      useEffect(() => {
        getDepartments()
      }, [getDepartments])

      const onClose = () => {
        showPopup({ ispopupOpen: false })(reduxDispatch);
      };

      const handleDelete = (departmentId) => {
        setIsDeleting(true)(reduxDispatch)
        departmentServices
          .deleteDepartment(departmentId)
          .then(() => {
            toast.success("Department was deleted");
            getDepartments();
          })
          .catch((err) => toast.error(err.message))
          .finally(() => {
            onClose();
            navigate(paths.departments);
            setIsDeleting(false)(reduxDispatch)
          });
      };

      const onClickAction = (departmentId) => {
        reduxDispatch(
          setPopupComponent(
            <DeleteModal
              onClose={onClose}
              handleDelete={() => handleDelete(departmentId)}
              title="Department"
            />
          )
        );
        showPopup({ ispopupOpen: true, onClose: () => onClose() })(reduxDispatch);
      };
  return {
    onClickAction,
    currentUserAbilities,
  }
}

export default useDepartment