import React from "react";
import Card from "../../../../UI/Card/Card";
import { FileInput } from "../../../../UI/FormComponents/Input";
import styles from "../../AddRegionForm.module.scss";
import Button from "../../../../UI/Button/Button";

const FormRHS = ({ handleSubmit, state, onSelectImage, edit }) => {
  return (
    <>
      <Card classes={styles["form-3grid-main"]}>
      <FileInput name="logo" label="Logo" onSelectImage={onSelectImage}
        value={state?.image}
        imgSrc={state?.image_src}
        imgName={state?.imgName} />
      </Card>
      <div className={styles.cards}>
        <Button
          name="Region"
          bgColor="green-bg"
          isLoading={state?.isLoading}
          createFnc={handleSubmit}
          edit={edit}
        />
      </div>
    </>
  );
};

export default FormRHS;
