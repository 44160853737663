export const paths = {
  
    dashboard: '/home',
    logs: '/logs',
    payments: '/payments',

    //locations
    operationalLocations: "/locations/countries",
    newOperationalLocation: "/locations/countries/add-country",
    newRegion: "/locations/countries/:countryId/region/new",
    editRegion: "/locations/countries/:countryId/region/edit/:regionId",

    //deliveryAreas
    deliveryAreas: "/locations/areas",
    newDeliveryArea: "/locations/areas/new",
    editDeliveryArea: "/locations/areas/edit/:zoneId",

    //kitchen
    kitchen: "/locations/klocations",
    newKitchen: "/locations/klocations/new",
    editKitchen: "/locations/klocations/edit/:kitchenId",

    //inventory
    inventory: "/kitchen/inventory",
    kitchenInventory: "/kitchin/:kitchenId/inventories",
    newInventory: "/kitchen/inventory/new",
    editInventory: "/kitchen/inventory/edit/:inventoryId",
    
    //stock
    stock: "/kitchen/stock",
    kitchenStock: "/kitchin/:kitchenId/stocks",
    newStock: "/kitchen/stock/new",
    editStock: "/kitchen/stock/edit/:stockId",

    //category
    categories: "/kitchen/categories",
    newCategory: "/kitchen/categories/new",
    editCategory: "/kitchen/categories/edit/:categoryId",

    //products
    products: "/kitchen/products",
    newProduct: "/kitchen/products/new",
    editProduct: "/kitchen/products/edit/:productId",

    //department
    departments: "/management/departments",
    newDepartment: "/management/departments/add-department",
    editDepartment: "/management/departments/edit-department/:departmentId",
    userProfile: "/management/users",

    //orders
    orders: "/orders/index",
    newOrder: "/orders/index/new",
    editOrder: "/orders/index/edit/:orderId",
    orderDetails: "/orders/index/order-details/:orderId",
    manageOrders: "/orders/manage",

    //riders
    riders: "/deliveries/riders",
    newRider: "/deliveries/riders/new",
    editRider: "/deliveries/riders/edit/:riderId",
    verifyRider: "/deliveries/riders/verify/:riderId",


    //manageDelivery
    manageDelivery: "/deliveries/index",
    assignDelivery: "/deliveries/assign-delivery/:orderId",

    //customers
    customers: "/customers",
    newCustomer: "/customers/add-customer",
    editCustomer: "/customers/edit-customer/:customerId",
    viewCustomer: "/customers/view-customer/:customerId",


    //staff
    staff: "/management/staffs",
    newStaff: "/management/staffs/add-staff",
    editStaff: "/management/staffs/edit-staff/:staffId",

    //warehouse
    warehouse:"/warehouse/:regionId",

    //influencer
    influencer: "/discount/influencers",
    newInfluencer: "/discount/influencers/add-influencer",
    editInfluencer: "/discount/influencers/edit-influencer/:influencerId",

    voucher: "/discount/vouchers",
    newVoucher: "/discount/vouchers/add-voucher",
    editVoucher: "/discount/vouchers/edit-voucher/:voucherId",
  }