import { useCallback, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { setIsLoading } from "../../../../redux/actions/appAction";
import { setZoneByRegion } from "../../../../redux/actions/deliveryZonesAction";
import { setRegion } from "../../../../redux/actions/location.action";
import { paths } from "../../../../Routes/path";
import deliveryZonesServices from "../../../../Services/deliveryZones.services";
import locationServices from "../../../../Services/location.services";

function useAddDeliveryArea(edit) {
  const reduxDispatch = useDispatch();
  const { zoneByRegion } = useSelector((state) => state.delivery);
  const { zoneId } = useParams();
  const { region } = useSelector((state) => state.location);
  const initState = {
    name: "",
    regionId: "",
    dayPrice: "",
    duskPrice: "",
    nightPrice: "",
    dawnPrice: "",
    isLoading: false,
  };
  const [state, dispatch] = useReducer(
    (deliveryState, value) => ({ ...deliveryState, ...value }),
    initState
  );
  const navigate = useNavigate();
  const getAllRegions = useCallback(() => {
    setIsLoading(true)(reduxDispatch);
    locationServices
      .getAllRegions()
      .then(({ data }) => {
        setRegion(data)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      })
      .finally(() => setIsLoading(false)(reduxDispatch));
  }, [reduxDispatch]);

  const getADeliveryZone = useCallback(() => {
    deliveryZonesServices
      .getADeliveryZone(zoneId)
      .then(({ data }) => {
        setZoneByRegion(data)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
  }, [reduxDispatch, zoneId]);

  useEffect(() => {
    getAllRegions();
  }, [getAllRegions]);

  useEffect(() => {
    if(edit) {
        getADeliveryZone();
    }
  }, [edit, getADeliveryZone])

  useEffect(() => {
    if (edit) {
      dispatch({
        name: zoneByRegion?.name,
        regionId: zoneByRegion?.available_region_id,
        dayPrice: zoneByRegion?.day_price,
        duskPrice: zoneByRegion?.dusk_price,
        nightPrice: zoneByRegion?.night_price,
        dawnPrice: zoneByRegion?.dawn_price,
      });
    }
  }, [edit, zoneByRegion]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!state.name) {
      toast.error("Name cannot be empty")
      return ;
    }
    if (!state.regionId) {
      toast.error("Select a region")
      return ;
    }
    if (!state.dayPrice && !state.dawnPrice && !state.nightPrice && !state.duskPrice) {
      toast.error("Price cannot be empty")
      return ;
    }
  
      if (!edit) {
        dispatch({ isLoading: true})
        deliveryZonesServices
          .createDeliveryZone({
            delivery_zone: {
              name: state?.name,
              available_region_id: +state?.regionId,
              day_price: +state?.dayPrice,
              dusk_price: +state?.duskPrice,
              night_price: +state?.nightPrice,
              dawn_price: +state?.dawnPrice,
            },
          })
          .then(() => {
            toast.success("Area was succesfully created");
          })
          .catch((err) => {
            dispatch({ isLoading: true})
            toast.error(err.message)
          })
          .finally(() => navigate(paths.deliveryAreas));
      } else {
        dispatch({ isLoading: true})
        deliveryZonesServices
          .updateDeliveryZone(zoneId, {
            delivery_zone: {
                name: state?.name,
                available_region_id: +state?.regionId,
                day_price: +state?.dayPrice,
                dusk_price: +state?.duskPrice,
                night_price: +state?.nightPrice,
                dawn_price: +state?.dawnPrice,
            },
          })
          .then(() => {
            toast.success("Area was succesfully updated");
          })
          .catch((err) => {
            dispatch({ isLoading: true})
            toast.error(err.message)
          })
          .finally(() => navigate(paths.deliveryAreas));
      }
  };
  return { region, state, dispatch, handleSubmit };
}

export default useAddDeliveryArea;
