import React from 'react'
import Grid from '../../UI/Grid/Grid'
import GridRow from '../../UI/Grid/GridRow'
import TopDisplay from '../../UI/TopDisplay/TopDisplay'
import DTable from './component/DTable/DTable'
import styles from './UserProfile.module.scss'

function UserProfile() {
  return (
    <div className={styles.main}>
      <TopDisplay
        title="User Profile"
        firstText="Dashboard"
        secondText="Management"
        thirdText="User Profile"
        style2
      />
      <div className={styles.section2}>
        <Grid span="9">
          <GridRow span="9" customClass="section2">
            <DTable />
          </GridRow>
        </Grid>
      </div>
    </div>
  )
}

export default UserProfile