import { useCallback, useEffect, useReducer } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { paths } from "../../../../Routes/path";
import locationServices from "../../../../Services/location.services";
import { objectToArray } from "../../../../utils/helpers";
import useLocation from "./useLocation";

function useAddCountry() {
    const { onClosePage, getAllLocations, getAllRegions } = useLocation();
  const initState = {
    country: "",
    isLoading: false,
    countryArray: [],
  };

  const navigate = useNavigate();

  const [state, dispatch] = useReducer(
    (customerState, value) => ({ ...customerState, ...value }),
    initState
  );

  const getAllCountries = useCallback(() => {
    locationServices
        .getAllPossibleLocations()
        .then(({data}) => {
          let valuesArr = data.map((item) => ({
            name: item?.country_data_or_code?.translated_names[0],
            id: item?.country_data_or_code?.alpha2,
          }));
          dispatch({ countryArray: valuesArr })
        })
        .catch(err => {
          toast.error(err.message || 'Unable to get');
        })
  }, [dispatch])

  useEffect(() => {
    getAllCountries();
  }, [getAllCountries])

  const handleSubmit = (event) => {
    event.preventDefault();
      dispatch({ isLoading: true})
      locationServices	
        .createLocation({
            country: state?.country,
        })
        .then(() => {
          toast.success("Country has been added");
          dispatch({ isLoading: false})
          onClosePage();
          getAllLocations();
          getAllRegions();
        })
        .catch((err) => {
          dispatch({ isLoading: false})
          if (err?.response?.status === 422) {
            let errorMsg = objectToArray(err?.response?.data?.data);
            toast.error(`${errorMsg[0][0]} ${errorMsg[0][1][0]}`);
          } else {
            toast.error(err.message);
          }
        })
        .finally(() => navigate(paths.newOperationalLocation))
  };

  return {
    state,
    handleSubmit,
    dispatch,
  };
}

export default useAddCountry;
