import React from "react";
import {
  ChevronIcon,
  EditIcon,
  PlusIcon,
  TrashIcon,
  ViewIcon,
} from "../RegularIcons";
import styles from "./Modal.module.scss";

const Modal = ({
  onClose,
  editFn,
  deleteFn,
  viewFn,
  assignFn,
  custom,
  style2,
  nextStep,
  archive,
  del,
  update,
  show,
  name,
  secondText,
  verify,
}) => {
  return (
    <div className={style2 ? styles.darkBG2 : styles.darkBG} onClick={onClose}>
      <div className={style2 ? styles.centered2 : styles.centered}>
        {custom ? (
          <div className={styles.hover} onClick={editFn}>
            <span className={styles["edit-icon"]}>
              {name === "Assign rider" ? <EditIcon /> : <PlusIcon />}
            </span>
            <span className={styles["edit-text"]}>{name}</span>
          </div>
        ) : (
          <>
            {(show || verify) && (
              <div className={styles.hover} onClick={viewFn}>
                {secondText !== "View" ? (
                  <span className={styles["chevron-icon"]}>
                    <ChevronIcon />
                  </span>
                ) : (
                  <span className={styles["view-icon"]}>
                    <ViewIcon />
                  </span>
                )}
                <span className={styles["edit-text"]}>{secondText}</span>
              </div>
            )}
            {!style2 ? (
              <>
                {update && (
                  <div className={styles.hover} onClick={editFn}>
                    <span className={styles["edit-icon"]}>
                      <EditIcon />
                    </span>
                    <span className={styles["edit-text"]}>Edit</span>
                  </div>
                )}
                {del && (
                  <div className={styles.hover} onClick={deleteFn}>
                    <span className={styles["delete-icon"]}>
                      <TrashIcon />
                    </span>
                    <span className={styles["delete-text"]}>
                      {archive ? "Archive" : "Delete"}
                    </span>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className={styles.hover} onClick={editFn}>
                  <span className={styles["chevron-icon"]}>
                    <ChevronIcon />
                  </span>
                  <span className={styles["edit-text"]}>
                    {nextStep || "--"}
                  </span>
                </div>
                <div className={styles.hover} onClick={assignFn}>
                  <span className={styles["chevron-icon"]}>
                    <ChevronIcon />
                  </span>
                  <span className={styles["edit-text"]}>
                    {name === "Assign rider"
                      ? "Assign rider"
                      : "Assign to Kitchen"}
                  </span>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Modal;
