import React from "react";
import CircleCharts from "../../../../UI/CircleCharts/CircleCharts";
import styles from "./UserStatistics.module.scss";
import { cashFormater } from "../../../../../utils/helpers";
import Skeleton from "react-loading-skeleton";

function UserStatistics({ state, userStats }) {

  return (
    <>
    {state?.isLoadingUserStats ? (
      <Skeleton height={300} width="310px" borderRadius="16px" />
    ) : (
    <div className={styles.cardDiv}>
      <h4 className={styles.title}>User Statistics</h4>
      <div className={styles.circle}>
        <CircleCharts
          value={userStats.guests_buyer_in_percent}
          strokeWidth={3}
          textColor="#000000"
          pathColor="#B71D18"
          trailColor="#C4CDD5"
        />
        <div className={styles.circleText}>
          <p className={styles.download}>Total Users</p>
          <p className={styles.downloadText}>{cashFormater(userStats?.total_users)}</p>
        </div>
      </div>
      <div className={styles.downSide}>
        <div className={styles.divv}>
          <div className={styles.redCircle} />
          <div className={styles.divText}>Guest Users</div>
        </div>
        <div className={styles.divv}>
          <div className={styles.greyCircle} />
          <div className={styles.divText}>Registered Users</div>
        </div>
      </div>
    </div>
    )}
    </>
  );
}

export default UserStatistics;
