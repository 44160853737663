import React, { useState } from "react";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import { PlusIcon } from "../../RegularIcons";
import styles from "./SearchBar.module.scss";

function SearchBar(props) {
  const [closeBox, setCloseBox] = useState(false);
  const handleOptionClick = (value) => {
    setCloseBox(false);
    props.handleCustomerId(value);
  };

  const handleOpen = () => {
    setCloseBox(!closeBox);
  };

  return (
    <div className={styles["form-roup"]} onClick={() => handleOpen()}>
      <div className={styles["form-container"]}>
        <input
          type="search"
          placeholder={props?.placeholder}
          name={props?.name}
          onChange={props?.onChange}
        />
        {props.isLoading && (
          <div className={styles["loading-icon"]}>
            <LoadingSpinner />
          </div>
        )}
      </div>
      {props.plusIcon && closeBox && (
        <div className={styles["search-container"]}>
          {props?.options?.map((option, i) => (
            <div
              key={i}
              className={styles["search-text"]}
              onClick={() => handleOptionClick(option)}
            >
              {option?.first_name} {option?.last_name} ({option?.email})
            </div>
          ))}
          {props.plusIcon && (
            <div
              className={styles["addBtn"]}
              onClick={() => props?.addOption()}
            >
              <div className={styles["add-icon"]}>
                <PlusIcon />
              </div>
              <div className={styles.addTxt}>Add New Customer</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default SearchBar;
