import React from "react";
import { ReactComponent as Bell } from "../../Assets/Images/RegularIcons/bell.svg";
import { ReactComponent as Chevron } from "../../Assets/Images/RegularIcons/chevron-right.svg";
import { ReactComponent as DownwardTrend } from "../../Assets/Images/RegularIcons/downward-trend.svg";
import { ReactComponent as MenuH } from "../../Assets/Images/RegularIcons/menu-h.svg";
import { ReactComponent as Plus } from "../../Assets/Images/RegularIcons/plus.svg";
import { ReactComponent as SubmenuLink } from "../../Assets/Images/RegularIcons/submenu-link.svg";
import { ReactComponent as UpwardTrend } from "../../Assets/Images/RegularIcons/upward-trend.svg";
import { ReactComponent as Staff } from "../../Assets/Images/RegularIcons/staff.svg";
import { ReactComponent as NgFlag } from "../../Assets/Images/RegularIcons/ng-flag.svg";
import { ReactComponent as UkFlag } from "../../Assets/Images/RegularIcons/usa-flag.svg";
import { ReactComponent as ArrowDownward } from "../../Assets/Images/RegularIcons/arrow-downward.svg";
import { ReactComponent as Sort } from "../../Assets/Images/RegularIcons/sort-icon.svg";
import { ReactComponent as ActionMenu } from "../../Assets/Images/RegularIcons/action-menu.svg";
import { ReactComponent as Delete } from "../../Assets/Images/RegularIcons/Delete-icon.svg";
import { ReactComponent as View } from "../../Assets/Images/RegularIcons/eye 2.svg";
import { ReactComponent as Edit } from "../../Assets/Images/RegularIcons/Edit-icon.svg";
import { ReactComponent as Trash } from "../../Assets/Images/RegularIcons/trash-icon.svg";
import { ReactComponent as ShareLink } from "../../Assets/Images/RegularIcons/share-nodes 1.svg";
import { ReactComponent as CopyLink } from "../../Assets/Images/RegularIcons/copy-icon.svg";
import { ReactComponent as SharePayment } from "../../Assets/Images/RegularIcons/Frame 40.svg";
import { ReactComponent as DownloadPdf } from "../../Assets/Images/RegularIcons/print 1.svg";
import { ReactComponent as ViewProof } from "../../Assets/Images/RegularIcons/image.svg";


function BellIcon() {
  return <Bell />;
}

function ViewProofIcon() {
  return <ViewProof />;
}

function ShareLinkIcon() {
  return <ShareLink />;
}

function CopyLinkIcon() {
  return <CopyLink />;
}

function SharePaymentIcon() {
  return <SharePayment />;
}

function DownloadPdfIcon() {
  return <DownloadPdf />;
}

function EditIcon() {
  return <Edit />;
}

function TrashIcon() {
  return <Trash />;
}

function DeleteIcon() {
  return <Delete />;
}

function ViewIcon() {
  return <View />;
}

function SortIcon() {
  return <Sort />;
}

function ActionMenuIcon() {
  return <ActionMenu />;
}

function NgFlagIcon() {
  return <NgFlag />;
}

function UkFlagIcon() {
  return <UkFlag />;
}

function ArrowDownwardIcon() {
  return <ArrowDownward />;
}

function ChevronIcon() {
  return <Chevron />;
}

function DownwardTrendIcon() {
  return <DownwardTrend />;
}

function MenuHIcon() {
  return <MenuH />;
}

function PlusIcon() {
  return <Plus />;
}

function SubmenuLinkIcon() {
  return <SubmenuLink />;
}

function UpwardTrendIcon() {
  return <UpwardTrend />;
}

function StaffIcon() {
  return <Staff />;
}

export {
  BellIcon,
  ChevronIcon,
  DownwardTrendIcon,
  MenuHIcon,
  PlusIcon,
  SubmenuLinkIcon,
  UpwardTrendIcon,
  StaffIcon,
  ActionMenuIcon,
  UkFlagIcon,
  NgFlagIcon,
  ArrowDownwardIcon,
  SortIcon,
  DeleteIcon,
  EditIcon,
  ViewIcon,
  TrashIcon,
  DownloadPdfIcon,
  ShareLinkIcon,
  SharePaymentIcon,
  CopyLinkIcon,
  ViewProofIcon,
};
