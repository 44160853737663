import {
  BellIcon,
  ChevronIcon,
  DownwardTrendIcon,
  MenuHIcon,
  PlusIcon,
  SubmenuLinkIcon,
  UpwardTrendIcon,
  StaffIcon,
  ActionMenuIcon,
} from "../RegularIcons";

import React from "react";

const Icon = (props) => {
  switch (props.icon) {
    case "bell":
      return <BellIcon />;
    case "chevron":
      return <ChevronIcon />;
    case "downward-trend":
      return <DownwardTrendIcon />;
    case "menu-h":
      return <MenuHIcon />;
    case "plus":
      return <PlusIcon />;
    case "submenu-link":
      return <SubmenuLinkIcon />;
    case "upward-trend":
      return <UpwardTrendIcon />;
    case "staff":
      return <StaffIcon />;
    case "action-menu":
      return <ActionMenuIcon />;
    default:
      return null;
  }
};

export default Icon;
