import { useCallback, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { setCategory } from "../../../../redux/actions/categories.action";
import { paths } from "../../../../Routes/path";
import categoriesServices from "../../../../Services/categories.services";
import { getBase64 } from "../../../../utils/helpers";

function useAddCategories(edit) {
    const {
        category,
      } = useSelector((state) => state.category);
  const initState = {
    name: "",
    image: "",
    image_src: "",
    imgName: "",
    description: "",
    isLoading: false,
  };

  const {categoryId} = useParams();
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();

  const [state, dispatch] = useReducer(
    (categoryState, value) => ({ ...categoryState, ...value }),
    initState
  );

  const getACategory = useCallback(() => {
    categoriesServices
      .getACategory(categoryId)
      .then(({ data }) => {
        const newCategory = data.category;
        setCategory(newCategory)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
  }, [categoryId, reduxDispatch]);

  const onSelectImage = (e) => {
    let file = e.target.files[0];
    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        dispatch({
          image: result,
          imgName: file?.name,
          image_src: URL.createObjectURL(e.target.files[0]),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (edit){
      getACategory();
    }
  }, [edit, getACategory])

  useEffect(() => {
    if (category) {
      dispatch({
        name: category?.name,
        image: category?.image?.url,
        image_src: category?.image?.url,
        description: category?.description,
      });
    }
    // eslint-disable-next-line
  }, [edit, category]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (state.name) {
        if (!edit) {
          dispatch({ isLoading: true})
            categoriesServices
              .createCategory({
                category: {
                  name: state?.name,
                  description: state?.description,
                  image: state?.image,
                },
              })
              .then(() => {
                toast.success("Category was succesfully created");
                navigate(paths.categories)
              })
              .catch((err) => {
                dispatch({ isLoading: false})
                toast.error(err.message)
              })
          } else {
            dispatch({ isLoading: true})
            categoriesServices
              .updateCategory(categoryId, {
                category: {
                  name: state.name,
                  description: state.description,
                  image: state.image,
                },
              })
              .then(() => {
                toast.success("Category was succesfully updated");
                navigate(paths.categories)
              })
              .catch((err) => {
                dispatch({ isLoading: false})
                toast.error(err.message)
              })
          }
    } else {
        toast.error("Fields cannot be empty")
    }
  };
  return { state, handleSubmit, dispatch, onSelectImage };
}

export default useAddCategories;
