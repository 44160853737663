import userImg from "../Assets/Images/user-icon.png";
import { NgFlagIcon, UkFlagIcon } from "../Components/UI/RegularIcons";
export const ordersData = [
  {
    id: "EAD16693456791",
    image: userImg,
    name: "Eva",
    status: "Paid",
    price: "873495.00",
  },
  {
    id: "EAD16693456792",
    image: userImg,
    name: "Castillo",
    status: "Paid",
    price: "58418.08",
  },
  {
    id: "EAD16693456793",
    image: userImg,
    name: "Eric",
    status: "Completed",
    price: "81555.00",
  },
  {
    id: "EAD16693456794",
    image: userImg,
    name: "Gloria",
    status: "Abandoned",
    price: "2743.34",
  },
  {
    id: "EAD16693456795",
    image: userImg,
    name: "Darren",
    status: "Processing",
    price: "22906.50",
  },
  {
    id: "EAD16693456796",
    image: userImg,
    name: "Ted",
    status: "Initiated",
    price: "8757.09",
  },
];

export const regionData = [
  {
    state: "Lagos",
    areas: 23,
    status: "Active",
    revenue: "8734118.34",
  },
  {
    state: "Abuja",
    areas: 45,
    status: "Deactivated",
    revenue: "5841827.00",
  },
  {
    state: "Port Harcourt",
    areas: 16,
    status: "Deactivated",
    revenue: "5841827.00",
  },
  {
    state: "London",
    areas: 1,
    status: "Active",
    revenue: "541827.00",
  },
];

export const secondData = [
  {
    qty: 12,
    text: "Lagos",
  },
  {
    qty: 1,
    text: "London",
  },
  {
    qty: 10,
    text: "Abuja",
  },
];

export const countryData = [
  {
    countryName: "Nigeria",
    flag: <NgFlagIcon />,
    state: "2",
    currency: "Naira",
  },
  {
    countryName: "United Kingdom",
    flag: <UkFlagIcon />,
    state: "1",
    currency: "Naira",
  },
];

export const inventoryData = [
  {
    code: "INCV166",
    name: "Bbq sauce",
    total: 12455,
  },
  {
    code: "INCV101",
    name: "Oyster sauce",
    total: 12455,
  },
  {
    code: "INCV102",
    name: "Soy sauce",
    total: 12455,
  },
  {
    code: "INCV103",
    name: "Bbq sauce",
    total: 12455,
  },
  {
    code: "INCV106",
    name: "Bbq sauce",
    total: 12455,
  },
  {
    code: "INCV160",
    name: "Bbq sauce",
    total: 12455,
  },
  {
    code: "INCV166",
    name: "Bbq sauce",
    total: 12455,
  },
  {
    code: "INCV101",
    name: "Oyster sauce",
    total: 12455,
  },
  {
    code: "INCV102",
    name: "Soy sauce",
    total: 12455,
  },
  {
    code: "INCV102",
    name: "Soy sauce",
    total: 12455,
  },
  {
    code: "INCV102",
    name: "Soy sauce",
    total: 12455,
  },
  {
    code: "INCV102",
    name: "Soy sauce",
    total: 12455,
  },
  {
    code: "INCV102",
    name: "Soy sauce",
    total: 12455,
  },
  {
    code: "INCV102",
    name: "Soy sauce",
    total: 12455,
  },
  {
    code: "INCV102",
    name: "Soy sauce",
    total: 12455,
  },
  {
    code: "INCV102",
    name: "Soy sauce",
    total: 12455,
  },
  {
    code: "INCV102",
    name: "Soy sauce",
    total: 12455,
  },
  {
    code: "INCV102",
    name: "Soy sauce",
    total: 12455,
  },
  {
    code: "INCV102",
    name: "Soy sauce",
    total: 12455,
  },
  {
    code: "INCV102",
    name: "Soy sauce",
    total: 12455,
  },
];
