import React from "react";
import { PlusIcon } from "../../../../UI/RegularIcons";
import Cards from "../Cards/Cards";
import styles from "./TopSide.module.scss";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { paths } from "../../../../../Routes/path";
import { useNavigate } from "react-router";

function TopSide({
  handleCountry,
  selectedState,
  location,
  isGettingCards,
  currentUserAbilities,
}) {
  const navigate = useNavigate();
  return (
    <div className={styles.topSideDiv}>
      {isGettingCards
        ? new Array(1)
            .fill("")
            .map((index) => (
              <Skeleton height={162} width="300px" borderRadius="16px" />
            ))
        : location.map(
            ({ id, states_count, currency, country_data, country }) => (
              <Cards
                flag={country}
                id={id}
                state={states_count}
                countryName={country_data.data.unofficial_names[0]}
                currency={currency}
                handleCountry={() =>
                  handleCountry(
                    country,
                    country_data.data.unofficial_names[0],
                    id
                  )
                }
                selectedState={selectedState}
              />
            )
          )}
      {currentUserAbilities?.create && (
        <div
          className={styles.card}
          onClick={() => navigate(paths.newOperationalLocation)}
        >
          <span className={styles.title}>
            <PlusIcon />
          </span>
          <span className={styles.qty}>Add Country</span>
          <div className={styles.div1}>Add a new country</div>
        </div>
      )}
    </div>
  );
}

export default TopSide;
