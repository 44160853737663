export const menuitems = (currentUserAbilities, region) => {
  // console.log("region", typeof {} === typeof [])
  const items = [
    {
      id: 2,
      title: "Management",
      items: [
        {
          
          id: 1,
          menuTitle: "Operational Locations",
          urlGroup: "locations",
          items: [
            currentUserAbilities?.location?.index && {
              id: 1,
              title: "Available Countries",
              url: "countries",
            },
            currentUserAbilities?.kitchen?.index && {
              id: 2,
              title: "Kitchen Locations",
              url: "klocations",
            },
            currentUserAbilities?.delivery_zone?.index && {
              id: 3,
              title: "Delivery Areas",
              url: "areas",
            },
          ],
        },
        {
          id: 2,
          menuTitle: "Kitchen",
          urlGroup: "kitchen",
          items: [
            currentUserAbilities?.inventory?.index && {
              title: "Inventory",
              url: "inventory",
            },
            currentUserAbilities?.stock?.index && {
              title: "Stock",
              url: "stock",
            },
            currentUserAbilities?.category?.index && {
              title: "Categories",
              url: "categories",
            },
            currentUserAbilities?.product?.index && {
              title: "Products",
              url: "products",
            },
          ],
        },
        currentUserAbilities?.admin?.index && {
          id: 3,
          menuTitle: "Staff Management",
          urlGroup: "management",
          items: [
            {
              title: "Departments",
              url: "departments",
            },
            {
              title: "Staff Profile",
              url: "staffs",
            },
          ],
        },
      ],
    },
    {
      id: 1,
      title: "Order Management",
      items: [
        currentUserAbilities?.order?.index && {
          id: 1,
          menuTitle: "Orders",
          urlGroup: "orders",
          items: [
            {
              title: "All Orders",
              url: "index",
            },
            {
              title: "Manage Orders",
              url: "manage",
            },
          ],
        },
        {
          id: 2,
          menuTitle: "Deliveries",
          urlGroup: "deliveries",
          items: [
            currentUserAbilities?.rider?.index && {
              title: "Riders",
              url: "riders",
            },
            {
              title: "Manage Deliveries",
              url: "index",
            },
          ],
        },
        currentUserAbilities?.user?.index && {
          id: 3,
          menuTitle: "Customers",
          urlGroup: "customers",
        },
        {
          id: 4,
          menuTitle: "Discount",
          urlGroup: "discount",
          items: [
            {
              title: "Influencers",
              url: "influencers",
            },
            {
              title: "Vouchers",
              url: "vouchers",
            },
          ],
        },
      ],
    },

    [region]?.map((item) => {
      return {
        id: 3,
        title: item?.state,
        items: [
          {
            id: 1,
            menuTitle: `${item?.state} Warehouse`,
            urlGroup: `warehouse/${item?.id}`,
          },
          item?.kitchens_list?.map((val) => {
            return {
              id: 2,
              menuTitle: `${val?.name}`,
              urlGroup: `kitchin/${val.id}`,
              items: [
                {
                  title: "Inventory",
                  url: "inventories",
                },
                {
                  title: "Stocks",
                  url: "stocks",
                },
              ],
            };
          }),
        ],
      };
    }),
  ];
  // Filter out any null or undefined items

  return items.filter((item) => {
    return item;
  });
};

export default menuitems;
