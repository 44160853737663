import React from "react";
import locationstyles from "./Vouchers.module.scss";
import useAddVoucher from "./hooks/useAddVoucher";
import TopDisplay from "../../UI/TopDisplay/TopDisplay";
import FormLHS from "./component/FormGroups/FormLHS";

function AddVoucherForm({ edit }) {
  const { state, dispatch, handleForm, influencers } = useAddVoucher(edit);
  return (
    <div className={locationstyles.mains}>
      <TopDisplay
        title={edit ? "Update Voucher" : "Add New Voucher"}
        firstText="Voucher"
        secondText={edit ? "Update Voucher" : "Add New Voucher"}
        style1
      />
      <FormLHS
        edit={edit}
        state={state}
        handleSubmit={handleForm}
        dispatch={dispatch}
        influencers={influencers}
      />
    </div>
  );
}

export default AddVoucherForm;
