import { useCallback, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { setKitchenZones } from "../../../../../../redux/actions/kitchen.action";
import { paths } from "../../../../../../Routes/path";
import kitchenServices from "../../../../../../Services/kitchen.services";
import ordersServices from "../../../../../../Services/orders.services";

function useAsssign(orderId, onClosePage) {
  const { kitchenZones } = useSelector((state) => state.kitchen);

  const initState = {
    kitchen: "",
    isLoading: "",
  };

  const navigate = useNavigate();
  const reduxDispatch = useDispatch();
  const myObjectString = localStorage.getItem("user");
  const currentUser = JSON.parse(myObjectString);
  const regionId = currentUser?.available_region_id;
  const locationRestricted = currentUser?.restricted_by_location;

  const [state, dispatch] = useReducer(
    (orderState, value) => ({ ...orderState, ...value }),
    initState
  );

  const getAllKichens = useCallback(() => {
    kitchenServices
      .getKitchens()
      .then(({ data }) => {
        const newArr = data?.filter(item => item?.available_region_id === regionId)
        if (locationRestricted) {
          setKitchenZones(newArr)(reduxDispatch);
        } else setKitchenZones(data)(reduxDispatch);;
        
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
  }, [locationRestricted, reduxDispatch, regionId]);

  useEffect(() => {
    getAllKichens();
  }, [getAllKichens]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!state?.kitchen) {
      toast.error("Select a kitchen");
      return;
    }
    dispatch({ isLoading: true });
    ordersServices
      .assignOrderToKitchen(orderId, state.kitchen)
      .then((res) => {
        toast.success(res.message);
        navigate(paths.manageOrders);
        onClosePage();
        dispatch({ isLoading: false });
      })
      .catch((err) => {
        dispatch({ isLoading: false });
          toast.error(err.message);
      });
  };
  return {
    state,
    dispatch,
    handleSubmit,
    kitchenZones,
  };
}

export default useAsssign;
