import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { setIsLoading } from "../../../../redux/actions/appAction";
import { setVouchers } from "../../../../redux/actions/discount.Action";
import {
  setIsDeleting,
  setPopupComponent,
  showPopup,
} from "../../../../redux/actions/popUp.action";
import { paths } from "../../../../Routes/path";
import discountServices from "../../../../Services/discount.services";
import DeleteModal from "../../../UI/DeleteModal";

function useVoucher() {
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const navigate = useNavigate();

  const myObjectString = localStorage.getItem("user");

  const currentUser = JSON.parse(myObjectString);
  const currentUserAbilities = currentUser?.account_abilities?.abilities;

  const reduxDispatch = useDispatch();
  const { vouchers } = useSelector((state) => state.discount);

  const getVouchers = useCallback(
    (page, perPage) => {
      setIsLoading(true)(reduxDispatch);
      discountServices
        .getVouchers(page, perPage)
        .then(({ data }) => {
          setVouchers(data?.data)(reduxDispatch);
          setTotalRows(data?.pagination?.total);
        })
        .catch((err) => {
          toast.error(err.message || "Unable to get");
        })
        .finally(() => setIsLoading(false)(reduxDispatch));
    },
    [reduxDispatch]
  );

  useEffect(() => {
    getVouchers(1, perPage);
  }, [getVouchers, perPage]);

  const handlePageChange = (page) => {
    getVouchers(page, perPage);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setIsLoading(true)(reduxDispatch);
    discountServices
      .getVouchers(page, newPerPage)
      .then(({ data }) => {
        setVouchers(data?.data)(reduxDispatch);
        setPerPage(newPerPage);
        setTotalRows(data.pagination.total);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      })
      .finally(() => setIsLoading(false)(reduxDispatch));
  };

  const onClose = () => {
    showPopup({ ispopupOpen: false })(reduxDispatch);
  };

  const onAddVoucher = () => {
    navigate(paths.newVoucher);
  };

  const handleDelete = (voucherId) => {
    setIsDeleting(true)(reduxDispatch);
    discountServices
      .deleteVoucher(voucherId)
      .then(() => {
        toast.success("Voucher was deleted");
        getVouchers();
        onClose();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => {
        setIsDeleting(false)(reduxDispatch);
        navigate(paths.voucher);
      });
  };

  const onClickAction = (voucherId) => {
    reduxDispatch(
      setPopupComponent(
        <DeleteModal
          onClose={onClose}
          handleDelete={() => handleDelete(voucherId)}
          title="Voucher"
        />
      )
    );
    showPopup({ ispopupOpen: true, onClose: () => onClose() })(reduxDispatch);
  };
  return {
    handlePageChange,
    handlePerRowsChange,
    vouchers,
    totalRows,
    onClickAction,
    onAddVoucher,
    currentUserAbilities,
  };
}

export default useVoucher;
