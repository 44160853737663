import React from "react";
import Card from "../../../../UI/Card/Card";
import {
  TextInput,
  SelectInput,
  NumberInput,
} from "../../../../UI/FormComponents/Input";
import FormGroupHeader from "../../../../UI/FormComponents/FormHeaders/FormGroupHeader";
import styles from "../../AddInventoryForm.module.scss";
import { stateOptions } from "../../../../../utils/constant";

const FormLHS = ({ state, dispatch }) => {
  const { alertLevel, state: inventoryState, units, name } = state;
  return (
    <Card classes={styles["form-6grid-main"]}>
      <FormGroupHeader
        title="Inventory Information"
        description="General information about this Inventory"
      />
      <TextInput
        name="item"
        label="Item"
        type="text"
        value={name}
        onChange={(e) => dispatch({ name: e.target.value })}
      />
      <SelectInput
        name="inventory_state"
        label="Inventory State"
        options={stateOptions}
        value={inventoryState}
        onChange={(e) => {
          dispatch({ units: e.target.selectedOptions[0].dataset.unit });
          dispatch({ state: e.target.value });
        }}
      />
      <NumberInput
        name="alert_level"
        label="Alert level"
        value={alertLevel}
        units={units}
        onChange={(e) => dispatch({ alertLevel: e.target.value })}
      />
    </Card>
  );
};

export default FormLHS;
