import React from "react";
import Tables from "../../../../UI/Tables/Tables";
import styles from "./DTable.module.scss";
import useDTable from "./hooks/useDTable";
import { cashFormater } from "../../../../../utils/helpers";
import getSymbolFromCurrency from "currency-symbol-map";

function DTable() {
  const { isLoading, payments, handlePageChange,
    handlePerRowsChange, totalRows } = useDTable();

  const columns = [
    {
      name: "Status",
      selector: (row) => row?.status,
      sortable: true,
      //width: "40%",
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      name: "Payment Type",
      selector: (row) => row?.payment_type,
      sortable: true,
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      name: "Order ID",
      selector: (row) => row?.order_id,
      sortable: true,
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      name: "Total",
      selector: (row) => `${getSymbolFromCurrency(row?.currency)} ${cashFormater(row?.total)}`,
      sortable: true,
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
  ];

  return (
    <div className={styles.divv}>
      <Tables
        data={payments}
        columns={columns}
        pagination
        title="All Payments"
        firstRow
        fetchingData={isLoading}
        onRowClicked={() => {}}
      />
    </div>
  );
}

export default DTable;
