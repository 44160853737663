import "./Components/UI/Colors.scss";
import "./Components/UI/TextStyles.scss";
import { ToastContainer } from 'react-toastify';
import { RouterProvider } from "react-router-dom";
import "./App.scss";
import 'react-toastify/dist/ReactToastify.css';
import { router } from "./Routes/roote";

function App() {
  return (
    <div className="App">
      <ToastContainer
          pauseOnHover
          hideProgressBar
          autoClose={3000}
          position="top-center"
          closeButton={false}
          toastStyle={{
            color: '#000000',
            background: '#FFFF',
          }}
        />
        <RouterProvider router={router} />
    </div>
  );
}

export default App;
