import { useCallback, useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { setIsLoading } from "../../../../redux/actions/appAction";
import { setCategories } from "../../../../redux/actions/categories.action";
import {
  setInventories,
  setInventoryItems,
} from "../../../../redux/actions/inventory.action";
import {
  setProduct,
  setProductPrices,
} from "../../../../redux/actions/product.action";
import {
  setStockItems,
  setStocks,
} from "../../../../redux/actions/stock.action";
import { paths } from "../../../../Routes/path";
import categoriesServices from "../../../../Services/categories.services";
import inventoriesServices from "../../../../Services/inventories.services";
import locationServices from "../../../../Services/location.services";
import productsServices from "../../../../Services/products.services";
import stocksServices from "../../../../Services/stocks.services";
import { getBase64, objectToArray } from "../../../../utils/helpers";

function useAddProduct(edit) {
  const { product, productPrices } = useSelector((state) => state.product);
  const { stocks, stockItems } = useSelector((state) => state.stock);
  const { inventories, inventoryItems } = useSelector(
    (state) => state.inventory
  );
  const { categories } = useSelector((state) => state.category);
  // console.log(product);
  const initState = {
    title: "",
    image: "",
    image_src: "",
    description: "",
    categoryId: null,
    productCode: "",
    isLoading: false,
    productPrices: productPrices?.map((region) => {
      return {
        available_region_id: region.id,
        currency: region.currency,
        cost_price: null,
        price: null,
        old_price: null,
        // region_name: region.state,
      };
    }),
    inventoryState: [],
    stockState: [],
    has_spice: false,
    has_dependent_product: false,
    has_allergens: false,
  };

  const [selectedProducts, setSelecetedProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [fetchingProducts, setFetchingProducts] = useState(false);
  const [dep_product, setDepProduct] = useState("");
  const [addOnCategory, setAddOnCatgory] = useState(1);
  const [onSale, setOnsale] = useState(false);
  const [allergens, setAllergens] = useState({
    vegetarian: false,
    vegan: false,
    celery: false,
    cereals_containing_gluten: false,
    crustaceans: false,
    eggs: false,
    fish: false,
    lupin: false,
    milk: false,
    mollusc: false,
    mustard: false,
    nuts: false,
    peanuts: false,
    sesame_seeds: false,
    soya: false,
    sulphur_dioxide: false,
  });

  const { productId } = useParams();
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(
    (productState, value) => ({ ...productState, ...value }),
    initState
  );
  const reduxDispatch = useDispatch();

  const getAProduct = useCallback(() => {
    productsServices
      .getAProduct(productId)
      .then(({ data }) => {
        console.log("found ", data);
        setProduct(data)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
  }, [productId, reduxDispatch]);

  const getCategories = useCallback(() => {
    setIsLoading(true)(reduxDispatch);
    categoriesServices
      .getCategories()
      .then(({ data }) => {
        setCategories(data)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      })
      .finally(() => setIsLoading(false));
  }, [reduxDispatch]);

  const onSelectImage = (e) => {
    let file = e.target.files[0];

    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        dispatch({
          image: result,
          image_src: URL.createObjectURL(e.target.files[0]),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFormData = useCallback(() => {
    locationServices
      .getAllRegions()
      .then(({ data }) => {
        setProductPrices(data)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
  }, [reduxDispatch]);

  const getInventories = useCallback(() => {
    inventoriesServices
      .getInventories()
      .then(({ data }) => {
        setInventories(data)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
  }, [reduxDispatch]);

  const getStocks = useCallback(() => {
    stocksServices
      .getStocks()
      .then(({ data }) => {
        setStocks(data)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
  }, [reduxDispatch]);

  useEffect(() => {
    getFormData();
    getInventories();
    getStocks();
    getCategories();
  }, [getCategories, getFormData, getInventories, getStocks]);

  useEffect(() => {
    dispatch({
      inventoryState: inventoryItems?.map((item, i) => {
        return {
          inventory_id: +item.inventory_name,
          quantity: +item.quantity,
        };
      }),
    });
    dispatch({
      stockState: stockItems?.map((item, i) => {
        return {
          stock_id: +item.stock_name,
          quantity: +item.quantity,
        };
      }),
    });
  }, [inventoryItems, stockItems]);

  useEffect(() => {
    dispatch({
      productPrices: productPrices?.map((region) => {
        return {
          available_region_id: region.id,
          currency: region.currency,
          cost_price: region.cost_price,
          price: region.price,
          old_price: region.old_price,
          region_name: region.state,
        };
      }),
    });
  }, [productPrices]);

  useEffect(() => {
    if (edit) {
      const productPriceArr = product?.product_prices?.map((item, i) => {
        return {
          available_region_id: item?.available_region_id,
          currency: item?.currency,
          price: item?.price,
          cost_price: item?.cost_price,
          old_price: item?.old_price,
          region_status: item?.region_status,
          region_name: item?.region_name,
        };
      });
      dispatch({
        title: product?.title,
        image: product?.image?.url,
        image_src: product?.image?.url,
        description: product?.description,
        categoryId: product?.category_id,
        productCode: product?.code,
        productPrices: productPriceArr,
        has_spice: product?.has_spice,
        has_dependent_product: product?.product_dependents?.length > 0,
        has_allergens: product?.has_allergens,
      });
      const newAllergens = product?.allergen;
      delete newAllergens?.productId;
      delete newAllergens?.id;
      delete newAllergens?.created_at;
      delete newAllergens?.updated_at;
      delete newAllergens?.product_id;
      setAllergens(newAllergens);
      setOnsale(product?.on_sale);
      setSelecetedProducts(
        product?.product_dependents?.map((d) => d?.product_information)
      );
    }
  }, [product, edit]);

  useEffect(() => {
    if (edit) {
      const stockArr = product?.product_stock_items?.map((item, i) => {
        return {
          stock_name: item?.stock_id,
          quantity: item?.quantity,
          unit: item?.stock?.measure,
        };
      });
      setStockItems(stockArr)(reduxDispatch);
    }
  }, [edit, reduxDispatch, product]);

  useEffect(() => {
    if (edit) {
      const inventoryArr = product?.product_inventory_items?.map((item, i) => {
        return {
          inventory_name: item?.inventory_id,
          quantity: item?.quantity,
          unit: item?.inventory?.measure,
        };
      });
      setInventoryItems(inventoryArr)(reduxDispatch);
    }
  }, [edit, product, reduxDispatch]);

  useEffect(() => {
    if (edit) getAProduct();
    // getAllProduct();
  }, [edit, getAProduct]);
  const fetchProducts = async (categoryId) => {
    try {
      setFetchingProducts(true);
      const res = await categoriesServices.getACategory(categoryId);
      console.log("res", res);
      setProducts(res.data.products);
      setFetchingProducts(false);
    } catch (err) {
      setFetchingProducts(false);
    }
  };
  const handleAddOnCatgory = (cat) => {
    setAddOnCatgory(cat);
    fetchProducts(cat);
  };
  const handleDependentProducts = (option) => {
    setDepProduct(option.name);
    const hasItem = selectedProducts.find((p) => p.id === option.id);
    if (!hasItem) setSelecetedProducts([option, ...selectedProducts]);
  };
  const removeSelectedProduct = (p) => {
    const arr = [...selectedProducts].filter((s) => s.id !== p.id);
    setSelecetedProducts(arr);
  };

  useEffect(() => {
    fetchProducts(addOnCategory);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!state.title) {
      toast.error("Title cannot be empty");
      return;
    }
    if (!state.productCode) {
      toast.error("Product Code cannot be empty");
      return;
    }
    if (!state.categoryId) {
      toast.error("Select a category");
      return;
    }
    if (
      state?.inventoryState?.filter((s) => s?.inventory_id !== 0).length === 0
    ) {
      toast.error("Inventory cannot be empty");
      return;
    }

    dispatch({ isLoading: true });
    // console.log(state?.inventoryState);
    if (!edit) {
      productsServices
        .createProduct({
          product: {
            title: state?.title,
            description: state?.description,
            image: state?.image,
            category_id: state?.categoryId,
            code: state?.productCode,
            has_spice: state?.has_spice,
            has_dependent_products: state?.has_dependent_product,
            product_dependents_attributes: selectedProducts?.map((d) => {
              return { dependent_id: d.id };
            }),
            allergen_attributes: allergens,
            on_sale: onSale,
          },
          product_price: {
            prices: state?.productPrices,
          },
          inventory: {
            inventories: state?.inventoryState.filter(
              (s) => s.inventory_id !== 0
            ),
          },
          stock: {
            stocks: state?.stockState.filter((s) => s.stock_id !== 0),
          },
        })
        .then(() => {
          toast.success("Product was succesfully created");
          navigate(paths.products);
        })
        .catch((err) => {
          dispatch({ isLoading: false });
          if (err.response.status === 422) {
            if (err.response.data.data !== null) {
              let errorMsg = objectToArray(err?.response?.data?.data);
              toast.error(`${errorMsg[0][0]} ${errorMsg[0][1][0]}`);
            } else toast.error(err.response.message);
          } else {
            toast.error("Something went wrong, Please trye again");
          }
        });
    } else {
      productsServices
        .updateProduct(productId, {
          product: {
            title: state.title,
            description: state.description,
            ...(state.image === product?.image.url
              ? {}
              : { image: state.image }),
            category_id: state.categoryId,
            code: state?.productCode,
            has_spice: state?.has_spice,
            has_dependent_products: state?.has_dependent_product,
            product_dependents_attributes: selectedProducts?.map((d) => {
              return { dependent_id: d?.id };
            }),
            allergen_attributes: allergens,
            on_sale: onSale,
          },
          product_price: {
            prices: state?.productPrices,
          },
          inventory: {
            inventories: state?.inventoryState.filter(
              (s) => s.inventory_id !== 0
            ),
          },
          stock: {
            stocks: state?.stockState.filter((s) => s.stock_id !== 0),
          },
        })
        .then(() => {
          toast.success("Product was succesfully updated");
          navigate(paths.products);
        })
        .catch((err) => {
          console.log(err.response);
          if (err.response.status === 422) {
            if (err.response.data.data !== null) {
              let errorMsg = objectToArray(err?.response?.data?.data);
              toast.error(`${errorMsg[0][0]} ${errorMsg[0][1][0]}`);
            } else toast.error(err.response.message);
          } else {
            toast.error("Something went wrong, Please trye again");
          }
        });
    }
  };

  return {
    state,
    handleSubmit,
    dispatch,
    onSelectImage,
    categories,
    productPrices,
    stocks,
    inventories,
    allergens,
    setAllergens,
    products,
    setProducts,
    selectedProducts,
    setSelecetedProducts,
    fetchingProducts,
    setFetchingProducts,
    dep_product,
    setDepProduct,
    addOnCategory,
    onSale,
    setOnsale,
    setAddOnCatgory,
    handleAddOnCatgory,
    handleDependentProducts,
    removeSelectedProduct,
  };
}

export default useAddProduct;
