import React from "react";
import { ReactComponent as Dashboard } from "../../Assets/Images/MenuIcons/dashboard.svg";
import { ReactComponent as Location } from "../../Assets/Images/MenuIcons/locations.svg";
import { ReactComponent as Kitchen } from "../../Assets/Images/MenuIcons/kitchen.svg";
import { ReactComponent as Management } from "../../Assets/Images/MenuIcons/staff.svg";
import { ReactComponent as Orders } from "../../Assets/Images/MenuIcons/orders.svg";
import { ReactComponent as Deliveries } from "../../Assets/Images/MenuIcons/delivery.svg";
import { ReactComponent as Customers } from "../../Assets/Images/MenuIcons/users.svg";
import { ReactComponent as Discounts } from "../../Assets/Images/MenuIcons/discount.svg";


function DashboardIcon() {
  return <Dashboard />;
}

function LocationIcon() {
  return <Location />;
}

function KitchenIcon() {
  return <Kitchen />;
}

function ManagementIcon() {
  return <Management />;
}

function OrdersIcon() {
  return <Orders />;
}

function DeliveriesIcon() {
  return <Deliveries />;
}

function UsersIcon() {
  return <Customers />;
}

function DiscountIcon() {
  return <Discounts />;
}

export {
  DashboardIcon,
  LocationIcon,
  KitchenIcon,
  ManagementIcon,
  OrdersIcon,
  DeliveriesIcon,
  UsersIcon,
  DiscountIcon,
};
