import React from "react";
import styles from "./Grid.module.css";

const Grid = (props) => {
  let gridSpan = "uigrid-span-" + props.span;
  return (
    <div className={`${styles["uigrid"]} ${styles[gridSpan]}`}>
      {props.children}
    </div>
  );
};

export default Grid;
