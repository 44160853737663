import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { setIsLoading } from "../../../../redux/actions/appAction";
import { setCategories } from "../../../../redux/actions/categories.action";
import { setZoneByRegion } from "../../../../redux/actions/deliveryZonesAction";
import { setIsDeleting, setPopupComponent, showPopup } from "../../../../redux/actions/popUp.action";
import {
  setProducts,
} from "../../../../redux/actions/product.action";
import { paths } from "../../../../Routes/path";
import categoriesServices from "../../../../Services/categories.services";
import productsServices from "../../../../Services/products.services";
import DeleteModal from "../../../UI/DeleteModal/DeleteModal";

function useProducts() {
  const {
    products,
  } = useSelector((state) => state.product);

  const { categories } = useSelector((state) => state.category);

  const { openModal } = useSelector((state) => state.app);

  const reduxDispatch = useDispatch();

  const navigate = useNavigate();

  const getProducts = useCallback(() => {
    setIsLoading(true)(reduxDispatch);
    productsServices
      .getProducts()
      .then(({ data }) => {
        setZoneByRegion(data)(reduxDispatch)
        setProducts(data)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      })
      .finally(() => setIsLoading(false)(reduxDispatch));
  }, [reduxDispatch]);

  const getCategories = useCallback(() => {
    categoriesServices
      .getCategories()
      .then(({ data }) => {
        let valuesArr = data?.map((item) => ({
          status: item.name,
          count: item.product_count,
          id: item.id,
        }));
        setCategories(valuesArr)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      })
      // .finally(() => setIsLoading(false));
  }, [reduxDispatch]);

  useEffect(() => {
    getProducts();
    getCategories();
  }, [getProducts, getCategories]);
 
  const onClose = () => {
    showPopup({ ispopupOpen: false })(reduxDispatch);
  };

  const getACategory = (categoryId) => {
    setIsLoading(true)(reduxDispatch);
    categoriesServices
      .getACategory(categoryId)
      .then(({ data }) => {
        setProducts(data?.products)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      })
     .finally(() => setIsLoading(false)(reduxDispatch));
  };

  const secondRowFcn = (id) => {
    getACategory(id);
  };

  const secondRowAllFcn = () => {
    getProducts();
  };

  const handleDelete = (productId) => {
    setIsDeleting(true)(reduxDispatch)
    productsServices
      .deleteProduct(productId)
      .then(() => {
        toast.success("Product was deleted");
        getProducts();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => {
        onClose();
        navigate(paths.products);
        setIsDeleting(false)(reduxDispatch)
      });
  };

  const onClickAction = (productId) => {
    reduxDispatch(
      setPopupComponent(
        <DeleteModal
          onClose={onClose}
          handleDelete={() => handleDelete(productId)}
          title="Product"
        />
      )
    );
    showPopup({ ispopupOpen: true, onClose: () => onClose() })(reduxDispatch);
  };

  const myObjectString = localStorage.getItem("user");

  const currentUser = JSON.parse(myObjectString);
  const currentUserAbilities = currentUser?.account_abilities?.abilities?.["inventory"];

  return {
    products,
    onClickAction,
    categories,
    secondRowFcn,
    secondRowAllFcn,
    openModal,
    handleDelete,
    onClose,
    currentUserAbilities,
  };
}

export default useProducts;
