import { API_URL } from "../env";
import { request } from "./api";

const URL = {
  searchUsers:(q, page, perPage) => `${API_URL}api/v1/be/orders/users/search?page=${page}&per_page=${perPage}&q=${q}`,
  searchOrders:(q, page, perPage) => `${API_URL}api/v1/be/orders/search?page=${page}&per_page=${perPage}&q=${q}`,
  getUserAddresses:(userId) => `${API_URL}api/v1/be/orders/users/${userId}/addresses`,
  createUserAddress: (userId) => `${API_URL}api/v1/be/users/${userId}/addresses/new`,
  getOrders:(page, perPage) => `${API_URL}api/v1/be/orders?page=${page}&per_page=${perPage}`,
  getAnOrder: (orderId) => `${API_URL}api/v1/be/orders/${orderId}`,
  createOrder: `${API_URL}api/v1/be/orders/new`,
  deleteOrder: (orderId) => `${API_URL}api/v1/be/orders/${orderId}`,
  updateOrder: (orderId) => `${API_URL}api/v1/be/orders/${orderId}`,
  updateOrderStatus: (url) => `${API_URL}${url}`,
  getOrderProducts: (userId, addressId) => `${API_URL}api/v1/be/users/${userId}/addresses/${addressId}/products`,
  getOrderStatus: `${API_URL}api/v1/be/orders/statuses`,
  getFilteredOrder: (orderStatus, page, perPage) => `${API_URL}api/v1/be/orders/filter/${orderStatus}?page=${page}&per_page=${perPage}`,
  assignOrderToKitchen: (orderId, kitchenId) => `${API_URL}api/v1/be/orders/${orderId}/kitchen/assign/${kitchenId}`,
  getReadyOrders: `${API_URL}api/v1/be/riders/orders/ready`,

  // payments
  verifyAPayment: (orderId, paymentId) => `${API_URL}api/v1/be/orders/${orderId}/payments/${paymentId}/verify`,
  verifyAPaymentManually: (orderId, paymentId) => `${API_URL}api/v1/be/orders/${orderId}/payments/${paymentId}/verify/manually`,
  getOrderPayments: (orderId) => `${API_URL}api/v1/be/orders/${orderId}/payments`,
  collectPayment: (orderId) => `${API_URL}api/v1/be/orders/${orderId}/payments/new/extras`,
  refreshPayment: (orderId) => `${API_URL}api/v1/be/orders/${orderId}/payments/new/refresh`,

  // discounts
  getDiscounts: `${API_URL}api/v1/be/orders/discounts`,
  applyDiscount: (orderId, discountId) =>`${API_URL}api/v1/be/orders/${orderId}/discounts/${discountId}/apply`,

  //
  downloadOrder: `${API_URL}api/v1/be/orders/download_csv`,
};

function getOrders(page, perPage) {
  return request(URL.getOrders(page, perPage), "get");
}

function getReadyOrders() {
  return request(URL.getReadyOrders, "get")
}

function getOrderStatus() {
  return request(URL.getOrderStatus, "get");
}

function getFilteredOrder(orderStatus, page, perPage) {
  return request(URL.getFilteredOrder(orderStatus, page, perPage), "get");
}

function searchUsers(q, page, perPage) {
  return request(URL.searchUsers(q, page, perPage), "get");

}

function searchOrders(q, page, perPage) {
  return request(URL.searchOrders(q, page, perPage), "get");

}

function getAnOrder(orderId) {
  return request(URL.getAnOrder(orderId), "get");
}

function getOrderProducts(userId, addressId) {
  return request(URL.getOrderProducts(userId, addressId), "get");
}

function getUserAddresses(userId) {
  return request(URL.getUserAddresses(userId), "get");
}

function downloadOrder() {
  return request(URL.downloadOrder, {}, "get", "" ,{
    isDownload: true,
    fileName: `order_records_${new Date().toISOString()}.csv`,
    isCsv: true,
  },);
}

function deleteOrder(orderId, order) {
  return request(URL.deleteOrder(orderId), order, "delete");
}

function createOrder(order) {
  return request(URL.createOrder, order, "post");
}

function createUserAddress(userId, address) {
  return request(URL.createUserAddress(userId), address, "post");
}

function updateOrder(orderId, order) {
  return request(URL.updateOrder(orderId), order, "put");
}

function updateOrderStatus(url, order) {
  return request(URL.updateOrderStatus(url), order, "put");
}

function assignOrderToKitchen(orderId, kitchenId, order) {
  return request(URL.assignOrderToKitchen(orderId, kitchenId), order, "post");
}

//payments
function verifyAPayment(orderId, paymentId, order) {
  return request(URL.verifyAPayment(orderId, paymentId), order, "post");
}

function verifyAPaymentManually(orderId, paymentId, order) {
  return request(URL.verifyAPaymentManually(orderId, paymentId), order, "post");
}

function getOrderPayments(orderId) {
  return request(URL.getOrderPayments(orderId), "get");
}

function collectPayment(orderId, order) {
  return request(URL.collectPayment(orderId), order, "post");
}

function refreshPayment(orderId, order) {
  return request(URL.refreshPayment(orderId), order, "post");
}


// discounts 
function getDiscounts() {
  return request(URL.getDiscounts, "get");
}

function applyDiscount(orderId, discountId, order) {
  return request(URL.applyDiscount(orderId, discountId), order, "post");
}

const ordersServices = {
  getOrders,
  getAnOrder,
  updateOrder,
  createOrder,
  deleteOrder,
  searchUsers,
  getUserAddresses,
  getOrderProducts,
  getOrderStatus,
  getFilteredOrder,
  createUserAddress,
  updateOrderStatus,
  assignOrderToKitchen,
  verifyAPayment,
  getOrderPayments,
  getReadyOrders,
  refreshPayment,
  collectPayment,
  verifyAPaymentManually,
  searchOrders,
  getDiscounts,
  downloadOrder,
  applyDiscount,
};

export default ordersServices;
