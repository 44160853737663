import {
  SET_INFLUENCER,
  SET_INFLUENCERS,
  SET_INFLUENCER_ID,
  SET_VOUCHER,
  SET_VOUCHERS,
  SET_VOUCHER_ID,
  SET_DISCOUNTS,
} from "../actionTypes/discountActionType";

export function setInfluencers(data) {
  return (dispatch) => {
    dispatch({ type: SET_INFLUENCERS, data });
  };
}

export function setInfluencer(data) {
  return (dispatch) => {
    dispatch({ type: SET_INFLUENCER, data });
  };
}

export function setInfluencerId(data) {
  return (dispatch) => {
    dispatch({ type: SET_INFLUENCER_ID, data });
  };
}

export function setVouchers(data) {
  return (dispatch) => {
    dispatch({ type: SET_VOUCHERS, data });
  };
}

export function setVoucher(data) {
  return (dispatch) => {
    dispatch({ type: SET_VOUCHER, data });
  };
}

export function setVoucherId(data) {
  return (dispatch) => {
    dispatch({ type: SET_VOUCHER_ID, data });
  };
}

export function setDiscounts(data) {
  return (dispatch) => {
    dispatch({ type: SET_DISCOUNTS, data });
  };
}
