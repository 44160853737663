import React from "react";
import Card from "../../../../UI/Card/Card";
import { TextInput } from "../../../../UI/FormComponents/Input";
import styles from "../../AddKitchenForm.module.scss";
import Button from "../../../../UI/Button/Button";
import ToggleSwitch from "../../../../UI/FormComponents/InputTypes/ToggleSwitch";

const FormRHS = ({ edit, handleSubmit, state, dispatch }) => {
  return (
    <>
      <Card classes={styles["form-3grid-main"]}>
        <TextInput
          name="longitude"
          label="Longitude"
          type="text"
          value={state?.longitude}
          onChange={({ target }) => dispatch({ longitude: target.value })}
        />
        <TextInput
          name="latitude"
          label="Latitude"
          type="text"
          value={state?.latitude}
          onChange={({ target }) => dispatch({ latitude: target.value })}
        />
        <ToggleSwitch
          selected={state?.status}
          toggleSelected={() => {
            dispatch({ status: !state?.status })
          }}
          title="Active"
        />
      </Card>
      <div className={styles.cards}>
        <Button
          name="Kitchen"
          bgColor="green-bg"
          createFnc={handleSubmit}
          edit={edit}
          isLoading={state?.isLoading}
        />
      </div>
    </>
  );
};

export default FormRHS;
