import {
  SET_DELIVERY_REGIONS,
  SET_DELIVERY_ZONES,
  SET_ZONE_BY_REGION,
  SET_ZONE_ID,
} from "../actionTypes/deliveryZonesType";

export function setDeliveryZones(data) {
  return (dispatch) => {
    dispatch({ type: SET_DELIVERY_ZONES, data });
  };
}

export function setZoneByRegion(data) {
  return (dispatch) => {
    dispatch({ type: SET_ZONE_BY_REGION, data });
  };
}

export function setZoneId(data) {
  return (dispatch) => {
    dispatch({ type: SET_ZONE_ID, data });
  };
}

export function setDeliveryRegions(data) {
  return (dispatch) => {
    dispatch({ type: SET_DELIVERY_REGIONS, data });
  };
}
