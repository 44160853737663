import React from "react";
import { SelectInput } from "../../../../UI/FormComponents/Input";
import styles from "../../AddRegionForm.module.scss";
import Button from "../../../../UI/Button/Button";

const FormLHS = ({ edit, state, handleSubmit, dispatch }) => {
  const { countryArray, isLoading, country } = state;
  
  return (
    <div className={styles["form-6grid-main"]}>
      <SelectInput
        name="country"
        label="Country"
        value={country}
        options={countryArray}
        onChange={(e) => dispatch({ country: e.target.value })}
      />
      <div className={styles["btn-div"]}>
        <Button
          name="Country"
          bgColor="green-bg"
          createFnc={handleSubmit}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default FormLHS;
