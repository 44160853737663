import { useReducer } from 'react'
import { toast } from 'react-toastify';
import ordersServices from '../../../../../../Services/orders.services';

function useRefreshOrders(orderId, onClose, order) {
    const initState = {
        refreshOrder: false,
        price: null,
        delivery: order?.deliver_charge,
        reason: "",
        isLoading: false,
        next: false,
        paymentLink: "",
      };
    
    
      const [state, dispatch] = useReducer(
        (orderState, value) => ({ ...orderState, ...value }),
        initState
      );

      const handleSubmit = (event) => {
        event.preventDefault();
        if (!state.price && state?.refreshOrder) {
          toast.error("Price is required");
          return;
        }
        dispatch({ isLoading: true });
        if (state.next) {
            dispatch({ isLoading: false });
            toast.success("Payment Link copied");
            navigator.clipboard.writeText(state?.paymentLink);
            onClose();
            dispatch({ next: false });
          } else {
          ordersServices
            .refreshPayment(orderId, {
              refresh_payment: {
                refresh: state?.refreshOrder,
                refresh_amount: state?.price,
              }
            })
            .then((res) => {
              toast.success(res.message);
              dispatch({ 
                paymentLink: res?.data?.paystack?.checkout_url,
                next: true,
                isLoading: false,
             });
            })
            .catch((err) => {
              dispatch({ isLoading: false });
              toast.error(err.message);
            });
        }
        }
  return {
    state,
    dispatch,
    handleSubmit,
  }
}

export default useRefreshOrders