import { useCallback, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setIsLoading } from "../../../../redux/actions/appAction";
import { setOrder } from "../../../../redux/actions/order.action";
import { setReadyOrders, setRiders } from "../../../../redux/actions/ridersAction";
import ordersServices from "../../../../Services/orders.services";
import ridersServices from "../../../../Services/riders.services";
import { useNavigate, useParams } from "react-router";
import { paths } from "../../../../Routes/path";

function useAssignDelivery() {
  const {orderId} = useParams();
  const navigate = useNavigate();
  const initState = {
    orderId: orderId,
    isLoading: false,
    riderId: null,
  };
  const [state, dispatch] = useReducer(
    (categoryState, value) => ({ ...categoryState, ...value }),
    initState
  );
  const { riderId } = state;
  const reduxDispatch = useDispatch();
  const { readyOrders, riders } = useSelector((state) => state?.rider);
  const { order } = useSelector((state) => state?.order);

  const getAnOrder = useCallback(() => {
    ordersServices
      .getAnOrder(state?.orderId)
      .then(({ data }) => {
        setOrder(data)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
  }, [state, reduxDispatch]);

  const getOrders = useCallback(() => {
    ordersServices
      .getReadyOrders()
      .then(({ data }) => {
        let valuesArr = data?.map((item) => ({
          name: `${item?.reference}`,
          id: item?.id,
        }));
        setReadyOrders(valuesArr)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
  }, [reduxDispatch]);

  const getRiders = useCallback(() => {
    setIsLoading(true)(reduxDispatch);
    ridersServices
      .getRiders()
      .then(({ data }) => {
        let valuesArr = data?.map((item) => ({
          name: `${item.first_name} ${item?.last_name}`,
          id: item.id,
        }));
        setRiders(valuesArr)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      })
      .finally(() => setIsLoading(false)(reduxDispatch));
  }, [reduxDispatch]);

  useEffect(() => {
    getOrders()
    if (state?.orderId)
    getAnOrder()
  }, [getAnOrder, getOrders, state])

  useEffect(() => {
    if (Object.keys(order).length)
    getRiders();
  }, [getRiders, order])

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch({ isLoading: true })
      ridersServices
        .assignRiderToOrder(riderId, state?.orderId)
        .then(() => {
          toast.success("Order was succesfully assigned");
          dispatch({ isLoading: false })
          navigate(paths.manageDelivery)
        })
        .catch((err) => {
          dispatch({ isLoading: false})
          if (err?.response?.status === 409) {
            toast.error(err.response.data.message)
          } else {
            toast.error(err.message);
          }
        });
  };

  useEffect(() => {
    setOrder([])(reduxDispatch)
  }, [reduxDispatch])

  return {
    dispatch,
    state,
    readyOrders,
    order,
    riders,
    handleSubmit,
  };
}

export default useAssignDelivery;
