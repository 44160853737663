import { API_URL } from "../env";
import { request } from "./api";

const URL = {
  getStocks: `${API_URL}api/v1/be/stocks`,
  getAStock: (stockId) => `${API_URL}api/v1/be/stocks/${stockId}`,
  createStock: `${API_URL}api/v1/be/stocks/new`,
  deleteStock:(stockId) => `${API_URL}api/v1/be/stocks/${stockId}`,
  updateStock: (stockId) =>
    `${API_URL}api/v1/be/stocks/${stockId}`,

  // kitchen based
  getLocationStock: (kitchenId) => `${API_URL}api/v1/be/kitchen/${kitchenId}/stocks`,
  getALocationStock: (kitchenId, stockId) => `${API_URL}api/v1/be/kitchen/${kitchenId}/stocks/${stockId}`,
  topUpStock: (kitchenId, stockId) => `${API_URL}api/v1/be/kitchen/${kitchenId}/stocks/${stockId}/top_up`,
  bulkUploadStock: (kitchenId) => `${API_URL}api/v1/be/kitchen/${kitchenId}/stocks/bulk_top_up`,
  downloadStocks: (kitchenId) => `${API_URL}api/v1/be/kitchen/${kitchenId}/stocks/download`,
  resetStock: (kitchenId, stockId) => `${API_URL}api/v1/be/kitchen/${kitchenId}/stocks/${stockId}/change`,
};

function getStocks() {
  return request(URL.getStocks, "get")
}

function getAStock(stockId) {
  return request(URL.getAStock(stockId), "get")
}

function deleteStock(stockId) {
  return request(URL.deleteStock(stockId), {}, "delete")
}

  function createStock(inventory) {
    return request(URL.createStock, inventory, "post")
}

function updateStock(stockId, product) {
    return request(URL.updateStock(stockId), product, "put")  
}

// kitchen
function getLocationStock(kitchenId) {
  return request(URL.getLocationStock(kitchenId), "get")  
}

function getALocationStock(kitchenId, stockId) {
  return request(URL.getALocationStock(kitchenId, stockId), "get")  
}

function topUpStock(kitchenId, stockId, stock) {
  return request(URL.topUpStock(kitchenId, stockId), stock, "post")  
}

function bulkUploadStock(kitchenId, stock) {
  return request(URL.bulkUploadStock(kitchenId), stock, "post")  
}

function downloadStocks(kitchenId) {
  return request(URL.downloadStocks(kitchenId), {}, "get", "" ,{
    isDownload: true,
    fileName: `stock_records_${new Date().toISOString()}.csv`,
    isCsv: true,
  },);
}

function resetStock(kitchenId, stockId, stock) {
  return request(URL.resetStock(kitchenId, stockId), stock, "post")  
}

const stocksServices = {
  getStocks,
  getAStock,
  updateStock,
  createStock,
  deleteStock,
  getALocationStock,
  getLocationStock,
  topUpStock,
  bulkUploadStock,
  downloadStocks,
  resetStock
}

export default stocksServices;
