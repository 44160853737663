import { SET_STAFF, SET_STAFFS, SET_STAFF_ID } from "../actionTypes/staffType";
  
  const initialState = {
    staffs: [],
    staff: [],
    staffId: null,
  };
  
  export default function staffReducer(state = initialState, action) {
    const { data, type } = action;
    switch (type) {
      case SET_STAFFS:
        return {
          ...state,
          staffs: data,
        };
      case SET_STAFF:
        return {
          ...state,
          staff: data,
        };
      case SET_STAFF_ID:
        return {
          ...state,
          staffId: data,
        };
      default:
        return state;
    }
  }
  