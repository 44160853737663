import React from 'react'
import locationstyles from "./Influencers.module.scss";
import TopDisplay from '../../UI/TopDisplay/TopDisplay';
import FormLHS from "./component/FormGroups/FormLHS";
import useAddInfluencer from './hooks/useAddInfluencer';

function AddInfluencerForm({ edit, view }) {
    const { state, dispatch, handleForm } = useAddInfluencer(edit, view);
    return (
        <div className={locationstyles.mains}>
          <TopDisplay
            title={edit ? "Update Influencer" : "Add New Influencer"}            firstText="Influencers"
            secondText={edit ? "Update Influencer" : "Add New Influencer"} 
            style1
          />
          <FormLHS
            edit={edit}
            view={view}
            state={state}
            handleSubmit={handleForm}
            dispatch={dispatch}
          />
        </div>
      );
}

export default AddInfluencerForm