import React from "react";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { PlusIcon } from "../RegularIcons";
import styles from "./AddItem.module.scss";

function AddItem({ name, addFnc, bgColor, noItem, isLoading }) {
  return (
    <div className={`${styles.addBtn} ${styles[bgColor]}`} onClick={addFnc}>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className={styles["add-icon"]}>
          <PlusIcon />
        </div>
      )}
      {bgColor ? (
        <div className={styles.addTxt}>
          {noItem ? `Add ${name}` : `Add ${name} Item`}
        </div>
      ) : (
        <div className={styles.addTxt}>Add New {name}</div>
      )}
    </div>
  );
}

export default AddItem;
