import {
  SET_PRODUCTS,
  SET_PRODUCT,
  SET_PRODUCT_ID,
  SET_PRODUCT_PRICES,
} from "../actionTypes/productActionType";

const initialState = {
  products: [],
  product: [],
  productId: null,
  productPrices: [],
};

export default function productReducer(state = initialState, action) {
  const { data, type } = action;
  switch (type) {
    case SET_PRODUCTS:
      return {
        ...state,
        products: data,
      };
    case SET_PRODUCT:
      return {
        ...state,
        product: data,
      };
    case SET_PRODUCT_ID:
      return {
        ...state,
        productId: data,
      };
    case SET_PRODUCT_PRICES:
      return {
        ...state,
        productPrices: data,
      };
    default:
      return state;
  }
}
