import React from "react";
import FormLHS from "./component/FormGroups/FormLHS";
import TopDisplay from "../../UI/TopDisplay/TopDisplay";
import locationstyles from "./CustomerDashboard.module.scss";
import useAddCustomer from "./hooks/useAddCustomer";

const AddCustomerForm = ({ edit, view }) => {
  const { state, dispatch, handleForm, pageTitle } = useAddCustomer(edit, view);
  return (
    <div className={locationstyles.mains}>
      <TopDisplay
        title={pageTitle}
        firstText="Customer"
        secondText={pageTitle}
        style1
      />
      <FormLHS
        edit={edit}
        view={view}
        state={state}
        handleSubmit={handleForm}
        dispatch={dispatch}
      />
    </div>
  );
};

export default AddCustomerForm;
