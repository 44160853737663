import Text from "./InputTypes/Text";
import TextArea from "./InputTypes/TextArea";
import File from "./InputTypes/File";
import Select from "./InputTypes/Select";
import Number from "./InputTypes/Number";
import React from "react";
import Dropdown from "./InputTypes/Dropdown";
import SearchBar from "./InputTypes/SearchBar";
import DateTime from "./InputTypes/DateTime";

function TextInput(props) {
  return (
    <Text
      label={props.label}
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      type={props.type}
      disabled={props.disabled}
    />
  );
}

function DateTimeInput(props) {
  return (
    <DateTime
      label={props.label}
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      type={props.type}
      disabled={props.disabled}
    />
  );
}

function TextAreaInput(props) {
  return (
    <TextArea
      label={props.label}
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      required={props.required}
      disabled={props.disabled}
    />
  );
}

function FileInput(props) {
  return (
    <File
      label={props.label}
      name={props.name}
      acceptedTypes={props.accepted_types}
      onSelectImage={props.onSelectImage}
      imgSrc={props.imgSrc}
      imgName={props?.imgName}
      disabled={props.disabled}
    />
  );
}

function SelectInput(props) {
  return (
    <Select
      label={props.label}
      name={props.name}
      value={props.value}
      options={props.options}
      onChange={props.onChange}
      custom={props.custom}
      disabled={props.disabled}
    />
  );
}

function SearchSelect(props) {
  return (
    <Dropdown
      label={props.label}
      name={props.name}
      value={props.value}
      options={props.options}
      onChange={props.onChange}
      searchFn={props.searchFn}
      searchBy={props.searchBy}
      labelField={props.labelField}
    />
  );
}

function SearchInput(props) {
  return (
    <SearchBar
      options={props.options}
      onChange={props.onChange}
      label={props.label}
      name={props.name}
      value={props.value}
      placeholder={props.placeholder}
      addOption={props.addOption}
      handleCustomerId={props.handleCustomerId}
      isLoading={props.isLoading}
      plusIcon={props.plusIcon}
    />
  );
}

function NumberInput(props) {
  return (
    <Number
      label={props.label}
      units={props.units}
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      required={props.required}
      integer={props.integer}
      disabled={props.disabled}
    />
  );
}

export {
  TextInput,
  TextAreaInput,
  SelectInput,
  NumberInput,
  FileInput,
  SearchSelect,
  SearchInput,
  DateTimeInput,
};
