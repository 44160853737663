import React from "react";
import ReactCountryFlag from "react-country-flag";
import styles from "./Cards.module.scss";

function Cards({
  state,
  flag,
  countryName,
  currency,
  handleCountry,
  selectedState,
  id,
}) {
  let isActive = selectedState.countryId === id;
  return (
    <div
      onClick={() => handleCountry(flag)}
      className={`${styles["card-div"]} ${isActive && styles["active"]}`}
    >
      <span className={styles.title}>
        <ReactCountryFlag
          countryCode={flag}
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            boxSizing: "border-box",
          }}
          svg
        />
      </span>
      <span className={styles.qty}>{countryName}</span>
      <div className={styles.div1}>
        {currency}
        <div>-</div>
         {state} Operational States
      </div>
    </div>
  );
}

export default Cards;
