import { API_URL } from "../env";
import { request } from "./api";

const URL = {
  login: `${API_URL}api/v1/be/auth/login`,
};


function login(userDetails) {
    return request(URL.login, userDetails, "post")
}

const authServices = {
  login,
}

export default authServices;
