import React from "react";
import {
  DashboardIcon,
  LocationIcon,
  KitchenIcon,
  ManagementIcon,
  OrdersIcon,
  DeliveriesIcon,
  UsersIcon,
  DiscountIcon,
} from "../MenuIcons";

const Icon = (props) => {
  switch (props.icon) {
    case "home":
      return <DashboardIcon />;
    case "locations":
      return <LocationIcon />;
    case "kitchen":
      return <KitchenIcon />;
    case "management":
      return <ManagementIcon />;
    case "orders":
      return <OrdersIcon />;
    case "deliveries":
      return <DeliveriesIcon />;
    case "customers":
      return <UsersIcon />;
    case "discount":
      return <DiscountIcon />;
    case "logs":
      return <ManagementIcon />;
    default:
      return null;
  }
};

export default Icon;
