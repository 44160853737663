import React from "react";
import MetaTags from "react-meta-tags";
import Grid from "../../UI/Grid/Grid";
import GridRow from "../../UI/Grid/GridRow";
import TopDisplay from "../../UI/TopDisplay/TopDisplay";
import ErrorPage from "../ErrorPage/ErrorPage";
import DTable from "./component/DTable/DTable";
import styles from "./CustomerDashboard.module.scss";
import useCustomer from "./hooks/useCustomer";

function CustomerDashboard() {
  const {
    handlePageChange,
    handlePerRowsChange,
    customers,
    totalRows,
    onClickAction,
    onAddCustomer,
    currentUserAbilities,
  } = useCustomer();
  
  
  return (
    <>
      <MetaTags>
        <title>Customer Page</title>
        <meta name="description" content="#" />
      </MetaTags>
      {currentUserAbilities["user"]?.index? (
        <div>
          <TopDisplay
            title="Customer"
            firstText="Dashboard"
            secondText="Customer"
            thirdText="Dashboard"
            style2
          />
          <div className={styles.section2}>
            <Grid span="9">
              <GridRow span="9" customClass="section2">
                <DTable
                  handlePageChange={handlePageChange}
                  handlePerRowsChange={handlePerRowsChange}
                  customers={customers}
                  totalRows={totalRows}
                  onClickAction={onClickAction}
                  onAddCustomer={onAddCustomer}
                  currentUserAbilities={currentUserAbilities}
                />
              </GridRow>
            </Grid>
          </div>
        </div>
      ) : <ErrorPage type2/>}
    </>
  );
}

export default CustomerDashboard;
