import { useCallback, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { setIsLoading } from "../../../../redux/actions/appAction";
import { setRegion, setStates } from "../../../../redux/actions/location.action";
import { paths } from "../../../../Routes/path";
import locationServices from "../../../../Services/location.services";
import { getBase64, objectToArray } from "../../../../utils/helpers";

function useAddLocation(edit) {
  const initState = {
    image: "",
    image_src: "",
    imgName: "",
    isLoading: false,
    stateCode: "",
    email: "",
    facebook: "",
    whatsapp: "",
    youtube: "",
    instagram: "",
    linkedin: "",
    twitter: "",
  };
  const location = useLocation();
  const { regionId } = useParams();
  const navigate = useNavigate();
  const { countryCode, countryId } = (location && location.state) || {};
  const { states, region } = useSelector(({ location }) => location);
  const reduxDispatch = useDispatch();
  const [state, dispatch] = useReducer(
    (categoryState, value) => ({ ...categoryState, ...value }),
    initState
  );
  const onSelectImage = (e) => {
    let file = e.target.files[0];
    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        dispatch({
          image: result,
          imgName: file?.name,
          image_src: URL.createObjectURL(e.target.files[0]),
        });
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const getARegion = useCallback(() => {
    setIsLoading(true)(reduxDispatch);
    locationServices
      .getARegion(regionId)
      .then(({ data }) => {
        setRegion(data)(reduxDispatch);
      })
      .catch((err) => {
        // toast.error(err.message || "Unable to get");
      })
      .finally(() => setIsLoading(false)(reduxDispatch));
  }, [regionId, reduxDispatch]);

  const getStates = useCallback(() => {
    setIsLoading(true)(reduxDispatch);
    locationServices
      .getAllState(countryCode)
      .then(({ data }) => {
        let values = Object?.entries(data);
        let valuesArr = values?.map((item) => ({
          name: item[1],
          id: item[1],
        }));
        setStates(valuesArr)(reduxDispatch);
      })
      .catch((err) => {
        // toast.error(err.message || "Unable to get");
      })
      .finally(() => setIsLoading(false)(reduxDispatch));
  }, [countryCode, reduxDispatch]);

  useEffect(() => {
    getStates();
    getARegion();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch({
      image_src: region?.logo?.url,
      stateCode: region?.state,
      email: region?.region_contact?.email,
      facebook: region?.region_contact?.facebook,
      whatsapp: region?.region_contact?.whatsapp,
      youtube: region?.region_contact?.youtube,
      instagram: region?.region_contact?.instagram,
      linkedin: region?.region_contact?.linkedin,
      twitter: region?.region_contact?.twitter,
    })
  }, [region])

  const handleSubmit = (event) => {
    if (!state.stateCode) {
      toast.error("Please select a state");
      return;
    }
    event.preventDefault();
    dispatch({ isLoading: true });
    if (!edit) {
      locationServices
        .createRegion({
          available_region: {
            location_id: countryId,
            state: state?.stateCode,
          },
          contact: {
            logo: state?.image,
            facebook: state?.facebook,
            twitter: state?.twitter,
            instagram: state?.instagram,
            linkedin: state?.linkedin,
            youtube: state?.youtube,
            whatsapp: state?.whatsapp,
            email: state?.email,
          },
        })
        .then(() => {
          toast.success("Region was succesfully created");
          navigate(paths.operationalLocations);
        })
        .catch((err) => {
          dispatch({ isLoading: false });
          if (err.response.status === 422) {
            let errorMsg = objectToArray(err?.response?.data?.data);
            toast.error(`${errorMsg[0][0]} ${errorMsg[0][1][0]}`);
          } else {
            toast.error(err.message);
          }
        });
    } else {
      if (state.stateCode !== region.state){
      locationServices
        .editRegion(regionId, {
          available_region: {
            location_id: countryId,
            state: state?.stateCode,
          },
        })
        .then(() => {
          toast.success("Region was succesfully updated");
          navigate(paths.operationalLocations);
        })
        .catch((err) => {
          dispatch({ isLoading: false });
          if (err.response.status === 422) {
            let errorMsg = objectToArray(err?.response?.data?.data);
            toast.error(`${errorMsg[0][0]} ${errorMsg[0][1][0]}`);
          } else {
            toast.error(err.message);
          }
        })
      }
        locationServices.editRegionContact(regionId, {
          contact: {
            logo: state?.image,
            facebook: state?.facebook,
            twitter: state?.twitter,
            instagram: state?.instagram,
            linkedin: state?.linkedin,
            youtube: state?.youtube,
            whatsapp: state?.whatsapp,
            email: state?.email,
          },
        })
        .then(() => {
          toast.success("Region Contact details was updated");
          navigate(paths.operationalLocations);
        })
        .catch((err) => {
          dispatch({ isLoading: false });
          if (err.response.status === 422) {
            let errorMsg = objectToArray(err?.response?.data?.data);
            toast.error(`${errorMsg[0][0]} ${errorMsg[0][1][0]}`);
          } else {
            toast.error(err.message);
          }
        });
    }
  };
  return { state, handleSubmit, dispatch, onSelectImage, states };
}

export default useAddLocation;
