import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import {
  setIsLoading,
} from "../../../../redux/actions/appAction";
import { setIsDeleting, setPopupComponent, showPopup } from "../../../../redux/actions/popUp.action";
import {
  setStocks,
} from "../../../../redux/actions/stock.action";
import { paths } from "../../../../Routes/path";
import stocksServices from "../../../../Services/stocks.services";
import DeleteModal from "../../../UI/DeleteModal/DeleteModal";
import { setWarehouseRegion } from "../../../../redux/actions/location.action";
import kitchenServices from "../../../../Services/kitchen.services";

function useStocks() {
  const { stocks } = useSelector((state) => state.stock);

  const { openModal } = useSelector((state) => state.app);

  const {
    warehouseRegion: kitchen,
  } = useSelector((state) => state.location);

  const {kitchenId} = useParams();
  const location = useLocation();

  const myObjectString = localStorage.getItem("user");

  const currentUser = JSON.parse(myObjectString);
  const currentUserAbilities = currentUser?.account_abilities?.abilities?.["stock"];

  const reduxDispatch = useDispatch();

  const navigate = useNavigate();

  const getAllKitchen = useCallback(() => {
    kitchenServices
      .getKitchens()
      .then(({ data }) => {
        let regionName = data?.filter(
          (item) => +item?.id === +kitchenId
        );
        setWarehouseRegion(regionName[0])(reduxDispatch);
      })
      .catch((err) => {
        // toast.error(err.message || "Unable to get");
      });
  }, [reduxDispatch, kitchenId]);

  useEffect(() => {
    getAllKitchen()
  }, [getAllKitchen])

  const getStocks = useCallback(() => {
    setIsLoading(true)(reduxDispatch);
    if (location.pathname.startsWith("/kitchin")) {
      stocksServices
      .getLocationStock(kitchenId)
      .then(({ data }) => {
        setStocks(data?.products)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      })
      .finally(() => setIsLoading(false)(reduxDispatch));
    } else {
    stocksServices
      .getStocks()
      .then(({ data }) => {
        setStocks(data)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      })
      .finally(() => setIsLoading(false)(reduxDispatch));
    }
  }, [kitchenId, location.pathname, reduxDispatch]);

  useEffect(() => {
    getStocks();
  }, [getStocks]);

  const onClose = () => {
    showPopup({ ispopupOpen: false })(reduxDispatch);
  };

  const handleDelete = (stockId) => {
    setIsDeleting(true)(reduxDispatch)
    stocksServices
      .deleteStock(stockId)
      .then(() => {
        toast.success("Stock was deleted");
        getStocks();
        onClose();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => {
        setIsDeleting(false)(reduxDispatch)
        navigate(paths.stock);
      });
  };

  const onClickAction = (stockId) => {
    reduxDispatch(
      setPopupComponent(
        <DeleteModal
          onClose={onClose}
          handleDelete={() => handleDelete(stockId)}
          title="Stock"
        />
      )
    );
    showPopup({ ispopupOpen: true, onClose: () => onClose() })(reduxDispatch);
  };

  return {
    stocks,
    onClickAction,
    openModal,
    handleDelete,
    onClose,
    currentUserAbilities,
    kitchen,
    kitchenId
  };
}

export default useStocks;
