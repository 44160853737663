import React, { useEffect } from "react";
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import AddCustomerForm from "../../Components/Pages/CustomerDashboard/AddCustomerForm";
import AddInfluencerForm from "../../Components/Pages/Influencers/AddInfluencerForm";
import AddCountryForm from "../../Components/Pages/OperationalLocations/AddCountryForm";
import AddVoucherForm from "../../Components/Pages/Vouchers/AddVoucherForm";
import { setPopupComponent, showPopup } from "../../redux/actions/popUp.action";

function usePopRoutes(){
  const location = useLocation()
  const reduxDispatch = useDispatch()
  const history = useNavigate();

    const onCloseDrawer = () => {
    const splitPath = location?.pathname.split('/');
    splitPath.pop();
    return history(splitPath.join('/'));
  };

  const onNewDrawer = () => {
    const splitPath = location?.pathname.split('/');
    splitPath.splice(-1);
    return history(splitPath.join('/'));
  };

  const onEditDrawer = () => {
    const splitPath = location?.pathname.split('/');
    splitPath.splice(-2);
    return history(splitPath.join('/'));
  };

  useEffect(() => {
    if (/\/add-influencer\/?/g.test(location.pathname)){
      reduxDispatch(setPopupComponent(<AddInfluencerForm onClose={() => {
       onCloseDrawer()
       reduxDispatch(showPopup({ispopupOpen: false}))
     }} />))
       reduxDispatch(showPopup({ispopupOpen: true, onClose: () => onNewDrawer() }))
     }
     if (/\/edit-influencer\/?/g.test(location.pathname)){
      reduxDispatch(setPopupComponent(<AddInfluencerForm onClose={
        () => {
      onEditDrawer()
      reduxDispatch(showPopup({ispopupOpen: false}))
    }
      } edit />))
      reduxDispatch(showPopup({ispopupOpen: true, onClose: () => onEditDrawer() }))
    } 
    if (/\/add-voucher\/?/g.test(location.pathname)){
      reduxDispatch(setPopupComponent(<AddVoucherForm onClose={() => {
       onCloseDrawer()
       reduxDispatch(showPopup({ispopupOpen: false}))
     }} />))
       reduxDispatch(showPopup({ispopupOpen: true, onClose: () => onNewDrawer() }))
     }
     if (/\/edit-voucher\/?/g.test(location.pathname)){
      reduxDispatch(setPopupComponent(<AddVoucherForm onClose={
        () => {
      onEditDrawer()
      reduxDispatch(showPopup({ispopupOpen: false}))
    }
      } edit />))
      reduxDispatch(showPopup({ispopupOpen: true, onClose: () => onEditDrawer() }))
    } 
    if (/\/add-customer\/?/g.test(location.pathname)){
     reduxDispatch(setPopupComponent(<AddCustomerForm onClose={() => {
      onCloseDrawer()
      reduxDispatch(showPopup({ispopupOpen: false}))
    }} />))
      reduxDispatch(showPopup({ispopupOpen: true, onClose: () => onNewDrawer() }))
    }
    if (/\/edit-customer\/?/g.test(location.pathname)){
      reduxDispatch(setPopupComponent(<AddCustomerForm onClose={
        () => {
      onEditDrawer()
      reduxDispatch(showPopup({ispopupOpen: false}))
    }
      } edit />))
      reduxDispatch(showPopup({ispopupOpen: true, onClose: () => onEditDrawer() }))
    } 
    if (/\/view-customer\/?/g.test(location.pathname)){
      reduxDispatch(setPopupComponent(<AddCustomerForm onClose={
        () => {
      onEditDrawer()
      reduxDispatch(showPopup({ispopupOpen: false}))
    }
      } view />))
      reduxDispatch(showPopup({ispopupOpen: true, onClose: () => onEditDrawer() }))
    } 
    if (/\/add-country\/?/g.test(location.pathname)){
      reduxDispatch(setPopupComponent(<AddCountryForm onClose={() => {
       onCloseDrawer()
       reduxDispatch(showPopup({ispopupOpen: false}))
     }} />))
       reduxDispatch(showPopup({ispopupOpen: true, onClose: () => onNewDrawer() }))
     }
  }, [location.pathname]) // eslint-disable-line
}

export default usePopRoutes;