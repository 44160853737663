import { useCallback, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setInventories } from "../../../../../../redux/actions/inventory.action";
import inventoriesServices from "../../../../../../Services/inventories.services";

function useTopup(inventoryId, onClose, manual) {
  const initState = {
    inventory: inventoryId,
    qty: null,
    isLoading: false,
  };

  const [state, dispatch] = useReducer(
    (productState, value) => ({ ...productState, ...value }),
    initState
  );
  const { inventories } = useSelector((state) => state.inventory);

  const reduxDispatch = useDispatch();
  const myObjectString = localStorage.getItem("user");

  const currentUser = JSON.parse(myObjectString);
  const regionId = currentUser?.available_region_id;

  const getInventories = useCallback(() => {
    inventoriesServices
      .getRegionInventories(regionId)
      .then(({ data }) => {
        setInventories(data?.products)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
    // .finally(() => setIsLoading(false)(reduxDispatch));
  }, [regionId, reduxDispatch]);

  useEffect(() => {
    getInventories();
  }, [getInventories])

  useEffect(() => {
    dispatch({inventory: null})
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault();
    if (state.qty !== null) {
    if(manual) {
      dispatch({ isLoading: true });
      inventoriesServices
      .resetRegionInventory(regionId, inventoryId, {
        inventory: {
            quantity: state?.qty
        },
      })
      .then(() => {
        toast.success("Warehouse Inventory has been manually changed");
        dispatch({ isLoading: false });
        onClose();
        getInventories();
      })
      .catch((err) => {
        dispatch({ isLoading: false });
        toast.error(err.message);
      })
    } else {
      dispatch({ isLoading: true });
    inventoriesServices
      .topUpRegionInventory(regionId, inventoryId, {
        inventory: {
            quantity: state?.qty
        },
      })
      .then(() => {
        toast.success("Warehouse Inventory has been topped up");
        dispatch({ isLoading: false });
        onClose();
        getInventories();
      })
      .catch((err) => {
        dispatch({ isLoading: false });
        toast.error(err.message);
      })
  }
 } else {
  // Display an error toast when state.qty is null
  toast.error("Add quantity");
}
};

  return {
    handleSubmit,
    inventories,
    state,
    dispatch,
  };
}

export default useTopup;
