import React, { useEffect, useState } from "react";
import SubMenuItem from "./SubMenuItem";
import styles from "../LocationSidebar.module.scss";
import Icon from "../Icon";
import { NavLink, useLocation } from "react-router-dom";
import { ChevronIcon } from "../../RegularIcons";

const MenuItem = (props) => {
  const location = useLocation();

  const [isActive, setIsActive] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const hrefArray = ["home", "warehouse"];

  useEffect(() => {
    const activeState = window.location.href.includes(props.href);
    setIsActive(activeState);
  }, [location.pathname, props.href]);

  useEffect(() => {
    const activeState = window.location.href.includes(props.href);
    if (isActive && activeState){
      setMenuOpen(true)
    } else 
    setMenuOpen(false)
  }, [isActive, location.pathname, props.href])

  let hasSubMenu = props.submenus !== undefined;

  return (
    <div
      className={`${styles["menu-item-group"]} ${
        isActive ? styles["active"] : ""
      }`}
    >
      <NavLink
        to={
          hrefArray.includes(props.href) 
            ? props.href 
            : props.href.startsWith("warehouse") 
              ? props.href
              : location.pathname
        }
        className={styles["menu-item"]}
        onClick={() => setMenuOpen(!menuOpen)}
      >
        <div className={styles["menu-side-one"]}>
          <div className={styles["menu-item-icon"]}>
            <Icon icon={props.href} />
          </div>
          <div className={styles["menu-item-title"]}>
            <span className="body-2">{props.title}</span>
          </div>
        </div>
        {hasSubMenu && (
          <div
            className={
              menuOpen ? styles["menu-chevron-open"] : styles["menu-chevron"]
            }
          >
            <ChevronIcon />
          </div>
        )}
      </NavLink>
      {hasSubMenu &&
        menuOpen &&
        props.submenus.map((subitem, index) => {
          if (!subitem) {
            return null;
          }
          return (
            <div className={styles["shown-menu"]} key={index}>
              <SubMenuItem
                key={index}
                title={subitem.title}
                url={subitem.url}
                parent={props.href}
                children={props.submenus}
              />
            </div>
          );
        })}
    </div>
  );
};

export default MenuItem;
