import React from "react";
import Card from "../../../../UI/Card/Card";
import { SearchInput } from "../../../../UI/FormComponents/Input";
import FormGroupHeader from "../../../../UI/FormComponents/FormHeaders/FormGroupHeader";
import styles from "../../AddOrderForm.module.scss";
import AddItemArray from "../../../../UI/AddItemArray/AddItemArray";
import Select from "../../../../UI/FormComponents/InputTypes/Select";

const FormLHS = ({
  handleCustomerId,
  products,
  state,
  dispatch,
  users,
  onAddCustomer,
  userAddress,
}) => {
  const { customer, customerAddress, customerName, isSearching } = state;

  return (
    <Card classes={styles["form-6grid-main"]}>
      <FormGroupHeader
        title="Customer Details"
        description="General information about this customer"
      />
      <SearchInput
        name="customer"
        label="Customer"
        placeholder="Search for a Customer"
        onChange={({ target }) => dispatch({ searchValue: target.value })}
        options={users}
        addOption={onAddCustomer}
        handleCustomerId={handleCustomerId}
        isLoading={isSearching}
        plusIcon
      />
      <div className={styles["customer_name"]}>
        <p>{customerName}</p>
      </div>
      <Select
        name="customer_address"
        label="Customer Address"
        placeholder="Search for a Customer"
        custom={customer}
        options={userAddress}
        value={customerAddress}
        onChange={({ target }) => dispatch({ customerAddress: target.value })}
      />
      <div className={styles["inventory-div"]}>
        <FormGroupHeader
          title="Order Items"
          description="add items and quantity of each to the order"
        />
        <AddItemArray type="product" productOptions={products} />
      </div>
    </Card>
  );
};

export default FormLHS;
