import React from "react";
import MetaTags from "react-meta-tags";
import Grid from "../../UI/Grid/Grid";
import GridRow from "../../UI/Grid/GridRow";
import TopDisplay from "../../UI/TopDisplay/TopDisplay";
import ErrorPage from "../ErrorPage/ErrorPage";
import styles from "./Categories.module.scss";
import DTable from "./component/DTable/DTable";
import useCategories from "./hooks/useCategories";

function Categories() {
  const {
    isFetching,
    categories,
    onClickAction,
    currentUserAbilities,
  } = useCategories();

  return (
    <>
      <MetaTags>
        <title>Categories Page</title>
        <meta name="description" content="#" />
      </MetaTags>
      {currentUserAbilities?.index ? (
      <div className={styles.main}>
        <TopDisplay
          title="Categories"
          firstText="Dashboard"
          secondText="Kitchen"
          thirdText="Categories"
          style2
        />
        <div className={styles.section2}>
          <Grid span="9">
            <GridRow span="9" customClass="section2">
              <DTable
                categories={categories}
                isFetching={isFetching}
                onClickAction={onClickAction}
                currentUserAbilities={currentUserAbilities}
              />
            </GridRow>
          </Grid>
        </div>
      </div>
      ) : (
        <ErrorPage type2 />
      )}
    </>
  );
}

export default Categories;
