import React from "react";
import Card from "../../../../UI/Card/Card";
import FormGroupHeader from "../../../../UI/FormComponents/FormHeaders/FormGroupHeader";
import styles from "../../AssignDelivery.module.scss";
import Select from "../../../../UI/FormComponents/InputTypes/Select";
import { cashFormater } from "../../../../../utils/helpers";
import getSymbolFromCurrency from "currency-symbol-map";

const FormLHS = ({ dispatch, state, readyOrders, order = [] }) => {
  const { orderId } = state;
  return (
    <Card classes={styles["form-6grid-main"]}>
      <FormGroupHeader
        title="Order Details"
        description="General information about this order"
      />
      <Select
        name="select_order"
        label="Select Order"
        placeholder="Search for a Customer"
        options={readyOrders}
        value={orderId}
        onChange={({ target }) => dispatch({ orderId: target.value })}
      />
      <div className={styles["inventory-div"]}>
        <FormGroupHeader title="Delivery Recipient" />
        <div className={styles["user-name"]}>{order?.recipient?.full_name || "--"}</div>
        <div></div>
        <div className={styles["user-details"]}>
          <div>
            <div className={styles["address-title"]}>Phone Number</div>
            <div className={styles["address-details"]}>{order?.recipient?.phone || "--"}</div>
          </div>
          <div>
            <div className={styles["address-title"]}>Email Address</div>
            <div className={styles["user-email"]}>{order?.recipient?.email || "--"}</div>
          </div>
        </div>
        {order?.address && (
          <div className={styles["user-div"]}>
            <div className={styles["address-title"]}>Address</div>
            <div className={styles["address-details"]}>{`${
              order?.address?.house_number || ""
            }, ${order?.address?.street || ""}, ${
              order?.address?.area || ""
            }, ${order?.address?.state || ""}`}</div>
          </div>
        )}
      </div>
      <div className={styles["inventory-div"]}>
        <FormGroupHeader
          title="Order Items"
          description="confirm items in this Order"
        />
        {order?.order_items?.map((item) => (
          <div className={styles["order-items"]}>
            <div className={styles["order-details"]}>
              {` ${item?.quantity}x ${
                item?.product?.title
              } at ${getSymbolFromCurrency(order?.currency)} ${
                item?.unit_price?.price
              }`}{" "}
              <span className={styles["mid-text"]}>each</span>
            </div>
            <h6 className={styles["order-price"]}>{`${
              order?.currency
            }${cashFormater(item?.unit_price?.price * item?.quantity)}`}</h6>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default FormLHS;
