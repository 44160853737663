import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { setIsLoading } from "../../../../redux/actions/appAction";
import { setInfluencers } from "../../../../redux/actions/discount.Action";
import {
  setIsDeleting,
  setPopupComponent,
  showPopup,
} from "../../../../redux/actions/popUp.action";
import { paths } from "../../../../Routes/path";
import discountServices from "../../../../Services/discount.services";
import DeleteModal from "../../../UI/DeleteModal";

function useInfluencer() {
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const navigate = useNavigate();
  const myObjectString = localStorage.getItem('user');

  const currentUser = JSON.parse(myObjectString)
  const currentUserAbilities = currentUser?.account_abilities?.abilities

  const reduxDispatch = useDispatch();
  const { influencers } = useSelector((state) => state.discount);

  const getInfluencers = useCallback(
    (page, perPage) => {
      setIsLoading(true)(reduxDispatch);
      discountServices
        .getInfluencers(page, perPage)
        .then(({ data }) => {
          setInfluencers(data?.data)(reduxDispatch);
          setTotalRows(data?.pagination?.total);
        })
        .catch((err) => {
          toast.error(err.message || "Unable to get");
        })
        .finally(() => setIsLoading(false)(reduxDispatch));
    },
    [reduxDispatch]
  );

  useEffect(() => {
    getInfluencers(1, perPage);
  }, [getInfluencers, perPage]);

  const handlePageChange = (page) => {
    getInfluencers(page, perPage);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setIsLoading(true)(reduxDispatch);
    discountServices
      .getInfluencers(page, newPerPage)
      .then(({ data }) => {
        setInfluencers(data?.data)(reduxDispatch);
        setPerPage(newPerPage);
        setTotalRows(data.pagination.total);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      })
      .finally(() => setIsLoading(false)(reduxDispatch));
  };

  const onClose = () => {
    showPopup({ ispopupOpen: false })(reduxDispatch);
  };

  const onAddInfluencer = () => {
    navigate(paths.newInfluencer)
  };

  const handleDelete = (influencerId) => {
    setIsDeleting(true)(reduxDispatch);
    discountServices
      .deleteInfluencer(influencerId)
      .then(() => {
        toast.success("Influencer was deleted");
        getInfluencers();
        onClose();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => {
        setIsDeleting(false)(reduxDispatch);
        navigate(paths.influencer)
      });
  };

  const onClickAction = (influencerId) => {
    reduxDispatch(
      setPopupComponent(
        <DeleteModal
          onClose={onClose}
          handleDelete={() => handleDelete(influencerId)}
          title="Influencer"
        />
      )
    );
    showPopup({ ispopupOpen: true, onClose: () => onClose() })(reduxDispatch);
  };

  return {
    handlePageChange,
    handlePerRowsChange,
    influencers,
    totalRows,
    onClickAction,
    onAddInfluencer,
    getInfluencers,
    currentUserAbilities,
  };
}

export default useInfluencer;
