import React from "react";
import Logo from "../../../Assets/Images/eve_after_dark 1.jpg";
import Button from "../../UI/Button/Button";
import { TextInput } from "../../UI/FormComponents/Input";
import useLogin from "./hooks/useLogin";
import styles from "./Login.module.scss";

function Login() {
  const { handleLogin, dispatch, state } = useLogin();
  return (
    <main className={styles.main}>
      <section className={styles["section-one"]}>
        <p>Hi, Welcome Back</p>
        <figure className={styles["img-box"]}>
          <img src={Logo} alt="EAD Logo" className={styles["logo-img"]} />
        </figure>
      </section>
      <section className={styles["section-two"]}>
        <p>Sign in to EAD Admin</p>
        <TextInput
          name="email"
          label="Email"
          value={state.email}
          onChange={({ target }) => dispatch({ email: target.value })}
        />
        <TextInput
          name="password"
          label="Password"
          type="password"
          value={state.password}
          onChange={({ target }) => dispatch({ password: target.value })}
        />
        {state.error && <span className={styles["error"]}>{state?.error}</span> }
        <div className={styles["btn-div"]}>
          <Button
            custom
            name="Login"
            bgColor="red-bg"
            createFnc={handleLogin}
            isLoading={state.isLoading}
          />
        </div>
      </section>
    </main>
  );
}

export default Login;
