import React from "react";
import fileIcon from "../../../../Assets/Images/RegularIcons/file-image.svg";
import styles from "./File.module.scss";

const File = (props) => {
  return (
    <div className={styles["form-group"]}>
      <div className={styles["form-container"]}>
        <span className="caption grey-500">{props.label}</span>
        <label className={styles["file-select"]}>
          <input
            type="file"
            name={props.name}
            onChange={(e) => props?.onSelectImage(e)}
            disabled={props.disabled}
          />
          <div className={styles["file-input-ui-container"]}>
            {props?.imgSrc ? (
              <div className={styles["selected-image"]}>
                <img src={props?.imgSrc} alt="product" />
              </div>
            ) : (
              <div className={styles["file-image"]}>
                <img src={fileIcon} alt="file icon" />
              </div>
            )}
            <div className={styles["file-image-description"]}>
              <h6 className={styles["file-input-heading"]}>
                {props?.imgSrc ? 'Change File' : 'Drop or Select File'}
              </h6>
              <span className="caption grey-500">
               {props?.imgName ? props?.imgName : 'Drop files here or select a file from your file explorer' }
              </span>
            </div>
          </div>
        </label>
      </div>
    </div>
  );
};

export default File;
