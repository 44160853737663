import React, { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { setIsLoading } from "../../../../redux/actions/appAction";
import {
  setIsDeleting,
  setPopupComponent,
  showPopup,
} from "../../../../redux/actions/popUp.action";
import { setStaffs } from "../../../../redux/actions/staffAction";
import { paths } from "../../../../Routes/path";
import staffServices from "../../../../Services/staff.services";
import DeleteModal from "../../../UI/DeleteModal";

function useStaff() {
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();

  const myObjectString = localStorage.getItem("user");

  const currentUser = JSON.parse(myObjectString);
  const currentUserAbilities = currentUser?.account_abilities?.abilities;

  const getStaffs = useCallback(() => {
    setIsLoading(true)(reduxDispatch);
    staffServices
      .getStaffs()
      .then(({ data }) => {
        setStaffs(data)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      })
      .finally(() => setIsLoading(false)(reduxDispatch));
  }, [reduxDispatch]);

  useEffect(() => {
    getStaffs();
  }, [getStaffs]);

  const onClose = () => {
    showPopup({ ispopupOpen: false })(reduxDispatch);
  };

  const handleDelete = (staffId) => {
    setIsDeleting(true)(reduxDispatch);
    staffServices
      .deleteStaff(staffId)
      .then(() => {
        toast.success("Staff was deleted");
        getStaffs();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => {
        onClose();
        navigate(paths.staff);
        setIsDeleting(false)(reduxDispatch);
      });
  };

  const onClickAction = (staffId) => {
    reduxDispatch(
      setPopupComponent(
        <DeleteModal
          onClose={onClose}
          handleDelete={() => handleDelete(staffId)}
          title="Staff"
        />
      )
    );
    showPopup({ ispopupOpen: true, onClose: () => onClose() })(reduxDispatch);
  };
  return {
    onClickAction,
    currentUserAbilities,
  };
}

export default useStaff;
