import React from 'react'
import { MetaTags } from 'react-meta-tags'
import Grid from '../../UI/Grid/Grid';
import GridRow from '../../UI/Grid/GridRow';
import TopDisplay from '../../UI/TopDisplay/TopDisplay'
import styles from "./Vouchers.module.scss";
import DTable from "./component/DTable/DTable"
import useVoucher from './hooks/useVoucher';


function Vouchers() {
    const { 
      handlePageChange,
      handlePerRowsChange,
      vouchers,
      totalRows,
      onClickAction,
      onAddVoucher,
      currentUserAbilities
     } = useVoucher();
  return (
    <>
    <MetaTags>
      <title>Voucher Page</title>
      <meta name="description" content="#" />
    </MetaTags>
    <div>
        <TopDisplay
          title="Voucher"
          firstText="Discount"
          secondText="Voucher"
          thirdText="Voucher"
          style2
        />
        <div className={styles.section2}>
          <Grid span="9">
            <GridRow span="9" customClass="section2">
              <DTable
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
                vouchers={vouchers}
                totalRows={totalRows}
                onClickAction={onClickAction}
                onAddVoucher={onAddVoucher}
                currentUserAbilities={currentUserAbilities}
              />
            </GridRow>
          </Grid>
        </div>
    </div>
    </>
  )
}

export default Vouchers