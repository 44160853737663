import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { setIsLoading } from "../../../../redux/actions/appAction";
import {
  setInventories,
} from "../../../../redux/actions/inventory.action";
import { setIsDeleting, setPopupComponent, showPopup } from "../../../../redux/actions/popUp.action";
import { paths } from "../../../../Routes/path";
import inventoriesServices from "../../../../Services/inventories.services";
import DeleteModal from "../../../UI/DeleteModal";
import { setWarehouseRegion } from "../../../../redux/actions/location.action";
import kitchenServices from "../../../../Services/kitchen.services";

function useInventories() {
  const {
    inventories,
  } = useSelector((state) => state.inventory);

  const {
    warehouseRegion: kitchen,
  } = useSelector((state) => state.location);

  const { openModal } = useSelector((state) => state.app);
  const {kitchenId} = useParams();
  const location = useLocation();
  const myObjectString = localStorage.getItem("user");

  const currentUser = JSON.parse(myObjectString);
  const currentUserAbilities = currentUser?.account_abilities?.abilities?.["inventory"];
  const locationRestricted = currentUser?.restricted_by_location;
  const kitchenRestricted = currentUser?.restricted_by_kitchen;

  const reduxDispatch = useDispatch();


  const navigate = useNavigate();

  const getAllKitchen = useCallback(() => {
    kitchenServices
      .getKitchens()
      .then(({ data }) => {
        let regionName = data?.filter(
          (item) => +item?.id === +kitchenId
        );
        setWarehouseRegion(regionName[0])(reduxDispatch);
      })
      .catch((err) => {
        // toast.error(err.message || "Unable to get");
      });
  }, [reduxDispatch, kitchenId]);

  const getInventories = useCallback(() => {
    setIsLoading(true)(reduxDispatch);
    if (location.pathname.startsWith("/kitchin")) {
      inventoriesServices
      .getLocationInventories(kitchenId)
      .then(({ data }) => {
        setInventories(data?.products)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      })
      .finally(() => setIsLoading(false)(reduxDispatch));
    } else {
    inventoriesServices
      .getInventories()
      .then(({ data }) => {
        setInventories(data)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      })
      .finally(() => setIsLoading(false)(reduxDispatch));
    }
  }, [kitchenId, location.pathname, reduxDispatch]);

  useEffect(() => {
    getAllKitchen()
  }, [getAllKitchen])

  useEffect(() => getInventories(), [getInventories]);

  const onClose = () => {
    showPopup({ ispopupOpen: false })(reduxDispatch);
  };

  const handleDelete = (inventoryId) => {
    setIsDeleting(true)(reduxDispatch);
    inventoriesServices
      .deleteInventory(inventoryId)
      .then(() => {
        toast.success("Inventory was deleted");
        getInventories();
        onClose();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => {
        setIsDeleting(false)(reduxDispatch)
        navigate(paths.inventory)
      });
  };

  const onClickAction = (inventoryId) => {
    reduxDispatch(
      setPopupComponent(
        <DeleteModal
          onClose={onClose}
          handleDelete={() => handleDelete(inventoryId)}
          title="Inventory"
        />
      )
    );
    showPopup({ ispopupOpen: true, onClose: () => onClose() })(reduxDispatch);
  };

  return {
    inventories,
    onClickAction,
    openModal,
    handleDelete,
    onClose,
    currentUserAbilities,
    kitchen,
    kitchenId,
    locationRestricted,
    kitchenRestricted,
  };
}

export default useInventories;
