import {
  IS_LOADING,
  SET_OPEN_MODAL,
  SET_DELETE,
  SET_OPEN_ADD_MODAL,
  SET_OPEN_EDIT_MODAL,
} from "../actionTypes/appActionType";

const initialState = {
  isLoading: false,
  openModal: false,
  openAddModal: false,
  openEditModal: false,
  onDelete: false,
};

export default function appReducer(state = initialState, action) {
  const { data, type } = action;
  switch (type) {
    case IS_LOADING:
      return {
        ...state,
        isLoading: data,
      };
    case SET_OPEN_MODAL:
      return {
        ...state,
        openModal: data,
      };
    case SET_OPEN_ADD_MODAL:
      return {
        ...state,
        openAddModal: data,
      };
    case SET_OPEN_EDIT_MODAL:
      return {
        ...state,
        openEditModal: data,
      };
    case SET_DELETE:
      return {
        ...state,
        onDelete: data,
      };
    default:
      return state;
  }
}
