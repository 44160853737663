import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { paths } from '../../../../../../Routes/path';

function useDTable( onClickAction, currentUserAbilities ) {
    const [openModal, setOpenModal] = useState(false);
    const [selected, setSelected] = useState(null);
    const onClick = (value) => {
      setOpenModal(true);
      setSelected(value);
    };
    const { departments } = useSelector(
      (state) => state.department
    );
    const isLoading = useSelector(state => state.app.isLoading) 
    const navigate = useNavigate();

    const update = currentUserAbilities["admin"]?.update
    const create = currentUserAbilities["admin"]?.create
    const show = currentUserAbilities["admin"]?.show

    const del = currentUserAbilities["admin"]?.destroy
  
    const handleEdit = (departmentId) => {
      navigate(paths.editDepartment.replace(':departmentId', departmentId))
    };
  
    const onDelete = (departmentId) => {
        onClickAction(departmentId)
    };

  return {
    onDelete,
    handleEdit,
    onClick,
    selected,
    openModal,
    setOpenModal,
    isLoading,
    departments,
    del,
    update,
    create,
    show,
  }
}

export default useDTable