import React from "react";
import styles from "./Select.module.scss";

const Select = (props) => {
  return (
    <div className={styles["form-group"]}>
      <div className={styles["form-container"]}>
        <select
          className={styles["form-select"]}
          name={props?.name}
          value={props?.value}
          placeholder=" "
          onChange={props?.onChange}
          disabled={props?.disabled}
        >
          <option value="" disabled selected>
            Select {props?.label}
          </option>
          {props.custom
            ? Array.isArray(props?.options) && props?.options.map((option, index) => {
                return (
                  <option key={index} value={option?.id}>
                    {option?.house_number} {option?.street} {option?.area} {option?.state}
                  </option>
                );
              })
            : Array.isArray(props?.options) && props?.options.map((option, index) => {
                return (
                  <option
                    key={index}
                    value={option?.id || index}
                    data-unit={option?.measure || option?.country}
                  >
                    {option?.name || option?.state || option?.title}
                  </option>
                );
              })}
          {props.custom && (
            <option className={styles["addBtn"]} value="addBtn">
              Add Missing Address
            </option>
          )}
        </select>
        <label htmlFor={props?.name}>{props?.label}</label>
      </div>
    </div>
  );
};

export default Select;
