import { useCallback, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setIsLoading } from "../../../../../../redux/actions/appAction";
import {
  setCities,
  setCountries,
  setStates,
} from "../../../../../../redux/actions/location.action";
import { setUserAddress } from "../../../../../../redux/actions/order.action";
import deliveryZonesServices from "../../../../../../Services/deliveryZones.services";
import locationServices from "../../../../../../Services/location.services";
import ordersServices from "../../../../../../Services/orders.services";
import { objectToArray } from "../../../../../../utils/helpers";

function useAddAddress(customer, onClosePage) {
  const { countries, cities, states } = useSelector((state) => state.location);
  const initState = {
    countryId: null,
    stateId: null,
    cityId: null,
    latitude: "",
    longitude: "",
    houseNumber: "",
    street: "",
    isLoading: false,
  };
  const [state, dispatch] = useReducer(
    (addressState, value) => ({ ...addressState, ...value }),
    initState
  );
  const { countryId, stateId } = state;
  const reduxDispatch = useDispatch();
  const getCountries = useCallback(() => {
    setIsLoading(true)(reduxDispatch);
    locationServices
      .getAllLocations()
      .then(({ data }) => {
        let valuesArr = data.map((item) => ({
          name: item.country_data?.data?.translated_names[0],
          id: item.id,
        }));
        setCountries(valuesArr)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      })
      .finally(() => setIsLoading(false)(reduxDispatch));
  }, [reduxDispatch]);

  const getAllRegions = useCallback(() => {
    locationServices
      .getAllRegions()
      .then(({ data }) => {
        let regionsAvail = data?.filter(
          (item) => item?.location_id === countryId
        );
        let valuesArr = regionsAvail.map((item) => ({
          name: item.state,
          id: item.id,
        }));
        setStates(valuesArr)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
  }, [countryId, reduxDispatch]);

  const getCities = useCallback(() => {
    deliveryZonesServices
      .getDeliveryZones()
      .then(({ data }) => {
        let regionsAvail = data?.filter(
          (item) => item?.region_details?.id === +stateId
        );
        let valuesArr = regionsAvail.map((item) => ({
          name: item.name,
          id: item.id,
        }));
        setCities(valuesArr)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
  }, [reduxDispatch, stateId]);

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  useEffect(() => {
    if (countryId) getAllRegions();
  }, [countryId, getAllRegions]);

  const getUserAddresses = useCallback(() => {
    setIsLoading(true)(reduxDispatch);
    ordersServices
      .getUserAddresses(customer)
      .then(({ data }) => {
        setUserAddress(data)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      })
      .finally(() => setIsLoading(false)(reduxDispatch));
  }, [reduxDispatch, customer]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!state.countryId) {
      toast.error("Select a Country");
      return;
    }
    if (!state.stateId) {
      toast.error("Select a State");
      return;
    }
    if (!state.cityId) {
      toast.error("Select a LGA");
      return;
    }
    if (!state.street) {
      toast.error("Street is required");
      return;
    }
    if (!state.latitude && !state.longitude) {
      toast.error("Latitude & Longitude are required");
      return;
    }

    dispatch({ isLoading: true });
    ordersServices
      .createUserAddress(customer, {
        address: {
          house_number: state.houseNumber,
          street: state.street,
          delivery_zone_id: state.cityId,
          location_id: state.countryId,
          available_region_id: state.stateId,
          longitude: state.longitude,
          latitude: state.latitude,
        },
      })
      .then(() => {
        toast.success("Address was succesfully added");
        onClosePage();
        getUserAddresses();
      })
      .catch((err) => {
        dispatch({ isLoading: false });
        if (err.response.status === 422) {
          let errorMsg = objectToArray(err?.response?.data?.data);
          toast.error(`${errorMsg[0][0]} ${errorMsg[0][1][0]}`);
        } else {
          toast.error(err.message);
        }
      });
  };

  useEffect(() => {
    if (stateId) getCities();
  }, [stateId, getCities]);

  return {
    countries,
    state,
    dispatch,
    states,
    cities,
    handleSubmit,
  };
}

export default useAddAddress;
