import React from "react";
import Modal from "../../../../UI/Modal/Modal";
import { ActionMenuIcon } from "../../../../UI/RegularIcons";
import Tables from "../../../../UI/Tables/Tables";
import styles from "./DTable.module.scss";
import useDTable from "./hooks/useDTable";
import { camelToNormalCase } from "../../../../../utils/helpers";

function DTable({
  handlePageChange,
  handlePerRowsChange,
  orders,
  totalRows,
  onClickAction,
  secondRowFcn,
  secondRowAllFcn,
  orderStatus,
  totalOrders,
  currentUserAbilities,
  handleKitchen,
  getOrderStatus,
}) {
  const {
    isLoading,
    handleProcess,
    onClick,
    selected,
    openModal,
    setOpenModal,
    handleView,
    onRowClicked,
    update,
    show,
  } = useDTable(onClickAction, currentUserAbilities, getOrderStatus);

  const columns = [
    {
      name: "Reference",
      selector: (row) => row.reference,
      sortable: true,
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      name: "Name",
      selector: (row) => row.recipient.full_name,
      sortable: true,
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      name: "Status",
      selector: (row) => camelToNormalCase(row.status),
      sortable: true,
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      selector: (row, i) => (
        <>
          <div className={styles["circle-icon"]} onClick={() => onClick(i)}>
            <ActionMenuIcon />
          </div>
          {openModal && i === selected && update && (
            <Modal
              style2
              openModal={openModal}
              nextStep={row?.actions?.next_action?.text}
              onClose={() => setOpenModal(false)}
              viewFn={() => handleView(row?.reference, row?.id)}
              editFn={() => handleProcess(row?.actions)}
              assignFn={() => handleKitchen(row?.id, row?.reference)}
            />
          )}
        </>
      ),
      sortable: true,
      width: "5%",
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
  ];
  return (
    <div className={styles.divv}>
      <Tables
        data={orders}
        columns={columns}
        pagination
        title="Manage Orders"
        firstRow
        fetchingData={isLoading}
        paginationServer
        secondRowData={orderStatus}
        secondRow
        handlePageChange={handlePageChange}
        handlePerRowsChange={handlePerRowsChange}
        totalRows={totalRows}
        type="manageOrder"
        allLength={totalOrders}
        secondRowFcn={secondRowFcn}
        secondRowAllFcn={secondRowAllFcn}
        onRowClicked={show ? (row) => onRowClicked(row) : () => {}}
      />
    </div>
  );
}

export default DTable;
