import { SET_CATEGORIES, SET_CATEGORY, SET_CATEGORY_ID } from '../actionTypes/categoriesActionType';

export function setCategories(data) {
  return dispatch => {
    dispatch({ type: SET_CATEGORIES, data });
  };
}

export function setCategory(data) {
    return dispatch => {
      dispatch({ type: SET_CATEGORY, data });
    };
}

export function setCategoryId(data) {
    return dispatch => {
      dispatch({ type: SET_CATEGORY_ID, data });
    };
}


  