import { useCallback, useEffect, useState } from "react";
import { setOrderPayments } from "../../../../../../redux/actions/order.action";
import { toast } from "react-toastify";
import ordersServices from "../../../../../../Services/orders.services";
import { useDispatch, useSelector } from "react-redux";

function useViewPayments(orderId, onClose) {
  const payments = {
    order: "Main Payment for Order",
    refresh: "Order Refresh Payment",
    extras: "Extra Collected Payments",
  };

  const { orderPayments } = useSelector((state) => state.order);
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState(null);

  const onClick = (event, value) => {
    setOpenModal(true);
    event.stopPropagation();
    setSelected(value);
  };
  const reduxDispatch = useDispatch();
  const getOrderPayments = useCallback(() => {
    ordersServices
      .getOrderPayments(orderId)
      .then(({ data }) => {
        setOrderPayments(data)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err);
      });
  }, [orderId, reduxDispatch]);

  useEffect(() => {
    getOrderPayments();
  }, [getOrderPayments]);

  const verifyAuto = (event, paymentId) => {
    event.preventDefault();
    console.log("verify auto");
    ordersServices
      .verifyAPayment(orderId, paymentId)
      .then((res) => {
        toast.success(res.message);
        onClose();
      })
      .catch((err) => {
        if (err.response.status === 402) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.response);
        }
      });
  };

  return {
    openModal,
    onClick,
    setOpenModal,
    verifyAuto,
    orderPayments,
    payments,
    selected,
  };
}

export default useViewPayments;
