import React from "react";
import { useLocation, useNavigate } from "react-router";
import { paths } from "../../../../../Routes/path";
import AddItem from "../../../../UI/AddItem/AddItem";
import BulkUpload from "../../../../UI/BulkUpload/BulkUpload";
import Modal from "../../../../UI/Modal/Modal";
import {
  ActionMenuIcon,
  EditIcon,
  PlusIcon,
} from "../../../../UI/RegularIcons";
import Tables from "../../../../UI/Tables/Tables";
import styles from "./DTable.module.scss";
import useDTable from "./hooks/useDTable";
import Button from "../../../../UI/Button/Button";

function DTable({ stocks, onClickAction, currentUserAbilities }) {
  const {
    onDelete,
    isLoading,
    handleEdit,
    onClick,
    selected,
    openModal,
    setOpenModal,
    del,
    update,
    create,
    addStock,
    manualStock,
    onSelectImage,
    downloadStocks,
    isDownloading,
    sideBarType,
  } = useDTable(onClickAction, currentUserAbilities);
  const location = useLocation();
  const columns = [
    {
      name: "Code",
      selector: (row) => row.code,
      sortable: true,
      width: location.pathname.startsWith("/kitchin") ? "12%" : "17%",
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: location.pathname.startsWith("/kitchin") ? "25%" : "40%",
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      name: "State",
      selector: (row) => row?.state,
      sortable: true,
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    location.pathname.startsWith("/kitchin") && {
      name: <span>Total Available</span>,
      width: "50%",
      selector: (row, i) => (
        <>
          <div className={styles["inventory"]}>
            <div className={styles["qty-div"]}>
              {row?.related_quantity} {row?.measure}
            </div>
            <div className={styles["buttons"]}>
              <div
                className={styles["add-inventory"]}
                onClick={() => addStock(row?.id)}
              >
                <span className={styles["plus-icon"]}>
                  <PlusIcon />
                </span>
                <span className={styles["edit-text"]}>Add to Stock</span>
              </div>
              {!sideBarType && (
                <div className={styles["reset-inventory"]}>
                  <span className={styles["edit-icon"]}>
                    <EditIcon />
                  </span>
                  <span
                    className={styles["reset-text"]}
                    onClick={() => manualStock(row?.id)}
                  >
                    Change Manually
                  </span>
                </div>
              )}
            </div>
          </div>
        </>
      ),
      sortable: true,
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    !location.pathname.startsWith("/kitchin") && {
      selector: (row, i) => (
        <>
          <div className={styles["circle-icon"]} onClick={() => onClick(i)}>
            <ActionMenuIcon />
          </div>
          {openModal && i === selected && (
            <Modal
              openModal={openModal}
              onClose={() => setOpenModal(false)}
              editFn={() => handleEdit(row?.id)}
              deleteFn={() => onDelete(row?.id, row?.name)}
              del={del}
              update={update}
            />
          )}
        </>
      ),
      sortable: true,
      width: "4%",
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
  ];

  const navigate = useNavigate();
  return (
    <div className={styles.divv}>
      <Tables
        data={stocks}
        columns={columns}
        pagination
        title="All Stocks"
        type="orders"
        atcBtn={
          create && !location.pathname.startsWith("/kitchin") ? (
            <AddItem name="Stock" addFnc={() => navigate(paths.newStock)} />
          ) : (
            ""
          )
        }
        atcBtn2={
          <div className={styles["second-field"]}>
            {location.pathname.startsWith("/kitchin") && (
              <div className={styles.btnExport}>
                {" "}
                <Button
                  name="Export To CSV"
                  bgColor="black-bg"
                  createFnc={downloadStocks}
                  isLoading={isDownloading}
                  custom
                />
              </div>
            )}
            <div className={styles.searchBar}>
              <BulkUpload name="Stocks" createFn={onSelectImage} />
            </div>
          </div>
        }
        firstRow
        selectableRows
        fetchingData={isLoading}
        onRowClicked={() => {}}
      />
    </div>
  );
}

export default DTable;
