import React from "react";
import MetaTags from "react-meta-tags";
import Grid from "../../UI/Grid/Grid";
import GridRow from "../../UI/Grid/GridRow";
import Popup from "../../UI/Popup/Popup";
import TopDisplay from "../../UI/TopDisplay/TopDisplay";
import ErrorPage from "../ErrorPage/ErrorPage";
import DTable from "./component/DTable/DTable";
import useProducts from "./hooks/useProduct";
import styles from "./Products.module.scss";

function Products() {
  const {
    products,
    onClickAction,
    categories,
    secondRowFcn,
    secondRowAllFcn,
    openModal,
    handleDelete,
    onClose,
    isDeleting,
    currentUserAbilities,
  } = useProducts();
  return (
    <>
      <MetaTags>
        <title>Product Page</title>
        <meta name="description" content="#" />
      </MetaTags>
      {currentUserAbilities?.index ? (
        <div className={styles.main}>
          {openModal && (
            <Popup
              onClose={onClose}
              handleDelete={handleDelete}
              title="Product"
              isLoading={isDeleting}
            />
          )}
          <TopDisplay
            title="Products"
            firstText="Dashboard"
            secondText="Kitchen"
            thirdText="Products"
            style2
          />
          <div className={styles.section2}>
            <Grid span="9">
              <GridRow span="9" customClass="section2">
                <DTable
                  products={products}
                  onClickAction={onClickAction}
                  categories={categories}
                  secondRowFcn={secondRowFcn}
                  secondRowAllFcn={secondRowAllFcn}
                  currentUserAbilities={currentUserAbilities}
                />
              </GridRow>
            </Grid>
          </div>
        </div>
      ) : (
        <ErrorPage type2 />
      )}
    </>
  );
}

export default Products;
