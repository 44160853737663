import React from "react";
import Card from "../../../../UI/Card/Card";
import { TextInput, NumberInput } from "../../../../UI/FormComponents/Input";
import PriceSetHeader from "../../../../UI/FormComponents/FormHeaders/PriceSetHeader";
import styles from "../../AddProductForm.module.scss";
import Button from "../../../../UI/Button/Button";
import getSymbolFromCurrency from "currency-symbol-map";

const FormRHS = ({
  edit,
  state,
  handleSubmit,
  dispatch,
}) => {
  const { productCode, isLoading } = state;

  const onBlurHandler = (e, id) => {
    let cost_price = e.target.value;
    let price = state?.productPrices?.find(
      (item) => item?.available_region_id === id
    );
    price[e.target.name] = +cost_price;
  };

  return (
    <>
      <Card classes={styles["form-3grid-main"]}>
        <TextInput
          name="product_code"
          label="Product Code"
          type="text"
          value={productCode}
          onChange={({ target }) => dispatch({ productCode: target.value })}
        />
      </Card>
      {state?.productPrices?.map((location, index) => {
        return (
          <Card
            key={index}
            classes={`${styles["form-3grid-main"]} ${styles["cards"]} price-set-card`}
          >
            <PriceSetHeader title={`${location?.region_name} Prices`} />
            <NumberInput
              name="cost_price"
              label="Cost Price"
              units={getSymbolFromCurrency(location?.currency)}
              value={location?.cost_price}
              onChange={(e) => onBlurHandler(e, location?.available_region_id)}
            />
            <NumberInput
              name="price"
              label="Price"
              units={getSymbolFromCurrency(location?.currency)}
              value={location?.price}
              onChange={(e) => onBlurHandler(e, location?.available_region_id)}
            />
          </Card>
        );
      })}
      <div className={styles.cards}>
        <Button
          name="Product"
          bgColor="green-bg"
          createFnc={handleSubmit}
          edit={edit}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default FormRHS;
