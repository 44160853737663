import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setIsLoading } from "../../../../redux/actions/appAction";
import { setReadyOrders } from "../../../../redux/actions/ridersAction";
import ordersServices from "../../../../Services/orders.services";

function useManageDelivery() {
  const reduxDispatch = useDispatch();
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [activeStatus, setActiveStatus] = useState("packaged")
  const { readyOrders } = useSelector((state) => state?.rider);

  const orderStatus = [
    {
      status: "packaged",
      count: "",
      id: "packaged",
    },
    {
      status: "delivering",
      count: "",
      id: "delivering",
    }
    ]

  const getReadyOrders = useCallback((page, perPage) => {
    setIsLoading(true)(reduxDispatch);
    ordersServices
      .getFilteredOrder("packaged", page, perPage)
      .then(({ data }) => {
        setReadyOrders(data.data)(reduxDispatch);
        setTotalRows(data.pagination.total);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      })
      .finally(() => setIsLoading(false)(reduxDispatch));
  }, [reduxDispatch])

  const getFilteredOrders = useCallback(
    (orderStatus, page, perPage) => {
      setIsLoading(true)(reduxDispatch);
      ordersServices
        .getFilteredOrder(orderStatus, page, perPage)
        .then(({ data }) => {
          setReadyOrders(data.data)(reduxDispatch);
          setTotalRows(data.pagination.total);
        })
        .catch((err) => {
          toast.error(err.message || "Unable to get");
        })
        .finally(() => setIsLoading(false)(reduxDispatch));
    },
    [reduxDispatch]
  );

  useEffect(() => {
    getReadyOrders(1, perPage);
  }, [getReadyOrders, perPage]);

  const handlePageChange = (page) => {
    getReadyOrders(page, perPage);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setIsLoading(true)(reduxDispatch);
    ordersServices
      .getFilteredOrder(activeStatus, page, newPerPage)
      .then(({ data }) => {
        setReadyOrders(data)(reduxDispatch);
        setPerPage(newPerPage);
        setTotalRows(data.pagination.total);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      })
      .finally(() => setIsLoading(false)(reduxDispatch));
  };

  const secondRowFcn = (orderStatus) => {
    getFilteredOrders(orderStatus, 1, perPage);
    setActiveStatus(orderStatus)
  };

  return {
    readyOrders,
    handlePageChange,
    handlePerRowsChange,
    totalRows,
    secondRowFcn,
    orderStatus,
  };
}

export default useManageDelivery;
