import {
  SET_KITCHEN,
  SET_KITCHEN_LOCATIONS,
  SET_KITCHEN_REGIONS,
  SET_KITCHEN_ZONES,
} from "../actionTypes/kitchenActionTypes";

const initialState = {
  kitchenLocations: [],
  kitchenZones: [],
  kitchen: [],
  kitchenRegions: [],
};

export default function kitchenReducer(state = initialState, action) {
  const { data, type } = action;
  switch (type) {
    case SET_KITCHEN_LOCATIONS:
      return {
        ...state,
        kitchenLocations: data,
      };
    case SET_KITCHEN_REGIONS:
      return {
        ...state,
        kitchenRegions: data,
      };
    case SET_KITCHEN_ZONES:
      return {
        ...state,
        kitchenZones: data,
      };
    case SET_KITCHEN:
      return {
        ...state,
        kitchen: data,
      };
    default:
      return state;
  }
}
