import {
  SET_STOCK,
  SET_STOCKS,
  SET_STOCK_ID,
  SET_STOCK_ITEMS,
} from "../actionTypes/stockActionType";

const initialState = {
  stockItems: [
    {
      stock_name: null,
      quantity: null,
      unit: "",
    },
  ],
  stocks: [],
  stock: [],
  stockId: null,
};

export default function stockReducer(state = initialState, action) {
  const { data, type } = action;
  switch (type) {
    case SET_STOCK_ITEMS:
      return {
        ...state,
        stockItems: data,
      };
    case SET_STOCKS:
      return {
        ...state,
        stocks: data,
      };
    case SET_STOCK:
      return {
        ...state,
        stock: data,
      };
    case SET_STOCK_ID:
      return {
        ...state,
        stockId: data,
      };
    default:
      return state;
  }
}
