import React from "react";
import Modal from "../../../../../UI/Modal/Modal";
import styles from "../DTable.module.scss";
import { ActionMenuIcon } from "../../../../../UI/RegularIcons";
import { useState } from "react";
import { useNavigate } from "react-router";
import { cashFormater } from "../../../../../../utils/helpers";
import { useSelector } from "react-redux";
import { paths } from "../../../../../../Routes/path";
import { LazyLoadImage } from "react-lazy-load-image-component";

function useDTable(onClickAction, currentUserAbilities) {
  const isLoading = useSelector((state) => state.app.isLoading);
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const onClick = (value) => {
    setOpenModal(true);
    setSelected(value);
  };
  const navigate = useNavigate();
  const handleEdit = (categoryId) => {
    navigate(paths.editCategory.replace(":categoryId", categoryId));
  };

  const onDelete = (categoryId) => {
    onClickAction(categoryId);
  };

  const update = currentUserAbilities?.update;
  const create = currentUserAbilities?.create;

  const del = currentUserAbilities?.destroy;

  const columns = [
    {
      selector: (row) => (
        <div className={styles.imageDiv}>
          <LazyLoadImage
            src={row?.image?.url}
            alt={row?.name}
            width={23}
            height={23}
          />
        </div>
      ),
      width: "7%",
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      name: "Name",
      selector: (row) => row?.name,
      sortable: true,
      width: "70%",
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      name: <span>Total Products</span>,
      selector: (row) => cashFormater(row?.product_count),
      sortable: true,
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      selector: (row, i) => (
        <>
          <div className={styles["circle-icon"]} onClick={() => onClick(i)}>
            <ActionMenuIcon />
          </div>
          {openModal && i === selected && (
            <Modal
              openModal={openModal}
              onClose={() => setOpenModal(false)}
              editFn={() => handleEdit(row?.id)}
              deleteFn={() => onDelete(row?.id, row?.name)}
              del={del}
              update={update}
            />
          )}
        </>
      ),
      sortable: true,
      width: "5%",
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
  ];
  return {
    columns,
    isLoading,
    create,
  };
}

export default useDTable;
