import React from "react";
import MetaTags from "react-meta-tags";
import Grid from "../../UI/Grid/Grid";
import GridRow from "../../UI/Grid/GridRow";
import FormLHS from "./component/FormGroups/FormLHS";
import TopDisplay from "../../UI/TopDisplay/TopDisplay";
import styles from "./AddDepartmentForm.module.scss";
import areastyles from "./Departments.module.scss";
import useManageDepartment from "./hooks/useManageDepartment";

const AddDepartmentForm = ({ edit }) => {
  const { state, dispatch, handleSubmit } = useManageDepartment(edit);
  return (
    <>
      <MetaTags>
        <title>Add Department Page</title>
        <meta name="description" content="#" />
      </MetaTags>
      <div className={areastyles.main}>
        <TopDisplay
          title={edit ? "Update Department" : "Add New Department"}
          firstText="Management"
          secondText="Department"
          thirdText={edit ? "Update Department" : "Add New Department"}
          style2
        />
        <div className={styles["form-main"]}>
          <Grid span="9">
            <GridRow span="6" customClass="section2">
              <FormLHS
                edit={edit}
                state={state}
                handleSubmit={handleSubmit}
                dispatch={dispatch}
              />
            </GridRow>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default AddDepartmentForm;
