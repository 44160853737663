import React from "react";
import { useNavigate } from "react-router";
import { paths } from "../../../../../Routes/path";
import AddItem from "../../../../UI/AddItem/AddItem";
import Tables from "../../../../UI/Tables/Tables";
import styles from "./DTable.module.scss";
import useDTable from "./hooks/useDTable";

function DTable({ categories, onClickAction, currentUserAbilities }) {
  const navigate = useNavigate();
  const { columns, isLoading, create } = useDTable(onClickAction, currentUserAbilities);


  return (
    <div className={styles.divv}>
      <Tables
        data={categories}
        columns={columns}
        title="All Categories"
        atcBtn={
          create ? 
          <AddItem
            name="Category"
            addFnc={() => navigate(paths.newCategory)}
          /> : ""
        }
        firstRow
        selectableRows
        pagination
        fetchingData={isLoading}
        onRowClicked={() => {}}
      />
    </div>
  );
}

export default DTable;
