import React from "react";
import { useNavigate } from "react-router";
import { paths } from "../../../../../Routes/path";
import AddItem from "../../../../UI/AddItem/AddItem";
import Modal from "../../../../UI/Modal/Modal";
import { ActionMenuIcon } from "../../../../UI/RegularIcons";
import Tables from "../../../../UI/Tables/Tables";
import styles from "./DTable.module.scss";
import useDTable from "./hooks/useDTable";

function DTable({
  products,
  onClickAction,
  categories,
  secondRowFcn,
  secondRowAllFcn,
  currentUserAbilities,
}) {
  const {
    onDelete,
    zoneByRegion,
    isLoading,
    handleEdit,
    onClick,
    selected,
    openModal,
    setOpenModal,
    del,
    update,
    create,
  } = useDTable(onClickAction, currentUserAbilities);
  const columns = [
    {
      name: "Code",
      selector: (row) => row?.code,
      sortable: true,
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      name: "Name",
      selector: (row) => row?.title,
      sortable: true,
      width: "30%",
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      name: "Description",
      selector: (row) => row?.description,
      sortable: true,
      width: "40%",
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
        width: "100px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    {
      selector: (row, i) => (
        <>
          <div className={styles["circle-icon"]} onClick={() => onClick(i)}>
            <ActionMenuIcon />
          </div>
          {openModal && i === selected && (
            <Modal
              openModal={openModal}
              onClose={() => setOpenModal(false)}
              editFn={() => handleEdit(row?.id)}
              deleteFn={() => onDelete(row?.id, row?.name)}
              del={del}
              update={update}
            />
          )}
        </>
      ),
      sortable: true,
      width: "5%",
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
  ];

  const navigate = useNavigate();
  return (
    <div className={styles.divv}>
      <Tables
        data={products}
        columns={columns}
        pagination
        title="All Products"
        atcBtn={
          create ? (
            <AddItem name="Product" addFnc={() => navigate(paths.newProduct)} />
          ) : (
            ""
          )
        }
        firstRow
        selectableRows
        secondRowData={categories}
        secondRow
        secondRowFcn={secondRowFcn}
        secondRowAllFcn={secondRowAllFcn}
        type="products"
        fetchingData={isLoading}
        allLength={zoneByRegion?.length}
        typeCount="product_count"
        onRowClicked={() => {}}
      />
    </div>
  );
}

export default DTable;
