import React from "react";
import Card from "../../../../UI/Card/Card";
import {
  NumberInput,
  SelectInput,
  TextInput,
} from "../../../../UI/FormComponents/Input";
import ToggleSwitch from "../../../../UI/FormComponents/InputTypes/ToggleSwitch";

import styles from "../../AddStockForm.module.scss";
import Button from "../../../../UI/Button/Button";
import { dateDurations } from "../../../../../utils/constant";

const FormRHS = ({ edit, state, handleSubmit, dispatch }) => {
  const { expires, period, periodLength } = state;
  return (
    <>
      <Card classes={styles["form-3grid-main"]}>
        <TextInput
          name="code"
          label="Stock Simple Code"
          type="text"
          value={state?.code}
          onChange={({ target }) => dispatch({ code: target.value })}
        />
        <div>
          <ToggleSwitch
            selected={expires}
            toggleSelected={() => {
              dispatch({ expires: !expires });
            }}
            title="Expires"
          />
          { expires &&
            <div className={styles.cardss}>
              <div className={styles["cardss-div1"]}>
              <SelectInput
                name="date_duration"
                label="Date Duration"
                options={dateDurations}
                value={period}
                onChange={(e) => dispatch({ period: e.target.value })}
              />
              </div>

              <div className={styles["cardss-div2"]}>
              <NumberInput
                name="expiry date"
                label="expiry date"
                type="number"
                integer
                value={periodLength}
                onChange={(e) => dispatch({ periodLength: e.target.value })}
              />
              </div>
            </div>
          }
        </div>
      </Card>
      <div className={styles.cards}>
        <Button
          name="Stock"
          bgColor="green-bg"
          edit={edit}
          createFnc={handleSubmit}
        />
      </div>
    </>
  );
};

export default FormRHS;
