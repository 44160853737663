export const initState = {
    title: "",
    description: "",
    address: "none", // read or none.. view
    admin: "none",
    available_region: "none",
    category: "none",
    delivery_zone: "none",
    general_review: "none",
    inventory: "none",
    kitchen: "none",
    location_contact: "none",
    location: "none",
    order: "none",
    order_item: "none",
    payment: "none",
    product: "none",
    rider: "none",
    rider_verification: "none",
    social_medium: "none",
    stock: "none",
    user: "none",
    discount: "none",
  };