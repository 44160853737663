import React from 'react'
import styles from './ViewProof.module.scss'

function ViewProof({img}) {
  return (
    <div className={styles.modal}>
        <img src={img} alt='' className={styles.image}/>
    </div>
  )
}

export default ViewProof