import React from "react";
import MetaTags from "react-meta-tags";
import Grid from "../../UI/Grid/Grid";
import GridRow from "../../UI/Grid/GridRow";
import TopDisplay from "../../UI/TopDisplay/TopDisplay";
import ErrorPage from "../ErrorPage/ErrorPage";
import DTable from "./component/DTable/DTable";
import TopSide from "./component/TopSide/TopSide";
import useLocation from "./hooks/useLocation";
import styles from "./OperationalLocations.module.scss";

function OperationalLocations() {
  const {
    locations,
    isGettingCards,
    regionsAvail,
    region,
    selectedState,
    handleCountry,
    onClickAction,
    onAddCountry,
    currentUserAbilities,
  } = useLocation();

  return (
    <>
      <MetaTags>
        <title>Locations Page</title>
        <meta name="description" content="#" />
      </MetaTags>
      {currentUserAbilities?.index ? (
        <>
      <TopDisplay
        title="Operational Locations"
        firstText="Dashboard"
        secondText="Operational Locations"
        style1
      />
      <TopSide
        handleCountry={handleCountry}
        selectedState={selectedState}
        location={locations}
        isGettingCards={isGettingCards}
        region={region}
        onAddCountry={onAddCountry}
        currentUserAbilities={currentUserAbilities}
      />
      <div className={styles.section2}>
        <Grid span="9">
          <GridRow span="9" customClass="section2">
            <DTable
              selectedState={selectedState}
              onClickAction={onClickAction}
              regionsAvail={regionsAvail}
              currentUserAbilities={currentUserAbilities}
            />
          </GridRow>
        </Grid>
      </div>
      </> ) : (
        <ErrorPage type2 />
      )}
    </>
  );
}

export default OperationalLocations;
