import React from "react";
import Tables from "../../../../UI/Tables/Tables";
import styles from "./DTable.module.scss";
import useDTable from "./hooks/useDTable";

function DTable() {
  const { isLoading, logs, handlePageChange,
    handlePerRowsChange, totalRows } = useDTable();

  const columns = [
    {
      name: "Request Method",
      selector: (row) => row?.request_method,
      sortable: true,
      width: "18%",
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      name: "Description",
      selector: (row) => row?.description,
      sortable: true,
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      name: "Email",
      selector: (row) => row?.admin?.email,
      sortable: true,
      width: "30%",
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    }
  ];

  return (
    <div className={styles.divv}>
      <Tables
        data={logs}
        columns={columns}
        pagination
        title="Activity Logs"
        firstRow
        fetchingData={isLoading}
        onRowClicked={() => {}}
      />
    </div>
  );
}

export default DTable;
