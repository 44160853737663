import React from "react";
import Card from "../../../../UI/Card/Card";
import { FileInput, TextInput } from "../../../../UI/FormComponents/Input";
import FormGroupHeader from "../../../../UI/FormComponents/FormHeaders/FormGroupHeader";
import styles from "../../AddStaffForm.module.scss";
import { sentenceCase } from "../../../../../utils/helpers";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const FormLHS = ({ state, dispatch, onSelectImage }) => {
  return (
    <Card classes={styles["form-6grid-main"]}>
      <FormGroupHeader
        title="Staff Information"
        description="General information about this Staff"
      />
      <TextInput
        name="first_name"
        label="First Name"
        type="text"
        value={sentenceCase(state?.firstName)}
        onChange={({ target }) =>
          dispatch({ firstName: sentenceCase(target.value) })
        }
      />
      <TextInput
        name="last_name"
        label="Last Name"
        type="text"
        value={sentenceCase(state?.lastName)}
        onChange={({ target }) =>
          dispatch({ lastName: sentenceCase(target.value) })
        }
      />
      <TextInput
        name="email"
        label="Email"
        type="email"
        value={state.email}
        onChange={({ target }) => dispatch({ email: target.value })}
      />
      <p className={styles["whatsapp"]}>Phone</p>
      <PhoneInput
        country={"ng"}
        value={state.phone}
        onChange={(phone) => {
          dispatch({ phone: phone })
        }
        }
      />
      <FileInput
        name="staff_image"
        label="Staff Image"
        onSelectImage={onSelectImage}
        value={state.image}
        imgSrc={state.image_src}
      />
      <TextInput
        name="password"
        label="Password"
        type="text"
        value={state.password}
        onChange={({ target }) => dispatch({ password: target.value })}
      />
    </Card>
  );
};

export default FormLHS;
