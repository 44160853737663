import { SET_KITCHEN, SET_KITCHEN_LOCATIONS, SET_KITCHEN_REGIONS, SET_KITCHEN_ZONES } from "../actionTypes/kitchenActionTypes";
  
  export function setKitchenLocations(data) {
    return (dispatch) => {
      dispatch({ type: SET_KITCHEN_LOCATIONS, data });
    };
  }

  export function setKitchenRegions(data) {
    return (dispatch) => {
      dispatch({ type: SET_KITCHEN_REGIONS, data });
    };
  }

  export function setKitchenZones(data) {
    return (dispatch) => {
      dispatch({ type: SET_KITCHEN_ZONES, data });
    };
  }

  export function setKitchen(data) {
    return (dispatch) => {
      dispatch({ type: SET_KITCHEN, data });
    };
  }
  
  