import { useCallback, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { setIsLoading } from "../../../../redux/actions/appAction";
import { setKitchen } from "../../../../redux/actions/kitchen.action";
import { setRegion } from "../../../../redux/actions/location.action";
import { paths } from "../../../../Routes/path";
import kitchenServices from "../../../../Services/kitchen.services";
import locationServices from "../../../../Services/location.services";

function useAddKitchen(edit) {
  const reduxDispatch = useDispatch();
  const { kitchenId } = useParams();
  const { region } = useSelector((state) => state?.location);
  const { kitchen } = useSelector((state) => state?.kitchen);
  const initState = {
    name: "",
    regionId: "",
    city: "",
    description: "",
    status: true,
    latitude: "",
    longitude: "",
  };
  const [state, dispatch] = useReducer(
    (formState, value) => ({ ...formState, ...value }),
    initState
  );
  const navigate = useNavigate();

  useEffect(() => {
    setRegion([])(reduxDispatch);
    // eslint-disable-next-line
  }, [])

  const getAllRegions = useCallback(() => {
    setIsLoading(true)(reduxDispatch);
    locationServices
      .getAllRegions()
      .then(({ data }) => {
        setRegion(data)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      })
      .finally(() => setIsLoading(false)(reduxDispatch));
  }, [reduxDispatch]);

  const getADeliveryZone = useCallback(() => {
    kitchenServices
      .getAkitchen(kitchenId)
      .then(({ data }) => {
        setKitchen(data)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
  }, [reduxDispatch, kitchenId]);

  useEffect(() => {
    getAllRegions();
  }, [getAllRegions]);

  useEffect(() => {
    if (edit) {
      getADeliveryZone();
    }
  }, [edit, getADeliveryZone]);

  useEffect(() => {
    if (edit) {
      dispatch({
        name: kitchen?.name,
        regionId: kitchen?.available_region_id,
        city: kitchen?.city,
        description: kitchen?.description,
        latitude: kitchen?.latitude,
        longitude: kitchen?.longitude,
        status: kitchen?.status === "activated" ? true : false,
      });
    }
  }, [edit, kitchen]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!state?.name) {
      toast.error("Kitchen Name cannot be empty")
      return;
    }
    if (!state?.regionId) {
      toast.error("Region must be selected")
      return;
    }
    if (!state?.city) {
      toast.error("City cannot be empty")
      return;
    }
    if (!state?.latitude) {
      toast.error("Latitude cannot be empty")
      return;
    }
    if (!state?.longitude) {
      toast.error("Longitude cannot be empty")
      return;
    }
      if (!edit) {
        dispatch({ isLoading: true})
        kitchenServices
          .createKitchen({
            kitchen: {
              name: state?.name,
              available_region_id: +state?.regionId,
              city: state.city,
              description: state.description,
              latitude: state.latitude,
              longitude: state.longitude,
              status: state?.status === true ? "activated" : "inactive",
            },
          })
          .then(() => {
            toast.success("Kitchen was succesfully created");
            navigate(paths.kitchen)
          })
          .catch((err) => {
            dispatch({ isLoading: false})
            toast.error(err.message)
          })
      } else {
        dispatch({ isLoading: true})
        kitchenServices
          .updateKitchen(kitchenId, {
            kitchen: {
              name: state?.name,
              available_region_id: +state?.regionId,
              city: state.city,
              description: state.description,
              latitude: state.latitude,
              longitude: state.longitude,
              status: state?.status === true ? "activated" : "inactive",
            },
          })
          .then(() => {
            toast.success("Kitchen was succesfully updated");
            navigate(paths.kitchen)
          })
          .catch((err) => {
            dispatch({ isLoading: false})
            toast.error(err.message)
          })
      }
  };
  return { region, state, dispatch, handleSubmit };
}

export default useAddKitchen;
