import { SET_CUSTOMER, SET_CUSTOMERS, SET_CUSTOMER_ID } from "../actionTypes/customerActionType";
  
  const initialState = {
    customers: [],
    customer: [],
    customerId: null,
  };
  
  export default function customerReducer(state = initialState, action) {
    const { data, type } = action;
    switch (type) {
      case SET_CUSTOMERS:
        return {
          ...state,
          customers: data,
        };
      case SET_CUSTOMER:
        return {
          ...state,
          customer: data,
        };
      case SET_CUSTOMER_ID:
        return {
          ...state,
          customerId: data,
        };
      default:
        return state;
    }
  }
  