import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setKitchen } from '../../../../redux/actions/kitchen.action';
import kitchenServices from '../../../../Services/kitchen.services';
import { setRegion } from '../../../../redux/actions/location.action';
import locationServices from '../../../../Services/location.services';

function useLocationSidebar() {
    const myObjectString = localStorage.getItem("user");

  const currentUser = JSON.parse(myObjectString);
  const kitchenId = currentUser?.kitchen_id;
  const currentUserAbilities = currentUser?.account_abilities?.abilities;
    const reduxDispatch = useDispatch();
    const { kitchen } = useSelector((state) => state?.kitchen);
    const { region } = useSelector((state) => state?.location);

    const getADeliveryZone = useCallback(() => {
        kitchenServices
          .getAkitchen(kitchenId)
          .then(({ data }) => {
            setKitchen(data)(reduxDispatch);
          })
          .catch((err) => {
          });
      }, [reduxDispatch, kitchenId]);

      const getAllRegions = useCallback(() => {
        locationServices
          .getAllRegions()
          .then(({ data }) => {
            setRegion(data)(reduxDispatch);
          })
          .catch((err) => {
            // toast.error(err.message || "Unable to get");
          });
      }, [reduxDispatch]);

      useEffect(() => {
        getADeliveryZone();
        getAllRegions();
      }, [getADeliveryZone, getAllRegions]);

      
  return {
    kitchen,
    currentUserAbilities,
    region,
  }
}

export default useLocationSidebar