import { API_URL } from "../env";
import { request } from "./api";

const URL = {
  // influencers
  getInfluencers: (page, perPage) => `${API_URL}api/v1/be/discounts/influencers?page=${page}&per_page=${perPage}`,
  getTheInfluencers: `${API_URL}api/v1/be/discounts/influencers_unpaginated`,
  getAInfluencer: (influencerId) =>`${API_URL}api/v1/be/discounts/influencers/${influencerId}`,
  createInfluencer: `${API_URL}api/v1/be/discounts/influencers/new`,
  deleteInfluencer:(influencerId) => `${API_URL}api/v1/be/discounts/influencers/${influencerId}`,
  updateInfluencer: (influencerId) =>
    `${API_URL}api/v1/be/discounts/influencers/${influencerId}`,

  // vouchers
  getVouchers: (page, perPage) => `${API_URL}api/v1/be/discounts/vouchers?page=${page}&per_page=${perPage}`,
  getAVoucher: (voucherId) =>`${API_URL}api/v1/be/discounts/vouchers/${voucherId}`,
  createVoucher: `${API_URL}api/v1/be/discounts/vouchers/new`,
  deleteVoucher:(voucherId) => `${API_URL}api/v1/be/discounts/vouchers/${voucherId}`,
  updateVoucher: (voucherId) =>
    `${API_URL}api/v1/be/discounts/vouchers/${voucherId}`,

};

// influencers
function getInfluencers(page, perPage) {
  return request(URL.getInfluencers(page, perPage), "get")
}

function getTheInfluencers() {
  return request(URL.getTheInfluencers, "get")
}

function getAInfluencer(influencerId) {
  return request(URL.getAInfluencer(influencerId), "get")
}

function deleteInfluencer(influencerId) {
  return request(URL.deleteInfluencer(influencerId), "delete")
}

function createInfluencer(rider) {
    return request(URL.createInfluencer, rider, "post")
}

function updateInfluencer(influencerId, rider) {
    return request(URL.updateInfluencer(influencerId), rider, "put")  
}

// vouchers
function getVouchers(page, perPage) {
    return request(URL.getVouchers(page, perPage), "get")
  }
  
  function getAVoucher(voucherId) {
    return request(URL.getAVoucher(voucherId), "get")
  }
  
  function deleteVoucher(voucherId, voucher) {
    return request(URL.deleteVoucher(voucherId), voucher, "delete")
    
  }
  
  function createVoucher(voucher) {
      return request(URL.createVoucher, voucher, "post")
  }
  
  function updateVoucher(voucherId, voucher) {
      return request(URL.updateVoucher(voucherId), voucher, "put")  
  }
  

const discountServices = {
  getInfluencers,
  getTheInfluencers,
  getAInfluencer,
  deleteInfluencer,
  createInfluencer,
  updateInfluencer,
  getVouchers,
  getAVoucher,
  deleteVoucher,
  createVoucher,
  updateVoucher,
}

export default discountServices;
