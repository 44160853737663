import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
  setPopupComponent,
  showPopup,
} from "../../../../../../redux/actions/popUp.action";
import { paths } from "../../../../../../Routes/path";
import stocksServices from "../../../../../../Services/stocks.services";
import TopUp from "../../TopUp/TopUp";
import { getBase64 } from "../../../../../../utils/helpers";

function useDTable(onClickAction, currentUserAbilities) {
  const reduxDispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [selected, setSelected] = useState(null);
  const [selected2, setSelected2] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false)
  const onClick = (value) => {
    setOpenModal(true);
    setSelected(value);
  };

  const onClick2 = (value) => {
    setOpenModal2(true);
    setSelected2(value);
  };

  const isLoading = useSelector((state) => state.app.isLoading);
  const navigate = useNavigate();

  const handleEdit = (stockId) => {
    navigate(paths.editStock.replace(":stockId", stockId));
  };

  const onDelete = (stockId) => {
    onClickAction(stockId);
  };

  const onClosePage = useCallback(() => {
    reduxDispatch(showPopup({ ispopupOpen: false }));
  }, [reduxDispatch]);

  const addStock = (stockId) => {
    setOpenModal2(false);
    reduxDispatch(
      setPopupComponent(
        <TopUp
          onClose={onClosePage}
          stockId={stockId}
          onClosePage={onClosePage}
        />
      )
    );
    reduxDispatch(
      showPopup({ ispopupOpen: true, onClose: () => onClosePage() })
    );
  };

  const manualStock = (stockId) => {
    setOpenModal2(false);
    reduxDispatch(
      setPopupComponent(
        <TopUp
          onClose={onClosePage}
          stockId={stockId}
          onClosePage={onClosePage}
          manual
        />
      )
    );
    reduxDispatch(
      showPopup({ ispopupOpen: true, onClose: () => onClosePage() })
    );
  };

  const update = currentUserAbilities?.update;
  const create = currentUserAbilities?.create;
  const show = currentUserAbilities?.show;

  const del = currentUserAbilities?.destroy;

  const myObjectString = localStorage.getItem("user");
  const currentUser = JSON.parse(myObjectString);
  const kitchenId = currentUser?.kitchen_id;
  const sideBarType = currentUser?.restricted_by_location;

  const onSelectImage = (e) => {
    let file = e.target.files[0];

    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        onBulkUpload(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onBulkUpload = useCallback((e) => {
    console.log('loading bulk')
        stocksServices
          .bulkUploadStock(kitchenId, {
            stock: {stock_quantities: e?.split(',')[1]},
          })
          .then((res) => {
            toast.success(res?.message);
          })
          .catch((err) => {
            toast.error(err.message);
          })
          .finally(() => navigate(paths.inventory));
}, [kitchenId, navigate]);

const downloadStocks = () => {
  setIsDownloading(true)
  stocksServices
    .downloadStocks()
    .then(({ data }) => {
      setIsDownloading(false)
    })
    .catch((err) => {
      setIsDownloading(false)
      toast.error(err.message || "Unable to get");
    });
};

  return {
    onDelete,
    handleEdit,
    onClick,
    onClick2,
    selected,
    selected2,
    openModal,
    setOpenModal,
    openModal2,
    setOpenModal2,
    isLoading,
    del,
    update,
    create,
    show,
    sideBarType,
    addStock,
    manualStock,
    onSelectImage,
    downloadStocks,
    isDownloading,
  };
}

export default useDTable;
