import { SET_LOCATIONS, SET_REGION, SET_LOCATION_TABLE, SET_COUNTRIES, SET_STATES, SET_CITIES, SET_REGION_ID, SET_WAREHOUSE_REGION } from '../actionTypes/locationActionType';

export function setLocations(data) {
  return dispatch => {
    dispatch({ type: SET_LOCATIONS, data });
  };
}

export function setWarehouseRegion(data) {
  return dispatch => {
    dispatch({ type: SET_WAREHOUSE_REGION, data });
  };
}

export function setCountries(data) {
  return dispatch => {
    dispatch({ type: SET_COUNTRIES, data });
  };
}

export function setStates(data) {
  return dispatch => {
    dispatch({ type: SET_STATES, data });
  };
}

export function setCities(data) {
  return dispatch => {
    dispatch({ type: SET_CITIES, data });
  };
}

export function setRegion(data) {
  return dispatch => {
    dispatch({ type: SET_REGION, data });
  };
}

export function setRegionId(data) {
  return dispatch => {
    dispatch({ type: SET_REGION_ID, data });
  };
}

export function setLocationTable(data) {
  return dispatch => {
    dispatch({ type: SET_LOCATION_TABLE, data });
  };
}