import { useReducer } from "react";
import { getBase64 } from "../../../../../../utils/helpers";
import ordersServices from "../../../../../../Services/orders.services";
import { toast } from "react-toastify";

function useVerify(onClose, orderId, paymentId) {
    const paymentMethod = [
        {
            name: "Bank Transfer",
            id: "bank"
        },
        {
            name: "Compensation",
            id: "compensation"
        },
    ]

    const initState = {
        paymentType: "",
        proof: "",
        isLoading: false,
        image_src: "",
      };
    
    
      const [state, dispatch] = useReducer(
        (orderState, value) => ({ ...orderState, ...value }),
        initState
      );

      const onSelectImage = (e) => {
        let file = e.target.files[0];
    
        getBase64(file)
          .then((result) => {
            file["base64"] = result;
            dispatch({
              proof: result,
              image_src: URL.createObjectURL(e.target.files[0]),
            });
          })
          .catch((err) => {
            console.log(err);
          });
      };

      const handleSubmit = (event) => {
        event.preventDefault();
        if(!state?.paymentType){
            toast.error("Select a payment method")
            return;
        }
        if(!state?.proof){
            toast.error("Upload payment proof")
            return;
        }
        ordersServices
          .verifyAPaymentManually(orderId, paymentId, {
            payment_verification: {
                payment_method: state?.paymentType,
                proof: state?.proof,
            }
          })
          .then((res) => {
            toast.success(res.message);
            onClose();
          })
          .catch((err) => {
            if (err.response.status === 402) {
              toast.error(err.response.data.message);
            } else {
              toast.error(err.response);
            }
          });
      };
  return {
    paymentMethod,
    state,
    dispatch,
    onSelectImage,
    handleSubmit
  }
}

export default useVerify