import React from "react";
import MetaTags from "react-meta-tags";
import Grid from "../../UI/Grid/Grid";
import GridRow from "../../UI/Grid/GridRow";
import TopDisplay from "../../UI/TopDisplay/TopDisplay";
import styles from "./AssignDelivery.module.scss";
import FormLHS from "./component/FormGroups/FormLHS";
import FormRHS from "./component/FormGroups/FormRHS";
import useAssignDelivery from "./hooks/useAssignDelivery";

function AssignDelivery() {
  const { dispatch, state, readyOrders, order, riders, handleSubmit } = useAssignDelivery();
  return (
    <>
      <MetaTags>
        <title>Assign Delivery Page</title>
        <meta name="description" content="#" />
      </MetaTags>
      <div className={styles.main}>
        <TopDisplay
          title="Assign Delivery"
          firstText="Dashboard"
          secondText="Deliveries"
          thirdText="Assign Delivery"
          style2
        />
        <div className={styles["form-main"]}>
          <Grid span="9">
            <GridRow span="6" customClass="section2">
              <FormLHS
                readyOrders={readyOrders}
                dispatch={dispatch}
                state={state}
                order={order}
              />
            </GridRow>
            <GridRow span="3" customClass="section2">
              <FormRHS
                riders={riders}
                dispatch={dispatch}
                state={state}
                handleSubmit={handleSubmit}
              />
            </GridRow>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default AssignDelivery;
