import { useCallback, useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
  setIsLoading,
} from "../../../../redux/actions/appAction";
import {
  setOrder,
  setOrderStatus,
  setOrders,
  setProductItems,
} from "../../../../redux/actions/order.action";
import { setIsDeleting, setPopupComponent, showPopup } from "../../../../redux/actions/popUp.action";
import { paths } from "../../../../Routes/path";
import ordersServices from "../../../../Services/orders.services";
import DeleteModal from "../../../UI/DeleteModal";

function useOrder() {
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const initState = {
    totalOrders: null,
    isDeleting: false,
  };

  const navigate = useNavigate();
  const { openModal } = useSelector((state) => state.app);

  const [state, dispatch] = useReducer(
    (productState, value) => ({ ...productState, ...value }),
    initState
  );

  const myObjectString = localStorage.getItem('user');

  const currentUser = JSON.parse(myObjectString)
  const currentUserAbilities = currentUser?.account_abilities?.abilities

  const reduxDispatch = useDispatch();
  const {
    orders,
    orderStatus,
  } = useSelector((state) => state.order);
  
    const getOrderStatus = useCallback(() => {
      ordersServices
        .getOrderStatus()
        .then(({ data }) => {
            const newArr = data.filter(item => (
                item.status === 'completed' || item.status === 'initiated'
            ))
            let newArray = newArr?.map((item) => ({
              status: item.status,
              count: item.count,
              id: item.status,
            }));
          setOrderStatus(newArray)(reduxDispatch);
        })
        .catch((err) => {
          toast.error(err.message || "Unable to get");
        });
    }, [reduxDispatch]);

    useEffect(() => {
      getOrderStatus();
      // eslint-disable-next-line
    }, [getOrderStatus])
  const { totalOrders, isDeleting } = state;
  useEffect(() => {
    setOrders([])(reduxDispatch);
  }, [reduxDispatch])
  const getOrders = useCallback(
    (page, perPage) => {
      setIsLoading(true)(reduxDispatch);
      ordersServices
        .getOrders(page, perPage)
        .then(({ data }) => {
          dispatch({ totalOrders: data.pagination.total });
          setOrders(data.data)(reduxDispatch);
          setTotalRows(data.pagination.total);
        })
        .catch((err) => {
          toast.error(err.message || "Unable to get");
        })
        .finally(() => setIsLoading(false)(reduxDispatch));
    },
    [reduxDispatch]
  );

  const getFilteredOrders = useCallback(
    (orderStatus, page, perPage) => {
      setIsLoading(true)(reduxDispatch);
      ordersServices
        .getFilteredOrder(orderStatus, page, perPage)
        .then(({ data }) => {
          setOrders(data.data)(reduxDispatch);
          setTotalRows(data.pagination.total);
        })
        .catch((err) => {
          toast.error(err.message || "Unable to get");
        })
        .finally(() => setIsLoading(false)(reduxDispatch));
    },
    [reduxDispatch]
  );


  useEffect(() => {
    getOrders(1, perPage);
  }, [getOrders, perPage]);

  const handlePageChange = (page) => {
    getOrders(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setIsLoading(true)(reduxDispatch);
    ordersServices
      .getOrders(page, newPerPage)
      .then(({ data }) => {
        setOrders(data.data)(reduxDispatch);
        setPerPage(newPerPage);
        setTotalRows(data.pagination.total);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      })
      .finally(() => setIsLoading(false)(reduxDispatch));
  };

  const getAnOrder = (orderId) => {
    ordersServices
      .getAnOrder(orderId)
      .then(({ data }) => {
        setOrder(data)(reduxDispatch);
        setProductItems()(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
  };

  const onClose = () => {
    showPopup({ ispopupOpen: false })(reduxDispatch);
  };

  const secondRowFcn = (orderStatus) => {
    getFilteredOrders(orderStatus, 1, perPage);
  };

  const secondRowAllFcn = () => {
    getOrders(1, perPage);
  };

  const handleDelete = (orderId) => {
    setIsDeleting(true)(reduxDispatch)
    ordersServices
      .deleteOrder(orderId)
      .then(() => {
        toast.success("Order was deleted");
        getOrders();
        onClose();
      })
      .catch((err) => toast.error(err.message))
    .finally(() => {
      setIsDeleting(false)(reduxDispatch)
      navigate(paths.orders)
    });
  };

  const onClickAction = (orderId) => {
    reduxDispatch(
      setPopupComponent(
        <DeleteModal
          onClose={onClose}
          handleDelete={() => handleDelete(orderId)}
          title="Order"
        />
      )
    );
    showPopup({ ispopupOpen: true, onClose: () => onClose() })(reduxDispatch);
  };

  useEffect(() => {
    setProductItems([
      {
        product_name: "",
        quantity: "",
        unit: "",
      },
    ])(reduxDispatch);
  }, [reduxDispatch]);

  return {
    state,
    handlePageChange,
    handlePerRowsChange,
    dispatch,
    orders,
    totalRows,
    handleDelete,
    secondRowAllFcn,
    secondRowFcn,
    onClickAction,
    onClose,
    openModal,
    getAnOrder,
    orderStatus,
    totalOrders,
    isDeleting,
    currentUserAbilities,
  };
}

export default useOrder;
