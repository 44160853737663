import React from "react";
import MetaTags from "react-meta-tags";
import Grid from "../../UI/Grid/Grid";
import GridRow from "../../UI/Grid/GridRow";
import FormLHS from "./component/FormGroups/FormLHS";
import TopDisplay from "../../UI/TopDisplay/TopDisplay";
import styles from "./AddDeliveryAreaForm.module.scss";
import areastyles from "./DeliveryAreas.module.scss";
import useAddDeliveryArea from "./hooks/useAddDeliveryArea";

const AddDeliveryAreaForm = ({ edit }) => {
  const { region, state, dispatch, handleSubmit } = useAddDeliveryArea(edit);
  return (
    <>
      <MetaTags>
        <title>Add Delivery Area Page</title>
        <meta name="description" content="#" />
      </MetaTags>
      <div className={areastyles.main}>
        <TopDisplay
          title={edit ? "Update Area" : "Add New Area"}
          firstText="Locations"
          secondText="Delivery Area"
          thirdText={edit ? "Update Area" : "Add New Area"}
          style2
        />
        <div className={styles["form-main"]}>
          <Grid span="9">
            <GridRow span="6" customClass="section2">
              <FormLHS
                edit={edit}
                region={region}
                state={state}
                handleSubmit={handleSubmit}
                dispatch={dispatch}
              />
            </GridRow>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default AddDeliveryAreaForm;
