import React from "react";
import Card from "../../../../UI/Card/Card";
import {
  TextInput,
  SelectInput,
  FileInput,
} from "../../../../UI/FormComponents/Input";
import FormGroupHeader from "../../../../UI/FormComponents/FormHeaders/FormGroupHeader";
import styles from "../../AddRiderForm.module.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const FormLHS = ({
  region,
  state,
  dispatch,
  verify,
  kitchenZones,
  countries,
  cities,
  edit,
  onViewImage,
  onSelectImage
}) => {
  let options = [
    { id: "motorcycle", name: "Motorcycle" },
    { id: "pickup truck", name: "Pickup Truck" },
  ];
  return (
    <Card classes={styles["form-6grid-main"]}>
      <FormGroupHeader
        title="Rider Information"
        description="General information about this rider"
      />
      <div className={styles["form-flex"]}>
        <div className={styles["form-flex-1"]}>
          <TextInput
            name="first_name"
            label="First Name"
            type="text"
            value={state.first_name}
            disabled={verify}
            onChange={(e) => dispatch({ first_name: e.target.value })}
          />
        </div>
        <div className={styles["form-flex-2"]}>
          <TextInput
            name="last_name"
            label="Last Name"
            type="text"
            disabled={verify}
            value={state.last_name}
            onChange={(e) => dispatch({ last_name: e.target.value })}
          />
        </div>
      </div>
      <div className={styles["form-flex"]}>
        <div className={styles["form-flex-1"]}>
          <TextInput
            name="email"
            label="Email"
            type="email"
            value={state.email}
            disabled={verify}
            onChange={(e) => dispatch({ email: e.target.value })}
          />
        </div>
        <div className={styles["form-flex-2"]}>
          <p className={styles["whatsapp"]}>Phone</p>
          <PhoneInput
            country={"ng"}
            value={state.phone}
            onChange={(phone) => dispatch({ phone: phone })}
            disabled={verify}
          />
        </div>
      </div>
      {!verify ? (
        <FileInput
          name="passport_image"
          label="Passport"
          onSelectImage={onSelectImage}
          value={state.image}
          imgSrc={state.image_src}
        />
      ) : (
        <div
          className={styles.modal}
          onClick={() => onViewImage(state.image_src)}
        >
          <p className={styles.verifyText}>Verification Document</p>
          <img
            src={state.image_src}
            alt="verification document"
            className={styles.image}
          />
        </div>
      )}
      <div className={styles["form-flex"]}>
        <div className={styles["form-flex-1"]}>
          <SelectInput
            name="country"
            label="Country"
            options={countries}
            disabled={verify}
            value={state?.location_id}
            onChange={(e) => dispatch({ location_id: e.target.value })}
          />
        </div>
        <div className={styles["form-flex-2"]}>
          <SelectInput
            name="available_region"
            label="Available Region"
            options={region}
            disabled={verify}
            value={state.available_region_id}
            onChange={(e) => dispatch({ available_region_id: e.target.value })}
          />
        </div>
      </div>

      <div className={styles["form-flex"]}>
        <div className={styles["form-flex-1"]}>
          <SelectInput
            name="kitchen"
            label="Kitchen"
            options={kitchenZones}
            disabled={verify}
            value={state.kitchenId}
            onChange={(e) => dispatch({ kitchenId: e.target.value })}
          />
        </div>
        <div className={styles["form-flex-2"]}>
          <SelectInput
            name="delivery_zone"
            label="Delivery Zone"
            options={cities}
            disabled={verify}
            value={state?.delivery_zone_id}
            onChange={(e) => dispatch({ delivery_zone_id: e.target.value })}
          />
        </div>
      </div>
      <div className={styles["form-flex"]}>
        <div className={styles["form-flex-1"]}>
          <TextInput
            name="house_number"
            label="House Number"
            type="text"
            value={state.house_number}
            disabled={verify}
            onChange={(e) => dispatch({ house_number: e.target.value })}
          />
        </div>
        <div className={styles["form-flex-2"]}>
          <TextInput
            name="street"
            label="Street"
            type="text"
            value={state.street}
            disabled={verify}
            onChange={(e) => dispatch({ street: e.target.value })}
          />
        </div>
      </div>
      {!(edit || verify) && (
        <div className={styles["form-flex"]}>
          <div className={styles["form-flex-1"]}>
            <TextInput
              name="password"
              label="Password"
              type="password"
              value={state.password}
              disabled={verify}
              onChange={(e) => dispatch({ password: e.target.value })}
            />
          </div>
          <div className={styles["form-flex-2"]}>
            <TextInput
              name="password_confirmation"
              label="Password Confirmation"
              type="password"
              disabled={verify}
              value={state.password_confirmation}
              onChange={(e) =>
                dispatch({ password_confirmation: e.target.value })
              }
            />
          </div>
        </div>
      )}
      <div className={styles.second}>
        <FormGroupHeader
          title="Delivery Vehicle"
          description="General information about the delivery vehicle"
        />

        <div className={styles["form-flex"]}>
          <div className={styles["form-flex-1"]}>
            <SelectInput
              name="automobile_type"
              label="Automobile Type"
              options={options}
              disabled={verify}
              value={state?.vehicle_type}
              onChange={(e) => dispatch({ vehicle_type: e.target.value })}
            />
          </div>
          <div className={styles["form-flex-2"]}>
            <TextInput
              name="manufacturer"
              label="Manufacturer"
              type="text"
              value={state.make}
              disabled={verify}
              onChange={(e) => dispatch({ make: e.target.value })}
            />
          </div>
        </div>
        <div className={styles["form-flex"]}>
          <div className={styles["form-flex-1"]}>
            <TextInput
              name="registration_number"
              label="Registration Number"
              type="text"
              value={state.registration_number}
              disabled={verify}
              onChange={(e) =>
                dispatch({ registration_number: e.target.value })
              }
            />
          </div>
          <div className={styles["form-flex-2"]}>
            <TextInput
              name="model"
              label="Model"
              type="text"
              value={state.model}
              disabled={verify}
              onChange={(e) => dispatch({ model: e.target.value })}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default FormLHS;
