import { useCallback, useEffect, useMemo, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { setIsLoading } from "../../../../redux/actions/appAction";
import {
  setOrder,
  setOrderProducts,
  setProductItems,
  setSelectedCustomer,
  setUserAddress,
  setUsers,
} from "../../../../redux/actions/order.action";
import {
  setPopupComponent,
  showPopup,
} from "../../../../redux/actions/popUp.action";
import { paths } from "../../../../Routes/path";
import customersServices from "../../../../Services/customer.services";
import ordersServices from "../../../../Services/orders.services";
import AddAddressForm from "../component/AddAddressForm/AddAddressForm";
import { setDiscounts } from "../../../../redux/actions/discount.Action";

function useAddOrder(edit) {
  const { users, userAddress, order, productItems, orderProducts, selectedCustomer } =
    useSelector((state) => state?.order);
  const { discounts } = useSelector((state) => state.discount);

  const { openAddModal } = useSelector((state) => state.app);
  const initState = {
    customer: null,
    customerName: "",
    customerAddress: "",
    searchValue: "",
    orderItems: [],
    sumOfPrices: null,
    discount: 0,
    discountId: null,
    discountOf: null,
    deliveryFee: 0,
    isLoading: false,
    isSearching: false,
  };
  const [state, dispatch] = useReducer(
    (productState, value) => ({ ...productState, ...value }),
    initState
  );
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  const { orderId } = useParams();
  const { searchValue, customer, customerAddress, deliveryFee, discount } =
    state;

  const searchUsers = useCallback(() => {
    dispatch({ isSearching: true });
    ordersServices
      .searchUsers(searchValue, 1, 50)
      .then(({ data }) => {
        setUsers(data.data)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      })
      .finally(() => dispatch({ isSearching: false }));
  }, [reduxDispatch, searchValue]);

  const getAnOrder = useCallback(() => {
    ordersServices
      .getAnOrder(orderId)
      .then(({ data }) => {
        setOrder(data)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
  }, [orderId, reduxDispatch]);

  const getDiscounts = useCallback(() => {
    ordersServices
      .getDiscounts()
      .then(({ data }) => {
        let newArray = data?.map((item) => ({
          name: `${item.campaign_name}(${item.code})`,
          measure: item.percentage,
          id: item.id,
        }));
        setDiscounts(newArray)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
  }, [reduxDispatch]);

  const getOrderProducts = useCallback(() => {
    ordersServices
      .getOrderProducts(customer, +customerAddress)
      .then(({ data }) => {
        setOrderProducts(data.products)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
  }, [customer, customerAddress, reduxDispatch]);

  useEffect(() => {
    if (customerAddress && customerAddress !== "addBtn") getOrderProducts();
  }, [customerAddress, getOrderProducts]);

  useEffect(() => {
    getDiscounts();
    if (edit) getAnOrder();
    // eslint-disable-next-line
  }, [edit]);

  useEffect(() => {
    if (searchValue) searchUsers();
  }, [searchUsers, searchValue]);

  const onAddCustomer = () => {
    navigate(paths.newCustomer);
  };

  const handleCustomerId = (value) => {
    dispatch({
      customer: value.id,
      customerName: value && `${value.first_name} ${value.last_name} (${value.phone})`,
    });
  };
  

  const getUserAddresses = useCallback(() => {
    setIsLoading(true)(reduxDispatch);
    ordersServices
      .getUserAddresses(customer)
      .then(({ data }) => {
        setUserAddress(data)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      })
      .finally(() => setIsLoading(false)(reduxDispatch));
  }, [reduxDispatch, customer]);

  useEffect(() => {
    if (customer) {
      getUserAddresses();
    }
    return;
  }, [customer, getUserAddresses]);

  useEffect(() => {
    dispatch({
      orderItems: productItems?.map((item, i) => {
        return {
          product_id: +item.product_name,
          quantity: +item.quantity,
        };
      }),
    });
  }, [productItems]);

  const getACustomer = useCallback(() => {
    customersServices
      .getACustomer(order?.user_id)
      .then(({ data }) => {
        setSelectedCustomer([data])(reduxDispatch)
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
  }, [order, reduxDispatch]);

  useEffect(() => {
    if (edit && customer) getACustomer();
  }, [customer, edit, getACustomer]);

  useEffect(() => {
    if (edit) {
      if (selectedCustomer)
        dispatch({
          customer: order?.user_id,
          customerAddress: order?.address_id,
          customerName: selectedCustomer.length ? `${selectedCustomer[0]?.first_name} ${selectedCustomer[0]?.last_name} (${selectedCustomer[0]?.phone})` : '--',
        });
      const orderArr = order?.order_items?.map((item, i) => {
        return {
          product_name: item?.product_id,
          quantity: item?.quantity,
          title: item?.product.title,
          id: item?.product_id,
          price: item.unit_price.price,
          currency: order.currency,
        };
      });
      setProductItems(orderArr)(reduxDispatch);
    }
  }, [edit, reduxDispatch, order, selectedCustomer]);

  const quantity = state?.orderItems?.map((item) => item.quantity);
  const selectedProduct = state?.orderItems?.map((item) => item.product_id);

  const applyDiscount = (data) => {
    ordersServices
      .applyDiscount(data?.id, state?.discountOf)
      .then(() => {
        toast.success("Order was succesfully created");
        navigate(paths.orderDetails.replace(":orderId", data?.reference), {
          state: data.id,
        });
      })
      .catch((err) => {
        dispatch({ isLoading: false });
        toast.error(err.message);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!state.customer) {
      toast.error("Customer is required");
      return;
    }
    if (!state.customerAddress) {
      toast.error("Address required");
      return;
    }
    if (!selectedProduct[0] && !quantity[0]) {
      toast.error("Add product and Quantity");
      return;
    }
    if (!edit) {
      dispatch({ isLoading: true });
      ordersServices
        .createOrder({
          order: {
            user_id: +state?.customer,
            address_id: +state?.customerAddress,
          },
          order_items: {
            items: state?.orderItems,
          },
        })
        .then(({ data }) => {
          if (discount) {
            applyDiscount(data);
          } else {
            toast.success("Order was succesfully created");
            navigate(paths.orderDetails.replace(":orderId", data?.reference), {
              state: data.id,
            });
          }
        })
        .catch((err) => {
          dispatch({ isLoading: false });
          toast.error(err.message);
        });
    } else {
      dispatch({ isLoading: true });
      ordersServices
        .updateOrder(orderId, {
          order: {
            user_id: +state?.customer,
            address_id: +state?.customerAddress,
          },
          order_items: {
            items: state?.orderItems,
          },
        })
        .then(({ data }) => {
          if (discount) {
            applyDiscount(data);
          } else {
            toast.success("Order was succesfully updated");
            navigate(paths.orderDetails.replace(":orderId", data?.reference), {
              state: data.id,
            });
          }
        })
        .catch((err) => {
          dispatch({ isLoading: false });
          toast.error(err.message);
        });
    }
  };

  useEffect(() => {
    if (!edit)
      dispatch({
        orderItems: [],
      });
  }, [edit, reduxDispatch]);

  const onClosePage = useCallback(() => {
    dispatch({
      customerAddress: edit ? order?.address_id : "",
    });
    reduxDispatch(showPopup({ ispopupOpen: false }));
  }, [edit, order?.address_id, reduxDispatch]);

  useEffect(() => {
    if (customerAddress === "addBtn") {
      reduxDispatch(
        setPopupComponent(
          <AddAddressForm
            onClose={() => {}}
            customer={customer}
            onClosePage={onClosePage}
          />
        )
      );
      reduxDispatch(
        showPopup({ ispopupOpen: true, onClose: () => onClosePage() })
      );
    }
  }, [customer, customerAddress, onClosePage, reduxDispatch]);

  const sumOfPrices = useMemo(() => {
    return productItems?.reduce(
      (sum, product) => sum + +product?.price * product?.quantity,
      0
    );
  }, [productItems]);

  const totalPaymentDue = useMemo(() => {
    const discountNew = sumOfPrices * (discount / 100)
    if (discount === 0) {
      return sumOfPrices + deliveryFee;
    } else return (sumOfPrices - discountNew) + deliveryFee;
  }, [deliveryFee, discount, sumOfPrices]);

  const currency = useMemo(() => {
    if (productItems) {
      return productItems[0]?.currency;
    }
    return "";
  }, [productItems]);

  return {
    state,
    handleSubmit,
    dispatch,
    orderProducts,
    users,
    onAddCustomer,
    openAddModal,
    handleCustomerId,
    userAddress,
    sumOfPrices,
    totalPaymentDue,
    currency,
    discounts,
  };
}

export default useAddOrder;
