import React from "react";
import MetaTags from "react-meta-tags";
import Grid from "../../UI/Grid/Grid";
import GridRow from "../../UI/Grid/GridRow";
import FormLHS from "./component/FormGroups/FormLHS";
import FormRHS from "./component/FormGroups/FormRHS";
import TopDisplay from "../../UI/TopDisplay/TopDisplay";
import styles from "./AddRegionForm.module.scss";
import locationstyles from "./OperationalLocations";
import useAddLocation from "./hooks/useAddLocation";

const AddRegionForm = ({ edit }) => {
  const { state, handleSubmit, dispatch, onSelectImage, states } =
    useAddLocation(edit);
  return (
    <>
      <MetaTags>
        <title>Region Page</title>
        <meta name="description" content="#" />
      </MetaTags>
      <div className={locationstyles.mains}>
        <TopDisplay
          title={edit ? "Edit Region" : "Add New Region"}
          firstText="Dashboard"
          secondText="Operational Locations"
          thirdText={edit ? "Edit Region" : "Add New Region"}
          style2
        />
        <div className={styles["form-main"]}>
          <Grid span="9">
            <GridRow span="6" customClass="section2">
              <FormLHS
                state={state}
                dispatch={dispatch}
                states={states}
              />
            </GridRow>
            <GridRow span="3" customClass="section2">
              <FormRHS
                edit={edit}
                state={state}
                handleSubmit={handleSubmit}
                dispatch={dispatch}
                onSelectImage={onSelectImage}
              />
            </GridRow>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default AddRegionForm;
