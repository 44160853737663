import { API_URL } from "../env";
import { request } from "./api";

const URL = {
  getInventories: `${API_URL}api/v1/be/inventories`,
  getAInventory: (inventoryId) =>
    `${API_URL}api/v1/be/inventories/${inventoryId}`,
  createInventory: `${API_URL}api/v1/be/inventories/new`,
  deleteInventory: (inventoryId) =>
    `${API_URL}api/v1/be/inventories/${inventoryId}`,
  updateInventory: (inventoryId) =>
    `${API_URL}api/v1/be/inventories/${inventoryId}`,

  // kitchen based
  getLocationInventories: (kitchenId) =>
    `${API_URL}api/v1/be/kitchen/${kitchenId}/inventories`,
  getALocationInventory: (kitchenId, inventoryId) =>
    `${API_URL}api/v1/be/kitchen/${kitchenId}/inventories/${inventoryId}`,
  topUpInventory: (kitchenId, inventoryId) =>
    `${API_URL}api/v1/be/kitchen/${kitchenId}/inventories/${inventoryId}/top_up`,
  bulkUploadInventory: (kitchenId) =>
    `${API_URL}api/v1/be/kitchen/${kitchenId}/inventories/bulk_top_up`,

  // region based
  getRegionInventories: (regionId) =>
    `${API_URL}api/v1/be/regions/${regionId}/inventories`,
  getARegionInventory: (regionId, inventoryId) =>
    `${API_URL}api/v1/be/regions/${regionId}/inventories/${inventoryId}`,
  topUpRegionInventory: (regionId, inventoryId) =>
    `${API_URL}api/v1/be/regions/${regionId}/inventories/${inventoryId}/top_up`,
  bulkUploadRegionInventory: (regionId) =>
    `${API_URL}api/v1/be/regions/${regionId}/inventories/bulk_top_up`,
  downloadInventories: (kitchenId) =>
    `${API_URL}api/v1/be/kitchen/${kitchenId}/inventories/download`,
  downloadRegionInventories: (regionId) =>
    `${API_URL}api/v1/be/regions/${regionId}/inventories/download`,
  resetInventory: (kitchenId, inventoryId) =>
    `${API_URL}api/v1/be/kitchen/${kitchenId}/inventories/${inventoryId}/change`,
  resetRegionInventory: (regionId, inventoryId) =>
    `${API_URL}api/v1/be/regions/${regionId}/inventories/${inventoryId}/change`,
};

function getInventories() {
  return request(URL.getInventories, "get");
}

function getAInventory(inventoryId) {
  return request(URL.getAInventory(inventoryId), "get");
}

function deleteInventory(inventoryId, inventory) {
  return request(URL.deleteInventory(inventoryId), inventory, "delete");
}

function createInventory(inventory) {
  return request(URL.createInventory, inventory, "post");
}

function updateInventory(inventoryId, product) {
  return request(URL.updateInventory(inventoryId), product, "put");
}

// kitchen

function getLocationInventories(kitchenId) {
  return request(URL.getLocationInventories(kitchenId), "get");
}

function getALocationInventory(kitchenId, inventoryId) {
  return request(URL.getALocationInventory(kitchenId, inventoryId), "get");
}

function topUpInventory(kitchenId, inventoryId, inventory) {
  return request(URL.topUpInventory(kitchenId, inventoryId), inventory, "post");
}

function bulkUploadInventory(kitchenId, inventory) {
  return request(URL.bulkUploadInventory(kitchenId), inventory, "post");
}

// region
function getRegionInventories(regionId) {
  return request(URL.getRegionInventories(regionId), "get");
}

function getARegionInventory(regionId, inventoryId) {
  return request(URL.getARegionInventory(regionId, inventoryId), "get");
}

function topUpRegionInventory(regionId, inventoryId, inventory) {
  return request(
    URL.topUpRegionInventory(regionId, inventoryId),
    inventory,
    "post"
  );
}

function bulkUploadRegionInventory(regionId, inventory) {
  return request(URL.bulkUploadRegionInventory(regionId), inventory, "post");
}

function downloadRegionInventories(regionId) {
  return request(URL.downloadRegionInventories(regionId), {}, "get", "", {
    isDownload: true,
    fileName: `inventory_records_${new Date().toISOString()}.csv`,
    isCsv: true,
  });
}

function downloadInventories(kitchenId) {
  return request(URL.downloadInventories(kitchenId), {}, "get", "", {
    isDownload: true,
    fileName: `inventory_records_${new Date().toISOString()}.csv`,
    isCsv: true,
  });
}

function resetInventory(kitchenId, inventoryId, inventory) {
  return request(URL.resetInventory(kitchenId, inventoryId), inventory, "post");
}

function resetRegionInventory(regionId, inventoryId, inventory) {
  return request(
    URL.resetRegionInventory(regionId, inventoryId),
    inventory,
    "post"
  );
}

const inventoriesServices = {
  getInventories,
  getAInventory,
  updateInventory,
  createInventory,
  deleteInventory,
  getLocationInventories,
  getALocationInventory,
  topUpInventory,
  getARegionInventory,
  getRegionInventories,
  topUpRegionInventory,
  bulkUploadRegionInventory,
  bulkUploadInventory,
  downloadRegionInventories,
  downloadInventories,
  resetInventory,
  resetRegionInventory,
};

export default inventoriesServices;
