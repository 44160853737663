import { useCallback, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { setStocks } from "../../../../../../redux/actions/stock.action";
import stocksServices from "../../../../../../Services/stocks.services";

function useTopup(stockId, onClose, manual) {
  const initState = {
    stock: stockId,
    qty: false,
    isLoading: false,
  };

  const [state, dispatch] = useReducer(
    (productState, value) => ({ ...productState, ...value }),
    initState
  );
  const { kitchenId } = useParams();
  const { stocks } = useSelector((state) => state.stock);

  const reduxDispatch = useDispatch();
  // const myObjectString = localStorage.getItem("user");

  // const currentUser = JSON.parse(myObjectString);
  // const kitchenId = currentUser?.kitchen_id;

  const getStocks = useCallback(() => {
    stocksServices
      .getLocationStock(kitchenId)
      .then(({ data }) => {
        setStocks(data?.products)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
  }, [kitchenId, reduxDispatch]);

  useEffect(() => {
    getStocks();
  }, [getStocks])

  const handleSubmit = (event) => {
    event.preventDefault();
    if (state.qty !== null) {
    if(manual) {
      dispatch({ isLoading: true });
      stocksServices
      .resetStock(kitchenId, stockId, {
        stock: {
            quantity: state?.qty
        },
      })
      .then(() => {
        toast.success("Stock has been manually changed");
        dispatch({ isLoading: false });
        onClose();
        getStocks();
      })
      .catch((err) => {
        dispatch({ isLoading: false });
        toast.error(err.message);
      })
    } else {
      dispatch({ isLoading: true });
      stocksServices
      .topUpStock(kitchenId, stockId, {
        stock: {
            quantity: state?.qty
        },
      })
      .then(() => {
        toast.success("Stock has been topped up");
        dispatch({ isLoading: false });
        onClose();
        getStocks();
      })
      .catch((err) => {
        dispatch({ isLoading: false });
        toast.error(err.message);
      })
    } 
  }else {
     toast.error("Add quantity")
    }
  };

  return {
    handleSubmit,
    stocks,
    state,
    dispatch,
  };
}

export default useTopup;
