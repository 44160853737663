import React from "react";
import { cashFormater } from "../../../utils/helpers";
import AddItem from "../AddItem/AddItem";
import {
  NumberInput,
  SearchSelect,
  SelectInput,
} from "../FormComponents/Input";
import { TrashIcon } from "../RegularIcons";
import useInventory from "./hooks/useAddItem";
import styles from "./AddItemArray.module.scss";

function AddItemArray({ type, inventoryOptions, stockOptions, productOptions }) {
  const {
    options,
    valueItems,
    handleChange,
    handleQuantity,
    removeFnc,
    addFunction,
    handleProduct,
  } = useInventory(type, inventoryOptions, stockOptions, productOptions);

  return (
    <>
      {valueItems[type]?.map((item, i) => (
        <div className={styles["form-flex"]} key={i}>
          <div className={styles["form-flex-1"]}>
            {type === "product" ? (
              <SearchSelect
                name={`${type}_name`}
                label={`${type} name`}
                options={options[type]}
                value={[item]}
                labelField="title"
                searchBy="title"
                onChange={(e) => handleProduct(e, i)}
              />
            ) : (
              <SelectInput
                name={`${type}_name`}
                label={`${type} name`}
                options={options[type]}
                value={
                  type === "inventory" ? item.inventory_name : item?.stock_name
                }
                onChange={(e) => handleChange(e, i)}
              />
            )}
          </div>
          <div className={styles["form-flex-2"]}>
            <NumberInput
              name="quantity"
              label="Quantity"
              units={type === "product" ? "#" : item?.unit}
              value={item?.quantity}
              onChange={(e) => handleQuantity(e, i)}
            />
            {type === "product" && item?.title && (
              <div className={styles["price-span"]}>
                {`${item?.quantity || "--"}x ${item?.title || "--"} at ${item.currency}`}{" "}
                {cashFormater(item?.price)} :
                <span className={styles["total-price"]}>{` ${item.currency} ${cashFormater(
                  item?.quantity * item?.price
                )}`}</span>
              </div>
            )}
          </div>
          {valueItems[type]?.length > 1 ? (
            <div
              className={styles["form-flex-3"]}
              onClick={() => removeFnc[type](i)}
            >
              <span className={styles["delete-icon"]}>
                <TrashIcon />
              </span>
            </div>
          ) : (
            <div className={styles["form-flex-4"]}></div>
          )}
        </div>
      ))}

      <div className={styles["btn-div"]}>
        <AddItem name={type} bgColor="blue-bg" addFnc={addFunction[type]} />
      </div>
    </>
  );
}

export default AddItemArray;
