import { SET_APP_THEME, IS_LOADING, SET_OPEN_MODAL, SET_DELETE, SET_OPEN_ADD_MODAL, SET_OPEN_EDIT_MODAL } from '../actionTypes/appActionType';

export function setAppTheme(data) {
  return (dispatch) => {
    dispatch({ type: SET_APP_THEME, data });
  };
}

export function setIsLoading(data) {
  return (dispatch) => {
    dispatch({ type: IS_LOADING, data });
  };
}

export function setDelete(data) {
  return (dispatch) => {
    dispatch({ type: SET_DELETE, data });
  };
}

export function setOpenModal(data) {
  return (dispatch) => {
    dispatch({ type: SET_OPEN_MODAL, data });
  };
}

export function setOpenAddModal(data) {
  return (dispatch) => {
    dispatch({ type: SET_OPEN_ADD_MODAL, data });
  };
}

export function setOpenEditModal(data) {
  return (dispatch) => {
    dispatch({ type: SET_OPEN_EDIT_MODAL, data });
  };
}
