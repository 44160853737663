import React from "react";
import Modal from "../../../../UI/Modal/Modal";
import { ActionMenuIcon } from "../../../../UI/RegularIcons";
import Tables from "../../../../UI/Tables/Tables";
import useDTable from "./hooks/useDTable";
import styles from "./DTable.module.scss";

function DTable({ readyOrders, secondRowFcn, orderStatus }) {
  const { isLoading, handleEdit, onClick, selected, openModal, setOpenModal } =
    useDTable();

  const columns = [
    {
      name: 'Code',
      selector: (row) => row?.reference,
      sortable: true,
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      name: "Name",
      selector: (row) => `${row?.recipient?.full_name} (${row?.recipient?.email})`,
      sortable: true,
      width: "40%",
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      name: 'Kitchen',
      selector: (row) => (row?.kitchen_id ? row?.kitchen_id : "--"),
      sortable: true,
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      name: 'Rider',
      selector: (row) => (row?.rider ? row?.rider?.first_name : "--"),
      sortable: true,
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      name: 'Status',
      selector: (row) => row?.status,
      sortable: true,
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
    {
      selector: (row, i) => (
        <>
          <div
            className={styles["circle-icon"]}
            onClick={() => onClick(i)}
          >
            <ActionMenuIcon />
          </div>
          {openModal && i === selected && (
            <Modal
            style2
            name="Assign rider"
            openModal={openModal}
            onClose={() => setOpenModal(false)}
            assignFn={() => handleEdit(row?.id)}
            editFn={() => (row?.id)}
            nextStep="Automatically assign to rider"
            />
          )}
        </>
      ),table: true,
      width: "5%",
      style: {
        color: "#212b36",
        fontSize: "14px",
        fontWeight: "400",
      },
    },
  ];

  return (
    <div className={styles.divv}>
      <Tables
        data={readyOrders}
        columns={columns}
        pagination
        title="Manage Delivery"
        firstRow
        fetchingData={isLoading}
        secondRowFcn={secondRowFcn}
        secondRowData={orderStatus}
        secondRow
        type="manageDelivery"
        onRowClicked={() => {}}
      />
    </div>
  );
}

export default DTable;
