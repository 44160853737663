import { API_URL } from "../env";
import { request } from "./api";

const URL = {
  getCategories: `${API_URL}api/v1/be/categories`,
  getACategory: (categoryId) =>
    `${API_URL}api/v1/be/categories/${categoryId}`,
  createCategory: `${API_URL}api/v1/be/categories/new`,
  deleteCategory:(categoryId) => `${API_URL}api/v1/be/categories/${categoryId}`,
  updateCategory: (categoryId) =>
    `${API_URL}api/v1/be/categories/${categoryId}`,
};

function getCategories() {
  return request(URL.getCategories, "get")
}

function getACategory(categoryId) {
  return request(URL.getACategory(categoryId), "get")
}

function deleteCategory(categoryId, category) {
  return request(URL.deleteCategory(categoryId), category, "delete")
}

function createCategory(category) {
    return request(URL.createCategory, category, "post")
}

function updateCategory(categoryId, category) {
    return request(URL.updateCategory(categoryId), category, "put")  
}

const categoriesServices = {
  getCategories,
  getACategory,
  deleteCategory,
  createCategory,
  updateCategory,
}

export default categoriesServices;
