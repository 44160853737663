import {
  SET_STOCK,
  SET_STOCKS,
  SET_STOCK_ID,
  SET_STOCK_ITEMS,
} from "../actionTypes/stockActionType";

export function setStockItems(data) {
  return (dispatch) => {
    dispatch({ type: SET_STOCK_ITEMS, data });
  };
}

export function setStocks(data) {
  return (dispatch) => {
    dispatch({ type: SET_STOCKS, data });
  };
}

export function setStock(data) {
  return (dispatch) => {
    dispatch({ type: SET_STOCK, data });
  };
}

export function setStockId(data) {
  return (dispatch) => {
    dispatch({ type: SET_STOCK_ID, data });
  };
}
