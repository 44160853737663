import { useCallback, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { setIsLoading } from "../../../../redux/actions/appAction";
import { setInventories, setInventoryItems } from "../../../../redux/actions/inventory.action";
import { setStock } from "../../../../redux/actions/stock.action";
import { paths } from "../../../../Routes/path";
import inventoriesServices from "../../../../Services/inventories.services";
import stocksServices from "../../../../Services/stocks.services";

function useAddStock(edit) {
  const reduxDispatch = useDispatch();
  const { inventories, inventoryItems } = useSelector(
    (state) => state.inventory
  );
  const { stock } = useSelector((state) => state.stock);
  const initState = {
    name: "",
    code: "",
    alertLevel: "",
    expires: false,
    period: "",
    periodLength: "",
    state: "",
    inventoryState: [],
    units: "",
  };

  const { stockId } = useParams();
  const navigate = useNavigate();

  const myObjectString = localStorage.getItem("user");

  const currentUser = JSON.parse(myObjectString);
  const kitchenId = currentUser?.kitchen_id;
  const sideBarType = currentUser?.restricted_by_location;

  const getInventories = useCallback(() => {
    setIsLoading(true)(reduxDispatch);
    if (sideBarType) {
      inventoriesServices
      .getLocationInventories(kitchenId)
      .then(({ data }) => {
        setInventories(data?.products)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      })
      .finally(() => setIsLoading(false)(reduxDispatch));
    } else {
    inventoriesServices
      .getInventories()
      .then(({ data }) => {
        setInventories(data)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      })
      .finally(() => setIsLoading(false)(reduxDispatch));
    }
  }, [kitchenId, reduxDispatch, sideBarType]);

  const getAStock = useCallback(() => {
    if (sideBarType) {
       stocksServices
      .getALocationStock(kitchenId, stockId)
      .then(({ data }) => {
        setStock(data)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      });
    } else {
    stocksServices
      .getAStock(stockId)
      .then(({ data }) => {
        setStock(data?.stock)(reduxDispatch);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      })
    }
  }, [sideBarType, kitchenId, stockId, reduxDispatch]);

  useEffect(() => {
    getInventories();
  }, [ getInventories]);

  const [state, dispatch] = useReducer(
    (stockState, value) => ({ ...stockState, ...value }),
    initState
  );

  const quantity = state?.inventoryState?.map((item) => item.quantity);
  const selectedInventory = state?.inventoryState?.map(
    (item) => item.inventory_id
  );

  useEffect(() => {
    if (edit) {
      const inventoryArr = stock?.stock_inventory_items?.map((item, i) => {
        return {
          inventory_name: item?.inventory_id,
          quantity: item?.quantity,
          unit: item?.inventory.measure,
        };
      });
      setInventoryItems(inventoryArr)(reduxDispatch);
    }
  }, [edit, reduxDispatch, stock]);

  useEffect(() => {
    if (edit) getAStock()
  }, [edit, getAStock])

  useEffect(() => {
    if (edit) {
      dispatch({
        name: stock?.name,
        code: stock?.code,
        alertLevel: stock?.alert_level,
        expires: stock?.expires,
        state: stock?.state,
        units: stock?.measure,
        period: stock?.expiry_period,
        periodLength: stock?.expiry_count,
      });
    }
  }, [stock, edit, inventoryItems]);

  useEffect(() => {
    dispatch({
      inventoryState: inventoryItems?.map((item, i) => {
        return {
          inventory_id: +item.inventory_name,
          quantity: +item.quantity,
        };
      }),
    });
  }, [inventoryItems]);
  const handleSubmit = (event) => {
    event.preventDefault();
    if (state.name && state.state && quantity[0] && selectedInventory[0]) {
      if (!edit) {
        stocksServices
          .createStock({
            stock: {
              name: state?.name,
              code: state?.code,
              alert_level: state?.alertLevel,
              expires: state?.expires,
              expiry_period: state?.period,
              expiry_count: state?.periodLength,
              state: state?.state,
            },
            inventory: {
              inventories: state?.inventoryState,
            },
          })
          .then(() => {
            toast.success("Stock was succesfully created");
            navigate(paths.stock);
          })
          .catch((err) => toast.error(err.message))
      } else {
        stocksServices
          .updateStock(stockId, {
            stock: {
              name: state?.name,
              code: state?.code,
              alert_level: state?.alertLevel,
              expires: state?.expires,
              expiry_period: state?.period,
              expiry_count: state?.periodLength,
              state: state?.state,
            },
            inventory: {
              inventories: state?.inventoryState,
            },
          })
          .then(() => {
            toast.success("Stock was succesfully updated");
            navigate(paths.stock);
          })
          .catch((err) => toast.error(err.message))
      }
    } else {
      toast.error("Fields cannot be empty");
    }
  };
  return {
    state,
    handleSubmit,
    dispatch,
    inventories,
  };
}

export default useAddStock;
