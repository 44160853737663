import React from "react";
import MetaTags from "react-meta-tags";
import Grid from "../../UI/Grid/Grid";
import GridRow from "../../UI/Grid/GridRow";
import TopDisplay from "../../UI/TopDisplay/TopDisplay";
import DTable from "./component/DTable/DTable";
import useManageDelivery from "./hooks/useManageDelivery";
import styles from "./ManageDelivery.module.scss";

function ManageDelivery() {
  const { 
    readyOrders,
    handlePageChange,
    handlePerRowsChange,
    totalRows,
    secondRowFcn,
    orderStatus,
   } = useManageDelivery();
  return (
    <>
      <MetaTags>
        <title>Manage Delivery Page</title>
        <meta name="description" content="#" />
      </MetaTags>
      <div className={styles.main}>
        <TopDisplay
          title="Manage Delivery"
          firstText="Dashboard"
          secondText="Deliveries"
          thirdText="Manage Delivery"
          style2
        />
        <div className={styles.section2}>
          <Grid span="9">
            <GridRow span="9" customClass="section2">
              <DTable 
                readyOrders={readyOrders}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
                totalRows={totalRows}
                secondRowFcn={secondRowFcn}
                orderStatus={orderStatus}
               />
            </GridRow>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default ManageDelivery;
