import { useCallback, useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dashboardServices from "../../../../../../Services/dashboard.services";
import { setIsLoading } from "../../../../../../redux/actions/appAction";
import { toast } from "react-toastify";
import { setPayments } from "../../../../../../redux/actions/dashboardAction";

function useDTable() {
  const { payments } = useSelector((state) => state.dashboard);
  const isLoading = useSelector((state) => state.app.isLoading);

  const reduxDispatch = useDispatch();

  const initState = {
    totalPayment: null,
  };

  const [state, dispatch] = useReducer(
    (productState, value) => ({ ...productState, ...value }),
    initState
  );

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const getPayments = useCallback(
    (page, perPage) => {
      setIsLoading(true)(reduxDispatch);
      dashboardServices
        .getAllPayments(page, perPage)
        .then(({ data }) => {
          dispatch({ totalPayment: data.pagination.total });
          setPayments(data.data)(reduxDispatch);
          setTotalRows(data.pagination.total);
        })
        .catch((err) => {
          toast.error(err.message || "Unable to get");
        })
        .finally(() => setIsLoading(false)(reduxDispatch));
    },
    [reduxDispatch]
  );

  useEffect(() => {
    getPayments(1, perPage);
  }, [getPayments, perPage]);

  const handlePageChange = (page) => {
    getPayments(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setIsLoading(true)(reduxDispatch);
    dashboardServices
      .getAllPayments(page, newPerPage)
      .then(({ data }) => {
        setPayments(data.data)(reduxDispatch);
        setPerPage(newPerPage);
        setTotalRows(data.pagination.total);
      })
      .catch((err) => {
        toast.error(err.message || "Unable to get");
      })
      .finally(() => setIsLoading(false)(reduxDispatch));
  };

  console.log(payments)

  return {
    isLoading,
    payments,
    handlePageChange,
    handlePerRowsChange,
    totalRows
  };
}

export default useDTable;
