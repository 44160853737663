import { API_URL } from "../env";
import { request } from "./api";

const URL = {
  getDepartments: `${API_URL}api/v1/be/coy/departments`,
  getADepartment: (departmentId) => `${API_URL}api/v1/be/coy/departments/${departmentId}`,
  createDepartment: `${API_URL}api/v1/be/coy/departments/new`,
  deleteDepartment: (departmentId) => `${API_URL}api/v1/be/coy/departments/${departmentId}`,
  updateDepartment: (departmentId) => `${API_URL}api/v1/be/coy/departments/${departmentId}`,
};

function getDepartments() {
  return request(URL.getDepartments, "get");
}

function getADepartment(departmentId) {
  return request(URL.getADepartment(departmentId), "get");
}

function deleteDepartment(departmentId, department) {
  return request(URL.deleteDepartment(departmentId), department, "delte");
}

function createDepartment(staff) {
  return request(URL.createDepartment, staff, "post");
}

function updateDepartment(departmentId, staff) {
  return request(URL.updateDepartment(departmentId), staff, "put");
}

const departmentServices = {
  getDepartments,
  getADepartment,
  updateDepartment,
  createDepartment,
  deleteDepartment,
};

export default departmentServices;
