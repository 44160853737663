import { API_URL } from "../env";
import { request } from "./api";

const URL = {
  getKitchens: `${API_URL}api/v1/be/kitchen`,
  getFormData: `${API_URL}api/v1/be/kitchens/new/form-config`,
  getAkitchen: (kitchenId) => `${API_URL}api/v1/be/kitchen/${kitchenId}`,
  createKitchen: `${API_URL}api/v1/be/kitchen/new`,
  deleteKitchen: (kitchenId) => `${API_URL}api/v1/be/kitchen?kitchen_id=${kitchenId}`,
  updateKitchen: (kitchenId) => `${API_URL}api/v1/be/kitchen/${kitchenId}`,
};

function getKitchens() {
  return request(URL.getKitchens, "get");
}

function getFormData() {
  return request(URL.getFormData, "get");
}

function getAkitchen(kitchenId) {
  return request(URL.getAkitchen(kitchenId), "get");
}

function deleteKitchen(kitchenId, kitchen) {
  return request(URL.deleteKitchen(kitchenId), kitchen,  "delete");
}

function createKitchen(kitchen) {
  return request(URL.createKitchen, kitchen, "post");
}

function updateKitchen(kitchenId, kitchen) {
  return request(URL.updateKitchen(kitchenId), kitchen, "put");
}

const kitchenServices = {
  getKitchens,
  getAkitchen,
  updateKitchen,
  createKitchen,
  deleteKitchen,
  getFormData,
};

export default kitchenServices;
