import React, { useRef } from "react";
import { MetaTags } from "react-meta-tags";
import moment from "moment";
import Card from "../../UI/Card/Card";
import TopDisplay from "../../UI/TopDisplay/TopDisplay";
import styles from "./OrderDetails.module.scss";
import { titleCase } from "../../../utils/helpers";
import TopSide from "./components/TopSide/TopSide";
import Table from "./components/Table/Table";
import useOrderDetails from "./hooks/useOrderDetails";

function OrderDetails() {
  const {
    reference,
    order,
    userAddress,
    openModal,
    onClick,
    setOpenModal,
    openModal2,
    setOpenModal2,
    currentUserAbilities,
    refreshOrder,
    collectPayment,
    viewPayments,
    voucher,
  } = useOrderDetails();
  const componentRef = useRef();
  return (
    <>
      <MetaTags>
        <title>Order Details</title>
        <meta name="description" content="#" />
      </MetaTags>
      <div
        className={styles.main}
        onClick={() => {
          setOpenModal(false);
          setOpenModal2(false);
        }}
      >
        <TopDisplay
          title="Order Details"
          firstText="Dashboard"
          secondText="Order"
          thirdText={reference}
          style2
        />
        <Card classes={styles["form-6grid-main"]}>
          <div ref={componentRef}>
            <div>
              <TopSide
                state={order}
                reference={reference}
                sendPaymentLink={onClick}
                componentRef={componentRef}
                openModal={openModal}
                setOpenModal={setOpenModal}
                openModal2={openModal2}
                setOpenModal2={setOpenModal2}
                refreshOrder={refreshOrder}
                collectPayment={collectPayment}
                viewPayments={viewPayments}
              />
              <section className={styles["section-a-2"]}>
                <p className={styles["address-title"]}>Date Created</p>
                <p className={styles["details"]}>
                  {moment(order?.created_at).format("DD MMM YYYY")}
                </p>
              </section>
              {currentUserAbilities && (
                <section className={styles["section-two"]}>
                  <section className={styles["section-a"]}>
                    <section className={styles["section-a-1"]}>
                      <p className={styles["address-title"]}>
                        Customer Information
                      </p>
                      <p className={styles["recipient-name"]}>
                        {order?.recipient?.full_name && titleCase(order?.recipient?.full_name)}
                      </p>
                      <p className={styles["details"]}>
                        {userAddress?.length && userAddress?.length} known addresses
                      </p>
                      <div className={styles["details-two"]}>
                        <p className={styles["details"]}>Registered Phone: </p>
                        <p className={styles["user-email"]}>
                          {order?.recipient?.phone && order?.recipient?.phone}
                        </p>
                      </div>
                      <div className={styles["details-two"]}>
                        <p className={styles["details"]}>Email: </p>
                        <p className={styles["user-email"]}>
                          {order?.recipient?.email && order?.recipient?.email}
                        </p>
                      </div>
                    </section>
                  </section>
                  <section className={styles["section-an"]}>
                    <section className={styles["section-a-n"]}>
                      <p className={styles["address-title"]}>
                        Recipient/delivery Information
                      </p>
                      <p className={styles["recipient-name"]}>
                        {order?.recipient && titleCase(order?.recipient?.full_name)}
                      </p>
                      <p className={styles["details"]}>
                        {order?.address && `${order?.address?.house_number}, ${order?.address?.street}`}
                      </p>
                      <p
                        className={styles["details"]}
                      >{order?.address && `${order?.address?.area}, ${order?.available_region?.state}`}</p>
                      <div className={styles["details-two"]}>
                        <p className={styles["details"]}>Registered Phone: </p>
                        <p className={styles["user-email"]}>
                          {order?.recipient && order?.recipient?.phone}
                        </p>
                      </div>
                      <div className={styles["details-two"]}>
                        <p className={styles["details"]}>Email: </p>
                        <p className={styles["user-email"]}>
                          {order?.recipient && order?.recipient?.email}
                        </p>
                      </div>
                    </section>
                  </section>
                </section>
              )}
            </div>
            <div className={styles["table-section"]}>
              <Table
                orderItems={order?.order_items}
                totalItems={order}
                voucher={voucher}
                order={order}
              />
            </div>
          </div>
        </Card>
      </div>
    </>
  );
}

export default OrderDetails;
