import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
  setPopupComponent,
  showPopup,
} from "../../../../../../redux/actions/popUp.action";
import { paths } from "../../../../../../Routes/path";
import inventoriesServices from "../../../../../../Services/inventories.services";
import TopUp from "../../TopUp/TopUp";
import { getBase64 } from "../../../../../../utils/helpers";

function useDTable(onClickAction, currentUserAbilities) {
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);

  const isLoading = useSelector((state) => state.app.isLoading);
  const [selected, setSelected] = useState(null);
  const [selected2, setSelected2] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false)
  const reduxDispatch = useDispatch();

  const onClick = (value) => {
    setOpenModal(true);
    setSelected(value);
  };
  const onClick2 = (value) => {
    setOpenModal2(true);
    setSelected2(value);
  };
  const navigate = useNavigate();

  const handleEdit = (inventoryId) => {
    navigate(paths.editInventory.replace(":inventoryId", inventoryId));
  };

  const onDelete = (inventoryId) => {
    onClickAction(inventoryId);
  };

  const onClosePage = useCallback(() => {
    reduxDispatch(showPopup({ ispopupOpen: false }));
  }, [reduxDispatch]);

  const addInventory = (inventoryId) => {
    setOpenModal2(false);
    reduxDispatch(
      setPopupComponent(
        <TopUp
          onClose={onClosePage}
          inventoryId={inventoryId}
          onClosePage={onClosePage}
        />
      )
    );
    reduxDispatch(
      showPopup({ ispopupOpen: true, onClose: () => onClosePage() })
    );
  };

  const manualInventory = (inventoryId) => {
    setOpenModal2(false);
    reduxDispatch(
      setPopupComponent(
        <TopUp
          onClose={onClosePage}
          inventoryId={inventoryId}
          onClosePage={onClosePage}
          manual
        />
      )
    );
    reduxDispatch(
      showPopup({ ispopupOpen: true, onClose: () => onClosePage() })
    );
  };

  const update = currentUserAbilities?.update;
  const create = currentUserAbilities?.create;
  const show = currentUserAbilities?.show;

  const del = currentUserAbilities?.destroy;
  const myObjectString = localStorage.getItem("user");
  const currentUser = JSON.parse(myObjectString);
  const kitchenId = currentUser?.kitchen_id;
  const sideBarType = currentUser?.restricted_by_location;

  const onSelectImage = (e) => {
    let file = e.target.files[0];

    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        onBulkUpload(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onBulkUpload = useCallback(
    (e) => {
      inventoriesServices
        .bulkUploadInventory(kitchenId, {
          inventory: { inventory_quantities: e?.split(",")[1] },
        })
        .then((res) => {
          toast.success(res?.message);
        })
        .catch((err) => {
          // dispatch({ isLoading: false });
          toast.error(err.message);
        })
        .finally(() => navigate(paths.inventory));
    },
    [kitchenId, navigate]
  );

  const downloadInventories = () => {
    setIsDownloading(true)
    inventoriesServices
      .downloadInventories(kitchenId)
      .then(({ data }) => {
        setIsDownloading(false)
      })
      .catch((err) => {
        setIsDownloading(false)
        toast.error(err.message || "Unable to get");
      });
  };

  return {
    onDelete,
    handleEdit,
    onClick,
    onClick2,
    selected,
    selected2,
    openModal,
    setOpenModal,
    openModal2,
    setOpenModal2,
    isLoading,
    del,
    update,
    create,
    show,
    kitchenId,
    sideBarType,
    addInventory,
    manualInventory,
    onSelectImage,
    isDownloading,
    downloadInventories
  };
}

export default useDTable;
